import button from "../../../pages/assets/css/global.module.css";

interface ButtonProps {
  title: any;
  onClick?: () => any;
  className?: string;
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
  loader?: any;
  id?: any;
}

const PrimaryButton = ({
  title,
  onClick,
  type,
  id,
  disabled,
  loader,
  ...rest
}: ButtonProps) => {
  return (
    <button
      className={[button.btn, button['btn-primary'], button['btn-block']].join(' ')}
      type={type}
      id={id}
      onClick={onClick}
      disabled={disabled}
    >
      {title}
    </button>
  );
};

export default PrimaryButton;
