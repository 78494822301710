import { FormEvent, useEffect, useState } from "react";
import "../../../components/assets/css/dashboard.scoped.css";
import "../../../components/assets/css/style.scoped.css";
import "../../../components/assets/css/radio.scoped.css";
import { Input } from "../../../components/ui/Input/Input";
import { useInput } from "../../../validation/InputValidation";
import { toast } from 'react-toastify';
import { postData } from "../../../helpers/request";

export const CreateTag = ({
  onClose,
  onProceed,
  connection,
  setCreateConnection,
}: any) => {
  const [requestLoader, setRequestLoader] = useState(false);
  const tagInput = useInput("");
  const validateInput = (input: {
    value: string;
    setError: (value: boolean) => void;
  }) => {
    if (!input.value.trim()) {
      return input.setError(true);
    } else {
      input.setError(false);
    }
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    validateInput(tagInput);
    setRequestLoader(true);

    const payload = {
      name: tagInput.value,
    };

    const id = toast.loading("...Loading");
    const res: any = await postData("/tag", payload);

    if (res.isSuccess || res.isSucess) {
      setRequestLoader(false);
      toast.update(id, { render: res?.message, type: "success", isLoading: false, autoClose: 5000, draggablePercent: 60, closeOnClick: true });
      return onClose();
    }

    setRequestLoader(false);
    return toast.update(id, { render: res?.message, type: "error", isLoading: false, autoClose: 5000, draggablePercent: 60, closeOnClick: true  });
  };
  return (
    <>
      <div className="card bg-lighter bg-opacity-80 shadow-none mt-2">
        <div className="card-body contact-form">
          <div className="row g-4">
            <div className="col-12">
              <div className="form-group">
                <Input
                  label="Tag"
                  type="text"
                  name="tag"
                  required={true}
                  id="connectionTag"
                  {...tagInput}
                  placeholder="Enter tag"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ul className="row gx-4 mt-4">
        <li className="col-6">
          <button
            className="btn btn-outline-light w-100"
            data-bs-dismiss="modal"
            onClick={onClose}
          >
            Cancel
          </button>
        </li>
        <li className="col-6">
          <button
            className="btn btn-primary w-100"
            data-bs-dismiss="modal"
            onClick={handleSubmit}
            disabled={requestLoader}
          >
            Create tag
          </button>
        </li>
      </ul>
    </>
  );
};
