// import { Fragment } from "react";
import React from "react";
import "../../assets/css/dashboard.scoped.css";
import "../../assets/css/style.scoped.css";

interface ModalProps {
  title: any;
  handleClose?: () => void;
  handleAction?: () => void;
  onClick?: () => any;
  open: boolean;
  proceedText?: string;
  ModalComponent?: React.ReactNode | string;
  closeText?: string;
  hideClose?: boolean;
  buttonText?: string;
}

const ConnectionModal: React.FC<ModalProps> = ({
  title,
  onClick,
  handleClose,
  handleAction,
  ModalComponent,
  open,
  proceedText,
  hideClose,
  buttonText,
  ...rest
}) => {
  return (
    <React.Fragment>
      <div
        className={`modal ${open ? "fade show" : ""}`}
        id="successModal"
        tabIndex={-1}
        aria-modal="true"
        role="dialog"
        style={{ display: open ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body p-4">
              <button 
                className="media media-rg media-middle media-circle text-primary bg-primary bg-opacity-20 mb-3 cursor" 
                onClick={handleClose}
              >
                <em className="ri-close-circle-line"></em>
              </button>
              <h2 className="h1">{title}</h2>

              {ModalComponent}
            </div>
          </div>
        </div>
      </div>

      <div className={`modal-backdrop  ${open ? "fade show" : ""}`} onClick={handleClose} />
    </React.Fragment>
  );
};

export default ConnectionModal;
