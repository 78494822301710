import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import "../assets/css/dashboard.scoped.css";
// import "../assets/css/style.scoped.css";
// import "../assets/css/radio.scoped.css";
import Avatar from "../assets/img/avatar.png";
import Logo from "../assets/img/logo.png";
import Logo2 from "../assets/img/logo-2.png";
import useScript from "../../pages/hooks/useScript";
import { useCallback, useEffect, useRef, useState } from "react";
import SessionStorageService from "../../services/sessionStorage";
import { AuthContext, UserInfo } from "../../store/AuthContext";
import { getData, postData } from "../../helpers/request";
import ConnectionModal from "../ui/Modal/ConnectionModal";
import { Connection } from "../../views/Dashboard/Connections";
import { toast } from 'react-toastify';
import { UserProfile } from "../../views/Dashboard/ProfileSetup/ProfileShare";
import { AddConnection } from "../../views/Dashboard/Component/AddConnection";

function DashboardLayout() {
  const navigate = useNavigate();
  useScript("https://code.jquery.com/jquery-3.5.1.min.js", "html", false);
  useScript(
    "https://unpkg.com/isotope-layout@3/dist/isotope.pkgd.min.js",
    "html",
    false
  );
  // useScript("script.js", "html", true);
  // useScript("scripts2888.js", "html");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
  const [subMenu, setSubMenu] = useState(false);
  const [userHasSubscription, setUserSubscription] = useState(false);
  const [openConnectionModal, setConnectionModal] = useState(false);
  const paramValue = queryParams.get("id");
  const [connection, setCreateConnection] = useState<Connection>({
    card_id: "",
    is_private: false,
    is_favourite: 0,
    connection_tag: "",
    note: "",
  });
  const [requestLoader, setRequestLoader] = useState(false);
  const [userProfile, setUsrProfile] = useState<UserProfile | null>(null);
  const [profileUpdated, setProfileUpdate] = useState(false);
  const [userIsCompanyAdmin, setUserAsCompanyAdmin] = useState(false);
  const [adminNav, setAdminNav] = useState(false);
  const menuRef: any = useRef();

  useEffect(() => {
    const token = SessionStorageService.getItem("token");
    if (token) {
      const user = SessionStorageService.decodeToken();
      const userIsUpdate = SessionStorageService.getItem("is_profile_updated");

      // if (user?.is_profile_updated || userIsUpdate) {
      //   return setUserInfo(user);
      // }
      const isProfileUpdated = user.is_profile_updated === true || JSON.parse(userIsUpdate) === true;
      const isCompanyAdmin = user.is_company_admin === true;
      setUserAsCompanyAdmin(isCompanyAdmin);
      setProfileUpdate(isProfileUpdated);

      if (isProfileUpdated) {
        return setUserInfo(user);
      } else {
        setUserInfo(user);
        if (paramValue) return addQueryToUrl("/dashboard/card-setup");
        return navigate("/dashboard/card-setup");
      }
    }
    if (!token) {
      if (paramValue) return addQueryToUrl('/login');
      return navigate("/login");
    }
  }, []);

  useEffect(() => {
    if (userInfo) {
      getProfileStatus();
    }
  }, [userInfo]);

  const getProfile = useCallback(async () => {
    if (paramValue) {
      const res = await getData(`/card/sharedcard/${paramValue}`);
      if (res) {
        setUsrProfile({ ...res });
        setCreateConnection({ ...connection, card_id: paramValue });
      }
    }
  }, []);

  const toggleAdminNav = () => {
    setAdminNav(!adminNav);
  }

  useEffect(() => {
    getProfile();
  }, []);

  useEffect(() => {
    if (paramValue && profileUpdated) {
      if (userInfo?.profile_id !== userProfile?.profile_id) {
        setConnectionModal(true);
      }
    }
  }, [userInfo, userProfile, profileUpdated])

  const addQueryToUrl = (pathname: string) => {
    queryParams.set("id", paramValue as string);
    const newSearch = `${queryParams.toString()}`;

    return navigate({
      pathname: pathname,
      search: newSearch
    });
  };

  const logout = () => {
    SessionStorageService.clear();
    return navigate("/login");
  };

  const toggleConnectionModal = () => {
    if (openConnectionModal) return setConnectionModal(false);
    return setConnectionModal(true);
  }

  const handleProceedConnection = async () => {
    setRequestLoader(true);
    const id = toast.loading("...Loading");
    const res = await postData(`/cardconnection/create`, connection);

    if (res?.isSuccess || res?.isSucess) {
      setRequestLoader(false);
      toggleConnectionModal();
      navigate('/dashboard/index');
      return toast.update(id, { render: res?.message, type: "success", isLoading: false, autoClose: 5000, draggablePercent: 60, closeOnClick: true });
    }

    toast.update(id, { render: res?.message, type: "error", isLoading: false, autoClose: 5000, draggablePercent: 60, closeOnClick: true });
    return setRequestLoader(false);
  }

  const getProfileStatus = async () => {
    const res: any = await getData("/subscription/check/active");

    if (res?.isSuccess) {
      return setUserSubscription(res?.isSubscribed);
    }
    return setUserSubscription(res?.isSubscribed);
  }

  const showSubMenu = () => setSubMenu(!subMenu);

  const showMobileNav = () => menuRef.current?.classList.add('sidebar-active');

  const closeMobileNav = () => menuRef.current?.classList.remove('sidebar-active');

  return (
    <AuthContext.Provider value={userInfo}>
      <div className="body">
        <div className="nk-app-root " data-sidebar-collapse="lg">
          <div className="nk-main">
            <div className="nk-sidebar nk-sidebar-fixed" id="sidebar" ref={menuRef}>
              <div className="nk-sidebar-element nk-sidebar-head">
                <div className="nk-sidebar-brand">
                  <NavLink to="/dashboard/index" className="logo-link">
                    <div className="logo-wrap">
                      <img className="logo-img" src={Logo2} alt="app logo" />

                      <img
                        className="logo-img logo-icon"
                        src={Logo}
                        alt="app logo"
                      />
                    </div>
                  </NavLink>
                </div>
              </div>

              <div className="nk-sidebar-element nk-sidebar-body">
                <div
                  className="nk-sidebar-content h-100 simplebar-scrollable-y"
                  data-simplebar="init"
                >
                  <div className="simplebar-wrapper" style={{ margin: "0" }}>
                    <div className="simplebar-height-auto-observer-wrapper">
                      <div className="simplebar-height-auto-observer"></div>
                    </div>

                    <div className="simplebar-mask">
                      <div
                        className="simplebar-offset"
                        style={{ right: "0px", bottom: "0px" }}
                      >
                        <div
                          className="simplebar-content-wrapper"
                          tabIndex={0}
                          role="region"
                          aria-label="scrollable content"
                          style={{ height: "100%", overflow: "hidden scroll" }}
                        >
                          <div
                            className="simplebar-content"
                            style={{ padding: "0px" }}
                          >
                            <div className="nk-sidebar-menu">
                              <ul className="nk-menu">
                                <li
                                  className="nk-menu-item mb-4"
                                >
                                  <NavLink
                                    to="/dashboard/index"
                                    className="nk-menu-link"
                                  >
                                    <span className="nk-menu-icon">
                                      <em className="icon ri-dashboard-fill"></em>
                                    </span>
                                    <span className="nk-menu-text">
                                      Dashboard
                                    </span>
                                  </NavLink>
                                </li>

                                <li className="nk-menu-item mb-4">
                                  <NavLink
                                    to="/dashboard/cards"
                                    className="nk-menu-link"
                                  >
                                    <span className="nk-menu-icon">
                                      <em className="icon ri-profile-line"></em>
                                    </span>
                                    <span className="nk-menu-text">
                                      Cards{" "}
                                    </span>
                                  </NavLink>
                                </li>

                                <li className="nk-menu-item mb-4">
                                  <NavLink
                                    to="/dashboard/connections"
                                    className="nk-menu-link"
                                  >
                                    <span className="nk-menu-icon">
                                      <em className="icon ri-link"></em>
                                    </span>
                                    <span className="nk-menu-text">
                                      Connections{" "}
                                    </span>
                                  </NavLink>
                                </li>

                                {/* <li className="nk-menu-item mb-4">
                                  <NavLink
                                    to="/dashboard/favorites"
                                    className="nk-menu-link"
                                  >
                                    <span className="nk-menu-icon">
                                      <em className="icon ri-star-fill"></em>
                                    </span>
                                    <span className="nk-menu-text">
                                      Favourites{" "}
                                    </span>
                                  </NavLink>
                                </li> */}

                                {/* <li className="nk-menu-item mb-4">
                                  <NavLink
                                    to="/dashboard/cards"
                                    className="nk-menu-link"
                                  >
                                    <span className="nk-menu-icon">
                                      <em className="icon ri-profile-line"></em>
                                    </span>
                                    <span className=" nk-menu-text">
                                      View Cards{" "}
                                    </span>
                                  </NavLink>
                                </li> */}

                                {/* <li className="nk-menu-item has-sub">
                                  <a href="#" className="nk-menu-link nk-menu-toggle">
                                    <span className="nk-menu-icon">
                                      <em className="icon ri-login-box-line"></em>
                                    </span>
                                    <span className="nk-menu-text">Auth Pages</span>
                                  </a>
                                  <ul className="nk-menu-sub">
                                    <li className="nk-menu-item"><a href="login.html"
                                      className="nk-menu-link"><span className="nk-menu-text">Login</span></a></li>
                                    <li className="nk-menu-item"><a href="register.html"
                                      className="nk-menu-link"><span className="nk-menu-text">Register</span></a></li>
                                    <li className="nk-menu-item"><a href="forgot-password.html"
                                      className="nk-menu-link"><span className="nk-menu-text">Forgot
                                        Password</span></a></li>

                                    <li className="nk-menu-item"><a href="verify.html"
                                      className="nk-menu-link"><span className="nk-menu-text">Verify Email</span></a>
                                    </li>

                                  </ul>
                                </li> */}

                                {userIsCompanyAdmin && (
                                  <li className="nk-menu-item has-sub" onClick={toggleAdminNav}>
                                    <a href="#" className="nk-menu-link nk-menu-toggle">
                                      <span className="nk-menu-icon">
                                        <em className="icon ri-login-box-line"></em>
                                      </span>
                                      <span className="nk-menu-text">Administrator</span>
                                      <span className="end">
                                        <em className="ri-arrow-down-s-line"></em>
                                      </span>
                                    </a>

                                    {adminNav && (
                                      <ul className="nk-menu-sub remve--before" style={{ display: "block", boxSizing: "border-box" }}>
                                        <li className="nk-menu-item">
                                          <NavLink
                                            to="/dashboard/admin-dashboard"
                                            className="nk-menu-link"
                                          >
                                            <span className="nk-menu-icon">
                                              <em className="icon ri-dashboard-fill"></em>
                                            </span>
                                            <span className="nk-menu-text">
                                              Dashboard
                                            </span>
                                          </NavLink>
                                        </li>

                                        <li className="nk-menu-item">
                                          <NavLink
                                            to="/dashboard/admin-staff"
                                            className="nk-menu-link"
                                          >
                                            <span className="nk-menu-icon">
                                              <em className="icon ri-group-fill"></em>
                                            </span>
                                            <span className="nk-menu-text">
                                              Staff
                                            </span>
                                          </NavLink>
                                        </li>

                                        <li className="nk-menu-item">
                                          <NavLink
                                            to="/dashboard/company"
                                            className="nk-menu-link"
                                          >
                                            <span className="nk-menu-icon">
                                              <em className="icon ri-admin-fill"></em>
                                            </span>
                                            <span className="nk-menu-text">
                                              Company
                                            </span>
                                          </NavLink>
                                        </li>

                                        <li className="nk-menu-item">
                                          <NavLink
                                            to="/dashboard/company-cards"
                                            className="nk-menu-link"
                                          >
                                            <span className="nk-menu-icon">
                                              <em className="icon ri-profile-line"></em>
                                            </span>
                                            <span className="nk-menu-text">
                                              Company cards
                                            </span>
                                          </NavLink>
                                        </li>

                                        <li className="nk-menu-item">
                                          <NavLink
                                            to="/dashboard/admin-services"
                                            className="nk-menu-link"
                                          >
                                            <span className="nk-menu-icon">
                                              <em className="icon ri-shopping-bag-fill"></em>
                                            </span>
                                            <span className="nk-menu-text">
                                              Service
                                            </span>
                                          </NavLink>
                                        </li>
                                      </ul>
                                    )}
                                  </li>)}

                                <li className="nk-menu-item mb-4">
                                  <div
                                    onClick={showSubMenu}
                                    className="nk-menu-link"
                                    style={{ cursor: "pointer" }}
                                  >
                                    <span className="nk-menu-icon">
                                      <em className="icon ri-profile-fill"></em>
                                    </span>
                                    <span className="nk-menu-text">
                                      Profile
                                    </span>
                                    <span className="end">
                                      <em className="ri-arrow-down-s-line"></em>
                                    </span>
                                  </div>
                                  {subMenu && (
                                    <div className="nk-menu--dropdown nk-menu--dropdown__menu">
                                      {/* <NavLink
                                        to="/dashboard/profile"
                                        onClick={showSubMenu}
                                        className="nk-menu-link"
                                      >
                                        <span className="nk-menu-icon">
                                          <em className="icon ri-user-fill"></em>
                                        </span>
                                        <span className="nk-menu-text">
                                          Profile
                                        </span>
                                      </NavLink> */}
                                      <NavLink
                                        to="/dashboard/services"
                                        onClick={showSubMenu}
                                        className="nk-menu-link"
                                      >
                                        <span className="nk-menu-icon">
                                          <em className="icon ri-customer-service-fill"></em>
                                        </span>
                                        <span className="nk-menu-text">
                                          My services
                                        </span>
                                      </NavLink>
                                      <NavLink
                                        to="/dashboard/settings"
                                        onClick={showSubMenu}
                                        className="nk-menu-link"
                                      >
                                        <span className="nk-menu-icon">
                                          <em className="icon ri-settings-3-fill"></em>
                                        </span>
                                        <span className="nk-menu-text">
                                          Settings
                                        </span>
                                      </NavLink>
                                    </div>
                                  )}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="nk-sidebar-element nk-sidebar-footer">
                <div className="nk-sidebar-footer-extended pt-3">
                  <div className="border border-light rounded-3">
                    {!(userHasSubscription || userInfo?.is_subscribed) && (
                      <div className="px-3 py-2 bg-white border-bottom border-light rounded-top-3">
                        <div className="d-flex flex-wrap align-items-center justify-content-between">
                          <h6 className="lead-text">Free Plan</h6>

                          <NavLink
                            className="link link-primary"
                            to="/dashboard/upgrade-plan"
                          >
                            <em className="ri-flashlight-fill icon text-warning"></em>
                            <span>Upgrade</span>
                          </NavLink>
                        </div>

                        <div className="progress progress-md">
                          <div className="progress-bar" data-progress="25%"></div>
                        </div>
                      </div>
                    )}

                    <NavLink
                      className="d-flex px-3 py-2 bg-primary bg-opacity-10 rounded-bottom-3"
                      to="/dashboard/profile"
                    >
                      <div className="media-group">
                        <div className="media media-sm media-middle media-circle text-bg-primary">
                          {userProfile?.photograph ? (
                            <img src={userProfile?.photograph} alt="" />
                          ) :
                            (
                              <img src={Logo} alt="" />
                            )}
                        </div>

                        <div className="media-text">
                          <h6 className="fs-6 mb-0">{`${userInfo?.first_name} ${userInfo?.last_name}`}</h6>
                          <span className="text-light fs-7" style={{ wordBreak: "break-all" }}>
                            {userInfo?.email}
                          </span>
                        </div>

                        <em className="icon ri-arrow-right-s-line ms-auto ps-1"></em>
                      </div>
                    </NavLink>
                  </div>
                </div>

                <div className="nk-menu-item mb-2 mt-4">
                  <NavLink
                    to=""
                    className="nk-menu-link"
                    onClick={() => logout()}
                  >
                    <span className="nk-menu-icon">
                      <em className="icon ri-logout-box-r-line"></em>
                    </span>
                    <span className="nk-menu-text">
                      Logout{" "}
                    </span>
                  </NavLink>
                </div>
              </div>
            </div>

            <div className="nk-wrap">
              <div className="nk-header nk-header-fixed">
                <div className="container-fluid">
                  <div className="nk-header-wrap">
                    <div className="nk-header-logo ms-n1">
                      <div className="nk-sidebar-toggle me-5" onClick={showMobileNav} onBlur={closeMobileNav}>
                        <button className="btn btn-sm btn-zoom btn-icon sidebar-toggle d-sm-none">
                          <em className="icon ri-menu-line"></em>
                        </button>
                        <button className="btn btn-md btn-zoom btn-icon sidebar-toggle d-none d-sm-inline-flex">
                          <em className="icon ri-menu-line"></em>
                        </button>
                      </div>

                      <NavLink to="/dashboard" className="logo-link">
                        <div className="logo-wrap">
                          <img
                            className="logo-icon"
                            src={Logo}
                            alt="app logo"
                          />
                          <img
                            className="logo-icon"
                            src={Logo}
                            alt="app logo"
                          />
                        </div>
                      </NavLink>
                    </div>

                    <div className="nk-header-tools">
                      <ul className="nk-quick-nav ms-2">
                        <li className="dropdown d-inline-flex">
                          <a
                            data-bs-toggle="dropdown"
                            className="d-inline-flex"
                            href="#"
                          >
                            <div className="media media-md media-circle media-middle text-bg-primary">
                              {userProfile?.photograph ? (
                                <img src={userProfile?.photograph} alt="" />
                              ) :
                                (
                                  <img src={Logo} alt="" />
                                )}
                            </div>
                          </a>

                          <div className="dropdown-menu dropdown-menu-md rounded-3">
                            <div className="dropdown-content py-3">
                              <div className="border border-light rounded-3">
                                <div className="px-3 py-2 bg-white border-bottom border-light rounded-top-3">
                                  <div className="d-flex flex-wrap align-items-center justify-content-between">
                                    <h6 className="lead-text">Free Plan</h6>
                                    <NavLink
                                      className="link link-primary"
                                      to="/dashboard/upgrade-plan"
                                    >
                                      <em className="ri-flashlight-fill icon text-warning"></em>
                                      <span>Upgrade</span>
                                    </NavLink>
                                  </div>

                                  <div className="progress progress-md">
                                    <div
                                      className="progress-bar"
                                      data-progress="25%"
                                    ></div>
                                  </div>
                                </div>

                                <a
                                  className="d-flex px-3 py-2 bg-primary bg-opacity-10 rounded-bottom-3"
                                  href="profile.html"
                                >
                                  <div className="media-group">
                                    <div className="media media-sm media-middle media-circle text-bg-primary">
                                      <img src={Avatar} alt="" />
                                    </div>

                                    <div className="media-text">
                                      <h6 className="fs-6 mb-0">
                                        Shawn Mahbub
                                      </h6>
                                      <span className="text-light fs-7">
                                        shawn@websbd.com
                                      </span>
                                    </div>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="nk-content">
                <div className="container-xl">
                  <div className="nk-content-inner">
                    <div className="nk-content-body">
                      <div className="nk-page-head">
                        <div className="nk-block-head-between">
                          <div className="nk-block-head-content">
                            <h2 className="display-6">
                              Welcome {userInfo?.first_name}!
                            </h2>
                          </div>
                        </div>
                      </div>

                      <div className="nk-block">
                        <Outlet />

                        {openConnectionModal && (
                          <ConnectionModal
                            title={`Do you want to add ${userProfile?.first_name}?`}
                            handleClose={toggleConnectionModal}
                            ModalComponent={
                              <AddConnection
                                connection={connection}
                                setCreateConnection={setCreateConnection}
                                onProceed={() => {
                                  handleProceedConnection();
                                }}
                                requestLoader={requestLoader}
                                onClose={toggleConnectionModal}
                              />
                            }
                            open={openConnectionModal}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AuthContext.Provider>
  );
}

export default DashboardLayout;