// import { Fragment } from "react";
import React from "react";
import "../../assets/css/dashboard.scoped.css";
import "../../assets/css/style.scoped.css";
import PrimaryButton from "../Button/PrimaryButton";

interface ModalProps {
  title: any;
  handleClose?: () => void;
  handleAction?: () => void;
  onClick?: () => any;
  open: boolean;
  proceedText?: string;
  ModalComponent?: React.ReactNode | string;
  closeText?: string;
  hideClose?: boolean;
  buttonText?: string;
  showOtherButton?: boolean;
  otherButtonText?: string;
  requestLoader?: boolean;
}

const SuccessModal: React.FC<ModalProps> = ({
  title,
  onClick,
  handleClose,
  handleAction,
  ModalComponent,
  open,
  proceedText,
  hideClose,
  buttonText,
  showOtherButton,
  otherButtonText,
  requestLoader,
  ...rest
}) => {
  return (
    <React.Fragment>
      <div
        className={`modal ${open ? "fade show" : ""}`}
        id="successModal"
        tabIndex={-1}
        aria-modal="true"
        role="dialog"
        style={{ display: open ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body p-5 text-center">
              <button className="media media-huge media-middle media-circle text-primary bg-primary bg-opacity-20 mb-3" onClick={handleClose}>
                <em className="ri-close-circle-line" />
              </button>
              <h2 className="h1">{title}</h2>
              <p>{proceedText}</p>
              <div className="mt-4">
                <ul className="row gx-4 mt-4">
                  {showOtherButton && (
                    <li className="col-6">
                      <button
                        className="btn btn-outline-light w-100"
                        data-bs-dismiss="modal"
                        onClick={handleClose}
                        title={otherButtonText}
                      >
                        Cancel
                      </button>
                    </li>
                  )}
                  <li className={showOtherButton ? "col-6" : "col-12"}>
                    <PrimaryButton
                      type="button"
                      title={buttonText}
                      onClick={handleAction}
                      disabled={requestLoader}
                      className="btn btn-primary w-100"
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={`modal-backdrop  ${open ? "fade show" : ""}`} />
    </React.Fragment>
  );
};

export default SuccessModal;
