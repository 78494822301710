import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Logo from "../assets/img/logo-2.png";
import styles from "../assets/css/global.module.css";
import { useInput } from "../../validation/InputValidation";
import PrimaryButton from "../../components/ui/Button/PrimaryButton";
import { FormEvent, useEffect, useState } from "react";
import { postData } from "../../helpers/request";
import SuccessModal from "../../components/ui/Modal/SuccessModal";
import { toast } from "react-toastify";
import CustomOtpInput from "../../components/ui/Input/CustomOTPInput";

type VerInfo = {
  id: string;
  code: string;
};

function Verification() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [verProceed, setVerProcced] = useState(false);
  const [verificationInfo, setVerInfo] = useState<VerInfo>({
    id: "",
    code: "",
  });
  const [regError, setRegError] = useState("");
  const [requestLoader, setRequestLoader] = useState(false);
  let { id } = useParams();
  const otpInput = useInput("");
  const [openSuccessModal, setSuccessModal] = useState(false);
  const [proceedText, setProceedText] = useState("");
  const [buttonText, setButtonText] = useState("Continue");
  const [value, setValue] = useState("");

  const initialCountdown = 300; // Initial countdown value
  const [countdown, setCountdown] = useState(initialCountdown);
  const [isRunning, setIsRunning] = useState(true);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    setVerInfo({
      id: id as string,
      code: otpInput.value,
    });

    setVerProcced(true);
  };

  useEffect(() => {
    let timer: any;

    if (isRunning) {
      timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    }

    if (countdown === 0) {
      setIsRunning(false);
    }

    return () => clearInterval(timer);
  }, [countdown, isRunning]);

  const minutes = Math.floor(countdown / 60);
  const seconds = countdown % 60;

  const navigate = useNavigate();
  const paramValue = queryParams.get("id");

  const verifyContact = async () => {
    setRequestLoader(true);
    const reqBody = {
      id: id,
      code: value,
    };
    const res: any = await postData("/profile/activate", reqBody);

    if (res.isSuccess) {
      setRequestLoader(false);
      setVerProcced(false);
      setProceedText(res?.message);
      return setSuccessModal(true);
    }
    if (!res.isSuccess) {
      setRequestLoader(false);
      setVerProcced(false);
      setRegError(res.message);
    }
  };

  const handleChange = (e: string) => {
    setValue(e);
  };

  const handleLogin = () => {
    setSuccessModal(false);
    const newSearch = `${queryParams.toString()}`;
    if (paramValue) {
      return navigate({
        pathname: "/login",
        search: newSearch,
      });
    }
    return navigate(`/login`);
  };

  const handleResendOTP = async () => {
    setRequestLoader(true);

    const reqBody = {
      profile_id: id,
    };

    const res: any = await postData("/profile/resendcode", reqBody);

    if (res.isSuccess || res.isSucess) {
      toast.success(res?.message, { closeOnClick: true });
      setCountdown(300);
      setIsRunning(!isRunning);
      setRequestLoader(false);
      return;
    }
    toast.error(res?.message, { closeOnClick: true });
    return setRequestLoader(false);
  };

  useEffect(() => {
    if (verProceed) {
      verifyContact();
    }
  }, [verProceed]);

  return (
    <div className={styles["nk-app-root"]}>
      <main className={styles["nk-pages"]}>
        <div
          className={[
            styles["min-vh-100"],
            styles["d-flex"],
            styles["flex-column"],
            styles["has-mask"],
          ].join(" ")}
        >
          <div
            className={[
              styles["nk-mask"],
              styles["bg-pattern-dot"],
              styles["bg-blend-around"],
            ].join(" ")}
          />

          <div className={styles["my-auto"]}>
            <div className={styles.container}>
              <div
                className={[
                  styles["text-center"],
                  styles["mt-6"],
                  styles["mb-5"],
                ].join(" ")}
              >
                <Link to="/" className={styles["logo-link"]}>
                  <div className={styles["logo-wrap"]}>
                    <img
                      className={styles["logo-img"]}
                      width="180"
                      src={Logo}
                      alt=""
                    />
                  </div>
                </Link>
              </div>

              <div
                className={[
                  styles["row"],
                  styles["g-gs"],
                  styles["justify-content-center"],
                ].join(" ")}
              >
                <div
                  className={[
                    styles["col-md-7"],
                    styles["col-lg-6"],
                    styles["col-xl-5"],
                  ].join(" ")}
                >
                  <div
                    className={[
                      styles["card"],
                      styles["border-0"],
                      styles["shadow-sm"],
                      styles["rounded-4"],
                    ].join(" ")}
                  >
                    <div className={styles["card-body"]}>
                      <h4>Confirm Email Address</h4>
                      <p>
                        Enter the code sent to your email to verify this GitCard
                        account
                      </p>

                      <form onSubmit={handleSubmit}>
                        <div
                          className={[styles["row"], styles["g-4"]].join(" ")}
                        >
                          <div className={styles["col-12"]}>
                            {/* <Input
                              label="Confirmation code"
                              type="number"
                              name="emailorusername"
                              id="code"
                              placeholder="Enter Confirmation Code"
                              required={true}
                              {...otpInput}
                            /> */}

                            <CustomOtpInput
                              valueLength={6}
                              value={value}
                              onChange={handleChange}
                            />
                          </div>

                          <div className={styles["col-12"]}>
                            <div className={styles["form-group"]}>
                              {regError && (
                                <p className={styles["form-error-message"]}>
                                  {regError}
                                </p>
                              )}
                            </div>
                          </div>

                          <div className={styles["col-12"]}>
                            <div
                              className={[
                                styles["otp--input"],
                                styles["flex-wrap"],
                                styles["justify-content-between"],
                                styles["align-items-center"],
                                styles["has-gap"],
                                styles["g-3"],
                              ].join(" ")}
                            >
                              <div className={styles["form-group"]}>
                                <PrimaryButton
                                  type="submit"
                                  title="Submit"
                                  id="submit-btn"
                                  disabled={otpInput.error || requestLoader}
                                />
                              </div>
                            </div>
                          </div>

                          <div className={styles["col-12"]}>
                            <p>
                              {countdown !== 0 && (
                                <span>
                                  O.T.P will expire in {minutes}:
                                  {seconds < 10 ? "0" : ""}
                                  {seconds}{" "}
                                </span>
                              )}
                              {countdown === 0 && (
                                <span
                                  onClick={handleResendOTP}
                                  style={{
                                    color: "#3f3eed",
                                    fontWeight: "bold",
                                    cursor: "pointer",
                                  }}
                                  className={styles['font-bold']}
                                >
                                  Resend OTP
                                </span>
                              )}
                            </p>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>

              {openSuccessModal && (
                <SuccessModal
                  title={"Account created successfully!"}
                  handleAction={handleLogin}
                  open={openSuccessModal}
                  showOtherButton={false}
                  proceedText={proceedText}
                  buttonText={buttonText}
                />
              )}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Verification;
