import React from "react";
import dashboard from "../../../components/assets/css/dashboard.module.css";

type Allowed = string | number;

type BaseProps<Value> = {
  value: Value;
  label: string;
  id: string;
  onChange: (newValue: Value) => void;
  options: readonly Value[];
  mapOptionToLabel?: (option: Value) => Allowed;
  mapOptionToValue?: (option: Value) => Allowed;
  defaultOptionIsSelected?: string;
};

// mappers required only in certain cirumstances
// we could get fancier here and also not require if `Value` has `value`/`label` properties
type Props<Value> = Value extends Allowed
  ? BaseProps<Value>
  : Required<BaseProps<Value>>;

const isAllowed = (v: any): v is Allowed =>
  typeof v === "string" || typeof v === "number";

function CustomSelect<Value>({
  value,
  label,
  id,
  onChange,
  options,
  mapOptionToLabel,
  mapOptionToValue,
  defaultOptionIsSelected
}: Props<Value>) {
  const toLabel = (option: Value): Allowed => {
    if (mapOptionToLabel) {
      return mapOptionToLabel(option);
    }
    // if our props are provided correctly, this should never be false
    return isAllowed(option) ? option : String(option);
  };

  const toValue = (option: Value): Allowed => {
    if (mapOptionToValue) {
      return mapOptionToValue(option);
    }
    return isAllowed(option) ? option : String(option);
  };

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    onChange(options[e.target.selectedIndex]);
  };

  return (
    <>
      <label
        htmlFor={id}
        className={dashboard['form-control-label']}
      >
        {label}
      </label>
      <select 
        className={dashboard['form-control']}
        id={id} 
        value={toValue(value)} 
        onChange={handleChange}
      >
        {options.map((value) => (
          <option value={toValue(value)} key={toValue(value)}>
            {toLabel(value)}
          </option>
        ))}
      </select>
    </>
  );
}

export default CustomSelect;