// import { Fragment } from "react";
import React from "react";
import "../../assets/css/dashboard.scoped.css";
import "../../assets/css/style.scoped.css";
import { Input } from "../Input/Input";

interface ModalProps {
  title?: any;
  handleClose?: () => void;
  handleAction?: () => any;
  onClick?: () => any;
  open: boolean;
  proceedText?: string;
  ModalComponent?: React.ReactNode | string;
  closeText?: string;
  hideClose?: boolean;
  buttonText?: string;
  showOtherButton?: boolean;
  otherButtonText?: string;
  requestLoader?: boolean;
  emailRequest?: boolean;
  id: string;
  setEmail: any;
  emailErrorMessage?: string;
  emailError?: boolean;
}

const ShareProfileWithEmail: React.FC<ModalProps> = ({
  title,
  onClick,
  handleClose,
  handleAction,
  ModalComponent,
  open,
  proceedText,
  hideClose,
  buttonText,
  showOtherButton,
  otherButtonText,
  requestLoader,
  id,
  emailRequest,
  setEmail,
  emailErrorMessage,
  emailError,
  ...rest
}) => {

  return (
    <React.Fragment>
      <div
        className={`modal ${open ? "fade show" : ""}`}
        id="successModal"
        tabIndex={-1}
        aria-modal="true"
        role="dialog"
        style={{ display: open ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Send card</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}></button>
            </div>
            <div className="modal-body">
              <p>Email your card to</p>

              <form>
                <Input
                  label="Email *"
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email"
                  required={true}
                  value=""
                  error={emailError}
                  errorMessage={emailErrorMessage}
                  onChange={(e: any) => { setEmail(e.target?.value) }}
                />

                <button
                  data-bs-toggle="modal"
                  data-bs-target="#successModal"
                  type="button"
                  className="btn btn-primary"
                  style={{ marginTop: "30px" }}
                  disabled={emailRequest}
                  onClick={handleAction}
                >
                  Send
                </button>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-sm btn-secondary"
                data-bs-dismiss="modal"
                onClick={handleClose}
              >Close</button>
            </div>
          </div>
        </div>
      </div>

      <div className={`modal-backdrop  ${open ? "fade show" : ""}`} />
    </React.Fragment>
  );
};

export default ShareProfileWithEmail;
