// import { Fragment } from "react";
import React from "react";
import "../../assets/css/dashboard.scoped.css";
import "../../assets/css/style.scoped.css";
import QRCode from "react-qr-code";

interface ModalProps {
  title?: any;
  handleClose?: () => void;
  handleAction?: () => any;
  onClick?: () => any;
  open: boolean;
  proceedText?: string;
  ModalComponent?: React.ReactNode | string;
  closeText?: string;
  hideClose?: boolean;
  buttonText?: string;
  showOtherButton?: boolean;
  otherButtonText?: string;
  requestLoader?: boolean;
  qrcode_url: string;
}

const ScanCodeModal: React.FC<ModalProps> = ({
  title,
  onClick,
  handleClose,
  handleAction,
  ModalComponent,
  open,
  proceedText,
  hideClose,
  buttonText,
  showOtherButton,
  otherButtonText,
  requestLoader,
  qrcode_url,
  ...rest
}) => {

  return (
    <React.Fragment>
      <div
        className={`modal ${open ? "fade show" : ""}`}
        id="successModal"
        tabIndex={-1}
        aria-modal="true"
        role="dialog"
        style={{ display: open ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Scan Profile</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}></button>
            </div>
            <div className="modal-body text-center m-auto">
              {/* <img src={BarCode} alt="scan profile barcode" /> */}
              <div style={{ height: "auto", margin: "0 auto", maxWidth: 125, width: "100%" }}>
                <QRCode
                  size={256}
                  style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                  value={qrcode_url}
                  viewBox={`0 0 256 256`}
                />
              </div>
              <p>Share your profile with others</p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-sm btn-secondary"
                data-bs-dismiss="modal"
                onClick={handleClose}
              >Close</button>
              <button
                data-bs-toggle="modal"
                data-bs-target="#successModal"
                type="button"
                className="btn btn-sm btn-primary"
                onClick={handleAction}
              >
                Share Profile
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className={`modal-backdrop  ${open ? "fade show" : ""}`} />
    </React.Fragment>
  );
};

export default ScanCodeModal;
