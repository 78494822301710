import { createContext } from "react";
import { Templates } from "../views/Dashboard/Component/CardTemplateDesigns";

export type UserInfo = {
  note?: string;
  first_name: string;
  last_name: string;
  other_name?: string;
  password?: string;
  Phone_number?: string;
  profile_id?: string;
  is_profile_updated?: boolean;
  prefix?: string;
  suffix?: string;
  email: string;
  tag_name?: string;
  tag_id?: string;
  date_created?: string;
  id?: string;
  photograph?: string;
  is_favourite?: number;
  business_name?: string;
  about_me?: string;
  designation?: string;
  city?: string;
  state?: string;
  country?: string;
  is_subscribed?: boolean;
  roles?: Array<String>;
  company_id?: string;
  address?: string;
  website?: string;
  whatsapp_phone_number?: string;
  facebook?: string;
  twitter?: string;
  linkedin?: string;
  instagram?: string;
  youtube?: string;
  tiktok?: string;
  step?: number;
  is_default_card?: boolean;
};

export interface UserCard {
  last_name: string;
  first_name: string;
  other_name: string;
  prefix: string;
  suffix: string;
  business_name: string;
  designation: string;
  about_me: string;
  phone_number: string;
  photograph: string;
  address: string;
  address2: string;
  city: string;
  state: string;
  country: string;
  website: string;
  whatsapp_country_code: string;
  whatsapp_phone_number: string;
  facebook: string;
  twitter: string;
  linkedin: string;
  instagram: string;
  youtube: string;
  tiktok: string;
  professional_field_id: string;
  image?: string;
  email?: string;
  is_default_card?: boolean;
  private_mode?: boolean;
  template?: Templates;
  template_id?: string;
  profile_id?: string;
}

export const AuthContext = createContext<UserInfo | null>(null);
