import "../../../../components/assets/css/dashboard.scoped.css";
import "../../../../components/assets/css/style.scoped.css";
import Avatar from "../../../../components/assets/img/logo_2.png";
import { FormEvent, useCallback, useEffect, useState } from "react";
import { getData, postData } from "../../../../helpers/request";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import { toast } from "react-toastify";
import { UserCard } from "../../../../store/AuthContext";
import useScript from "../../../../pages/hooks/useScript";
import { FullUserCard } from "../../../Dashboard/Cards";
import { CreatePersonalDetails } from "../../../Dashboard/Component/CreatePersonalDetails";
import CreateSocialDetails from "../../../Dashboard/Component/CreateSocialDetails";
import { AdminCardTemplateDesign } from "./AdminCardTemplateDesign";

function CreateStaffCard() {
  useScript("https://res.cloudinary.com/dovtransd/raw/upload/v1694620004/fx5xdbukdszs8vld93ar.js", "html", false);
  const { id } = useParams();
  const [cardProfile, setCardProfile] = useState<FullUserCard>({} as FullUserCard);
  const [requestLoader, setPageLoader] = useState(false);
  const [pageLoader, setRequestLoader] = useState(false);
  let [stepper, setStepper] = useState(1);
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState<UserCard>({
    last_name: "",
    first_name: "",
    other_name: "",
    prefix: "",
    suffix: "",
    business_name: "",
    designation: "",
    about_me: "",
    phone_number: "",
    photograph: "",
    address: "",
    address2: "",
    city: "",
    state: "",
    country: "",
    website: "",
    whatsapp_country_code: "",
    whatsapp_phone_number: "",
    facebook: "",
    twitter: "",
    linkedin: "",
    instagram: "",
    youtube: "",
    tiktok: "",
    professional_field_id: "",
    is_default_card: false,
    profile_id: id?.toString(),
  });

  // useEffect(() => {
  //   getUserTemplate();
  // }, []);

  const proceedToNextStep = () => {
    setStepper(++stepper);
  }

  const onHandle = async (e: FormEvent) => {
    e.preventDefault();
    setRequestLoader(true);
    const tid = toast.loading("...Loading");
    const payload = { ...userInfo, template_id: cardProfile?.template_id, profile_id: id?.toString() }
    const res = await postData('companycard/createcard', payload);

    if (res?.isSuccess || res?.isSucess) {
      setRequestLoader(false);
      toast.update(tid, { render: res?.message, type: "success", isLoading: false, autoClose: 5000, draggablePercent: 60, closeOnClick: true });
      return navigate(`/dashboard/staff/${id?.toString()}`);
    }
    setRequestLoader(false);
    return toast.update(tid, { render: res?.message, type: "error", isLoading: false, autoClose: 5000, draggablePercent: 60, closeOnClick: true });
  }

  useEffect(() => {
    getProfile();
  }, []);

  const getProfile = useCallback(async () => {
    setPageLoader(true);
    const res = await getData(`/companystaff/profile?profile_id=${id?.toString()}`);
    if (res?.isSuccess) {
      setUserInfo({
        last_name: res?.data?.profile?.last_name,
        first_name: res?.data?.profile?.first_name,
        other_name: res?.data?.profile?.other_name,
        email: res?.data?.profile?.email,
        prefix: res?.data?.profile?.prefix,
        suffix: res?.data?.profile?.suffix,
        business_name: res?.data?.profile?.business_name,
        designation: res?.data?.profile?.designation,
        about_me: res?.data?.profile?.about_me,
        phone_number: res?.data?.profile?.Phone_number,
        photograph: res?.data?.profile?.photograph,
        address: res?.data?.profile?.address,
        address2: res?.data?.profile?.address2,
        city: res?.data?.profile?.city,
        state: res?.data?.profile?.state,
        country: res?.data?.profile?.country,
        website: res?.data?.profile?.website,
        whatsapp_country_code: res?.data?.profile?.whatsapp_country_code,
        whatsapp_phone_number: res?.data?.profile?.whatsapp_phone_number,
        facebook: res?.data?.profile?.facebook,
        twitter: res?.data?.profile?.twitter,
        linkedin: res?.data?.profile?.linkedin,
        instagram: res?.data?.profile?.instagram,
        youtube: res?.data?.profile?.youtube,
        tiktok: res?.data?.profile?.tiktok,
        professional_field_id: res?.data?.professional_field_id,
        is_default_card: false,
        template_id: "",
      });
      setPageLoader(false);
    }
  }, []);

  return (
    <>
      <div>
        <div className="row">
          <div className="col-xxl-3 col-xl-3">
            <div className="bostami-parsonal-info-area shadow">
              <div className="bostami-parsonal-info-wrap">

                <section className="container text-center m-auto position-relative">
                  {/* <form> */}
                  <div
                    role="presentation"
                    tabIndex={0}
                    className="dropzone"
                    style={{ cursor: "pointer" }}
                  >
                    <input
                      className="b-none"
                      accept="image/*"
                      multiple
                      type="file"
                      tabIndex={-1}
                      style={{ display: "none" }}
                    />
                    <div className="upload">
                      {" "}
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 24 24"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M13 19v-4h3l-4-5-4 5h3v4z" />
                        <path d="M7 19h2v-2H7c-1.654 0-3-1.346-3-3 0-1.404 1.199-2.756 2.673-3.015l.581-.102.192-.558C8.149 8.274 9.895 7 12 7c2.757 0 5 2.243 5 5v1h1c1.103 0 2 .897 2 2s-.897 2-2 2h-3v2h3c2.206 0 4-1.794 4-4a4.01 4.01 0 0 0-3.056-3.888C18.507 7.67 15.56 5 12 5 9.244 5 6.85 6.611 5.757 9.15 3.609 9.792 2 11.82 2 14c0 2.757 2.243 5 5 5z" />
                      </svg>{" "}
                    </div>
                  </div>
                  <div className="mt-n4 mt-lg-n6 mb-lg-0 text-center m-auto">
                    {userInfo?.photograph ? (
                      <img
                        src={userInfo?.photograph}
                        alt="placeholder"
                        className="upload_image img-fluid border border-2 border-white"
                        style={{
                          width: "108px",
                          height: "108px",
                          objectFit: "cover"
                        }}
                      />
                    ) : (
                      <img
                        src={Avatar}
                        alt="placeholder"
                        className="upload_image img-fluid border border-2 border-white"
                      />
                    )}
                  </div>
                  {/* </form> */}
                </section>

                <h4 className="bostami-parsonal-info-name">
                  <NavLink to="#">
                    {userInfo?.prefix && (
                      <span>
                        {userInfo?.prefix}
                      </span>
                    )}
                    {" "}
                    {pageLoader ? <Skeleton /> : `${userInfo?.first_name} ${userInfo?.other_name !== null ? userInfo?.other_name : ''} ${userInfo?.last_name}`}{" "}
                    {" "}
                    {userInfo?.suffix && (
                      <span>
                        {userInfo?.suffix}
                      </span>
                    )}
                    {/* <span style={{ color: pageLoader ? "" : "#1773EA" }}>
                      {pageLoader ? <Skeleton /> : (<i className="ri-verified-badge-fill"></i>)}
                    </span>{" "} */}
                  </NavLink>
                </h4>

                <h4 className="mt-0 pt-0">{pageLoader ? <Skeleton /> : userInfo?.business_name}</h4>

                <span className="bostami-parsonal-info-bio mb-15">
                  {pageLoader ? <Skeleton /> : userInfo?.designation}
                </span>

                <ul className="bostami-parsonal-info-social-link mb-30">
                  {pageLoader ? <Skeleton /> : (
                    <li>
                      {userInfo?.facebook && (
                        <a style={{ color: "#1773EA" }} href={userInfo?.facebook} target="_blank" rel="noreferrer">
                          <i className="fa-brands ri-facebook-fill"></i>
                        </a>
                      )}
                    </li>
                  )}
                  {pageLoader ? <Skeleton /> : (
                    <li>
                      {userInfo?.twitter && (
                        <a style={{ color: "#1C9CEA" }} href={userInfo?.twitter} target="_blank" rel="noreferrer">
                          <i className="fa-brands ri-twitter-fill"></i>
                        </a>
                      )}
                    </li>
                  )}
                  {pageLoader ? <Skeleton /> : (
                    <li>
                      {userInfo?.instagram && (
                        <a style={{ color: "#e12a72" }} href={userInfo?.instagram} target="_blank" rel="noreferrer">
                          <i className="fa-brands ri-instagram-fill"></i>
                        </a>
                      )}
                    </li>
                  )}
                  {pageLoader ? <Skeleton /> : (
                    <li>
                      {userInfo?.linkedin && (
                        <a style={{ color: "#144679" }} href={userInfo?.linkedin} target="_blank" rel="noreferrer">
                          <i className="fa-brands ri-linkedin-fill"></i>
                        </a>
                      )}
                    </li>
                  )}
                </ul>

                <div className="bostami-parsonal-info-contact mb-30">
                  <div className="bostami-parsonal-info-contact-item">
                    {pageLoader ? <Skeleton /> : (
                      <>
                        {userInfo?.phone_number && (
                          <div className="bostami-parsonal-info-contact-item">
                            <div
                              style={{ color: "#E93B81", fontSize: "22px" }}
                              className="icon"
                            >
                              <i className="ri-phone-line"></i>
                            </div>
                            <div className="text">
                              <span>Phone</span>
                              <p>{userInfo?.phone_number}</p>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>

                  {pageLoader ? <Skeleton /> : (
                    <>
                      {userInfo?.email && (
                        <div className="bostami-parsonal-info-contact-item">
                          <div
                            style={{ color: "#E93B81", fontSize: "22px" }}
                            className="icon"
                          >
                            <i className="ri-mail-line"></i>
                          </div>
                          <div className="text">
                            <span>Email</span>
                            <p style={{ textTransform: "lowercase" }}>{userInfo?.email}</p>
                          </div>
                        </div>
                      )}
                    </>
                  )}

                  {pageLoader ? <Skeleton /> : (
                    <>
                      {userInfo?.address && (
                        <div className="bostami-parsonal-info-contact-item">
                          <div
                            style={{ color: "#E93B81", fontSize: "22px" }}
                            className="icon"
                          >
                            <i className="ri-map-pin-line"></i>
                          </div>
                          <div className="text">
                            <span>Location</span>
                            <p>{userInfo?.address}</p>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Mobile stepper */}
          <div className="col-xxl-1 col-xl-1 show-mobileStepper mb-2">
            <div className="bostami-main-menu-wrap shadow">
              <nav className="bastami-main-menu main_menu">
                {pageLoader ? <Skeleton /> : (
                  <ul>
                    <li
                      className={`menu-item profile_bar ${stepper === 1 ? 'active' : ''}`}
                      data-target="about"
                    >
                      <a href="#" onClick={() => setStepper(1)}>
                        <span>
                          <em
                            style={{ fontSize: "25px" }}
                            className="icon ri-profile-line"
                          ></em>
                        </span>
                        Card design
                      </a>
                    </li>
                    <li className={`menu-item profile_bar ${stepper === 2 ? 'active' : ''}`}>
                      <a href="#" onClick={() => setStepper(2)}>
                        <span>
                          <i
                            style={{ fontSize: "25px" }}
                            className="ri-user-line"
                          ></i>
                        </span>
                        Information
                      </a>
                    </li>
                    <li className={`menu-item profile_bar ${stepper === 3 ? 'active' : ''}`}>
                      <a href="#" onClick={() => setStepper(3)}>
                        <span>
                          <i
                            style={{ fontSize: "25px" }}
                            className="ri-file-text-line"
                          ></i>
                        </span>
                        Socials
                      </a>
                    </li>
                  </ul>
                )}
              </nav>
            </div>
          </div>

          <div className="col-xxl-8 col-xl-8">

            {stepper === 1 && (
              /* <!-- PERSONAL SECTION --> */
              <AdminCardTemplateDesign
                pageLoader={pageLoader}
                cardProfile={cardProfile}
                setCardProfile={setCardProfile}
                onProceed={() => {
                  proceedToNextStep();
                }}
              />
            )}

            {stepper === 2 && (
              /* <!-- PERSONAL SECTION --> */
              <CreatePersonalDetails
                pageLoader={pageLoader}
                cardProfile={userInfo}
                setCardProfile={setUserInfo}
                onProceed={() => {
                  proceedToNextStep();
                }}
              />
            )}

            {stepper === 3 && (
              /* <!-- SOCIAL SECTION --> */
              <CreateSocialDetails
                pageLoader={pageLoader}
                cardProfile={userInfo}
                setCardProfile={setUserInfo}
                onProceed={(e: FormEvent) => {
                  onHandle(e);
                }}
              />
            )}
          </div>

          <div className="col-xxl-1 col-xl-1 d-xl-block d-none">
            <div className="bostami-main-menu-wrap shadow">
              <nav className="bastami-main-menu main_menu">
                {pageLoader ? <Skeleton /> : (
                  <ul>
                    <li
                      className={`menu-item profile_bar ${stepper === 1 ? 'active' : ''}`}
                      data-target="about"
                    >
                      <a href="#" onClick={() => setStepper(1)}>
                        <span>
                          <em
                            style={{ fontSize: "25px" }}
                            className="icon ri-profile-line"
                          ></em>
                        </span>
                        Card design
                      </a>
                    </li>
                    <li className={`menu-item profile_bar ${stepper === 2 ? 'active' : ''}`}>
                      <a href="#" onClick={() => setStepper(2)}>
                        <span>
                          <i
                            style={{ fontSize: "25px" }}
                            className="ri-user-line"
                          ></i>
                        </span>
                        Information
                      </a>
                    </li>
                    <li className={`menu-item profile_bar ${stepper === 3 ? 'active' : ''}`}>
                      <a href="#" onClick={() => setStepper(3)}>
                        <span>
                          <i
                            style={{ fontSize: "25px" }}
                            className="ri-file-text-line"
                          ></i>
                        </span>
                        Socials
                      </a>
                    </li>
                  </ul>
                )}
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateStaffCard;
