import "../../../components/assets/css/dashboard.scoped.css";
import "../../../components/assets/css/style.scoped.css";
import "../../../components/assets/css/radio.scoped.css";
import PageLoader from "../../../components/ui/PageLoader";

export const ViewService = ({
  onClose,
  details,
  loader,
}: any) => {

  return (
    <>
      {loader ? (<PageLoader />) : (
        <>
          <div className="card bg-lighter bg-opacity-80 shadow-none mt-2">
            <div className="card-body">
              <div className="row gx-4 mt-4">
                <div className="col-6">
                  <h5>Button text</h5>
                  <ul className="list-dot gap gy-2 mt-2">
                    <li>{details?.button_text}</li>
                  </ul>
                </div>

                <div className="col-6">
                  <h5>Button url</h5>
                  <ul className="list-dot gap gy-2 mt-2">
                    <li>
                      <a href={details?.url} target="_blank">
                        {details?.button_url}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="row gx-4 mt-4">
                <div className="col-12">
                  <h5>Short description of your service</h5>
                  <ul className="list-dot gap gy-2 mt-2">
                    <li>{details?.short_description}</li>
                  </ul>
                </div>
              </div>

              <div className="row gx-4 mt-4">
                <div className="col-12">
                  <h5>Long description of your service</h5>
                  <ul className="list-dot gap gy-2 mt-2">
                    <li>{details?.long_description}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <ul className="row gx-4 mt-4">
            <li className="col-12">
              <button
                className="btn btn-primary w-100"
                data-bs-dismiss="modal"
                onClick={onClose}
              >
                Close
              </button>
            </li>
          </ul>
        </>
      )}
    </>
  );
};
