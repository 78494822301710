import "../../../components/assets/css/dashboard.scoped.css";
import "../../../components/assets/css/style.scoped.css";
import "../../../components/assets/css/radio.scoped.css";
import { Input } from "../../../components/ui/Input/Input";

export const UploadImage = ({
  onClose,
  onProceed,
  profile,
  requestLoader,
  setUserProfile,
}: any) => {

  return (
    <>
      <div className="card bg-lighter bg-opacity-80 shadow-none mt-2">
        <div className="card-body contact-form">
          <div className="row g-4">
            <div className="col-12">
              <div className="form-group">
                <Input
                  label="Image"
                  type="text"
                  name="photograph"
                  required={true}
                  id="photograph"
                  value={profile?.photograph}
                  onChange={(e) => { setUserProfile({ ...profile, photograph: e?.target?.value }) }}
                  placeholder="Enter profile picture link"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ul className="row gx-4 mt-4">
        <li className="col-6">
          <button
            className="btn btn-outline-light w-100"
            data-bs-dismiss="modal"
            onClick={onClose}
          >
            Cancel
          </button>
        </li>
        <li className="col-6">
          <button
            className="btn btn-primary w-100"
            data-bs-dismiss="modal"
            onClick={onProceed}
            disabled={requestLoader}
          >
            Upload
          </button>
        </li>
      </ul>
    </>
  );
};
