import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Logo from "../assets/img/logo-2.png";
import styles from "../assets/css/global.module.css";
import { Input } from "../../components/ui/Input/Input";
import { useInput } from "../../validation/InputValidation";
import PrimaryButton from "../../components/ui/Button/PrimaryButton";
import { FormEvent, useState } from "react";
import { postData } from "../../helpers/request";
import { toast } from "react-toastify";

function ForgotPassword() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { id } = useParams();
  const navigate = useNavigate();
  const [requestLoader, setRequestLoader] = useState(false);
  const emailInput = useInput("");
  const [regError, setRegError] = useState("");

  const validateInput = (input: {
    value: string;
    setError: (value: boolean) => void;
  }) => {
    if (!input.value.trim()) {
      return input.setError(true);
    } else {
      input.setError(false);
    }
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    validateInput(emailInput);

    const payload = {
      email: emailInput.value,
    };

    setRequestLoader(true);
    const res: any = await postData("/profile/forgotpassword", payload);

    if (res.isSuccess) {
      setRequestLoader(false);
      toast.success(res?.message);
      queryParams.set("id", res?.id);
      const paramValue = queryParams.get("id");
      const newSearch = `${
        paramValue ? paramValue?.toString() : id?.toString()
      }`;
      window.localStorage.setItem(
        "card-git-email",
        JSON.stringify(emailInput.value)
      );
      if (paramValue) {
        if (!res?.isVerified) {
          return navigate({
            pathname: `/verification/${newSearch}`,
          });
        }
        return navigate({
          pathname: "/reset-password",
          search: newSearch,
        });
      }

      if (!res?.isVerified) {
        return navigate({
          pathname: `/verification/${newSearch}`,
          search: newSearch,
        });
      }
      return navigate(`/reset-password`);
    }
    if (!res.isSuccess) {
      setRequestLoader(false);
      setRegError(res.message);
    }
  };

  return (
    <div className={styles["nk-app-root"]}>
      <main className={styles["nk-pages"]}>
        <div
          className={[
            styles["min-vh-100"],
            styles["d-flex"],
            styles["flex-column"],
            styles["has-mask"],
          ].join(" ")}
        >
          <div
            className={[
              styles["nk-mask"],
              styles["bg-pattern-dot"],
              styles["bg-blend-around"],
            ].join(" ")}
          />

          <div className={styles["my-auto"]}>
            <div className={styles.container}>
              <div
                className={[
                  styles["text-center"],
                  styles["mt-6"],
                  styles["mb-5"],
                ].join(" ")}
              >
                <Link to="/" className={styles["logo-link"]}>
                  <div className={styles["logo-wrap"]}>
                    <img
                      className={styles["logo-img"]}
                      width="180"
                      src={Logo}
                      alt=""
                    />
                  </div>
                </Link>
              </div>

              <div
                className={[
                  styles["row"],
                  styles["g-gs"],
                  styles["justify-content-center"],
                ].join(" ")}
              >
                <div
                  className={[
                    styles["col-md-7"],
                    styles["col-lg-6"],
                    styles["col-xl-5"],
                  ].join(" ")}
                >
                  <div
                    className={[
                      styles["card"],
                      styles["border-0"],
                      styles["shadow-sm"],
                      styles["rounded-4"],
                    ].join(" ")}
                  >
                    <div className={styles["card-body"]}>
                      <h4>Forgot password</h4>
                      <p>
                        If you forgot your password, well, then we’ll email you
                        instructions to reset your password.
                      </p>

                      <form onSubmit={handleSubmit}>
                        <div
                          className={[styles["row"], styles["g-4"]].join(" ")}
                        >
                          <div className={styles["col-12"]}>
                            <Input
                              label="Email Address"
                              type="email"
                              name="emailorusername"
                              id="emailorusername"
                              placeholder="Enter Email"
                              required={true}
                              {...emailInput}
                            />
                          </div>

                          <div className={styles["col-12"]}>
                            <div className={styles["form-group"]}>
                              {regError && (
                                <p className={styles["form-error-message"]}>{regError}</p>
                              )}
                            </div>
                          </div>

                          <div className={styles["col-12"]}>
                            <div
                              className={[
                                styles["d-flex"],
                                styles["flex-wrap"],
                                styles["justify-content-between"],
                                styles["align-items-center"],
                                styles["has-gap"],
                                styles["g-3"],
                              ].join(" ")}
                            >
                              <div className={styles["form-group"]}>
                                <PrimaryButton
                                  type="submit"
                                  title="Send Reset Code"
                                  id="submit-btn"
                                  disabled={emailInput.error || requestLoader}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <p className={`${styles['text-center']} ${styles['mt-4']}`}>
                    <Link className={`${styles['link']} ${styles['has-gap']} ${styles['g-2']}`} to="/login">
                      <em className={`${styles.icon} ${styles['ri-arrow-left-line']}`} />
                      <span>Back to Login</span>
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default ForgotPassword;
