import Avatar from "../../../components/assets/img/logo_2.png";
import "../../../components/assets/css/dashboard.scoped.css";
import "../../../components/assets/css/style.scoped.css";
import { FormEvent, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getData, putData } from "../../../helpers/request";
import { toast } from 'react-toastify';

export type CompanyProf = {
  id: string;
  name: string;
  address: string;
  address2?: string;
  country: string;
  state: string;
  city: string;
  logo?: string;
  email: string;
  phone_number: string | number;
  website?: string;
  company_summary?: string;
  company_edit?: boolean;
  modified_by?: string;
  created_by?: string;
  createdAt?: string;
  updatedAt?: string;
  profile_edit?: boolean;
  photograph?: string;
  notify_service_click?: boolean;
}

export type Permissions = {
  allow_create_card: boolean;
  allow_card_delete: boolean;
  allow_card_edit: boolean;
  allow_card_revoke: boolean;
  allow_card_unrevoke: boolean;
  allow_profile_edit: boolean;
  allow_service_create: boolean;
  allow_service_edit: boolean;
  allow_service_activate: boolean;
  allow_service_deactivate: boolean;
}

function CompanyProfile() {
  const [userProfile, setUserProfile] = useState<CompanyProf>({
    id: "",
    name: "",
    address: "",
    address2: "",
    country: "",
    state: "",
    city: "",
    logo: "",
    email: "",
    phone_number: "",
    website: "",
    company_summary: "",
    company_edit: false,
    createdAt: "",
  });
  const [requestLoader, setRequestLoader] = useState(false);
  const [openStaffPermModal, setToggleStaffModal] = useState(false);
  const [openCompanyPermModal, setToggleCompanyModal] = useState(false);
  const [companyPermission, setCompanyPermission] = useState<Permissions>({
    allow_create_card: true,
    allow_card_delete: true,
    allow_card_edit: true,
    allow_card_revoke: true,
    allow_card_unrevoke: false,
    allow_profile_edit: true,
    allow_service_create: true,
    allow_service_edit: true,
    allow_service_activate: true,
    allow_service_deactivate: true
  });
  const [staffPermission, setStaffPermission] = useState<Permissions>({
    allow_create_card: true,
    allow_card_delete: true,
    allow_card_edit: true,
    allow_card_revoke: true,
    allow_card_unrevoke: false,
    allow_profile_edit: true,
    allow_service_create: true,
    allow_service_edit: true,
    allow_service_activate: true,
    allow_service_deactivate: true
  });

  const navigate = useNavigate();

  const getProfile = useCallback(async () => {
    const res = await getData(`/company/profile`);
    if (res?.isSuccess) {
      setUserProfile({ ...res?.data });
    }
  }, []);

  useEffect(() => {
    getProfile();
  }, [getProfile]);

  const toggleStaffPermModal = () => {
    setToggleStaffModal(!openStaffPermModal);
  }

  const toggleCompanyPermModal = () => {
    setToggleCompanyModal(!openCompanyPermModal);
  }

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setRequestLoader(true);
    const id = toast.loading("...Loading");

    const res = await putData(`company/setprofileaccess`, staffPermission);

    if (res?.isSuccess || res?.isSucess) {
      setRequestLoader(false);
      toggleStaffPermModal();
      return toast.update(id, { render: res?.message, type: "success", isLoading: false, autoClose: 5000, draggablePercent: 60, closeOnClick: true });
    }

    toast.update(id, { render: res?.message, type: "error", isLoading: false, autoClose: 5000, draggablePercent: 60, closeOnClick: true });
    return setRequestLoader(false);
  }

  const handleCompanyPermission = async (e: FormEvent) => {
    e.preventDefault();
    setRequestLoader(true);
    const id = toast.loading("...Loading");

    const res = await putData(`company/setcompanyaccess`, companyPermission);

    if (res?.isSuccess || res?.isSucess) {
      setRequestLoader(false);
      toggleCompanyPermModal();
      getCompanyAccess();
      return toast.update(id, { render: res?.message, type: "success", isLoading: false, autoClose: 5000, draggablePercent: 60, closeOnClick: true });
    }

    toast.update(id, { render: res?.message, type: "error", isLoading: false, autoClose: 5000, draggablePercent: 60, closeOnClick: true });
    return setRequestLoader(false);
  }

  const getCompanyAccess = async () => {
    const res = await getData('company/companyaccess');

    if (res?.isSuccess) {
      setCompanyPermission({
        allow_create_card: res?.data.allow_create_card,
        allow_card_delete: res?.data.allow_card_delete,
        allow_card_edit: res?.data.allow_card_edit,
        allow_card_revoke: res?.data.allow_card_revoke,
        allow_card_unrevoke: res?.data.allow_card_unrevoke,
        allow_profile_edit: res?.data.allow_profile_edit,
        allow_service_create: res?.data.allow_service_create,
        allow_service_edit: res?.data.allow_service_edit,
        allow_service_activate: res?.data.allow_service_activate,
        allow_service_deactivate: res?.data.allow_service_deactivate
      });
    }
  }

  useEffect(() => {
    getCompanyAccess();
  }, []);

  const editProfile = () => {
    return navigate('/dashboard/company-edit');
  }

  return (
    <>
      <div className="row">
        <div className="col-md-4 mb-3">
          <div className="card card-profile" style={{ paddingTop: "50px" }}>
            {/* <img
              src={userTemplate?.image}
              alt=""
              className="card-img-top card--format"
            /> */}
            <div className="row justify-content-center">
              <div className="mt-n4 mt-lg-n6 mb-4 mb-lg-0">
                <section className="container text-center m-auto position-relative">
                  {/* <form> */}
                  <div
                    role="presentation"
                    tabIndex={0}
                    className="dropzone"
                    style={{ cursor: "pointer" }}
                  >
                    <input
                      className="b-none"
                      accept="image/*"
                      multiple
                      type="file"
                      tabIndex={-1}
                      style={{ display: "none" }}
                    />
                    <div className="upload">
                      {" "}
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 24 24"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M13 19v-4h3l-4-5-4 5h3v4z" />
                        <path d="M7 19h2v-2H7c-1.654 0-3-1.346-3-3 0-1.404 1.199-2.756 2.673-3.015l.581-.102.192-.558C8.149 8.274 9.895 7 12 7c2.757 0 5 2.243 5 5v1h1c1.103 0 2 .897 2 2s-.897 2-2 2h-3v2h3c2.206 0 4-1.794 4-4a4.01 4.01 0 0 0-3.056-3.888C18.507 7.67 15.56 5 12 5 9.244 5 6.85 6.611 5.757 9.15 3.609 9.792 2 11.82 2 14c0 2.757 2.243 5 5 5z" />
                      </svg>{" "}
                    </div>
                  </div>
                  <div className="mt-n4 mt-lg-n6 mb-lg-0 text-center m-auto">
                    {userProfile?.logo ? (
                      <img
                        src={userProfile?.logo}
                        alt="placeholder"
                        className="upload_image img-fluid border border-2 border-white"
                        style={{
                          width: "108px",
                          height: "108px",
                          objectFit: "cover"
                        }}
                      />
                    ) : (
                      <img
                        src={Avatar}
                        alt="placeholder"
                        className="upload_image img-fluid border border-2 border-white"
                      />
                    )}
                  </div>
                  {/* </form> */}
                </section>
              </div>
            </div>
            <div className="card-body pt-0">
              <div className="text-center mt-3">
                <h5 className="fw-bold">
                  {" "}
                  {`${userProfile.name}`}
                </h5>
                <p className="tex-sm">{userProfile.email}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-8">
          {/* Profile */}
          <div className="card px-2 px-lg-4 py-3 py-lg-0 mb-3">
            <div className="card-header bg-transparent pb-3 mb-3">
              <div className="d-flex align-items-center" style={{ columnGap: "40px" }}>
                <p className="mb-0 fw-bold mb-2">Company Information</p>

                <div className="d-flex align-items-center" style={{ flexFlow: "wrap" }}>
                  <div className="sm-12 me-2 mb-2">
                    <button
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                      className="btn btn-outline-light btn-sm"
                      onClick={editProfile}
                    >
                      <i className="ri-edit-2-line pe-2"></i>
                      Edit
                    </button>
                  </div>

                  <div className="sm-12 me-2 mb-2">
                    <button
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                      className="btn btn-outline-light btn-sm"
                      onClick={toggleCompanyPermModal}
                    >
                      <i className="ri-edit-2-line pe-2"></i>
                      Update company access
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="card-body">
              {/* <p className="text-uppercase text-sm">User Information</p> */}
              <form action="">
                <div className="row">
                  {userProfile?.email && (<div className="col-md-12 mb-2">
                    <label htmlFor="emailorusername" className="form-control-label fw-bold" style={{ color: "#43476b" }}>
                      Email Address</label>
                    <p className="" id="emailorusername">{userProfile?.email}</p>
                  </div>)}

                  {userProfile?.name && (<div className="col-md-6 mb-2">
                    <label htmlFor="first_name" className="form-control-label fw-bold" style={{ color: "#43476b" }}>Company name</label>
                    <p className="" id="first_name">{userProfile?.name}</p>
                  </div>)}

                  {userProfile?.phone_number && (<div className="col-md-6 mb-2">
                    <label htmlFor="phone" className="form-control-label fw-bold" style={{ color: "#43476b" }}>Phone</label>
                    <p className="" id="phone">{userProfile?.phone_number}</p>
                  </div>)}

                  {userProfile?.country && (<div className="col-md-4 mb-2">
                    <label htmlFor="phone" className="form-control-label fw-bold" style={{ color: "#43476b" }}>Country</label>
                    <p className="" id="phone">{userProfile?.country}</p>
                  </div>)}

                  {userProfile?.state && (<div className="col-md-4 mb-2">
                    <label htmlFor="phone" className="form-control-label fw-bold" style={{ color: "#43476b" }}>State</label>
                    <p className="" id="phone">{userProfile?.state}</p>
                  </div>)}

                  {userProfile?.city && (<div className="col-md-4 mb-2">
                    <label htmlFor="phone" className="form-control-label fw-bold" style={{ color: "#43476b" }}>City</label>
                    <p className="" id="phone">{userProfile?.city}</p>
                  </div>)}

                  {userProfile?.address && (<div className="col-md-12 mb-2">
                    <label htmlFor="address" className="form-control-label fw-bold" style={{ color: "#43476b" }}>Address</label>
                    <p className="" id="address">{userProfile?.address}</p>
                  </div>)}

                  {userProfile?.address2 && (<div className="col-md-12 mb-2">
                    <label htmlFor="address2" className="form-control-label fw-bold" style={{ color: "#43476b" }}>Address 2</label>
                    <p className="" id="address2">{userProfile?.address2}</p>
                  </div>)}

                  {userProfile?.company_summary && (<div className="col-md-12 mt-4 mb-2 contact-form">
                    <div className="form-input-item mb-40">
                      <label className="input-lebel message fw-bold" style={{ color: "#43476b" }}>Company summary</label>
                      <p>{userProfile?.company_summary}</p>
                    </div>
                  </div>)}

                  <div className="col-md-12 mt-4 mb-2">
                    <label htmlFor="Notify" className="form-control-label fw-bold" style={{ color: "#43476b" }}>Notify Service Click</label>
                    <p className="" id="Notify">{userProfile?.notify_service_click ? 'True' : 'False'}</p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        {/* <!-- UPDATE STAFF PERMISSION --> */}
        <div
          className={`offcanvas offcanvas-end customeoff ${openStaffPermModal ? 'show' : ''}`}
          tabIndex={-1}
          id="offcanvasExample"
          aria-modal="true"
          role="dialog"
        >
          <div className="offcanvas-header">
            <h5 className="modal-title" id="#gridSystemModal">Update Staff Permission</h5>
            <button type="button" className="btn-close" aria-label="Close" onClick={toggleStaffPermModal}>
              <i className="fa-solid fa-xmark"></i>
            </button>
          </div>
          <div className="offcanvas-body">
            <div className="container-fluid">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-xl-4 mt-4 mb-4">
                    <div className="form-check form-check-sm">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={staffPermission?.allow_create_card}
                        id="allowCreateCard"
                        onChange={(e) => setStaffPermission({ ...staffPermission, allow_create_card: e?.target?.checked })}
                      />
                      <label className="form-check-label" htmlFor="allowCreateCard">
                        {" "}
                        Allow staff create card{" "}
                      </label>
                    </div>
                  </div>
                  <div className="col-xl-4 mt-4 mb-4">
                    <div className="form-check form-check-sm">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={staffPermission?.allow_card_delete}
                        id="allowDeleteCard"
                        onChange={(e) => setStaffPermission({ ...staffPermission, allow_card_delete: e?.target?.checked })}
                      />
                      <label className="form-check-label" htmlFor="allowDeleteCard">
                        {" "}
                        Allow staff delete card{" "}
                      </label>
                    </div>
                  </div>
                  <div className="col-xl-4 mt-4 mb-4">
                    <div className="form-check form-check-sm">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={staffPermission?.allow_card_edit}
                        id="allowEditCard"
                        onChange={(e) => setStaffPermission({ ...staffPermission, allow_card_edit: e?.target?.checked })}
                      />
                      <label className="form-check-label" htmlFor="allowEditCard">
                        {" "}
                        Allow staff edit card{" "}
                      </label>
                    </div>
                  </div>

                  <div className="col-xl-4 mt-4 mb-4">
                    <div className="form-check form-check-sm">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={staffPermission?.allow_card_revoke}
                        id="allowRevokeCard"
                        onChange={(e) => setStaffPermission({ ...staffPermission, allow_card_revoke: e?.target?.checked })}
                      />
                      <label className="form-check-label" htmlFor="allowRevokeCard">
                        {" "}
                        Allow staff revoke card{" "}
                      </label>
                    </div>
                  </div>
                  <div className="col-xl-4 mt-4 mb-4">
                    <div className="form-check form-check-sm">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={staffPermission?.allow_card_unrevoke}
                        id="allowCardUnrevoke"
                        onChange={(e) => setStaffPermission({ ...staffPermission, allow_card_unrevoke: e?.target?.checked })}
                      />
                      <label className="form-check-label" htmlFor="allowCardUnrevoke">
                        {" "}
                        Allow staff unrevoke{" "}
                      </label>
                    </div>
                  </div>
                  <div className="col-xl-4 mt-4 mb-4">
                    <div className="form-check form-check-sm">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={staffPermission?.allow_profile_edit}
                        id="allow_profile_edit"
                        onChange={(e) => setStaffPermission({ ...staffPermission, allow_profile_edit: e?.target?.checked })}
                      />
                      <label className="form-check-label" htmlFor="allow_profile_edit">
                        {" "}
                        Allow staff edit profile{" "}
                      </label>
                    </div>
                  </div>

                  <div className="col-xl-6 mt-4 mb-4">
                    <div className="form-check form-check-sm">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={staffPermission?.allow_service_activate}
                        id="allowServiceActivate"
                        onChange={(e) => setStaffPermission({ ...staffPermission, allow_service_activate: e?.target?.checked })}
                      />
                      <label className="form-check-label" htmlFor="allowServiceActivate">
                        {" "}
                        Allow staff activate services{" "}
                      </label>
                    </div>
                  </div>
                  <div className="col-xl-6 mt-4 mb-4">
                    <div className="form-check form-check-sm">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={staffPermission?.allow_service_create}
                        id="allowServiceCreate"
                        onChange={(e) => setStaffPermission({ ...staffPermission, allow_service_create: e?.target?.checked })}
                      />
                      <label className="form-check-label" htmlFor="allowServiceCreate">
                        {" "}
                        Allow staff create services{" "}
                      </label>
                    </div>
                  </div>
                  <div className="col-xl-6 mt-4 mb-4">
                    <div className="form-check form-check-sm">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={staffPermission?.allow_service_deactivate}
                        id="allow_service_deactivate"
                        onChange={(e) => setStaffPermission({ ...staffPermission, allow_service_deactivate: e?.target?.checked })}
                      />
                      <label className="form-check-label" htmlFor="allow_service_deactivate">
                        {" "}
                        Allow staff deactivate services{" "}
                      </label>
                    </div>
                  </div>
                  <div className="col-xl-6 mt-4 mb-4">
                    <div className="form-check form-check-sm">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={staffPermission?.allow_service_edit}
                        id="allow_service_edit"
                        onChange={(e) => setStaffPermission({ ...staffPermission, allow_service_edit: e?.target?.checked })}
                      />
                      <label className="form-check-label" htmlFor="allow_service_edit">
                        {" "}
                        Allow staff edit services{" "}
                      </label>
                    </div>
                  </div>
                </div>

                <div>
                  <button type="button" className="btn btn-danger light ms-1" onClick={toggleStaffPermModal}>Cancel</button>
                  <button
                    type="submit"
                    className="btn btn-primary me-1 ms-1"
                    disabled={requestLoader}
                  >Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>

        {/* <!-- AUPDATE COMPANY PERMISSION --> */}
        <div
          className={`offcanvas offcanvas-end customeoff ${openCompanyPermModal ? 'show' : ''}`}
          tabIndex={-1}
          id="offcanvasExample"
          aria-modal="true"
          role="dialog"
        >
          <div className="offcanvas-header">
            <h5 className="modal-title" id="#gridSystemModal">Update Company Permission</h5>
            <button type="button" className="btn-close" aria-label="Close" onClick={toggleCompanyPermModal}>
              <i className="fa-solid fa-xmark"></i>
            </button>
          </div>
          <div className="offcanvas-body">
            <div className="container-fluid">
              <form onSubmit={handleCompanyPermission}>
                <div className="row">
                  <div className="col-xl-4 mt-4 mb-4">
                    <div className="form-check form-check-sm">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={companyPermission?.allow_create_card}
                        id="allowCreateCard"
                        onChange={(e) => setCompanyPermission({ ...companyPermission, allow_create_card: e?.target?.checked })}
                      />
                      <label className="form-check-label" htmlFor="allowCreateCard">
                        {" "}
                        Allow card creation{" "}
                      </label>
                    </div>
                  </div>
                  <div className="col-xl-4 mt-4 mb-4">
                    <div className="form-check form-check-sm">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={companyPermission?.allow_card_delete}
                        id="allowDeleteCard"
                        onChange={(e) => setCompanyPermission({ ...companyPermission, allow_card_delete: e?.target?.checked })}
                      />
                      <label className="form-check-label" htmlFor="allowDeleteCard">
                        {" "}
                        Allow card delete{" "}
                      </label>
                    </div>
                  </div>
                  <div className="col-xl-4 mt-4 mb-4">
                    <div className="form-check form-check-sm">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={companyPermission?.allow_card_edit}
                        id="allowEditCard"
                        onChange={(e) => setCompanyPermission({ ...companyPermission, allow_card_edit: e?.target?.checked })}
                      />
                      <label className="form-check-label" htmlFor="allowEditCard">
                        {" "}
                        Allow card edit{" "}
                      </label>
                    </div>
                  </div>

                  <div className="col-xl-4 mt-4 mb-4">
                    <div className="form-check form-check-sm">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={companyPermission?.allow_card_revoke}
                        id="allowRevokeCard"
                        onChange={(e) => setCompanyPermission({ ...companyPermission, allow_card_revoke: e?.target?.checked })}
                      />
                      <label className="form-check-label" htmlFor="allowRevokeCard">
                        {" "}
                        Allow card revoke{" "}
                      </label>
                    </div>
                  </div>
                  <div className="col-xl-4 mt-4 mb-4">
                    <div className="form-check form-check-sm">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={companyPermission?.allow_card_unrevoke}
                        id="allowCardUnrevoke"
                        onChange={(e) => setCompanyPermission({ ...companyPermission, allow_card_unrevoke: e?.target?.checked })}
                      />
                      <label className="form-check-label" htmlFor="allowCardUnrevoke">
                        {" "}
                        Allow card unrevoke{" "}
                      </label>
                    </div>
                  </div>
                  <div className="col-xl-4 mt-4 mb-4">
                    <div className="form-check form-check-sm">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={companyPermission?.allow_profile_edit}
                        id="allow_profile_edit"
                        onChange={(e) => setCompanyPermission({ ...companyPermission, allow_profile_edit: e?.target?.checked })}
                      />
                      <label className="form-check-label" htmlFor="allow_profile_edit">
                        {" "}
                        Allow profile edit{" "}
                      </label>
                    </div>
                  </div>

                  <div className="col-xl-6 mt-4 mb-4">
                    <div className="form-check form-check-sm">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={companyPermission?.allow_service_activate}
                        id="allowServiceActivate"
                        onChange={(e) => setCompanyPermission({ ...companyPermission, allow_service_activate: e?.target?.checked })}
                      />
                      <label className="form-check-label" htmlFor="allowServiceActivate">
                        {" "}
                        Allow service activation{" "}
                      </label>
                    </div>
                  </div>
                  <div className="col-xl-6 mt-4 mb-4">
                    <div className="form-check form-check-sm">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={companyPermission?.allow_service_create}
                        id="allowServiceCreate"
                        onChange={(e) => setCompanyPermission({ ...companyPermission, allow_service_create: e?.target?.checked })}
                      />
                      <label className="form-check-label" htmlFor="allowServiceCreate">
                        {" "}
                        Allow service creation{" "}
                      </label>
                    </div>
                  </div>
                  <div className="col-xl-6 mt-4 mb-4">
                    <div className="form-check form-check-sm">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={companyPermission?.allow_service_deactivate}
                        id="allow_service_deactivate"
                        onChange={(e) => setCompanyPermission({ ...companyPermission, allow_service_deactivate: e?.target?.checked })}
                      />
                      <label className="form-check-label" htmlFor="allow_service_deactivate">
                        {" "}
                        Allow service deactivation{" "}
                      </label>
                    </div>
                  </div>
                  <div className="col-xl-6 mt-4 mb-4">
                    <div className="form-check form-check-sm">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={companyPermission?.allow_service_edit}
                        id="allow_service_edit"
                        onChange={(e) => setCompanyPermission({ ...companyPermission, allow_service_edit: e?.target?.checked })}
                      />
                      <label className="form-check-label" htmlFor="allow_service_edit">
                        {" "}
                        Allow service edit{" "}
                      </label>
                    </div>
                  </div>
                </div>

                <div>
                  <button type="button" className="btn btn-danger light ms-1" onClick={toggleCompanyPermModal}>Cancel</button>
                  <button
                    type="submit"
                    className="btn btn-primary me-1 ms-1"
                    disabled={requestLoader}
                  >Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CompanyProfile;
