import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import "../../../components/assets/css/dashboard.scoped.css";
import 'react-loading-skeleton/dist/skeleton.css';
import { getData } from "../../../helpers/request";
import PageLoader from "../../../components/ui/PageLoader";

export default function Dashboard() {
  type AdminDashboardCount = {
    totalStaffCount: number;
    totalActiveStaffCount: number;
    totalInActiveStaffCount: number;
    totalServiceCount: number;
    totalActiveServiceCount: number;
    totalInActiveServiceCount: number;
    totalCardCount: number;
    totalActiveCardCount: number;
    totalInActiveCardCount: number;
  }
  const [pageLoader, setPageLoader] = useState(false);
  const [count, setDashboardCount] = useState<AdminDashboardCount>({
    totalStaffCount: 0,
    totalActiveStaffCount: 0,
    totalInActiveStaffCount: 0,
    totalServiceCount: 0,
    totalActiveServiceCount: 0,
    totalInActiveServiceCount: 0,
    totalCardCount: 0,
    totalActiveCardCount: 0,
    totalInActiveCardCount: 0
  });

  // const connections = useLoaderData() as Connections;

  useEffect(() => {
    getDashboard();
  }, []);

  const getDashboard = async () => {
    setPageLoader(true);
    const res = await getData(`company/dashboardcount`);
    if (res) {
      setPageLoader(false);
      return setDashboardCount(res);
    }
  }

  return (
    <React.Fragment>
      {pageLoader ? (
          <PageLoader />
        ) : (
          <>
            <div className="row row-sm">
              <div className="col-xl-3 col-lg-6 col-md-6 col-xm-12 mb-4">
                <div className="card overflow-hidden sales-card bg-primary-gradient">
                  <div className="px-3 pt-3  pb-2 pt-0">
                    <div className="">
                      <h6 className="mb-3 tx-12 text-white">TOTAL STAFF</h6>
                    </div>
                    <div className="pb-0 mt-0">
                      <div className="d-flex">
                        <div className="">
                          <h4 className="tx-20 fw-bold mb-1 text-white">{count?.totalStaffCount}</h4>
                          <NavLink to="/dashboard/admin-staff" className="mb-0 tx-12 text-white op-7">
                            See All
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                  <span id="compositeline" className="pt-1">
                    <canvas width="254" height="30" style={{ display: "inline-block", width: "254px", height: "30px", verticalAlign: "top" }}></canvas>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-6 col-md-6 col-xm-12 mb-4">
                <div className="card overflow-hidden sales-card bg-danger-gradient">
                  <div className="px-3 pt-3  pb-2 pt-0">
                    <div className="">
                      <h6 className="mb-3 tx-12 text-white">TOTAL CARDS</h6>
                    </div>
                    <div className="pb-0 mt-0">
                      <div className="d-flex">
                        <div className="">
                          <h4 className="tx-20 fw-bold mb-1 text-white">{count?.totalCardCount}</h4>
                          <NavLink to="/dashboard/company-cards" className="mb-0 tx-12 text-white op-7">
                            See All
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                  <span id="compositeline2" className="pt-1">
                    <canvas width="254" height="30" style={{ display: "inline-block", width: "254px", height: "30px", verticalAlign: "top" }}></canvas>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-6 col-md-6 col-xm-12 mb-4">
                <div className="card overflow-hidden sales-card bg-success-gradient">
                  <div className="px-3 pt-3  pb-2 pt-0">
                    <div className="">
                      <h6 className="mb-3 tx-12 text-white">TOTAL SERVICE</h6>
                    </div>
                    <div className="pb-0 mt-0">
                      <div className="d-flex">
                        <div className="">
                          <h4 className="tx-20 fw-bold mb-1 text-white">{ count?.totalServiceCount }</h4>
                          <NavLink to="/dashboard/admin-services" className="mb-0 tx-12 text-white op-7">
                            See All
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                  <span id="compositeline3" className="pt-1">
                    <canvas width="254" height="30" style={{ display: "inline-block", width: "254px", height: "30px", verticalAlign: "top" }}></canvas>
                  </span>
                </div>
              </div>

              <div className="col-xl-3 col-lg-6 col-md-6 col-xm-12 mb-4">
                <div className="card overflow-hidden sales-card bg-warning-gradient">
                  <div className="px-3 pt-3  pb-2 pt-0">
                    <div className="">
                      <h6 className="mb-3 tx-12 text-white">TOTAL ACTIVE STAFF</h6>
                    </div>
                    <div className="pb-0 mt-0">
                      <div className="d-flex">
                        <div className="">
                          <h4 className="tx-20 fw-bold mb-1 text-white">{count?.totalActiveStaffCount}</h4>
                          <p className="mb-0 tx-12 text-white op-7"> </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <span id="compositeline4" className="pt-1">
                    <canvas width="254" height="60" style={{ display: "inline-block", width: "254px", height: "60px", verticalAlign: "top" }}></canvas>
                  </span>
                </div>
              </div>
            </div>
          </>
        )
      }
    </React.Fragment >
  );
}
