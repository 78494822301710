import axios from "axios";
import { router } from "../router/router";
import SessionStorageService from "./sessionStorage";
import { useLocation } from "react-router-dom";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = SessionStorageService.getItem("token");
    if (token) {
      config.headers!.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    const originalRequest = error.config;

    if (
      error.response.status === 401 
      // && originalRequest?.url?.include("/token")
    ) {
      let url = window.location.search;
      url = url.replace("?", '');
      if (url) {
        window.location.replace(`/login?id=${url.split("=")[1]?.toString()}`);
        return Promise.reject(error);
      }
      window.location.replace("/login");
      return Promise.reject(error);
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const refreshToken = SessionStorageService.getItem("token");
      return axios
        .post("/auth/token", {
          refresh_token: refreshToken,
        })
        .then((res) => {
          if (res.status === 201 || res.status === 200) {
            SessionStorageService.setItem("token", res.data.token);
            axios.defaults.headers.common["Authorization"] =
              "Bearer " + SessionStorageService.getItem("token");
            return axios(originalRequest);
          }
        });
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
