import Avatar from "../../../components/assets/img/logo_2.png";
// import "../../../components/assets/css/dashboard.scoped.css";
// import "../../../components/assets/css/style.scoped.css";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserTemplate } from "../Favorites";
import { getData, putData } from "../../../helpers/request";
import ScanCodeModal from "../../../components/ui/Modal/ScanCodeModal";
import SessionStorageService from "../../../services/sessionStorage";
import { toast } from "react-toastify";

export type UserProfile = {
  id: string;
  last_name: string;
  registration_type: string;
  first_name: string;
  other_name: string;
  prefix: string;
  suffix: string;
  is_corporate_profile: boolean;
  company_id: string;
  service_group_id: string;
  business_name: string;
  designation: string;
  about_me: string;
  email: string;
  password?: string;
  country_code: string;
  Phone_number: string;
  corporate_id: string;
  show_corporate_services: boolean;
  create_corporate_services: boolean;
  photograph: string;
  address: string;
  address2: string;
  city: string;
  state: string;
  country: string;
  website: string;
  whatsapp_country_code: string;
  whatsapp_phone_number: string;
  facebook: string;
  twitter: string;
  linkedin: string;
  instagram: string;
  youtube: string;
  tiktok: string;
  plan_id: string;
  template_id: string;
  role_id: string;
  professional_field_id: string;
  status: string;
  date_confirmed: string;
  modified_by: string;
  agent_id: string;
  notify_new_connection: boolean;
  notify_service_click: boolean;
  profile_access: boolean;
  private_mode: boolean;
  private_share: boolean;
  email_private: boolean;
  phone_private: boolean;
  address_private: boolean;
  whatsapp_private: boolean;
  createdAt: string;
  updatedAt: string;
};

function ProfilePreview() {
  const navigate = useNavigate();
  const [userProfile, setUsrProfile] = useState<UserProfile>({
    id: "",
    last_name: "",
    registration_type: "",
    first_name: "",
    other_name: "",
    prefix: "",
    suffix: "",
    is_corporate_profile: false,
    company_id: "",
    service_group_id: "",
    business_name: "",
    designation: "",
    about_me: "",
    email: "",
    country_code: "",
    Phone_number: "",
    corporate_id: "",
    show_corporate_services: false,
    create_corporate_services: false,
    photograph: "",
    address: "",
    address2: "",
    city: "",
    state: "",
    country: "",
    website: "",
    whatsapp_country_code: "",
    whatsapp_phone_number: "",
    facebook: "",
    twitter: "",
    linkedin: "",
    instagram: "",
    youtube: "",
    tiktok: "",
    plan_id: "",
    template_id: "",
    role_id: "",
    professional_field_id: "",
    status: "",
    date_confirmed: "",
    modified_by: "",
    agent_id: "",
    notify_new_connection: false,
    notify_service_click: false,
    profile_access: false,
    private_mode: false,
    private_share: false,
    email_private: true,
    phone_private: true,
    address_private: true,
    whatsapp_private: true,
    createdAt: "",
    updatedAt: "",
  });
  const [requestLoader, setRequestLoader] = useState(false);

  const [userTemplate, setUserTemplate] = useState<UserTemplate | null>(null);
  const [openQRModal, setQRModal] = useState(false);

  const getUserTemplate = async () => {
    setRequestLoader(true);
    const res = await getData(`template/profile/owner`);

    if (res) {
      setRequestLoader(false);
      return setUserTemplate({ ...res });
    };
  }

  const getProfile = useCallback(async () => {
    const res = await getData(`/profile`);
    if (res) {
      setUsrProfile({ ...res });
    }
  }, []);

  const [qrcode_url, setQrcode] = useState("");

  const copyQR = () => {
    navigator.clipboard.writeText(qrcode_url);
    toggleScanModal();
    toast.success('Successfully copied qr code');
  }

  useEffect(() => {
    const token = SessionStorageService.getItem("token");
    if (token) {
      const user = SessionStorageService.decodeToken();
      return setQrcode(user?.qrcode_url);
    }
  }, []);

  useEffect(() => {
    getProfile();
  }, [getProfile]);

  useEffect(() => {
    getUserTemplate();
  }, []);

  const goToDashboard = () => {
    return navigate("/dashboard/index");
  };

  const toggleScanModal = () => {
    if (openQRModal) return setQRModal(false);
    return setQRModal(true);
  }

  return (
    <>
      <div className="profile--preview">
        <div className="container-xl">
          <div className="row">
            <div className="col-md-4 mb-3">
              <div className="card card-profile">
                <img
                  src={userTemplate?.image}
                  alt="placeholder"
                  className="card-img-top card--format"
                />
                <div className="row justify-content-center">
                  <div className="mt-n4 mt-lg-n6 mb-4 mb-lg-0">
                    <section className="container text-center m-auto position-relative">
                      <form>
                        <div className="mt-n4 mt-lg-n6 mb-lg-0 text-center m-auto">
                          {userProfile?.photograph ? (
                            <img
                              src={userProfile?.photograph}
                              alt="placeholder"
                              className="upload_image img-fluid border border-2 border-white"
                              style={{
                                width: "108px",
                                height: "108px",
                                objectFit: "cover"
                              }}
                            />
                          ) : (
                            <img
                              src={Avatar}
                              alt="placeholder"
                              className="upload_image img-fluid border border-2 border-white"
                            />
                          )}
                        </div>
                      </form>
                    </section>
                  </div>
                </div>
                <div className="card-body pt-0">
                  <div className="text-center mt-3">
                    <h5 className="fw-bold">
                      {" "}
                      {`${userProfile.first_name} ${userProfile.last_name}`}{" "}
                    </h5>
                    <p className="tex-sm">{userProfile.email}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-8">
              <div className="card px-2 px-lg-4 py-3 py-lg-0">
                <div className="card-header bg-transparent pb-3 mb-3">
                  <div className="d-flex align-items-center justify-content-between">
                    <p className="mb-0 fw-bold">Profile</p>
                    <div>
                      <button
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        className="btn btn-dark btn-sm ms-auto"
                        onClick={toggleScanModal}
                      >
                        <i className="ri-share-line pe-2"></i>
                        Share
                      </button>
                      <button
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        className="btn btn-outline-light btn-sm"
                        style={{
                          marginLeft: "10px"
                        }}
                        onClick={goToDashboard}
                      >
                        Dashboard
                      </button>
                    </div>
                  </div>
                </div>

                <div className="card-body">
                  <p className="text-uppercase text-sm">Your Information</p>

                  <form action="">
                    <div className="row">
                      <div className="bostami-parsonal-info-contact mb-30">
                        {userProfile?.Phone_number && (
                          <div className="col-md-12 mb-2">
                            <div className="bostami-parsonal-info-contact-item">
                              <div
                                style={{ color: "#E93B81", fontSize: "22px" }}
                                className="icon"
                              >
                                <i className="ri-phone-line"></i>
                              </div>
                              <div className="text">
                                <span>Phone</span>
                                <p>{userProfile?.Phone_number}</p>
                              </div>
                            </div>
                          </div>
                        )}
                        {userProfile?.email && (<div className="col-md-6 mb-2">
                          <div className="bostami-parsonal-info-contact-item">
                            <div
                              style={{ color: "#E93B81", fontSize: "22px" }}
                              className="icon"
                            >
                              <i className="ri-mail-line"></i>
                            </div>
                            <div className="text">
                              <span>Email</span>
                              <p>{userProfile?.email}</p>
                            </div>
                          </div>
                        </div>)}
                        {userProfile?.address && (<div className="col-md-6 mb-2">
                          <div className="bostami-parsonal-info-contact-item">
                            <div
                              style={{ color: "#E93B81", fontSize: "22px" }}
                              className="icon"
                            >
                              <i className="ri-map-pin-line"></i>
                            </div>
                            <div className="text">
                              <span>Location</span>
                              <p>{userProfile?.address}</p>
                            </div>
                          </div>
                        </div>)}
                        {userProfile?.facebook && (<div className="col-md-6 mb-2">
                          <div className="bostami-parsonal-info-contact-item">
                            <div
                              style={{ color: "#E93B81", fontSize: "22px" }}
                              className="icon"
                            >
                              <i className="ri-facebook-fill"></i>
                            </div>
                            <div className="text">
                              <span>Facebook</span>
                              <p>{userProfile?.facebook}</p>
                            </div>
                          </div>
                        </div>)}
                        {userProfile?.youtube && (<div className="col-md-6 mb-2">
                          <div className="bostami-parsonal-info-contact-item">
                            <div
                              style={{ color: "#E93B81", fontSize: "22px" }}
                              className="icon"
                            >
                              <i className="ri-youtube-line"></i>
                            </div>
                            <div className="text">
                              <span>Youtube</span>
                              <p>{userProfile?.youtube}</p>
                            </div>
                          </div>
                        </div>)}
                        {userProfile?.linkedin && (<div className="col-md-6 mb-2">
                          <div className="bostami-parsonal-info-contact-item">
                            <div
                              style={{ color: "#E93B81", fontSize: "22px" }}
                              className="icon"
                            >
                              <i className="ri-linkedin-fill"></i>
                            </div>
                            <div className="text">
                              <span>LinkedIn</span>
                              <p>{userProfile?.linkedin}</p>
                            </div>
                          </div>
                        </div>)}
                        {userProfile?.tiktok && (<div className="col-md-6 mb-2">
                          <div className="bostami-parsonal-info-contact-item">
                            <div
                              style={{ color: "#E93B81", fontSize: "22px" }}
                              className="icon"
                            >
                              <i className="ri-tiktok-fill"></i>
                            </div>
                            <div className="text">
                              <span>TikTok</span>
                              <p>{userProfile?.tiktok}</p>
                            </div>
                          </div>
                        </div>)}
                        {userProfile?.twitter && (<div className="col-md-6 mb-2">
                          <div className="bostami-parsonal-info-contact-item">
                            <div
                              style={{ color: "#E93B81", fontSize: "22px" }}
                              className="icon"
                            >
                              <i className="ri-twitter-x-fill"></i>
                            </div>
                            <div className="text">
                              <span>Twitter(X)</span>
                              <p>{userProfile?.twitter}</p>
                            </div>
                          </div>
                        </div>)}
                        {userProfile?.instagram && (<div className="col-md-6 mb-2">
                          <div className="bostami-parsonal-info-contact-item">
                            <div
                              style={{ color: "#E93B81", fontSize: "22px" }}
                              className="icon"
                            >
                              <i className="ri-instagram-fill"></i>
                            </div>
                            <div className="text">
                              <span>Instagram</span>
                              <p>{userProfile?.instagram}</p>
                            </div>
                          </div>
                        </div>)}
                        {userProfile?.whatsapp_phone_number && (<div className="col-md-6 mb-2">
                          <div className="bostami-parsonal-info-contact-item">
                            <div
                              style={{ color: "#E93B81", fontSize: "22px" }}
                              className="icon"
                            >
                              <i className="ri-whatsapp-fill"></i>
                            </div>
                            <div className="text">
                              <span>WhatsApp</span>
                              <p>{userProfile?.whatsapp_phone_number}</p>
                            </div>
                          </div>
                        </div>)}
                        {userProfile?.website && (<div className="col-md-6 mb-2">
                          <div className="bostami-parsonal-info-contact-item">
                            <div
                              style={{ color: "#E93B81", fontSize: "22px" }}
                              className="icon"
                            >
                              <i className="ri-ie-fill"></i>
                            </div>
                            <div className="text">
                              <span>Website</span>
                              <p>{userProfile?.website}</p>
                            </div>
                          </div>
                        </div>)}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        {openQRModal && (
          <ScanCodeModal
            handleClose={toggleScanModal}
            open={openQRModal}
            handleAction={copyQR}
            qrcode_url={qrcode_url}
          />
        )}
      </div>
    </>
  );
}

export default ProfilePreview;
