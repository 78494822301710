import Avatar from "../../components/assets/img/logo_2.png";
// import "../../components/assets/css/dashboard.scoped.css";
// import "../../components/assets/css/style.scoped.css";
import { FormEvent, useCallback, useEffect, useState } from "react";
import { getData, putData } from "../../helpers/request";
import { Input } from "../../components/ui/Input/Input";
import { useInput } from "../../validation/InputValidation";
import CustomSelect from "../../components/ui/Select/Select";
import ConnectionModal from "../../components/ui/Modal/ConnectionModal";
import { UploadImage } from "./Component/UploadImage";
import { toast } from 'react-toastify';
import { UserTemplate } from "./Favorites";
import SessionStorageService from "../../services/sessionStorage";

export type UserProfile = {
  id: string;
  last_name: string;
  registration_type: string;
  first_name: string;
  other_name: string;
  prefix: string;
  suffix: string;
  is_corporate_profile: boolean;
  company_id: string;
  service_group_id: string;
  business_name: string;
  designation: string;
  about_me: string;
  email: string;
  password: string;
  country_code: string;
  Phone_number: string;
  corporate_id: string;
  show_corporate_services: boolean;
  create_corporate_services: boolean;
  photograph: string;
  address: string;
  address2: string;
  city: string;
  state: string;
  country: string;
  website: string;
  whatsapp_country_code: string;
  whatsapp_phone_number: string;
  facebook: string;
  twitter: string;
  linkedin: string;
  instagram: string;
  youtube: string;
  tiktok: string;
  plan_id: string;
  template_id: string;
  role_id: string;
  professional_field_id: string;
  status: string;
  date_confirmed: string;
  modified_by: string;
  agent_id: string;
  notify_new_connection: boolean;
  notify_service_click: boolean;
 // profile_access: boolean;
  private_mode: boolean;
  // private_share: boolean;
  // email_private: boolean;
  // phone_private: boolean;
  // address_private: boolean;
  // whatsapp_private: boolean;
  createdAt: string;
  updatedAt: string;
};

function ProfileEdit() {
  const [userProfile, setUserProfile] = useState<UserProfile>({
    id: "",
    last_name: "",
    registration_type: "",
    first_name: "",
    other_name: "",
    prefix: "",
    suffix: "",
    is_corporate_profile: false,
    company_id: "",
    service_group_id: "",
    business_name: "",
    designation: "",
    about_me: "",
    email: "",
    password: "",
    country_code: "",
    Phone_number: "",
    corporate_id: "",
    show_corporate_services: false,
    create_corporate_services: false,
    photograph: "",
    address: "",
    address2: "",
    city: "",
    state: "",
    country: "",
    website: "",
    whatsapp_country_code: "",
    whatsapp_phone_number: "",
    facebook: "",
    twitter: "",
    linkedin: "",
    instagram: "",
    youtube: "",
    tiktok: "",
    plan_id: "",
    template_id: "",
    role_id: "",
    professional_field_id: "",
    status: "",
    date_confirmed: "",
    modified_by: "",
    agent_id: "",
    notify_new_connection: false,
    notify_service_click: false,
   // profile_access: false,
    private_mode: false,
    // private_share: false,
    // email_private: true,
    // phone_private: true,
    // address_private: true,
    // whatsapp_private: true,
    createdAt: "",
    updatedAt: "",
  });
  // /const [prefix, setPrefix] = useState([]);
  // /const [suffix, setSuffix] = useState([]);
  const [requestLoader, setRequestLoader] = useState(false);
  const [imageModal, setImageModal] = useState(false);
  const [userTemplate, setUserTemplate] = useState<UserTemplate | null>(null);
  const [openQRModal, setQRModal] = useState(false);
  const [stepper, setStepper] = useState(1);

  // useEffect(() => {
  //   getUserTemplate();
  // }, []);

  const getUserTemplate = async () => {
    setRequestLoader(true);
    const res = await getData(`template/profile/owner`);

    if (res) {
      setRequestLoader(false);
      return setUserTemplate({ ...res });
    };
  }

  const [qrcode_url, setQrcode] = useState("");

  const copyQR = () => {
    navigator.clipboard.writeText(qrcode_url);
    toggleScanModal();
    toast.success('Successfully copied qr code');
  }

  useEffect(() => {
    const token = SessionStorageService.getItem("token");
    if (token) {
      const user = SessionStorageService.decodeToken();
      return setQrcode(user?.qrcode_url);
    }
  }, []);

  // const getAllPrefixes = async () => {
  //   setRequestLoader(true);
  //   const res = await getData(`/utility/prefixtitle`);
  //   if (res) {
  //     setRequestLoader(false);
  //     return setPrefix(res?.data);
  //   }
  // }

  // const getAllSuffixes = async () => {
  //   setRequestLoader(true);
  //   const res = await getData(`/utility/suffixtitle`);
  //   if (res) {
  //     setRequestLoader(false);
  //     return setSuffix(res?.data);
  //   }
  // }

  const getProfile = useCallback(async () => {
    const res = await getData(`/profile`);
    if (res) {
      setUserProfile({ ...res });
    }
  }, []);

  useEffect(() => {
    getProfile();
  }, [getProfile]);

  const emailInput = useInput("");

  const validateInput = (input: {
    value: string;
    setError: (value: boolean) => void;
  }) => {
    if (!input.value.trim()) {
      return input.setError(true);
    } else {
      input.setError(false);
    }
  };

  const showImageModal = () => {
    if (imageModal) return setImageModal(false);
    return setImageModal(true);
  }

  const handleImageUpload = (e: FormEvent) => {
    submit(e);
  };

  const submit = async (e: FormEvent) => {
    e.preventDefault();
    setRequestLoader(true);
    const id = toast.loading("...Loading");
    const res = await putData(`/profile`, userProfile);

    if (res?.isSuccess || res?.isSucess) {
      setRequestLoader(false);
      getProfile();
      setImageModal(false);
      return toast.update(id, { render: res?.message, type: "success", isLoading: false, autoClose: 5000, draggablePercent: 60, closeOnClick: true });
    }

    toast.update(id, { render: res?.message, type: "error", isLoading: false, autoClose: 5000, draggablePercent: 60, closeOnClick: true });
    return setRequestLoader(false);
  }

  const toggleScanModal = () => {
    if (openQRModal) return setQRModal(false);
    return setQRModal(true);
  }

  const prefix = ["Mr", "Master", "Miss", "Mrs"] as const;
  const suffix = ["Phd", "FCC", "MSc", "Prof"] as const;

  return (
    <>
      <div className="row">
        <div className="col-md-4 mb-3">
          <div className="card card-profile" style={{ paddingTop: "50px" }}>
            {/* <img
              src={userTemplate?.image}
              alt=""
              className="card-img-top card--format"
            /> */}
            <div className="row justify-content-center">
              <div className="mt-n4 mt-lg-n6 mb-4 mb-lg-0">
                <section className="container text-center m-auto position-relative">
                  {/* <form> */}
                  <div
                    role="presentation"
                    tabIndex={0}
                    className="dropzone"
                    style={{ cursor: "pointer" }}
                  >
                    <input
                      className="b-none"
                      accept="image/*"
                      multiple
                      type="file"
                      tabIndex={-1}
                      style={{ display: "none" }}
                    />
                    <div className="upload">
                      {" "}
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 24 24"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M13 19v-4h3l-4-5-4 5h3v4z" />
                        <path d="M7 19h2v-2H7c-1.654 0-3-1.346-3-3 0-1.404 1.199-2.756 2.673-3.015l.581-.102.192-.558C8.149 8.274 9.895 7 12 7c2.757 0 5 2.243 5 5v1h1c1.103 0 2 .897 2 2s-.897 2-2 2h-3v2h3c2.206 0 4-1.794 4-4a4.01 4.01 0 0 0-3.056-3.888C18.507 7.67 15.56 5 12 5 9.244 5 6.85 6.611 5.757 9.15 3.609 9.792 2 11.82 2 14c0 2.757 2.243 5 5 5z" />
                      </svg>{" "}
                    </div>
                  </div>
                  <div className="mt-n4 mt-lg-n6 mb-lg-0 text-center m-auto">
                    {userProfile?.photograph ? (
                      <img
                        src={userProfile?.photograph}
                        alt="placeholder"
                        className="upload_image img-fluid border border-2 border-white"
                        style={{
                          width: "108px",
                          height: "108px",
                          objectFit: "cover"
                        }}
                      />
                    ) : (
                      <img
                        src={Avatar}
                        alt="placeholder"
                        className="upload_image img-fluid border border-2 border-white"
                      />
                    )}
                  </div>
                  <div className="text-center m-auto mt-4">
                    <button
                      type="button"
                      className="btn btn-sm btn-dark float-right"
                      onClick={showImageModal}
                    >
                      Update Image
                    </button>
                  </div>
                  {/* </form> */}
                </section>
              </div>
            </div>
            <div className="card-body pt-0">
              <div className="text-center mt-3">
                <h5 className="fw-bold">
                  {" "}
                  {`${userProfile.first_name} ${userProfile.last_name}`}{" "}
                </h5>
                <p className="tex-sm">{userProfile.email}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-8">
          {/* Profile */}
          <div className="card px-2 px-lg-4 py-3 py-lg-0 mb-3">
            <div className="card-header bg-transparent pb-3 mb-3">
              <div className="d-flex align-items-center">
                <p className="mb-0 fw-bold">User Information</p>
              </div>
            </div>

            <div className="card-body">
              {/* <p className="text-uppercase text-sm">User Information</p> */}
              <form onSubmit={submit}>
                <div className="row">
                  {/* <div className="col-md-6 mb-2">
                    <CustomSelect
                      label="Prefix/Title"
                      id="prefix"
                      value={userProfile?.prefix}
                      onChange={(e) => { setUserProfile({ ...userProfile, prefix: e }) }}
                      options={prefix}
                    />
                  </div>
                  <div className="col-md-6 mb-2">
                    <CustomSelect
                      label="Suffix/Title"
                      id="suffix"
                      value={userProfile?.suffix}
                      onChange={(e) => { setUserProfile({ ...userProfile, suffix: e }) }}
                      options={suffix}
                    />
                  </div> */}
                  <div className="col-md-6 mb-2">
                    <Input
                      label="Email Address"
                      type="email"
                      name="emailorusername"
                      id="emailorusername"
                      placeholder="Enter Email"
                      required={true}
                      readonly={true}
                      defaultValue={userProfile.email}
                      {...emailInput}
                    />
                  </div>
                  <div className="col-md-6 mb-2">
                    <Input
                      label="First name"
                      type="text"
                      name="first_name"
                      id="first_name"
                      placeholder=""
                      required={true}
                      defaultValue={userProfile.first_name}
                      value={userProfile.first_name}
                      onChange={(e) => setUserProfile({ ...userProfile, first_name: e?.target?.value })}
                    />
                  </div>
                  <div className="col-md-6 mb-2">
                    <Input
                      label="Last name"
                      type="text"
                      name="last_name"
                      id="last_name"
                      placeholder=""
                      required={true}
                      defaultValue={userProfile.last_name}
                      value={userProfile.last_name}
                      onChange={(e) => setUserProfile({ ...userProfile, last_name: e?.target?.value })}
                    />
                  </div>
                  <div className="col-md-6 mb-2">
                    <Input
                      label="Phone"
                      type="tel"
                      name="phone"
                      id="phone"
                      placeholder=""
                      required={false}
                      defaultValue={userProfile.Phone_number}
                      value={userProfile.Phone_number}
                      onChange={(e) => setUserProfile({ ...userProfile, Phone_number: e?.target?.value })}
                    />
                  </div>
                  {/* <div className="col-md-6 mb-2">
                    <Input
                      label="Business name"
                      type="text"
                      name="business_name"
                      id="businessName"
                      placeholder="Enter business name"
                      required={false}
                      defaultValue={userProfile.business_name}
                      value={userProfile.business_name}
                      onChange={(e) => setUserProfile({ ...userProfile, business_name: e?.target?.value })}
                    />
                  </div>
                  <div className="col-md-6 mb-2">
                    <Input
                      label="Designation"
                      type="text"
                      name="designation"
                      id="designation"
                      placeholder="Enter designation"
                      required={false}
                      defaultValue={userProfile.designation}
                      value={userProfile.designation}
                      onChange={(e) => setUserProfile({ ...userProfile, designation: e?.target?.value })}
                    />
                  </div>
                  <div className="col-md-12 mb-2">
                    <Input
                      label="address"
                      type="text"
                      name="address"
                      id="address"
                      placeholder="Enter address"
                      required={false}
                      defaultValue={userProfile.address}
                      value={userProfile.address}
                      onChange={(e) => setUserProfile({ ...userProfile, address: e?.target?.value })}
                    />
                  </div>
                  <div className="col-md-12 mb-2">
                    <Input
                      label="address2"
                      type="text"
                      name="address2"
                      id="address2"
                      placeholder="Enter address 2"
                      required={false}
                      defaultValue={userProfile.address2}
                      value={userProfile.address2}
                      onChange={(e) => setUserProfile({ ...userProfile, address2: e?.target?.value })}
                    />
                  </div>

                  <div className="col-md-12 mt-4 mb-2 contact-form">
                    <div className="form-input-item mb-40">
                      <label className="input-lebel message">About me</label>
                      <textarea name="message"
                        className="input-box message false "
                        style={{ height: "70px" }}
                        rows={10}
                        value={userProfile?.about_me}
                        onChange={(e) => { setUserProfile({ ...userProfile, about_me: e?.target?.value }) }}
                      >
                      </textarea>
                    </div>
                  </div> */}
                </div>
                <button
                  className="btn btn-dark mt-3 w-100"
                  type="submit"
                  disabled={requestLoader}
                  style={{ fontSize: "14px" }}
                >
                  Update Information{" "}
                </button>
              </form>
            </div>
          </div>

          {/* Socials */}
          {/* <div className="card px-2 px-lg-4 py-3 py-lg-0 mt-5">
            <div className="card-header bg-transparent pb-3 mb-3">
              <div className="d-flex align-items-center">
                <p className="mb-0 fw-bold">Socials</p>
              </div>
            </div>

            <div className="card-body">
              <form onSubmit={submit}>
                <div className="row">
                  <div className="col-md-12 mb-2">
                    <Input
                      label="WhatsApp number"
                      type="tel"
                      name="whatsapp"
                      id="whatsapp"
                      placeholder="Enter WhatsApp number"
                      required={false}
                      defaultValue={userProfile.whatsapp_phone_number}
                      value={userProfile.whatsapp_phone_number}
                      onChange={(e) => setUserProfile({ ...userProfile, whatsapp_phone_number: e?.target?.value })}
                    />
                  </div>
                  <div className="col-md-12 mb-2">
                    <Input
                      label="Facebook"
                      type="url"
                      name="facebook"
                      id="facebook"
                      placeholder="Enter your facebook profile url"
                      required={false}
                      defaultValue={userProfile.facebook}
                      value={userProfile.facebook}
                      onChange={(e) => setUserProfile({ ...userProfile, facebook: e?.target?.value })}
                    />
                  </div>
                  <div className="col-md-12 mb-2">
                    <Input
                      label="Twitter"
                      type="url"
                      name="twitter"
                      id="twitter"
                      placeholder="Enter your twitter profile url"
                      required={false}
                      defaultValue={userProfile.twitter}
                      value={userProfile.twitter}
                      onChange={(e) => setUserProfile({ ...userProfile, twitter: e?.target?.value })}
                    />
                  </div>
                  <div className="col-md-12 mb-2">
                    <Input
                      label="Linkedin"
                      type="url"
                      name="linkedin"
                      id="linkedin"
                      placeholder="Enter linkedin profile url"
                      required={false}
                      defaultValue={userProfile.linkedin}
                      value={userProfile.linkedin}
                      onChange={(e) => setUserProfile({ ...userProfile, linkedin: e?.target?.value })}
                    />
                  </div>
                  <div className="col-md-12 mb-2">
                    <Input
                      label="Instagram"
                      type="url"
                      name="instagram"
                      id="instagram"
                      placeholder="Enter instagram url"
                      required={false}
                      defaultValue={userProfile.instagram}
                      value={userProfile.instagram}
                      onChange={(e) => setUserProfile({ ...userProfile, instagram: e?.target?.value })}
                    />
                  </div>
                  <div className="col-md-12 mb-2">
                    <Input
                      label="Tiktok"
                      type="url"
                      name="tiktok"
                      id="tiktok"
                      placeholder="Enter tiktok"
                      required={false}
                      defaultValue={userProfile.tiktok}
                      value={userProfile.tiktok}
                      onChange={(e) => setUserProfile({ ...userProfile, tiktok: e?.target?.value })}
                    />
                  </div>
                  <div className="col-md-12 mb-2">
                    <Input
                      label="Youtube"
                      type="url"
                      name="youtube"
                      id="youtube"
                      placeholder="Enter youtube url"
                      required={false}
                      defaultValue={userProfile.youtube}
                      value={userProfile.youtube}
                      onChange={(e) => setUserProfile({ ...userProfile, youtube: e?.target?.value })}
                    />
                  </div>
                  <div className="col-md-12 mb-2">
                    <Input
                      label="website"
                      type="url"
                      name="website"
                      id="website"
                      placeholder="Enter your website"
                      required={false}
                      defaultValue={userProfile.website}
                      value={userProfile.website}
                      onChange={(e) => setUserProfile({ ...userProfile, website: e?.target?.value })}
                    />
                  </div>
                </div>
                <button
                  className="btn btn-dark mt-3 w-100"
                  type="submit"
                  disabled={requestLoader}
                  style={{ fontSize: "14px" }}
                >
                  Update Socials{" "}
                </button>
              </form>
            </div>
          </div> */}

          {/* Privacy */}
          <div className="card px-2 px-lg-4 py-3 py-lg-0 mt-5">
            <div className="card-header bg-transparent pb-3 mb-3">
              <div className="d-flex align-items-center">
                <p className="mb-0 fw-bold">Privacy</p>
              </div>
            </div>

            <div className="card-body">
              <form onSubmit={submit}>
                <div className="row">
                  <div className="col-md-6 mb-2">
                    <div className="form-check form-check-sm mt-4">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={userProfile?.private_mode}
                        id="privateMode"
                        onChange={(e) => setUserProfile({ ...userProfile, private_mode: e?.target?.checked })}
                      />
                      <label className="form-check-label" htmlFor="privateMode">
                        {" "}
                        Private mode{" "}
                      </label>
                    </div>
                  </div>
                  {/* <div className="col-md-6 mb-2">
                    <div className="form-check form-check-sm mt-4">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={userProfile?.private_share}
                        id="privateShare"
                        onChange={(e) => setUserProfile({ ...userProfile, private_share: e?.target?.checked })}
                      />
                      <label className="form-check-label" htmlFor="privateShare">
                        {" "}
                        Private share{" "}
                      </label>
                    </div>
                  </div> */}
                  {/* <div className="col-md-6 mb-2">
                    <div className="form-check form-check-sm mt-4">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={userProfile?.phone_private}
                        id="phoneMode"
                        onChange={(e) => setUserProfile({ ...userProfile, phone_private: e?.target?.checked })}
                      />
                      <label className="form-check-label" htmlFor="phoneMode">
                        {" "}
                        Make phone private{" "}
                      </label>
                    </div>
                  </div> */}
                  {/* <div className="col-md-6 mb-2">
                    <div className="form-check form-check-sm mt-4">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={userProfile?.email_private}
                        id="emailMode"
                        onChange={(e) => setUserProfile({ ...userProfile, email_private: e?.target?.checked })}
                      />
                      <label className="form-check-label" htmlFor="emailMode">
                        {" "}
                        Make email private{" "}
                      </label>
                    </div>
                  </div> */}
                  {/* <div className="col-md-6 mb-2">
                    <div className="form-check form-check-sm mt-4">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={userProfile?.address_private}
                        id="addressMode"
                        onChange={(e) => setUserProfile({ ...userProfile, address_private: e?.target?.checked })}
                      />
                      <label className="form-check-label" htmlFor="addressMode">
                        {" "}
                        Make address private{" "}
                      </label>
                    </div>
                  </div> */}
                  {/* <div className="col-md-6 mb-2">
                    <div className="form-check form-check-sm mt-4">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={userProfile?.whatsapp_private}
                        id="whatsappMode"
                        onChange={(e) => setUserProfile({ ...userProfile, whatsapp_private: e?.target?.checked })}
                      />
                      <label className="form-check-label" htmlFor="whatsappMode">
                        {" "}
                        Make WhatsApp private{" "}
                      </label>
                    </div>
                  </div> */}
                  <div className="col-md-6 mb-2">
                    <div className="form-check form-check-sm mt-4">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={userProfile?.notify_new_connection}
                        id="newConnectionMode"
                        onChange={(e) => setUserProfile({ ...userProfile, notify_new_connection: e?.target?.checked })}
                      />
                      <label className="form-check-label" htmlFor="newConnectionMode">
                        {" "}
                        Notify new connection{" "}
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6 mb-2">
                    <div className="form-check form-check-sm mt-4">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={userProfile?.notify_service_click}
                        id="norif"
                        onChange={(e) => setUserProfile({ ...userProfile, notify_service_click: e?.target?.checked })}
                      />
                      <label className="form-check-label" htmlFor="norif">
                        {" "}
                        Notify service click{" "}
                      </label>
                    </div>
                  </div>
                </div>
                <button
                  className="btn btn-dark mt-3 w-100"
                  type="submit"
                  disabled={requestLoader}
                  style={{ fontSize: "14px" }}
                >
                  Update Privacy{" "}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      {imageModal && (
        <ConnectionModal
          title={"Upload image"}
          handleClose={showImageModal}
          ModalComponent={
            <UploadImage
              profile={userProfile}
              setUserProfile={setUserProfile}
              onProceed={(e: FormEvent) => {
                handleImageUpload(e);
              }}
              requestLoader={requestLoader}
              onClose={showImageModal}
            />
          }
          open={imageModal}
        />
      )}
    </>
  );
}

export default ProfileEdit;
