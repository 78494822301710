import AvatarIcon from "../../components/assets/img/avatar.png";
import dashboard from "../../components/assets/css/dashboard.module.css";
import styles from "../../components/assets/css/style.module.css";
import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { getData } from "../../helpers/request";
import PageLoader from "../../components/ui/PageLoader";
import { UserTemplate } from "./Favorites";
import Logo from "../../components/assets/img/logo.png";
import { useNavigate } from "react-router-dom";
import { Templates } from "./Component/CardTemplateDesigns";

export type FullUserCard = {
  id: string;
  image?: string;
  profile_id: string;
  last_name: string;
  first_name: string;
  other_name: string;
  prefix: string;
  suffix: string;
  business_name: string;
  designation: string;
  about_me: string;
  email: string;
  phone_number: string;
  photograph: string;
  address: string;
  address2: string;
  city: string;
  state: string;
  country: string;
  website: string;
  whatsapp_country_code: string;
  whatsapp_phone_number: string;
  facebook: string;
  twitter: string;
  linkedin: string;
  instagram: string;
  youtube: string;
  tiktok: string;
  professional_field_id: string;
  status: number;
  modified_by: string;
  createdAt: string;
  updatedAt: string;
  cardQrCodeUrl?: string;
  private_mode?: boolean;
  template_id?: string;
  template?: Templates;
  is_connected?: boolean;
  is_default_card?: boolean;
  is_subscribed?: boolean;
};

function Cards() {
  const [pageLoader, setPageLoader] = useState(false);
  const [cardProfile, setCardProfile] = useState<Array<FullUserCard>>([]);
  const [userTemplate, setUserTemplate] = useState<UserTemplate | null>(null);
  const [userHasSubscription, setUserSubscription] = useState(false);
  const navigate = useNavigate();

  const createCard = () => {
    return navigate("/dashboard/card/add");
  };

  useEffect(() => {
    getUserCards();
  }, []);

  const getUserCards = async () => {
    setPageLoader(true);
    const res = await getData(`card?page=1&size=10`);

    if (res) {
      setPageLoader(false);
      return setCardProfile(res?.data);
    }
  };

  useEffect(() => {
    getProfileStatus();
  }, []);

  const getProfileStatus = async () => {
    const res: any = await getData("/subscription/check/active");

    if (res?.isSuccess) {
      return setUserSubscription(res?.isSubscribed);
    }
    return setUserSubscription(res?.isSubscribed);
  };

  const pattern = new RegExp("^(https?|ftp)://");
  const twitterUrl = (username: string = "") => {
    return `https://twitter.com/${username}`;
  };

  const instagramUrl = (username: string = "") => {
    return `https://www.instagram.com/${username}`;
  };

  return (
    <>
      <div
        className={[
          dashboard["d-flex"],
          dashboard["justify-content-between"],
          dashboard["align-items-center"],
          dashboard["mb-3"],
        ].join(" ")}
      >
        <h5 className={dashboard["mb-0"]} />
        <ul
          className={[
            dashboard["d-flex"],
            dashboard["align-items-center"],
            dashboard["gap gx-2"],
          ].join(" ")}
        >
          <li>
            <div className={dashboard.dropdown}>
              <div className={dashboard["form-control-wrap"]}>
                {pageLoader ? (
                  <Skeleton />
                ) : (
                  <button
                    className={[
                      dashboard.btn,
                      dashboard["btn-primary"],
                      dashboard["btn-block"],
                    ].join(" ")}
                    type="button"
                    onClick={createCard}
                  >
                    <em
                      className={[
                        dashboard.icon,
                        "ri-add-fill",
                        dashboard["pe-2"],
                      ].join(" ")}
                    />
                    Create new card
                  </button>
                )}
              </div>
            </div>
          </li>
        </ul>
      </div>

      <div className={`${dashboard["nk-block"]} ${dashboard.row}`}>
        {pageLoader ? (
          <PageLoader />
        ) : (
          <>
            {cardProfile.length > 0 ? (
              <div className={`${dashboard["nk-block"]} ${dashboard.row}`}>
                {cardProfile.map((item: FullUserCard, index: number) => {
                  return (
                    <div
                      key={index}
                      className={`${dashboard["col-md-6"]} ${dashboard["mb-4"]}`}
                      style={{ cursor: "pointer" }}
                    >
                      <div
                        className={`${dashboard.card} ${styles.connection_card} ${dashboard["p-4"]}`}
                        style={{
                          backgroundImage: `url(${
                            item?.template?.image
                              ? item?.template?.image
                              : require("../../components/assets/img/page-bg_2.jpeg")
                          })`,
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          minHeight: "300px",
                        }}
                        key={index}
                      >
                        <div
                          className={[
                            dashboard.container,
                            dashboard["position-relative"],
                            dashboard["text-center"],
                            dashboard["m-auto"],
                          ].join(" ")}
                        >
                          {item?.photograph ? (
                            <img
                              src={item?.photograph}
                              className={dashboard["rounded-circle"]}
                              style={{ height: "95px" }}
                              onClick={() =>
                                navigate(`/dashboard/card/${item?.id}`)
                              }
                            />
                          ) : (
                            <img
                              src={Logo}
                              className={dashboard["rounded-circle"]}
                              style={{ height: "95px" }}
                              onClick={() =>
                                navigate(`/dashboard/card/${item?.id}`)
                              }
                            />
                          )}

                          <h2
                            className={`${dashboard["text-white"]} ${dashboard["pt-3"]}`}
                            onClick={() =>
                              navigate(`/dashboard/card/${item?.id}`)
                            }
                          >
                            {`${item?.first_name} ${item?.last_name}`}{" "}
                            {userHasSubscription && (
                              <span style={{ color: "#1773EA" }}>
                                <i className="ri-verified-badge-fill" />
                              </span>
                            )}
                          </h2>

                          <h6
                            className={`${dashboard["mt-0"]} ${dashboard["pt-0"]} ${dashboard["text-white"]}`}
                          >
                            {" "}
                            <i>{item?.business_name}</i>{" "}
                          </h6>

                          <p className={dashboard["text-white"]}>
                            {item?.designation}
                          </p>

                          <ul
                            className={`${dashboard["m-auto"]} ${dashboard["text-center"]} ${dashboard["justify-content-center"]}`}
                          >
                            {item?.facebook && (
                              <li>
                                <a href={`${item?.facebook}`} target="_blank">
                                  {" "}
                                  <i className="ri-facebook-fill" />{" "}
                                </a>
                              </li>
                            )}
                            {item?.instagram && (
                              <li>
                                <a
                                  href={
                                    pattern.test(item?.instagram)
                                      ? item?.instagram
                                      : instagramUrl(item?.instagram)
                                  }
                                  target="_blank"
                                >
                                  {" "}
                                  <i className="ri-instagram-fill" />{" "}
                                </a>
                              </li>
                            )}
                            {item?.twitter && (
                              <li>
                                <a
                                  href={
                                    pattern.test(item?.twitter)
                                      ? item?.twitter
                                      : twitterUrl(item?.twitter)
                                  }
                                  target="_blank"
                                >
                                  {" "}
                                  <i className="ri-twitter-fill" />{" "}
                                </a>
                              </li>
                            )}
                            {item?.whatsapp_phone_number && (
                              <li>
                                {" "}
                                <a
                                  href={`https://wa.me/${item?.whatsapp_country_code}${item?.whatsapp_phone_number}`}
                                  target="_blank"
                                >
                                  <i className="ri-whatsapp-fill" />{" "}
                                </a>
                              </li>
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div
                className={dashboard["tab-pane"]}
                id="trash-documents-tab-pane"
              >
                <div
                  className={`${dashboard.row} ${dashboard["justify-content-center"]} ${dashboard["py-5"]}`}
                >
                  <div
                    className={`${dashboard["col-md-7"]} ${dashboard["col-lg-4"]}`}
                  >
                    <div
                      className={`${dashboard["mb-5"]} ${dashboard["text-center"]}`}
                    >
                      <img src="images/illustrations/no-documents.svg" alt="" />
                    </div>
                    <div
                      className={`${dashboard["nk-block-head"]} ${dashboard["text-center"]}`}
                    >
                      <div className={`${dashboard["nk-block-head-content"]}`}>
                        <h1
                          className={`${dashboard["nk-block-title"]} ${dashboard["mb-2"]}`}
                        >
                          You do not have any cards yet.
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default Cards;
