import { ChangeEvent } from "react";
import button from "../../../pages/assets/css/global.module.css";
import PasswordVisible from "../../assets/img/password-eye-icon.svg";
import PasswordClose from "../../assets/img/Password-visible-icon.svg";

interface InputProps {
  type: "text" | "number" | "email" | "password" | "tel" | "url";
  label: string;
  value: string | number | undefined;
  name: string;
  placeholder: string;
  error?: boolean;
  errorMessage?: string;
  disabled?: boolean;
  readonly?: boolean;
  required: boolean;
  id: string;
  defaultValue?: string | number;
  inputType?: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  togglePassword?: () => void;
}

export const Input = (props: InputProps) => {
  const {
    label,
    type,
    value,
    name,
    placeholder,
    error,
    errorMessage,
    disabled,
    readonly,
    required,
    onChange,
    id,
    defaultValue,
    togglePassword,
  } = props;

  return (
    <div className={button["form-group"]}>
      <label className={button["form-label"]} htmlFor={id}>
        {label}
      </label>

      <div className={button["form-control-wrap"]}>
        {props.inputType === "password" && (
          <div>
            <span
              className={[
                button["form-control-icon"],
                button["end"],
                button["password-toggle"],
              ].join(" ")}
              title="Toggle show/hide password"
              onClick={togglePassword}
            >
              {type === "password" ? (
                <img
                  src={PasswordVisible}
                  alt="password visibility"
                  className={[
                    button["on"],
                    button["icon"],
                    button["text-base"],
                    button["password-icon"]
                  ].join(" ")}
                />
              ) : (
                <img
                  src={PasswordClose}
                  alt="password hide"
                  className={[
                    button["on"],
                    button["icon"],
                    button["text-base"],
                    button["password-icon"]
                  ].join(" ")}
                />
              )}
            </span>
          </div>
        )}

        {type === "url" ? (
          <input
            type={type}
            name={name}
            id={id}
            className={[button["form-control"], button["form-control-lg"]].join(
              " "
            )}
            placeholder={placeholder}
            pattern="http(s?)://.*"
            required={required}
            disabled={disabled}
            readOnly={readonly}
            value={value ? value : defaultValue}
            onChange={onChange}
            defaultValue={defaultValue}
          />
        ) : (
          <input
            type={type}
            name={name}
            id={id}
            className={[button["form-control"], button["form-control-lg"]].join(
              " "
            )}
            placeholder={placeholder}
            required={required}
            disabled={disabled}
            readOnly={readonly}
            value={value ? value : defaultValue}
            onChange={onChange}
            defaultValue={defaultValue}
          />
        )}
        {error && (
          <p className={button.error} style={{ color: "#ce5a56" }}>
            {errorMessage}
          </p>
        )}
      </div>
    </div>
  );
};
