import { useCallback, useEffect, useState } from "react";
import "../../components/assets/css/dashboard.scoped.css";
import "../../components/assets/css/style.scoped.css";
import "../../components/assets/css/radio.scoped.css";
import { getData, postData } from "../../helpers/request";
import Logo from "../../components/assets/img/logo.png";
import { Popover } from "@headlessui/react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import RevokeModal from "../../components/ui/Modal/SuccessModal";
import PageLoader from "../../components/ui/PageLoader";

export type Connection = {
  card_id: string;
  profile_id: string;
}

export interface Connections {
  profile_id: string;
  Phone_number: string;
  last_name: string;
  first_name: string;
  other_name: string;
  photograph: string;
  whatsapp_phone_number: string;
  whatsapp_country_code: string;
  email: string;
  card_id: string;
  is_revoked: boolean;
}

function ConnectedProfiles() {
  const [allConnections, setAllConnections] = useState<Array<Connections>>([]);
  const [openRevokeModal, setRevokeModal] = useState(false);
  const [openFavouriteModal, setFavouriteModal] = useState(false);
  const [connection, setCreateConnection] = useState<Connection>({
    card_id: "",
    profile_id: "",
  })
  const [requestLoader, setRequestLoader] = useState(false);
  const [proceedText, setProceedText] = useState("They will not be able to view your shared card!");
  const [buttonText, setButtonText] = useState("Yes, Revoke");
  const [connections, setConnections] = useState<Connections | null>(null);
  const [pageLoader, setPageLoader] = useState(false);

  const { id } = useParams();

  const getProfileConnections = useCallback(async () => {
    setPageLoader(true);
    const res = await getData(`/profile/card/${id?.toString()}?page=1&size=1000`);
    if (res) {
      setAllConnections(res?.data);
      setPageLoader(false);
    }
  }, []);

  useEffect(() => {
    getProfileConnections();
  }, []);

  const toggleRevokeModal = () => {
    if (openRevokeModal) return setRevokeModal(false);
    return setRevokeModal(true);
  }

  const toggleFavouriteModal = () => {
    if (openFavouriteModal) return setFavouriteModal(false);
    return setFavouriteModal(true);
  }

  const handleRevokeModal = (id: string, profileId: string) => {
    setCreateConnection({
      card_id: id,
      profile_id: profileId,
    })
    return setRevokeModal(true);
  }

  const handleFavouriteModal = (id: string, profileId: string) => {
    setCreateConnection({
      card_id: id,
      profile_id: profileId,
    })
    return setFavouriteModal(true);
  }

  const handleRevoke = async () => {
    setRequestLoader(true);
    const id = toast.loading("...Loading");
    const payload = {
      card_id: connections?.card_id,
      profile_id_to_revoke: connection?.profile_id,
    };
    const res = await postData(`/cardconnection/revoke`, payload);

    if (res?.isSuccess || res?.isSucess) {
      setRequestLoader(false);
      toggleRevokeModal();
      getProfileConnections();
      return toast.update(id, { render: res?.message, type: "success", isLoading: false, autoClose: 5000, draggablePercent: 60, closeOnClick: true  });
    }

    toast.update(id, { render: res?.message, type: "error", isLoading: false, autoClose: 5000, draggablePercent: 60, closeOnClick: true  });
    return setRequestLoader(false);
  };

  const handleUnrevoke = async () => {
    setRequestLoader(true);
    const id = toast.loading("...Loading");
    const payload = {
      card_id: connections?.card_id,
      profile_id_to_unrevoke: connection?.profile_id,
    };
    const res = await postData(`cardconnection/unrevoke`, payload);

    if (res?.isSuccess || res?.isSucess) {
      setRequestLoader(false);
      toggleFavouriteModal();
      getProfileConnections();
      return toast.update(id, { render: res?.message, type: "success", isLoading: false, autoClose: 5000, draggablePercent: 60, closeOnClick: true });
    }

    toast.update(id, { render: res?.message, type: "error", isLoading: false, autoClose: 5000, draggablePercent: 60, closeOnClick: true });
    return setRequestLoader(false);
  }

  return (
    <>
      <div>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h5 className="mb-0">Connections</h5>
        </div>

        <div className="card shadow-none">
          <ul className="nav nav-tabs nav-tabs-s1 px-4">
            <li className="nav-item">
              <button
                className="nav-link active"
                type="button"
                data-bs-toggle="tab"
                data-bs-target="#recents-documents-tab-pane"
              >
                Your Connections
              </button>
            </li>
          </ul>
          <div className="tab-content">
            <div
              className="tab-pane fade show active"
              id="recents-documents-tab-pane"
            >
              <table className="table table-middle mb-0">
                {pageLoader ? (<PageLoader />) : (
                  <>
                    {allConnections.length > 0 ? (
                      <tbody>
                        {allConnections.map((connect: Connections, index: number) => {
                          return (
                            <>
                              <tr key={index}>
                                <td className="tb-col">
                                  <div className="caption-text line-clamp-2">
                                    {/* {connect?.photograph} */}
                                    {connect?.photograph ? (
                                      <img 
                                        src={connect?.photograph} 
                                        alt="profile image" 
                                        style={{ height: "50px", width: "50px" }} 
                                      />
                                    ) : (
                                      <img 
                                        src={Logo} 
                                        alt="user profile picture"
                                        style={{ height: "50px", width: "50px" }} 
                                      />
                                    )}
                                  </div>
                                </td>
                                <td className="tb-col tb-col-sm">
                                  <div className="badge text-bg-blue-soft rounded-pill px-2 py-1 fs-6 lh-sm">
                                    {`${connect?.first_name} ${connect?.last_name}`}
                                  </div>
                                </td>
                                <td className="tb-col tb-col-md">
                                  <div className="fs-6 text-light d-inline-flex flex-wrap gap gx-2">
                                    <span>{connect?.email} </span>
                                    <span>{connect?.Phone_number} </span>
                                  </div>
                                </td>
                                <td className="tb-col tb-col-end">
                                  <Popover className="dropdown">
                                    <Popover.Button className="btn btn-sm btn-icon btn-zoom me-n1" onClick={() => { setConnections({ ...connect }) }}>
                                      <em className="icon ri-separator" />
                                    </Popover.Button>

                                    <Popover.Panel>
                                      <div
                                        className="dropdown-menu dropdown-menu-end show"
                                        style={{
                                          position: "absolute",
                                          inset: "0px 0px auto auto",
                                          transform: "translate3d(-24px, 32px, 0px)"
                                        }}>
                                        <div className="dropdown-content">
                                          <ul className="link-list link-list-hover-bg-primary link-list-md">
                                            {!connect?.is_revoked && (
                                              <li>
                                                <a onClick={() => { handleRevokeModal(connect?.card_id, connect?.profile_id) }} className="pointer">
                                                  <em className="ri-link-unlink pe-2" />
                                                  <span>Revoke connection</span>
                                                </a>
                                              </li>
                                            )}
                                            {connect?.is_revoked && (
                                              <li>
                                                <a onClick={() => { handleFavouriteModal(connect?.card_id, connect?.profile_id) }} className="pointer">
                                                  <em className="ri-link pe-2" />
                                                  <span>Unrevoke connection</span>
                                                </a>
                                              </li>
                                            )}
                                          </ul>
                                        </div>
                                      </div>
                                    </Popover.Panel>
                                  </Popover>
                                </td>
                              </tr>
                            </>
                          )
                        })}
                      </tbody>
                    ) : (
                      <div className="tab-pane" id="trash-documents-tab-pane">
                        <div className="row justify-content-center py-5">
                          <div className="col-md-7 col-lg-4">
                            <div className="mb-5 text-center">
                              <img src="images/illustrations/no-documents.svg" alt="" />
                            </div>
                            <div className="nk-block-head text-center">
                              <div className="nk-block-head-content">
                                <h1 className="nk-block-title mb-2">
                                  You do not have any connections.
                                </h1>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </table>
            </div>
          </div>
        </div>

        {openRevokeModal && (
          <RevokeModal
            title={"Revoke connection!"}
            handleAction={handleRevoke}
            handleClose={toggleRevokeModal}
            open={openRevokeModal}
            proceedText={proceedText}
            buttonText={buttonText}
            showOtherButton={true}
            requestLoader={requestLoader}
            otherButtonText={"Cancel"}
          />
        )}

        {openFavouriteModal && (
          <RevokeModal
            title={"Unrevoke Connection!"}
            handleAction={handleUnrevoke}
            handleClose={toggleFavouriteModal}
            open={openFavouriteModal}
            proceedText={"They can see your shared card!"}
            buttonText={"Yes, connect"}
            showOtherButton={true}
            requestLoader={requestLoader}
            otherButtonText={"Cancel"}
          />
        )}

      </div >
    </>
  );
}

export default ConnectedProfiles;
