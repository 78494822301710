import jwt_decode from "jwt-decode";

export type Token = {
  profile_id: string;
  email: string;
  first_name: string;
  last_name: string;
  company_id?: string;
  roles: Array<String>;
  is_subscribed: boolean;
  is_profile_updated: boolean;
  qrcode_url: string;
  is_company_admin?: boolean;
  is_cardgit_admin?: boolean;
};

export default class SessionStorageService {
  public static setItem(key: string, value: any): void {
    window.sessionStorage.setItem(key, value);
  }

  public static setUser(key: string, value: any): void {
    window.sessionStorage.setItem(key, value);
  }

  public static setCurrency(key: string, value: any): void {
    window.sessionStorage.setItem(key, value);
  }

  public static getUser(key: string): any {
    const item = window.sessionStorage.getItem(key);
    return item;
  }

  public static getItem(key: string): any {
    const item = window.sessionStorage.getItem(key);
    return item;
  }

  public static user(): Token {
    let decoded = this.getItem("user");
    decoded = JSON.parse(decoded);
    return decoded;
  }

  public static decodeToken(): Token {
    let decoded = this.getItem("token");
    decoded = jwt_decode(decoded);
    return decoded?.data;
  }

  public static removeItem(key: string) {
    window.sessionStorage.removeItem(key);
  }

  public static clear() {
    window.sessionStorage.clear();
  }
}
