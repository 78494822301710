import Avatar from "../../components/assets/img/logo_2.png";
// import "../../components/assets/css/dashboard.scoped.css";
// import "../../components/assets/css/style.scoped.css";
import { FormEvent, useCallback, useEffect, useState } from "react";
import { getData, putData } from "../../helpers/request";
import { Input } from "../../components/ui/Input/Input";
import { useInput } from "../../validation/InputValidation";
import { toast } from 'react-toastify';
import SessionStorageService from "../../services/sessionStorage";
import { useNavigate } from "react-router-dom";
import { UserTemplate } from "./Favorites";

export type UserProfile = {
  id: string;
  last_name: string;
  registration_type: string;
  first_name: string;
  other_name: string;
  prefix: string;
  suffix: string;
  is_corporate_profile: boolean;
  company_id: string;
  service_group_id: string;
  business_name: string;
  designation: string;
  about_me: string;
  email: string;
  password: string;
  country_code: string;
  Phone_number: string;
  corporate_id: string;
  show_corporate_services: boolean;
  create_corporate_services: boolean;
  photograph: string;
  address: string;
  address2: string;
  city: string;
  state: string;
  country: string;
  website: string;
  whatsapp_country_code: string;
  whatsapp_phone_number: string;
  facebook: string;
  twitter: string;
  linkedin: string;
  instagram: string;
  youtube: string;
  tiktok: string;
  plan_id: string;
  template_id: string;
  role_id: string;
  professional_field_id: string;
  status: string;
  date_confirmed: string;
  modified_by: string;
  agent_id: string;
  notify_new_connection: boolean;
  notify_service_click: boolean;
  profile_access: boolean;
  private_mode: boolean;
  private_share: boolean;
  email_private: boolean;
  phone_private: boolean;
  address_private: boolean;
  whatsapp_private: boolean;
  createdAt: string;
  updatedAt: string;
};

function Settings() {
  const navigate = useNavigate();
  const [userProfile, setUsrProfile] = useState<UserProfile>({
    id: "",
    last_name: "",
    registration_type: "",
    first_name: "",
    other_name: "",
    prefix: "",
    suffix: "",
    is_corporate_profile: false,
    company_id: "",
    service_group_id: "",
    business_name: "",
    designation: "",
    about_me: "",
    email: "",
    password: "",
    country_code: "",
    Phone_number: "",
    corporate_id: "",
    show_corporate_services: false,
    create_corporate_services: false,
    photograph: "",
    address: "",
    address2: "",
    city: "",
    state: "",
    country: "",
    website: "",
    whatsapp_country_code: "",
    whatsapp_phone_number: "",
    facebook: "",
    twitter: "",
    linkedin: "",
    instagram: "",
    youtube: "",
    tiktok: "",
    plan_id: "",
    template_id: "",
    role_id: "",
    professional_field_id: "",
    status: "",
    date_confirmed: "",
    modified_by: "",
    agent_id: "",
    notify_new_connection: false,
    notify_service_click: false,
    profile_access: false,
    private_mode: false,
    private_share: false,
    email_private: true,
    phone_private: true,
    address_private: true,
    whatsapp_private: true,
    createdAt: "",
    updatedAt: "",
  });
  const [type, setType] = useState<"text" | "number" | "email" | "password">(
    "password"
  );
  const [newType, setNewType] = useState<"text" | "number" | "email" | "password">(
    "password"
  );
  const [confirmType, setConfirmType] = useState<"text" | "number" | "email" | "password">(
    "password"
  );
  const [requestLoader, setRequestLoader] = useState(false);
  const passwordInput = useInput("");
  const newPasswordInput = useInput("");
  const confirmPasswordInput = useInput("");
  const [userTemplate, setUserTemplate] = useState<UserTemplate | null>(null);

  const getUserTemplate = async () => {
    setRequestLoader(true);
    const res = await getData(`template/profile/owner`);

    if (res) {
      setRequestLoader(false);
      return setUserTemplate({ ...res });
    };
  }

  const validateInput = (input: {
    value: string;
    errorMessage: string;
    setError: (value: boolean) => void;
    setErrorMessage: (value: string) => void;
  }) => {
    if (!input.value.trim()) {
      input.setError(true);
      return input.setErrorMessage("Input filed can't be empty!");
    }
    input.setError(false);
    input.setErrorMessage("");
  };

  const togglePassword = () => {
    if (type === "password") return setType("text");
    return setType("password");
  };

  const toggleNewPassword = () => {
    if (newType === "password") return setNewType("text");
    return setNewType("password");
  };

  const toggleConfirmPassword = () => {
    if (confirmType === "password") return setConfirmType("text");
    return setConfirmType("password");
  };

  const getProfile = useCallback(async () => {
    const res = await getData(`/profile`);
    if (res) {
      setUsrProfile({ ...res });
    }
  }, []);

  useEffect(() => {
    getProfile();
  }, [getProfile]);

  // useEffect(() => {
  //   getUserTemplate();
  // }, []);

  const editProfile = () => {
    return navigate("/dashboard/profile-edit");
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    validateInput(passwordInput);
    validateInput(newPasswordInput);
    validateInput(confirmPasswordInput);

    const payload = {
      password: passwordInput.value,
      new_password: newPasswordInput.value,
      confirm_new_password: confirmPasswordInput.value,
    };

    if (passwordInput.error) return;
    if (confirmPasswordInput.error) return;

    setRequestLoader(true);
    const id = toast.loading("...Loading");
    const res: any = await putData("/profile/changepassword", payload);

    if (res.isSuccess) {
      setRequestLoader(false);
      toast.update(id, { render: res?.message, type: "success", isLoading: false, autoClose: 5000, draggablePercent: 60, closeOnClick: true });
      SessionStorageService.clear();
      return navigate("/login");
    }
    if (!res.isSuccess) {
      setRequestLoader(false);
      return toast.update(id, { render: res?.message, type: "error", isLoading: false, autoClose: 5000, draggablePercent: 60, closeOnClick: true });
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-md-4 mb-3">
          <div className="card card-profile" style={{ paddingTop: "50px" }}>
            {/* <img
              src={userTemplate?.image}
              alt="placeholder"
              className="card-img-top card--format"
            /> */}
            <div className="row justify-content-center">
              <div className="mt-n4 mt-lg-n6 mb-4 mb-lg-0">
                <section className="container text-center m-auto position-relative">
                  <form>
                    <div className="mt-n4 mt-lg-n6 mb-lg-0 text-center m-auto">
                      {userProfile?.photograph ? (
                        <img
                          src={userProfile?.photograph}
                          alt="placeholder"
                          className="upload_image img-fluid border border-2 border-white"
                          style={{
                            width: "108px",
                            height: "108px",
                            objectFit: "cover"
                          }}
                        />
                      ) : (
                        <img
                          src={Avatar}
                          alt="placeholder"
                          className="upload_image img-fluid border border-2 border-white"
                        />
                      )}
                    </div>
                  </form>
                </section>
              </div>
            </div>
            <div className="card-body pt-0">
              <div className="text-center mt-3">
                <h5 className="fw-bold">
                  {" "}
                  {`${userProfile.first_name} ${userProfile.last_name}`}{" "}
                </h5>
                <p className="tex-sm">{userProfile.email}</p>
              </div>

              <div className="text-center m-auto mt-4">
                <button
                  type="button"
                  className="btn btn-sm btn-dark float-right"
                  onClick={editProfile}
                >
                  Edit profile
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-8">
          <div className="card px-2 px-lg-4 py-3 py-lg-0">

            <div className="card-body">

              <p className="text-uppercase text-sm">Password</p>

              <div className="password">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-12 mb-4">
                      <Input
                        label="Current Password"
                        inputType="password"
                        type={type}
                        name="currentPassword"
                        id="toggle-password"
                        placeholder="Enter current password"
                        required={true}
                        togglePassword={togglePassword}
                        {...passwordInput}
                      />
                    </div>
                    <div className="col-md-6 mb-4">
                      <Input
                        label="New Password"
                        inputType="password"
                        type={newType}
                        name="newPassword"
                        id="new-password"
                        placeholder="Enter current password"
                        required={true}
                        togglePassword={toggleNewPassword}
                        {...newPasswordInput}
                      />
                    </div>
                    <div className="col-md-6 mb-4">
                      <Input
                        label="Confirm Password"
                        inputType="password"
                        type={confirmType}
                        name="confirmPassword"
                        id="confirm-password"
                        placeholder="Enter current password"
                        required={true}
                        togglePassword={toggleConfirmPassword}
                        {...confirmPasswordInput}
                      />
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="btn btn-dark w-100"
                    style={{ fontSize: "14px" }}
                    disabled={
                      passwordInput.error ||
                      newPasswordInput.error ||
                      requestLoader
                    }
                  >
                    {" "}
                    Update Password{" "}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Settings;
