import { useEffect, useState } from "react";
import upgrade from "../../components/assets/css/upgrade.module.css";
import styles from "../../pages/assets/css/global.module.css";
// import button from "../../pages/assets/css/button.module.css";
import { getData, postData } from "../../helpers/request";
import Skeleton from "react-loading-skeleton";
import ConnectionModal from "./Modal/ConnectionModal";
import SessionStorageService from "../../services/sessionStorage";
import { UserInfo } from "../../store/AuthContext";
import { ViewProfileSummary } from "../../views/Dashboard/Component/ViewProfileSummary";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  CompanyInfo,
  ViewAndCreateCompany,
} from "../../views/Dashboard/Component/ViewAndCreateCompany";
import { UpgradeSummary } from "../../views/Dashboard/Component/UpgradeSummary";

type FeeType = {
  type: string;
  fee: string | number;
};

type benefits = Array<String>;

export type FeeTypeAndBenefits = {
  feetype: FeeType;
  benefits: benefits;
  user?: number;
};

export type SubscriptionType = {
  monthly_fee: number;
  save_percent_on_yearly_sub: string;
  yearly_fee_with_discount: number;
  yearly_fee_without_discount: number;
  user?: number;
  feetype?: FeeType;
};

export type PaystackResponse = {
  reference: string;
  trans: string;
  status: string;
  message: string;
  transaction: string;
  trxref: string;
  redirecturl: string;
};

export const calculatePayStackFee = (amount: number | string = 0) => {
  let paystackAmount = Number(amount);

  return paystackAmount * 100;
};

export const formatCurrency = (amount: number | string = 0) => {
  if (!amount) {
    amount = 0;
  }

  let amountToBeUsedForCalculation = Number(amount);

  amountToBeUsedForCalculation = Number(amount);

  const amountInLocaleString = amountToBeUsedForCalculation
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, "$&,");

  if (amountToBeUsedForCalculation < 0) {
    return `${amountInLocaleString}`;
  }
  return `${amountInLocaleString}`;
};

export interface BusinessPayload {
  subscription_type: string;
  target: string;
  users: number;
  total_fee_paid: number;
  paystack: {
    reference: string;
  };
  company: CompanyInfo;
}

function UpgradePlan() {
  const [currency, setCurrency] = useState("");
  const [pageLoader, setPageLoader] = useState(false);
  const [feeTypeBenefits, setFeeTypeBenefits] = useState<
    Array<SubscriptionType>
  >([]);
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
  const [activeTab, setActiveTab] = useState(1);
  const [plan, setPlan] = useState<SubscriptionType | null>(null);
  const [summaryUpgrade, setSummaryUpgrade] = useState(false);
  const [summaryModal, setSummaryModal] = useState(false);
  const [summaryBusinessModal, setSummaryBusinessModal] = useState(false);
  const [requestLoader, setRequestLoader] = useState(false);
  const [userNumber, setUserNumber] = useState(0);
  const [userNumberError, setUserNumberError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    getCurrency();
    getFeeTypeAndBenefits();
  }, []);

  useEffect(() => {
    const token = SessionStorageService.getItem("token");
    if (token) {
      const user = SessionStorageService.decodeToken();
      return setUserInfo(user);
    }
  }, []);

  const getCurrency = async () => {
    setPageLoader(true);
    const res: any = await getData("/utility/currency");

    if (res) {
      setPageLoader(false);
      return setCurrency(res?.currency);
    }
  };

  const getFeeTypeAndBenefits = async () => {
    setPageLoader(true);
    const res: any = await getData("/utility/subscriptionfee");

    if (res) {
      setPageLoader(false);
      return setFeeTypeBenefits(res);
    }
  };

  const toggleCardModal = () => {
    if (summaryModal) {
      setUserNumber(0);
      setUserNumberError("");
      return setSummaryModal(false);
      // return setPlan(null);
    }
    return setSummaryModal(true);
  };

  const toggleUpgradeModal = () => {
    if (summaryUpgrade) {
      return setSummaryUpgrade(false);
      // return setPlan(null);
    }
    return setSummaryUpgrade(true);
  };

  const toggleBusinessCardModal = () => {
    if (summaryBusinessModal) {
      setUserNumber(0);
      setUserNumberError("");
      return setSummaryBusinessModal(false);
      // return setPlan(null);
    }
    return setSummaryBusinessModal(true);
  };

  const handleSubscription = () => {
    if (Number(userNumber) === 0)
      return setUserNumberError("Please enter number of user(s) here");
    if (Number(userNumber) < 0)
      return setUserNumberError("Enter a valid number of user(s)");
    toggleUpgradeModal();
    if (Number(userNumber) === 1) return toggleCardModal();
    return setBusinessPlan(plan as SubscriptionType);
  };

  const checkout = (subscription: SubscriptionType) => {
    setPlan(subscription);
    toggleUpgradeModal();
  };

  const setBusinessPlan = (subscription: SubscriptionType) => {
    if (userNumber === 0)
      return setUserNumberError("Number of user(s) is required!");
    if (userNumber < 0)
      return setUserNumberError("Enter a valid number of users");
    const fee =
      activeTab === 1
        ? subscription?.monthly_fee * Number(userNumber)
        : subscription?.yearly_fee_with_discount * Number(userNumber);
    const typeFee = activeTab === 1 ? "Monthly" : "Yearly";
    const feetype = {
      type: typeFee,
      fee: fee,
    };
    const user = Number(userNumber);
    setPlan({ ...subscription, feetype, user });
    toggleBusinessCardModal();
  };

  const createUserSub = async (response: PaystackResponse) => {
    const reqBody = {
      subscription_type: activeTab === 1 ? "Monthly" : "Yearly",
      target: "Individual",
      users: 1,
      total_fee_paid:
        activeTab === 1
          ? Number(plan?.monthly_fee)
          : Number(plan?.yearly_fee_with_discount),
      paystack: {
        reference: response?.reference,
      },
    };

    setRequestLoader(true);
    const id = toast.loading("...Loading");
    const res = await postData(`subscription`, reqBody);

    if (res?.isSuccess || res?.isSucess) {
      setRequestLoader(false);
      toggleCardModal();
      toast.update(id, {
        render: `${res?.message}, please log in again`,
        type: "success",
        isLoading: false,
        autoClose: 10000,
        draggablePercent: 60,
      });
      SessionStorageService.clear();
      navigate("/login");
      return;
    }

    toast.update(id, {
      render: res?.message,
      type: "error",
      isLoading: false,
      autoClose: 10000,
      draggablePercent: 60,
    });
    return setRequestLoader(false);
  };

  const createBusinessSub = async (payload: BusinessPayload) => {
    setRequestLoader(true);
    const id = toast.loading("...Loading");
    const res = await postData(`profile/createcompany`, payload);

    if (res?.isSuccess || res?.isSucess) {
      setRequestLoader(false);
      toggleBusinessCardModal();
      toast.update(id, {
        render: `${res?.message}, please log in again`,
        type: "success",
        isLoading: false,
        autoClose: 10000,
        draggablePercent: 60,
      });
      SessionStorageService.clear();
      navigate("/login");
      return;
    }

    toast.update(id, {
      render: res?.message,
      type: "error",
      isLoading: false,
      autoClose: 10000,
      draggablePercent: 60,
    });
    return setRequestLoader(false);
  };

  return (
    <>
      <div className={upgrade.container}>
        <>
          <section
            id="personal"
            className={[
              styles.section,
              styles["mt-5p"],
              upgrade["section"],
            ].join(" ")}
          >
            <div className={upgrade.container}>
              <div className={`${styles["section-content"]}`}>
                {pageLoader ? (
                  <Skeleton className={upgrade["empty-skeleton"]} />
                ) : (
                  <div
                    className={[
                      styles["row"],
                      styles["g-gs"],
                      styles["justify-content-between"],
                      styles["align-items-center"],
                    ].join(" ")}
                  >
                    <div
                      className={`${styles["col-lg-6"]} ${styles["col-md-6"]}`}
                    >
                      <div>
                        {pageLoader ? (
                          <Skeleton />
                        ) : (
                          <h4
                            className={styles["text-muted"]}
                            style={{ textAlign: "left", fontSize: "0.85rem" }}
                          >
                            PRICING
                          </h4>
                        )}
                        {pageLoader ? (
                          <Skeleton />
                        ) : (
                          <h3
                            className={styles.title}
                            style={{ textAlign: "left" }}
                          >
                            Start your Profile with CardGit
                          </h3>
                        )}
                        {pageLoader ? (
                          <Skeleton />
                        ) : (
                          <p
                            className={styles.lead}
                            style={{ textAlign: "left" }}
                          >
                            Select a plan that work for you
                          </p>
                        )}
                      </div>
                    </div>

                    <div
                      className={`${styles["col-lg-6"]} ${styles["col-md-6"]}`}
                    >
                      <div
                        className={upgrade["price-toggler"]}
                        style={{ marginBottom: "30px" }}
                      >
                        <span
                          className={`${
                            activeTab === 1 ? `${upgrade.active}` : ""
                          } month`}
                          onClick={() => setActiveTab(1)}
                        >
                          Monthly
                        </span>
                        <span
                          className={`${
                            activeTab === 2 ? `${upgrade.active}` : ""
                          } year`}
                          onClick={() => setActiveTab(2)}
                        >
                          Yearly (Save{" "}
                          {feeTypeBenefits[0]?.save_percent_on_yearly_sub})
                        </span>
                      </div>

                      <div className={styles.pricing}>
                        <div
                          className={`${styles["pricing-body"]} ${styles["p-5"]}`}
                        >
                          <div className={styles["text-center"]}>
                            <h3
                              className={[styles["h2"], styles["mb-4"]].join(
                                " "
                              )}
                              style={{ fontSize: "1.5rem" }}
                            >
                              {currency}{" "}
                              {activeTab === 1
                                ? formatCurrency(
                                    feeTypeBenefits[0]?.monthly_fee
                                  )
                                : formatCurrency(
                                    feeTypeBenefits[0]?.yearly_fee_with_discount
                                  )}{" "}
                              <span
                                className={`${styles["caption-text"]} ${styles["text-muted"]}`}
                              >
                                {" "}
                                / {`${activeTab === 1 ? "Month" : "Year"}`}/
                                user
                              </span>
                            </h3>
                            <button
                              className={[
                                styles["btn"],
                                styles["btn-primary"],
                                styles["btn-soft"],
                                styles["btn-block"],
                                styles["button"],
                                upgrade.button,
                              ].join(" ")}
                              onClick={() => checkout(feeTypeBenefits[0])}
                            >
                              Continue
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </section>
        </>

        {summaryUpgrade && (
          <ConnectionModal
            title={"Summary"}
            handleClose={toggleUpgradeModal}
            ModalComponent={
              <UpgradeSummary
                subscription={plan}
                activeTab={activeTab}
                user={userInfo}
                currency={currency}
                setUserNumber={setUserNumber}
                userNumberError={userNumberError}
                onProceed={() => {
                  handleSubscription();
                }}
                requestLoader={requestLoader}
                onClose={() => {
                  toggleUpgradeModal();
                }}
              />
            }
            open={summaryUpgrade}
          />
        )}

        {summaryModal && (
          <ConnectionModal
            title={"Your Cart"}
            handleClose={toggleCardModal}
            ModalComponent={
              <ViewProfileSummary
                subscription={plan}
                activeTab={activeTab}
                user={userInfo}
                currency={currency}
                onProceed={(response: PaystackResponse) => {
                  createUserSub(response);
                }}
                requestLoader={requestLoader}
                onClose={() => {
                  toggleCardModal();
                }}
              />
            }
            open={summaryModal}
          />
        )}

        {summaryBusinessModal && (
          <ConnectionModal
            title={"Your Cart"}
            handleClose={toggleBusinessCardModal}
            ModalComponent={
              <ViewAndCreateCompany
                subscription={plan}
                user={userInfo}
                activeTab={activeTab}
                currency={currency}
                onProceed={(response: BusinessPayload) => {
                  createBusinessSub(response);
                }}
                requestLoader={requestLoader}
                onClose={() => {
                  toggleBusinessCardModal();
                }}
              />
            }
            open={summaryBusinessModal}
          />
        )}
      </div>
    </>
  );
}

export default UpgradePlan;
