import "../../../components/assets/css/dashboard.scoped.css";
import "../../../components/assets/css/style.scoped.css";
import "../../../components/assets/css/radio.scoped.css";
import { Input } from "../../../components/ui/Input/Input";

export const EditService = ({
  onClose,
  onProceed,
  service,
  requestLoader,
  setCreateService,
}: any) => {

  return (
    <>
      <div className="card bg-lighter bg-opacity-80 shadow-none mt-2">
        <div className="card-body contact-form">
          <div className="row g-4">
            <div className="col-12">
              <div className="form-group">
                <Input
                  label="Short description"
                  type="text"
                  name="short_description"
                  required={true}
                  id="short_description"
                  value={service.short_description}
                  onChange={(e) => { setCreateService({ ...service, short_description: e?.target?.value }) }}
                  placeholder="Enter short description"
                />
              </div>
            </div>

            <div className="col-6">
              <div className="form-group">
                <Input
                  label="Button text"
                  type="text"
                  name="button_text"
                  required={true}
                  id="button_text"
                  value={service.button_text}
                  onChange={(e) => { setCreateService({ ...service, button_text: e?.target?.value }) }}
                  placeholder="Enter button text"
                />
              </div>
            </div>

            <div className="col-6">
              <div className="form-group">
                <Input
                  label="Button url"
                  type="text"
                  name="button_url"
                  required={true}
                  id="button_url"
                  value={service.button_url}
                  onChange={(e) => { setCreateService({ ...service, button_url: e?.target?.value }) }}
                  placeholder="Enter your url"
                />
              </div>
            </div>

            <div className="col-12">
              <div className="form-input-item mb-40">
                <label className="input-lebel message">Long description</label>
                <textarea name="message"
                  className="input-box message"
                  style={{ height: "70px" }}
                  rows={10}
                  value={service.long_description}
                  onChange={(e) => { setCreateService({ ...service, long_description: e?.target?.value }) }}
                >
                </textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ul className="row gx-4 mt-4">
        <li className="col-6">
          <button
            className="btn btn-outline-light w-100"
            data-bs-dismiss="modal"
            onClick={onClose}
          >
            Cancel
          </button>
        </li>
        <li className="col-6">
          <button
            className="btn btn-primary w-100"
            data-bs-dismiss="modal"
            onClick={onProceed}
            disabled={requestLoader}
          >
            Update
          </button>
        </li>
      </ul>
    </>
  );
};
