import axiosInstance from "../services/axios";

export const getData = async (url: string) => {
  const { data } = await axiosInstance({
    method: "get",
    url,
  });
  return data;
};

export const postData = async (url: string, reqBody: {}) => {
  const { data } = await axiosInstance({
    method: "POST",
    url,
    data: reqBody,
  });
  return data;
};

export const postFormData = async (url: string, reqBody: {}) => {
  const { data } = await axiosInstance({
    method: "POST",
    url,
    data: reqBody,
    headers: { "Content-Type": "multipart/form-data" },
  });
  return data;
};

export const patchData = async (url: string, reqBody: {}) => {
  const { data } = await axiosInstance({
    method: "PATCH",
    url,
    data: reqBody,
  });
  return data;
};

export const putData = async (url: string, reqBody: {}) => {
  const { data } = await axiosInstance({
    method: "PUT",
    url,
    data: reqBody,
  });
  return data;
};

export const deleteData = async (url: string, reqBody: {}) => {
  const { data } = await axiosInstance({
    method: "DELETE",
    url,
    data: reqBody,
  });
  return data;
};