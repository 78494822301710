// import { Fragment } from "react";
import React from "react";
import "../../assets/css/dashboard.scoped.css";
import "../../assets/css/style.scoped.css";

interface ModalProps {
  title: any;
  handleClose?: () => void;
  handleAction?: () => void;
  onClick?: () => any;
  open: boolean;
  proceedText?: string;
  ModalComponent?: React.ReactNode | string;
  closeText?: string;
  hideClose?: boolean;
  buttonText?: string;
  showOtherButton?: boolean;
  otherButtonText?: string;
  requestLoader?: boolean;
}

const DeleteModal: React.FC<ModalProps> = ({
  title,
  onClick,
  handleClose,
  handleAction,
  ModalComponent,
  open,
  proceedText,
  hideClose,
  buttonText,
  showOtherButton,
  otherButtonText,
  requestLoader,
  ...rest
}) => {
  return (
    <React.Fragment>
      <div
        className={`modal ${open ? "fade show" : ""}`}
        id="cancelSubscriptionModal"
        tabIndex={-1}
        aria-modal="true"
        role="dialog"
        style={{ display: open ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body p-4">
              <button className="media media-rg media-middle media-circle text-danger bg-danger bg-opacity-20 mb-3" onClick={handleClose}>
                <em className="ri-close-circle-line"></em>
              </button>
              <h2 className="h1">{title}</h2>
              <p>{proceedText}</p>
              <div className="mt-4">
                <ul className="row gx-4 mt-4">
                  <li className="col-6">
                    <button
                      className="btn btn-outline-light w-100"
                      data-bs-dismiss="modal"
                      onClick={handleClose}
                      title={otherButtonText}
                    >
                      {otherButtonText}
                    </button>
                  </li>
                  <li className={showOtherButton ? "col-6" : "col-12"}>
                    <button
                      className="btn btn-danger w-100"
                      type="button"
                      onClick={handleAction}
                      title={buttonText}
                      disabled={requestLoader}
                    >
                      {buttonText}
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={`modal-backdrop  ${open ? "fade show" : ""}`} />
    </React.Fragment>
  );
};

export default DeleteModal;
