import "../../components/assets/css/dashboard.scoped.css";
import "../../components/assets/css/style.scoped.css";
import Avatar from "../../components/assets/img/logo_2.png";
import useScript from "../../pages/hooks/useScript";
import EduCap from "../../components/assets/img/edu-icon.webp";
import IconCap from "../../components/assets/img/exper-icon.webp";
import { useEffect, useState } from "react";
import { getData } from "../../helpers/request";
import { useLocation, useParams } from "react-router-dom";
import { Connections } from "./Connections";
import PageLoader from "../../components/ui/PageLoader";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { ProfileServiceDetails } from "./Services";
import "../../pages/assets/css/dashboard.css";
import "../../pages/assets/css/style.css";
import BackgroundImage from "../../components/assets/img/page-bg_2.jpeg";

function Details() {
  useScript("https://res.cloudinary.com/dovtransd/raw/upload/v1694620004/fx5xdbukdszs8vld93ar.js", "html", false);
  const { id } = useParams();
  const [userDetails, setUserDetails] = useState<Connections>({
    id: "",
    last_name: "",
    first_name: "",
    other_name: "",
    prefix: "",
    suffix: "",
    business_name: "",
    designation: "",
    about_me: "",
    email: "",
    country_code: "",
    Phone_number: "",
    photograph: "",
    address: "",
    address2: "",
    profile_id: "",
    city: "",
    state: "",
    country: "",
    website: "",
    whatsapp_country_code: "",
    whatsapp_phone_number: "",
    facebook: "",
    twitter: "",
    linkedin: "",
    instagram: "",
    youtube: "",
    tiktok: "",
    is_subscribed: false,
  });
  const [pageLoader, setPageLoader] = useState(false);
  const [profileService, setProfileService] = useState<Array<ProfileServiceDetails>>([]);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const paramValue = queryParams.get("isSearched");

  const pattern = new RegExp('^(https?|ftp)://');

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = async () => {
    setPageLoader(true);
    if (JSON.parse(paramValue as string) === true) {
      const res = await getData(`/card/${id}`);
      if (res) {
        setPageLoader(false);
        getProfileServices(res?.profile_id);
        return setUserDetails({ ...res });
      }
      return;
    }
    const res = await getData(`/cardconnection/${id}`);
    if (res) {
      setPageLoader(false);
      getProfileServices(res?.profile_id);
      return setUserDetails({ ...res });
    }
  }

  const getProfileServices = async (id: string) => {
    setPageLoader(true);

    const res = await getData(`/profileservice/services/profile?page=1&size=100&profile_id=${id}`);

    if (res) {
      setPageLoader(false);
      return setProfileService([...res?.data]);
    }
  }

  const twitterUrl = (username: string = '') => {
    return `https://twitter.com/${username}`;
  }

  const instagramUrl = (username: string = '') => {
    return `https://www.instagram.com/${username}`;
  }

  const tiktokUrl = (username: string = '') => {
    return `https://www.tiktok.com/@${username}`;
  }

  return (
    <>
      <div>
        <div className="row">
          <div className="col-xxl-3 col-xl-3">
            <div className="bostami-parsonal-info-area shadow">
              <div className="bostami-parsonal-info-wrap">
                {/* <div className="bostami-parsonal-info-img">
                  {pageLoader ? (
                    <Skeleton
                      circle
                      height="100%"
                      containerClassName="avatar-skeleton"
                    />
                  ) : (
                    <div className="mt-n4 mt-lg-n6 mb-lg-0 text-center m-auto">
                      {userDetails?.photograph ? (
                        <img
                          src={userDetails?.photograph}
                          alt="placeholder"
                          className="upload_image img-fluid border border-2 border-white"
                          style={{
                            width: "108px",
                            height: "108px",
                            objectFit: "cover"
                          }}
                        />
                      ) : (
                        <img
                          src={Avatar}
                          alt="placeholder"
                          className="upload_image img-fluid border border-2 border-white"
                        />
                      )}
                    </div>
                  )}
                </div> */}

                <div className="bostami-parsonal-info-img">
                  {pageLoader ? (
                    <Skeleton
                      circle
                      height="100%"
                      containerClassName="avatar-skeleton"
                    />
                  ) : (
                    <img
                      alt=""
                      loading="lazy"
                      width="240"
                      height="240"
                      src={userDetails?.template?.image ? userDetails?.template?.image : BackgroundImage}
                    />
                  )}
                </div>

                <div className="row justify-content-center">
                  <div className="mt-n4 mt-lg-n6 mb-4 mb-lg-0">
                    <section className="container text-center m-auto position-relative">
                      <form>
                        {pageLoader ? (
                          <Skeleton
                            circle
                            height="100%"
                            containerClassName="avatar-skeleton"
                          />
                        ) : (
                          <div className="mt-n4 mt-lg-n6 mb-lg-0 text-center m-auto">
                            {userDetails?.photograph ? (
                              <img
                                src={userDetails?.photograph}
                                alt=""
                                className="upload_image img-fluid border border-2 border-white"
                                style={{
                                  width: "108px",
                                  height: "108px",
                                  objectFit: "cover"
                                }}
                              />
                            ) : (
                              <img
                                src={Avatar}
                                alt=""
                                className="upload_image img-fluid border border-2 border-white"
                              />
                            )}
                          </div>
                        )}
                      </form>
                    </section>
                  </div>
                </div>

                <h4 className="bostami-parsonal-info-name">
                  <a>
                    {pageLoader ? <Skeleton /> : `${userDetails?.first_name} ${userDetails?.last_name}`}{" "}
                    <span style={{ color: pageLoader ? "" : "#1773EA" }}>
                      {userDetails?.is_subscribed ? (<i className="ri-verified-badge-fill"></i>) : ('')}
                    </span>{" "}
                  </a>
                </h4>

                <h4 className="mt-0 pt-0">{pageLoader ? <Skeleton /> : userDetails?.business_name}</h4>

                <span className="bostami-parsonal-info-bio mb-15">
                  {pageLoader ? <Skeleton /> : userDetails?.designation}
                </span>

                <ul className="bostami-parsonal-info-social-link mb-30">
                  {pageLoader ? <Skeleton /> : (
                    <li>
                      {userDetails?.facebook && (
                        <a style={{ color: "#1773EA" }} href={userDetails?.facebook} target="_blank">
                          <i className="fa-brands ri-facebook-fill"></i>
                        </a>
                      )}
                    </li>
                  )}
                  {pageLoader ? <Skeleton /> : (
                    <li>
                      {userDetails?.twitter && (
                        <a style={{ color: "#1C9CEA" }} href={pattern.test(userDetails?.twitter) ? userDetails?.twitter : twitterUrl(userDetails?.twitter)} target="_blank">
                          <i className="fa-brands ri-twitter-fill"></i>
                        </a>
                      )}
                    </li>
                  )}
                  {pageLoader ? <Skeleton /> : (
                    <li>
                      {userDetails?.instagram && (
                        <a style={{ color: "#e12a72" }} href={pattern.test(userDetails?.instagram) ? userDetails?.instagram : instagramUrl(userDetails?.instagram)} target="_blank">
                          <i className="fa-brands ri-instagram-fill"></i>
                        </a>
                      )}
                    </li>
                  )}
                  {pageLoader ? <Skeleton /> : (
                    <li>
                      {userDetails?.linkedin && (
                        <a style={{ color: "#144679" }} href={userDetails?.linkedin} target="_blank">
                          <i className="fa-brands ri-linkedin-fill"></i>
                        </a>
                      )}
                    </li>
                  )}
                </ul>

                <div className="bostami-parsonal-info-contact mb-30">
                  <div className="bostami-parsonal-info-contact-item">
                    {pageLoader ? <Skeleton /> : (
                      <>
                        {userDetails?.Phone_number && (
                          <div className="bostami-parsonal-info-contact-item">
                            <a
                              href={`tel: ${userDetails?.Phone_number}`}
                              target="_blank"
                              style={{ color: "#E93B81", fontSize: "22px" }}
                              className="icon"
                            >
                              <i className="ri-phone-line"></i>
                            </a>
                            <div className="text">
                              <span>Phone</span>
                              <p>{userDetails?.Phone_number}</p>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>

                  {pageLoader ? <Skeleton /> : (
                    <>
                      {userDetails?.email && (
                        <div className="bostami-parsonal-info-contact-item">
                          <a
                            href={`mailto: ${userDetails?.email}`}
                            target="_blank"
                            style={{ color: "#E93B81", fontSize: "22px" }}
                            className="icon"
                          >
                            <i className="ri-mail-line"></i>
                          </a>
                          <div className="text">
                            <span>Email</span>
                            <p style={{ textTransform: "lowercase" }}>{userDetails?.email}</p>
                          </div>
                        </div>
                      )}
                    </>
                  )}

                  {pageLoader ? <Skeleton /> : (
                    <>
                      {userDetails?.tiktok && (
                        <div className="bostami-parsonal-info-contact-item">
                          <a
                            href={pattern.test(userDetails?.tiktok) ? userDetails?.tiktok : tiktokUrl(userDetails?.tiktok)}
                            target="_blank"
                            style={{ color: "#E93B81", fontSize: "22px" }}
                            className="icon"
                          >
                            <i className="ri-tiktok-fill"></i>
                          </a>
                          <div className="text">
                            <span>Titkok</span>
                            <p style={{ textTransform: "lowercase" }}>{pattern.test(userDetails?.tiktok) ? userDetails?.tiktok : `@${(userDetails?.tiktok)}`}</p>
                          </div>
                        </div>
                      )}
                    </>
                  )}

                  {pageLoader ? <Skeleton /> : (
                    <>
                      {userDetails?.whatsapp_phone_number && (
                        <div className="bostami-parsonal-info-contact-item">
                          <a
                            href={`https://wa.me/${userDetails?.whatsapp_country_code}${userDetails?.whatsapp_phone_number}`}
                            target="_blank"
                            style={{ color: "#E93B81", fontSize: "22px" }}
                            className="icon"
                          >
                            <i className="ri-whatsapp-fill"></i>
                          </a>
                          <div className="text">
                            <span>Whatsapp</span>
                            <p>{userDetails?.whatsapp_phone_number}</p>
                          </div>
                        </div>
                      )}
                    </>
                  )}

                  {pageLoader ? <Skeleton /> : (
                    <>
                      {userDetails?.website && (
                        <div className="bostami-parsonal-info-contact-item">
                          <a
                            href={`${userDetails?.website}`}
                            target="_blank"
                            style={{ color: "#E93B81", fontSize: "22px" }}
                            className="icon"
                          >
                            <i className="ri-ie-fill"></i>
                          </a>
                          <div className="text">
                            <span>Website</span>
                            <p>{userDetails?.website}</p>
                          </div>
                        </div>
                      )}
                    </>
                  )}

                  {pageLoader ? <Skeleton /> : (
                    <>
                      {userDetails?.address && (
                        <div className="bostami-parsonal-info-contact-item">
                          <div
                            style={{ color: "#E93B81", fontSize: "22px" }}
                            className="icon"
                          >
                            <i className="ri-map-pin-line"></i>
                          </div>
                          <div className="text">
                            <span>Location</span>
                            <p>{userDetails?.address}</p>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="col-xxl-8 col-xl-8">
            <div
              id="about"
              className="sections_pages bostami-page-content-wrap shadow mb-4"
            >
              <div className="section-wrapper pl-60 pr-60 pt-60">
                <div className="bostami-page-title-wrap mb-15">
                  <h1 className="page-title mb-3">{pageLoader ? <Skeleton /> : "About"}</h1>

                  {pageLoader ? <Skeleton /> : (
                    <>
                      {userDetails?.about_me ? (
                        <p>
                          {userDetails?.about_me}
                        </p>
                      ) : (
                        <p className="muted" style={{ fontStyle: "italic" }}>
                          About details not provided
                        </p>
                      )}
                    </>

                  )}
                </div>
              </div>

              <div className="section-wrapper mt-5">
                <div className="bostami-section-title-wrap mb-3">
                  <h1 className="page-title">{pageLoader ? <Skeleton /> : "Services"}</h1>
                </div>

                {pageLoader ? <Skeleton /> : (
                  <div className="bostami-what-do-wrap mb-30">
                    {profileService.length > 0 ? (
                      <div className="row">
                        {profileService.map((service: ProfileServiceDetails, index) => {
                          return (
                            <>
                              <div className="col-xxl-6 col-xl-6 col-lg-6" key={index}>
                                <div className="bostami-what-do-item bg-prink">
                                  <div className="text">
                                    <h4 className="title">{service?.short_description}</h4>
                                    <p>
                                      {service?.long_description}
                                    </p>
                                    <a href={service?.button_url} target="_blank">
                                      <span>{service?.button_text}</span>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </>
                          )
                        })}
                      </div>
                    ) : (
                      <p className="muted" style={{ fontStyle: "italic" }}>
                        No services provided yet
                      </p>
                    )}
                  </div>
                )}
              </div>

              <div className="footer-copyright text-center pt-25 pb-25">
                <span>
                  ©<script> document.write(new Date().getFullYear()) </script>{" "}
                  All Rights Reserved by CardGit.
                </span>
              </div>
            </div>

            {/* <!-- RESUME SECTION --> */}

            <div
              id="resume"
              className="sections_pages bostami-page-content-wrap shadow mb-4"
            >
              <div className="section-wrapper pl-60 pr-60 pt-60">
                <div className="bostami-page-title-wrap mb-15">
                  <h2 className="page-title">resume</h2>
                </div>
              </div>

              <div className="section-wrapper pl-60 pr-60 mb-60">
                <div className="row">
                  <div className="col-xl-6 col-lg-7">
                    <div className="bostami-section-title-wrap mb-20">
                      <h4 className="section-title">
                        <img
                          src={EduCap}
                          alt="icon"
                          loading="lazy"
                          width="38"
                          height="23"
                        />
                        Education
                      </h4>
                    </div>

                    <div className="bostami-card-wrap">
                      <div className="bostami-card-item bg-prink mb-20">
                        <span className="card-subtitle">2021-2023</span>
                        <h6 className="card-title">
                          Ph.D in Horriblensess
                          <span>- University</span>
                        </h6>
                        <p className="card-text">Los Angeles, CA</p>
                      </div>

                      <div className="bostami-card-item bg-catkrill mb-20">
                        <span className="card-subtitle">2021-2023</span>
                        <h6 className="card-title">
                          Ph.D in Horriblensess
                          <span>- University</span>
                        </h6>
                        <p className="card-text">Los Angeles, CA</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-6 col-lg-5">
                    <div className="bostami-section-title-wrap mb-20">
                      <h4 className="section-title">
                        <img
                          src={IconCap}
                          alt="icon"
                          loading="lazy"
                          width="28"
                        />
                        Education
                      </h4>
                    </div>

                    <div className="bostami-card-item bg-catkrill mb-20 ">
                      <span className="card-subtitle">2019 - Present</span>
                      <h6 className="card-title">Sr. Software Tester</h6>
                      <p className="card-text">Google Inc.</p>
                    </div>

                    <div className="bostami-card-item bg-prink mb-20 ">
                      <span className="card-subtitle">2015-2017</span>
                      <h6 className="card-title">Cr. Web Developer</h6>
                      <p className="card-text">ib-themes ltd.</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="section-wrapper bg-light-white-2 pt-50 pb-60 pl-60 pr-60 mt-4">
                <div className="row">
                  <div className="col-xl-6 col-lg-7">
                    <div className="bostami-section-title-wrap mb-20">
                      <h4 className="section-title">Working Skills</h4>
                    </div>

                    <div className="skill-bar-wrap">
                      <div className="skill-bar-item mb-30 ">
                        <div className="title-wrap">
                          <h5 className="title">Web Design</h5>
                          <span className="count">85%</span>
                        </div>
                        <div className="progress-bar-wrap">
                          <div
                            className="progress-line progress-bg-1"
                            style={{ width: "85%" }}
                          ></div>
                        </div>
                      </div>
                      <div className="skill-bar-item mb-30 ">
                        <div className="title-wrap">
                          <h5 className="title">Mobile App</h5>
                          <span className="count">55%</span>
                        </div>
                        <div className="progress-bar-wrap">
                          <div
                            className="progress-line progress-bg-2"
                            style={{ width: "55%" }}
                          ></div>
                        </div>
                      </div>
                      <div className="skill-bar-item mb-30 ">
                        <div className="title-wrap">
                          <h5 className="title">Illustrator</h5>
                          <span className="count">65%</span>
                        </div>
                        <div className="progress-bar-wrap">
                          <div
                            className="progress-line progress-bg-3"
                            style={{ width: "65%" }}
                          ></div>
                        </div>
                      </div>
                      <div className="skill-bar-item false ">
                        <div className="title-wrap">
                          <h5 className="title">Photoshop</h5>
                          <span className="count">72%</span>
                        </div>
                        <div className="progress-bar-wrap">
                          <div
                            className="progress-line progress-bg-4"
                            style={{ width: "72%" }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-6 col-lg-5">
                    <div className="bostami-section-title-wrap mb-20">
                      <h4 className="section-title">Knowledges</h4>
                    </div>

                    <div className="knowledeges-item-wrap">
                      <span className="gk-item">Digital Design</span>
                      <span className="gk-item">Marketing</span>
                      <span className="gk-item">Communication</span>
                      <span className="gk-item">Social Media</span>
                      <span className="gk-item">Time Management</span>
                      <span className="gk-item">Flexibility</span>
                      <span className="gk-item">Print</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="footer-copyright text-center pt-25 pb-25">
                <span>
                  ©<script> document.write(new Date().getFullYear()) </script>{" "}
                  All Rights Reserved by CardGit.
                </span>
              </div>
            </div>

            {/* <!-- CONTACT SECTION --> */}

            <div
              id="contact"
              className="sections_pages bostami-page-content-wrap shadow  mb-4"
            >
              <div className="section-wrapper pl-60 pr-60 pt-60">
                <div className="bostami-page-title-wrap mb-3">
                  <h2 className="page-title">Contact</h2>
                </div>
              </div>

              <div className="section-wrapper pr-60 pl-60 mb-60">
                <div className="contact-area bg-light-white-2">
                  <h5 className="contact-title">
                    I&#x27;m always open to discussing produuct
                  </h5>
                  <h5 className="contact-title-b">
                    design work or partnerships.
                  </h5>

                  <form className="contact-form">
                    <div className="form-input-item mb-60">
                      <label className="input-lebel name">name*</label>
                      <input
                        className="input-box name false "
                        type="text"
                        required
                        name="name"
                      />
                    </div>

                    <div className="form-input-item mb-60">
                      <label className="input-lebel name">Email*</label>
                      <input
                        className="input-box name false "
                        type="email"
                        required
                        name="email"
                      />
                    </div>

                    <div className="form-input-item mb-40">
                      <label className="input-lebel message">Message*</label>
                      <textarea
                        name="message"
                        className="input-box message false "
                        style={{ height: "100px" }}
                        cols={0}
                        rows={19}
                      ></textarea>
                    </div>

                    <div className="form-btn-wrap">
                      <button type="submit" value="Send" className="form-btn">
                        submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>

              <div className="footer-copyright text-center pt-25 pb-25">
                <span>
                  ©<script> document.write(new Date().getFullYear()) </script>{" "}
                  All Rights Reserved by CardGit.
                </span>
              </div>
            </div>
          </div>

          <div className="col-xxl-1 col-xl-1 d-xl-block d-none">
            <div className="bostami-main-menu-wrap shadow">
              <nav className="bastami-main-menu main_menu">
                {pageLoader ? <Skeleton /> : (
                  <ul>
                    <li
                      className="menu-item profile_bar active"
                      data-target="about"
                    >
                      <a href="#">
                        <span>
                          <i
                            style={{ fontSize: "25px" }}
                            className="ri-user-line"
                          ></i>
                        </span>
                        About
                      </a>
                    </li>
                    {/* <li className="menu-item profile_bar" data-target="resume">
                      <a href="#">
                        <span>
                          <i
                            style={{ fontSize: "25px" }}
                            className="ri-file-text-line"
                          ></i>
                        </span>
                        Resume
                      </a>
                    </li>
                    <li className="menu-item profile_bar" data-target="contact">
                      <a href="#">
                        <span>
                          <i
                            style={{ fontSize: "25px" }}
                            className="ri-contacts-book-line"
                          ></i>
                        </span>
                        Contact
                      </a>
                    </li> */}
                  </ul>
                )}
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Details;
