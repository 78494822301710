import "../../../components/assets/css/dashboard.scoped.css";
import "../../../components/assets/css/style.scoped.css";
import { ChangeEvent, useEffect, useState } from "react";
import Logo from "../../../components/assets/img/logo.png";
import { useNavigate } from "react-router-dom";
import { Templates } from "./Card/AdminCardTemplateDesign";
import { getData, postData } from "../../../helpers/request";
import PageLoader from "../../../components/ui/PageLoader";

export type FullUserCard = {
  id: string;
  image?: string;
  profile_id: string;
  last_name: string;
  first_name: string;
  other_name: string;
  prefix: string;
  suffix: string;
  business_name: string;
  designation: string;
  about_me: string;
  email: string;
  phone_number: string;
  photograph: string;
  address: string;
  address2: string;
  city: string;
  state: string;
  country: string;
  website: string;
  whatsapp_country_code: string;
  whatsapp_phone_number: string;
  facebook: string;
  twitter: string;
  linkedin: string;
  instagram: string;
  youtube: string;
  tiktok: string;
  professional_field_id: string;
  status: number;
  modified_by: string;
  createdAt: string;
  updatedAt: string;
  cardQrCodeUrl?: string;
  private_mode?: boolean;
  template_id?: string;
  template?: Templates;
  is_connected?: boolean;
  is_default_card?: boolean;
  is_subscribed?: boolean;
};

function CompanyCard() {
  const [pageLoader, setPageLoader] = useState(false);
  const [cardProfile, setCardProfile] = useState<Array<FullUserCard>>([]);
  const [userHasSubscription, setUserSubscription] = useState(false);
  
  const navigate = useNavigate();

  useEffect(() => {
    getUserCards();
  }, []);

  const getUserCards = async () => {
    setPageLoader(true);
    const res = await getData(`companycard?page=1&size=1000`);

    if (res) {
      setPageLoader(false);
      return setCardProfile(res?.data);
    }
  };

  useEffect(() => {
    getProfileStatus();
  }, []);

  const getProfileStatus = async () => {
    const res: any = await getData("/subscription/check/active");

    if (res?.isSuccess) {
      return setUserSubscription(res?.isSubscribed);
    }
    return setUserSubscription(res?.isSubscribed);
  }

  const searchCompanyCards = (value: string) => {
    getSearchedCompanyCard(value);
  }

  const getSearchedCompanyCard = async (value: string) => {
    const payload = {
      parameter: value,
      page: 1,
      size: 1000,
    };

    if (value.length > 2) {
      setPageLoader(true);
      const res = await postData(`companycard/search`, payload);

      if (res) {
        setCardProfile(res?.data)
        // setIsSearched(true);
        setPageLoader(false);
      }
      return;
    }

    return getUserCards();
  }

  const pattern = new RegExp('^(https?|ftp)://');
  const twitterUrl = (username: string = '') => {
    return `https://twitter.com/${username}`;
  }

  const instagramUrl = (username: string = '') => {
    return `https://www.instagram.com/${username}`;
  }

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-3">
        {/* <h5 className="mb-0" />
        <ul className="d-flex align-items-center gap gx-2">
          <li>
            <div className="dropdown">
              <div className="form-control-wrap">
                {pageLoader ? (
                  <Skeleton />
                ) : (
                  <button
                    className="btn btn-primary btn-block"
                    type="button"
                    onClick={createCard}
                  >
                    <em className="icon ri-add-fill pe-2" />
                    Create new card
                  </button>
                )}
              </div>
            </div>
          </li>
        </ul> */}
      </div>

      <div className="dropdown-content py-3">
        <div className="form-control-wrap">
          <div className="form-control-icon start md text-light">
            <em className="icon ri-search-line pt-3" />
          </div>
          <input
            type="text"
            className="form-control py-3 form-control-md"
            placeholder="Search Cards"
            onInput={(e: ChangeEvent<HTMLInputElement>) => searchCompanyCards(e.target?.value)}
          />
        </div>
      </div>

      <div className="nk-block row">
        {pageLoader ? (
          <PageLoader />
        ) : (
          <>
            {cardProfile.length > 0 ? (
              <div className="nk-block row">
                {cardProfile.map((item: FullUserCard, index: number) => {
                  return (
                    <div key={index} className="col-md-6 mb-4" style={{ cursor: "pointer" }}>
                      <div
                        className="card connection_card p-4"
                        style={{
                          backgroundImage: `url(${item?.template?.image ? item?.template?.image : require("../../../components/assets/img/page-bg_2.jpeg")})`,
                          backgroundSize: 'cover',
                          backgroundRepeat: 'no-repeat',
                          minHeight: "300px",
                        }}
                        key={index}
                      >
                        <div className="container position-relative text-center m-auto">
                          {item?.photograph ? (
                            <img src={item?.photograph} className="rounded-circle" style={{ height: "95px" }} onClick={() => navigate(`/dashboard/company-card/${item?.id}`)} alt="" />
                          ) :
                            (
                              <img src={Logo} className="rounded-circle" style={{ height: "95px" }} onClick={() => navigate(`/dashboard/company-card/${item?.id}`)} alt="" />
                            )
                          }

                          <h2 className="text-white pt-3" onClick={() => navigate(`/dashboard/company-card/${item?.id}`)}>
                            {`${item?.first_name} ${item?.last_name}`}{" "}
                            {userHasSubscription && (
                              <span style={{ color: "#1773EA" }}>
                                <i className="ri-verified-badge-fill" />
                              </span>
                            )}
                          </h2>

                          <h6 className="mt-0 pt-0 text-white">
                            {" "}
                            <i>{item?.business_name}</i>{" "}
                          </h6>

                          <p className="text-white">
                            {item?.designation}
                          </p>

                          <ul className="m-auto text-center justify-content-center">
                            {item?.facebook && <li>
                              <a href={`${item?.facebook}`} target="_blank" rel="noreferrer">
                                {" "}
                                <i className="ri-facebook-fill" />{" "}
                              </a>
                            </li>}
                            {item?.instagram && <li>
                              <a href={pattern.test(item?.instagram) ? item?.instagram : instagramUrl(item?.instagram)} target="_blank" rel="noreferrer">
                                {" "}
                                <i className="ri-instagram-fill" />{" "}
                              </a>
                            </li>}
                            {item?.twitter && <li>
                              <a href={pattern.test(item?.twitter) ? item?.twitter : twitterUrl(item?.twitter)} target="_blank" rel="noreferrer">
                                {" "}
                                <i className="ri-twitter-fill" />{" "}
                              </a>
                            </li>}
                            {item?.whatsapp_phone_number && <li>
                              {" "}
                              <a href={`https://wa.me/${item?.whatsapp_country_code}${item?.whatsapp_phone_number}`} target="_blank" rel="noreferrer">
                                <i className="ri-whatsapp-fill" />{" "}
                              </a>
                            </li>}
                          </ul>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            ) : (
              <div className="tab-pane" id="trash-documents-tab-pane">
                <div className="row justify-content-center py-5">
                  <div className="col-md-7 col-lg-4">
                    <div className="mb-5 text-center">
                      <img src="images/illustrations/no-documents.svg" alt="" />
                    </div>
                    <div className="nk-block-head text-center">
                      <div className="nk-block-head-content">
                        <h1 className="nk-block-title mb-2">
                          You do not have any cards yet.
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default CompanyCard;
