// import "../../../components/assets/css/dashboard.scoped.css";
import upgrade from "../../../components/assets/css/upgrade.module.css";
// import "../../../components/assets/css/radio.scoped.css";
// import "../../../pages/assets/css/global.scoped.css";
// import "../../../pages/assets/css/button.scoped.css";
import { PaystackButton } from "react-paystack";
import {
  BusinessPayload,
  PaystackResponse,
  calculatePayStackFee,
  formatCurrency,
} from "../../../components/ui/UpgradePlan";
import { useRef, useState } from "react";
import { Input } from "../../../components/ui/Input/Input";
import { country_code } from "../../../helpers/countryCode";
import { postData } from "../../../helpers/request";
import { toast } from "react-toastify";

export type CompanyInfo = {
  name: string;
  address: string;
  address2?: string;
  country: string;
  state: string;
  city: string;
  logo: string;
  phone_number: string;
  email: string;
  website: string;
  company_summary: string;
};

export const ViewAndCreateCompany = ({
  onClose,
  onProceed,
  subscription,
  requestLoader,
  user,
  currency,
}: any) => {
  const [companyInfo, setCompanyInfo] = useState<CompanyInfo>({
    name: "",
    address: "",
    address2: "",
    country: "",
    state: "",
    city: "",
    logo: "",
    phone_number: "",
    email: "",
    website: "",
    company_summary: "",
  });
  const [validateRequest, setValidateRequest] = useState(false);
  const payStackRef: any = useRef();

  const handlePaystackSuccessAction = (reference: PaystackResponse) => {
    // Create user subscription after paystack is successful
    const businessPayload: BusinessPayload = {
      subscription_type: subscription?.feetype?.type,
      target: "Corporate",
      users: subscription?.user,
      total_fee_paid: Number(subscription?.feetype?.fee),
      paystack: {
        reference: reference?.reference,
      },
      company: companyInfo,
    };
    onProceed(businessPayload);
  };

  // you can call this function anything
  const handlePaystackCloseAction = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    console.log("closed");
  };

  const config = {
    reference: new Date().getTime().toString(),
    email: user?.email as string,
    publicKey: process.env.REACT_APP_PAYSTACK_KEY as string,
    disabled: false,
  };

  const componentProps = {
    ...config,
    text: "Continue",
    onSuccess: (reference: PaystackResponse) =>
      handlePaystackSuccessAction(reference),
    onClose: handlePaystackCloseAction,
  };

  const disableButton = () => {
    return (
      !(
        companyInfo?.name &&
        companyInfo?.address &&
        companyInfo?.country &&
        companyInfo?.state &&
        companyInfo?.email &&
        companyInfo?.phone_number &&
        companyInfo?.city &&
        companyInfo?.company_summary
      ) ||
      requestLoader ||
      validateRequest
    );
  };

  const handlePayment = async () => {
    setValidateRequest(true);
    const payload = {
      subscription_type: subscription?.feetype?.type,
      target: "Corporate",
      users: subscription?.user,
      total_fee_paid: Number(subscription?.feetype?.fee),
      company: companyInfo,
    };

    const res = await postData(`company/validatecompany`, payload);

    if (res?.isSuccess || res?.isSucess) {
      const payStactBtn = document.querySelector(".paystack-btn") as any;
      setValidateRequest(false);
      return payStactBtn?.click();
    }
    toast.error(res?.message);
    setValidateRequest(false);
  };

  return (
    <>
      <div className={upgrade.section}>
        <div className="card bg-lighter bg-opacity-80 shadow-none mt-2">
          <div className="card-body contact-form">
            <div className="row g-4">
              <div className="list-dot gap gy-2 mt-2">
                <h4 className="mb-3">
                  <span className="caption-text text-muted">Type: </span>
                  <span>Business</span>
                  <br />
                </h4>
                <h4 className="mb-3">
                  <span className="caption-text text-muted">Duration: </span>
                  <span className="text-gradient-primary">
                    {subscription?.feetype?.type}
                  </span>
                  <br />
                </h4>
                <h4 className="mb-3">
                  <span className="caption-text text-muted">Amount: </span>
                  <span className="text-gradient-primary">
                    {currency}
                    {formatCurrency(subscription?.feetype?.fee)}{" "}
                  </span>
                  <br />
                </h4>
              </div>
            </div>

            <div className="text-left mt-4">
              <div className="row g-4">
                <div className={["col-12", upgrade.textLeft].join(" ")}>
                  <Input
                    label="Company name"
                    type="text"
                    name="name"
                    id="company_name"
                    placeholder="Enter company name"
                    required={true}
                    value=""
                    onChange={(e) => {
                      setCompanyInfo({
                        ...companyInfo,
                        name: e?.target?.value,
                      });
                    }}
                  />
                </div>

                <div className={["col-12", upgrade.textLeft].join(" ")}>
                  <Input
                    label="Company address"
                    type="text"
                    name="address"
                    id="company_address"
                    placeholder="Enter company address"
                    required={true}
                    value=""
                    onChange={(e) => {
                      setCompanyInfo({
                        ...companyInfo,
                        address: e?.target?.value,
                      });
                    }}
                  />
                </div>
                <div className={["col-12", upgrade.textLeft].join(" ")}>
                  <Input
                    label="Address2"
                    type="text"
                    name="address2"
                    id="company_address2"
                    placeholder="Enter address 2"
                    required={false}
                    value=""
                    onChange={(e) => {
                      setCompanyInfo({
                        ...companyInfo,
                        address2: e?.target?.value,
                      });
                    }}
                  />
                </div>

                <div className={["col-6", upgrade.textLeft].join(" ")}>
                  <label
                    htmlFor="country"
                    className="form-control-label form-label"
                  >
                    Country
                  </label>
                  <select
                    className="form-control"
                    value={companyInfo.country}
                    id="country"
                    onChange={(e: any) =>
                      setCompanyInfo({
                        ...companyInfo,
                        country: e?.target?.value,
                      })
                    }
                  >
                    <option selected disabled></option>
                    {country_code.map(
                      ({ country }: { country: string }, index: number) => {
                        return (
                          <option key={index} value={country}>
                            {country}
                          </option>
                        );
                      }
                    )}
                  </select>
                </div>

                {/* <div className="col-6 textLeft">
                  <Input
                    label="Country"
                    type="text"
                    name="country"
                    id="company_country"
                    placeholder="Enter country"
                    required={true}
                    value=""
                    onChange={(e) => { setCompanyInfo({ ...companyInfo, country: e?.target?.value }) }}
                  />
                </div> */}
                <div className={["col-6", upgrade.textLeft].join(" ")}>
                  <Input
                    label="State"
                    type="text"
                    name="state"
                    id="company_state"
                    placeholder="Enter State"
                    required={true}
                    value=""
                    onChange={(e) => {
                      setCompanyInfo({
                        ...companyInfo,
                        state: e?.target?.value,
                      });
                    }}
                  />
                </div>

                <div className={["col-6", upgrade.textLeft].join(" ")}>
                  <Input
                    label="City"
                    type="text"
                    name="city"
                    id="company_city"
                    placeholder="Enter city"
                    required={true}
                    value=""
                    onChange={(e) => {
                      setCompanyInfo({
                        ...companyInfo,
                        city: e?.target?.value,
                      });
                    }}
                  />
                </div>
                <div className={["col-6", upgrade.textLeft].join(" ")}>
                  <Input
                    label="Company logo"
                    type="url"
                    name="logo"
                    id="company_logo"
                    placeholder=""
                    required={false}
                    value=""
                    onChange={(e) => {
                      setCompanyInfo({
                        ...companyInfo,
                        logo: e?.target?.value,
                      });
                    }}
                  />
                </div>

                <div className={["col-6", upgrade.textLeft].join(" ")}>
                  <Input
                    label="Phone number"
                    type="tel"
                    name="phone_number"
                    id="phone_number"
                    placeholder="Enter Phone number"
                    required={true}
                    value=""
                    onChange={(e) => {
                      setCompanyInfo({
                        ...companyInfo,
                        phone_number: e?.target?.value,
                      });
                    }}
                  />
                </div>
                <div className={["col-6", upgrade.textLeft].join(" ")}>
                  <Input
                    label="Email"
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Enter your email"
                    required={true}
                    value=""
                    onChange={(e) => {
                      setCompanyInfo({
                        ...companyInfo,
                        email: e?.target?.value,
                      });
                    }}
                  />
                </div>

                <div className={["col-12", upgrade.textLeft].join(" ")}>
                  <Input
                    label="Website"
                    type="url"
                    name="website"
                    id="website"
                    placeholder="Enter your company's webite"
                    required={false}
                    value=""
                    onChange={(e) => {
                      setCompanyInfo({
                        ...companyInfo,
                        website: e?.target?.value,
                      });
                    }}
                  />
                </div>
                <div className={["col-12", upgrade.textLeft].join(" ")}>
                  <label htmlFor="company-message">Company summary</label>
                  <textarea
                    name="message"
                    className="input-box message false"
                    style={{ height: "70px" }}
                    rows={10}
                    id="company-message"
                    value={companyInfo?.company_summary}
                    onChange={(e) => {
                      setCompanyInfo({
                        ...companyInfo,
                        company_summary: e?.target?.value,
                      });
                    }}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ul className="row gx-4 mt-4">
          <li className="col-6">
            <button
              className="btn btn-outline-light w-100"
              data-bs-dismiss="modal"
              onClick={onClose}
            >
              Change
            </button>
          </li>
          <li className="col-6 paystack">
            <button
              type="button"
              className={[
                upgrade.button,
                "btn btn-primary btn-soft btn-block",
              ].join(" ")}
              disabled={disableButton()}
              onClick={() => {
                handlePayment();
              }}
            >
              Continue
            </button>
          </li>
          <button
            ref={payStackRef}
            style={{ visibility: "hidden", opacity: 0 }}
          >
            <PaystackButton
              {...componentProps}
              amount={calculatePayStackFee(subscription?.feetype?.fee)}
              className="paystack-btn"
            />
          </button>
        </ul>
      </div>
    </>
  );
};
