// import "../../components/assets/css/upgrade.scoped.css";
import UpgradePlan from "../../components/ui/UpgradePlan";

export type UserProfile = {
  id: string;
  last_name: string;
  registration_type: string;
  first_name: string;
  other_name: string;
  prefix: string;
  suffix: string;
  is_corporate_profile: boolean;
  company_id: string;
  service_group_id: string;
  business_name: string;
  designation: string;
  about_me: string;
  email: string;
  password: string;
  country_code: string;
  Phone_number: string;
  corporate_id: string;
  show_corporate_services: boolean;
  create_corporate_services: boolean;
  photograph: string;
  address: string;
  address2: string;
  city: string;
  state: string;
  country: string;
  website: string;
  whatsapp_country_code: string;
  whatsapp_phone_number: string;
  facebook: string;
  twitter: string;
  linkedin: string;
  instagram: string;
  youtube: string;
  tiktok: string;
  plan_id: string;
  template_id: string;
  role_id: string;
  professional_field_id: string;
  status: string;
  date_confirmed: string;
  modified_by: string;
  agent_id: string;
  notify_new_connection: boolean;
  notify_service_click: boolean;
  profile_access: boolean;
  private_mode: boolean;
  private_share: boolean;
  email_private: boolean;
  phone_private: boolean;
  address_private: boolean;
  whatsapp_private: boolean;
  createdAt: string;
  updatedAt: string;
};

function UpgradePlanPage() {

  return (
    <>
      <UpgradePlan />
    </>
  );
}

export default UpgradePlanPage;
