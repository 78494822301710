import React, { useEffect, useState } from "react";
import { NavLink, useLoaderData } from "react-router-dom";
import dashboard from "../../components/assets/css/dashboard.module.css";
import { getData } from "../../helpers/request";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export default function Dashboard() {
  type Connections = {
    connectedCardCount: number;
    favouriteCardCount: number;
  };
  const [pageLoader, setPageLoader] = useState(false);
  const [connections, setConnections] = useState<Connections>({
    connectedCardCount: 0,
    favouriteCardCount: 0,
  });

  // const connections = useLoaderData() as Connections;

  useEffect(() => {
    getDashboard();
  }, []);

  const getDashboard = async () => {
    setPageLoader(true);
    const res = await getData(`card/dashboard_connection_count`);
    if (res) {
      setPageLoader(false);
      return setConnections(res);
    }
  };

  return (
    <React.Fragment>
      <div className={`${dashboard["row"]} ${dashboard["g-gs"]}`}>
        <div className={`${dashboard["col-sm-6"]} ${dashboard["col-xxl-3}"]}`}>
          <div
            className={[
              dashboard["card"],
              dashboard["card-full"],
              dashboard["bg-blue"],
              dashboard["bg-opacity-10"],
              dashboard["border-0"],
            ].join(" ")}
          >
            <div className={dashboard["card-body"]}>
              {pageLoader ? (
                <Skeleton />
              ) : (
                <>
                  <div
                    className={[
                      dashboard["d-flex"],
                      dashboard["align-items-center"],
                      dashboard["justify-content-between"],
                      dashboard["mb-1"],
                    ].join(" ")}
                  >
                    <div
                      className={[
                        dashboard["fs-6"],
                        dashboard["text-light"],
                        dashboard["mb-0"],
                      ].join(" ")}
                    >
                      Connected Cards
                    </div>
                    {connections?.connectedCardCount > 0 && (
                      <NavLink
                        to="/dashboard/connections"
                        className={`${dashboard.link} ${dashboard["link-blue"]}`}
                      >
                        See All
                      </NavLink>
                    )}
                  </div>
                  <h5 className={dashboard["fs-1"]}>
                    {connections?.connectedCardCount}{" "}
                    <small className={dashboard["fs-3"]}>Connected</small>
                  </h5>
                  {/* <div className="fs-7 text-light mt-1">
                    <span className="text-dark">You</span>Connected to {connections?.connectedCardCount} Profile
                  </div> */}
                </>
              )}
            </div>
          </div>
        </div>

        <div className={`${dashboard["col-sm-6"]} ${dashboard["col-xxl-3}"]}`}>
          <div
            className={[
              dashboard["card"],
              dashboard["card-full"],
              dashboard["bg-purple"],
              dashboard["bg-opacity-10"],
              dashboard["border-0"],
            ].join(" ")}
          >
            <div className={dashboard["card-body"]}>
              {pageLoader ? (
                <Skeleton />
              ) : (
                <>
                  <div
                    className={[
                      dashboard["d-flex"],
                      dashboard["align-items-center"],
                      dashboard["justify-content-between"],
                      dashboard["mb-1"],
                    ].join(" ")}
                  >
                    <div
                      className={[
                        dashboard["fs-6"],
                        dashboard["text-light"],
                        dashboard["mb-0"],
                      ].join(" ")}
                    >
                      Favourites
                    </div>
                    {connections?.favouriteCardCount > 0 && (
                      <NavLink
                        to="/dashboard/favorites"
                        className={`${dashboard.link} ${dashboard["link-purple"]}`}
                      >
                        View
                      </NavLink>
                    )}
                  </div>
                  <h5 className="fs-1">
                    {connections?.favouriteCardCount}{" "}
                    <small className={dashboard['fs-3']}>Favourite Card</small>
                  </h5>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
