import { FormEvent, useState } from "react";
import PrimaryButton from "../../../components/ui/Button/PrimaryButton";
import { Input } from "../../../components/ui/Input/Input";
// import "../../../pages/assets/css/global.scoped.css";
// import "../../../components/assets/css/dashboard.scoped.css";
// import "../../../components/assets/css/style.scoped.css";
import { UserCard } from "../../../store/AuthContext";
import SessionStorageService from "../../../services/sessionStorage";
import { postData } from "../../../helpers/request";
import PageLoader from "../../../components/ui/PageLoader";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
// import "../../../pages/assets/css/global.css";
// import "../../../pages/assets/css/dashboard.css";
// import "../../../pages/assets/css/style.css";
import { country_code } from "../../../helpers/countryCode";

function SocialSetup({
  setFormSteps,
  userInfo,
  loading,
  pageLoader,
  setPageLoader,
  setUserInfo,
  setLoader
}: {
  setFormSteps: any;
  userInfo: UserCard;
  loading: boolean;
  pageLoader: boolean;
  setPageLoader: any;
  setUserInfo: any;
  setLoader: any;
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const paramValue = queryParams.get("id");

  const [facebookError, setFacebookError] = useState(false);
  const [facebookErrorMessage, setFacebookErrorMessage] = useState("");

  const [youtubeError, setYoutubeError] = useState(false);
  const [youtubeErrorMessage, setYoutubeErrorMessage] = useState("");

  const [linkedinError, setLinkedinError] = useState(false);
  const [linkedinErrorMessage, setLinkedinErrorMessage] = useState("");

  const [twitterError, setTwitterError] = useState(false);
  const [twitterErrorMessage, setTwitterErrorMessage] = useState("");

  const [instagramError, setInstagramError] = useState(false);
  const [instagramErrorMessage, setInstagramErrorMessage] = useState("");

  const [tiktokError, setTiktokError] = useState(false);
  const [tiktokErrorMessage, setTiktokErrorMessage] = useState("");

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setLoader(true);

    const res = await postData(`/card/createcard`, userInfo);
    if (res?.isSuccess || res?.isSucess) {
      SessionStorageService.setItem("is_profile_updated", true);
      setPageLoader(false);

      if (paramValue) {
        queryParams.set("id", paramValue as string);
        const newSearch = `${queryParams.toString()}`;

        return navigate({
          pathname: "/dashboard/index",
          search: newSearch
        });
      }
      return navigate("/dashboard/cards");
    }

    setLoader(false);
    toast.error(res?.message);
  }

  const validateTwitterUsername = (e: any) => {
    const { name, value } = e.target;
    const pattern = new RegExp('^(https?|ftp)://');
    const isValidUrl = pattern.test(value);

    if (isValidUrl) {
      if (!value.includes(name) && !value.includes('x.com')) {
        setTwitterError(true);
        // setUserInfo({ ...userInfo, [name]: "" });
        setUserInfo({ ...userInfo, [name]: value });
        return setTwitterErrorMessage("This fields value is invalid.");
      }
      // if (value.includes(name)) {
      setUserInfo({ ...userInfo, [name]: value });
      setTwitterError(false);
      return setTwitterErrorMessage("");
      // }
    }
    setTwitterError(false);
    setTwitterErrorMessage("");
    return setUserInfo({ ...userInfo, [name]: value });
  }

  const validateInstagramUsername = (e: any) => {
    const { name, value } = e.target;
    const pattern = new RegExp('^(https?|ftp)://');
    const isValidUrl = pattern.test(value);

    if (isValidUrl) {
      if (!value.includes(name)) {
        setInstagramError(true);
        // setUserInfo({ ...userInfo, [name]: "" });
        setUserInfo({ ...userInfo, [name]: value });
        return setInstagramErrorMessage("This fields value is invalid.");
      }
      // if (value.includes(name)) {
      setUserInfo({ ...userInfo, [name]: value });
      setInstagramError(false);
      return setInstagramErrorMessage("");
      // }
    }
    setInstagramError(false);
    setInstagramErrorMessage("");
    return setUserInfo({ ...userInfo, [name]: value });
  }

  const validateTiktokUsername = (e: any) => {
    const { name, value } = e.target;
    const pattern = new RegExp('^(https?|ftp)://');
    const isValidUrl = pattern.test(value);

    if (isValidUrl) {
      if (!value.includes(name)) {
        setTiktokError(true);
        // setUserInfo({ ...userInfo, [name]: "" });
        setUserInfo({ ...userInfo, [name]: value });
        return setTiktokErrorMessage("This fields value is invalid.");
      }
      // if (value.includes(name)) {
      setUserInfo({ ...userInfo, [name]: value });
      setTiktokError(false);
      return setTiktokErrorMessage("");
      // }
    }
    setTiktokError(false);
    setTiktokErrorMessage("");
    return setUserInfo({ ...userInfo, [name]: value });
  }

  const validateUrl = (e: any) => {
    const { name, value } = e.target;

    if (name === 'facebook') {
      const pattern = /^(?:(?:http|https):\/\/)?(?:www.)?(web.facebook|m\.facebook|facebook)\.(com|me)\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w\-]*\/)*?(?:profile.php\?id=(?=\d.*))?([\w\-]*)?(?:[\w\-\.]*)?/g;

      if (pattern.test(value)) {
        setFacebookErrorMessage("");
        setFacebookError(false);
        return setUserInfo({ ...userInfo, [name]: value });
      }
      setFacebookErrorMessage("Please enter a valid URL!");
      setFacebookError(true);
      return setUserInfo({ ...userInfo, [name]: value });
    }

    if (name === 'youtube') {
      const pattern = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;

      if (pattern.test(value)) {
        setLinkedinErrorMessage("");
        setYoutubeError(false);
        return setUserInfo({ ...userInfo, [name]: value });
      }
      setYoutubeErrorMessage("Please enter a valid URL!");
      setYoutubeError(true);
      return setUserInfo({ ...userInfo, [name]: value });
    }

    if (name === 'linkedin') {
      const pattern = /(https?:\/\/(www.)|(www.))?linkedin.com\/(mwlite\/|m\/)?in\/[a-zA-Z0-9_.-]+\/?/;

      if (pattern.test(value)) {
        setLinkedinErrorMessage("");
        setLinkedinError(false);
        return setUserInfo({ ...userInfo, [name]: value });
      }
      setLinkedinErrorMessage("Please enter a valid URL!");
      setLinkedinError(true);
      return setUserInfo({ ...userInfo, [name]: value });
    }
  }

  return (
    <div className="card-body pt-0">
      <h4 className="mb-3">Enter your social details!</h4>
      <form onSubmit={handleSubmit}>
        {pageLoader ? (<PageLoader />) : (
          <div className="row g-4">
            <div className="col-lg-12">
              <label
                htmlFor='Phone_code'
                className="form-control-label form-label"
              >
                WhatsApp contry code
              </label>
              <select
                className="form-control"
                value={userInfo.whatsapp_country_code}
                id='Phone_code'
                onChange={(e: any) => setUserInfo({ ...userInfo, whatsapp_country_code: e?.target?.value }) }
              >
                <option selected></option>
                {country_code.map(
                  ({ dial_code }: { dial_code: string }, index: number) => {
                    return (
                      <option
                        key={index}
                        value={dial_code}
                      >
                        {dial_code}
                      </option>
                    );
                  }
                )}
              </select>
            </div>
            {/* <div className="col-lg-12">
              <Input
                label="WhatsApp country code"
                type="number"
                name="whatsapp_phone_number"
                id="whatsapp"
                placeholder="Enter WhatsApp number"
                required={false}
                defaultValue='234'
                value='234'
                onChange={() => setUserInfo({ ...userInfo, whatsapp_country_code: '234' })}
              />
            </div> */}
            <div className="col-lg-12">
              <Input
                label="WhatsApp"
                type="tel"
                name="whatsapp"
                id="username"
                placeholder="Enter Number"
                required={false}
                value={userInfo?.whatsapp_phone_number}
                onChange={(e: any) => { setUserInfo({ ...userInfo, whatsapp_phone_number: e.target?.value }) }}
              />
            </div>

            <div className="col-lg-12">
              <Input
                label="Facebook"
                type="url"
                name="facebook"
                id="facebook"
                placeholder="Enter Facebook url"
                required={false}
                value={userInfo?.facebook}
                error={facebookError}
                errorMessage={facebookErrorMessage}
                onChange={(e: any) => { validateUrl(e) }}
              />
            </div>
            <div className="col-lg-12">
              <Input
                label="Twitter (X)"
                type="text"
                name="twitter"
                id="twitter"
                placeholder="Username or profile url"
                required={false}
                value={userInfo?.twitter}
                onChange={(e: any) => validateTwitterUsername(e)}
                error={twitterError}
                errorMessage={twitterErrorMessage}
              />
            </div>
            <div className="col-lg-12">
              <Input
                label="Instagram"
                type="text"
                name="instagram"
                id="instagram"
                placeholder="Username or profile url"
                required={false}
                value={userInfo?.instagram}
                onChange={(e: any) => validateInstagramUsername(e)}
                error={instagramError}
                errorMessage={instagramErrorMessage}
              />
            </div>

            <div className="col-lg-12">
              <Input
                label="LinkedIn"
                type="url"
                name="linkedin"
                id="linkedin"
                placeholder="Enter LinkedIn url"
                required={false}
                value={userInfo?.linkedin}
                error={linkedinError}
                errorMessage={linkedinErrorMessage}
                onChange={(e: any) => { validateUrl(e) }}
              />
            </div>

            <div className="col-lg-12">
              <Input
                label="Tiktok"
                type="text"
                name="tiktok"
                id="tiktok"
                placeholder="Username or profile url"
                required={false}
                value={userInfo?.tiktok}
                onChange={(e: any) => validateTiktokUsername(e)}
                error={tiktokError}
                errorMessage={tiktokErrorMessage}
              />
            </div>

            <div className="col-lg-12">
              <Input
                label="YouTube"
                type="url"
                name="youtube"
                id="youtube"
                placeholder="Enter YouTube url"
                required={false}
                value={userInfo?.youtube}
                error={youtubeError}
                errorMessage={youtubeErrorMessage}
                onChange={(e: any) => { validateUrl(e) }}
              />
            </div>

            <div className="col-12">
              <Input
                label="Website"
                type="url"
                name="website"
                id="website"
                defaultValue={userInfo?.website}
                placeholder="Enter website"
                required={false}
                value=""
                onChange={(e: any) => { setUserInfo({ ...userInfo, website: e.target?.value }) }}
              />
            </div>

            <ul className="row gx-4 mt-4">
              <li className="col-6">
                <button
                  className="btn btn-outline-light w-100"
                  data-bs-dismiss="modal"
                  onClick={() => setFormSteps(1)}
                >
                  Previous
                </button>
              </li>
              <li className="col-6">
                <div className="form-group">
                  <PrimaryButton
                    type="submit"
                    title="Proceed"
                    disabled={
                      twitterError ||
                      instagramError ||
                      tiktokError ||
                      loading
                    }
                  />
                </div>
              </li>
            </ul>
          </div>
        )}
      </form>
    </div>
  );
}

export default SocialSetup;
