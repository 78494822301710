import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import "../../../components/assets/css/dashboard.scoped.css";
import "../../../components/assets/css/style.scoped.css";
import "../../../components/assets/css/radio.scoped.css";
import { getData, putData, postData } from "../../../helpers/request";
import { Popover } from "@headlessui/react";
import ConnectionModal from "../../../components/ui/Modal/ConnectionModal";
import { toast } from 'react-toastify';
import RevokeModal from "../../../components/ui/Modal/SuccessModal";
import PageLoader from "../../../components/ui/PageLoader";
import { CreateService } from "../Component/CreateService";
import { EditService } from "../Component/EditService";
import { ViewService } from "../Component/ViewService";
import Skeleton from "react-loading-skeleton";

export type ProfileServiceDetails = {
  short_description: string;
  long_description: string;
  button_text: string;
  button_url: string;
  id: string;
  createdAt?: string;
  profile_id?: string;
  status?: number;
  updatedAt?: string;
}

export interface ProfileService {
  short_description: string;
  long_description: string;
  button_text: string;
  button_url: string;
}

function CompanyServices() {
  const [allServices, setServices] = useState<Array<ProfileServiceDetails>>([]);
  const [openConnectionModal, setConnectionModal] = useState(false);
  const [service, setCreateService] = useState<ProfileService>({
    short_description: "",
    long_description: "",
    button_text: "",
    button_url: ""
  })
  const [requestLoader, setRequestLoader] = useState(false);
  const [card, setCard] = useState(false);
  const [serviceDetails, setServiceDetails] = useState<ProfileServiceDetails | null>(null);
  const [pageLoader, setPageLoader] = useState(false);
  const [openConfirmModal, setConfirmModal] = useState(false);
  const [connectionLayout, setConnectionLayout] = useState("all");
  const [viewLoader, setViewLoader] = useState(false);
  const [viewServiceModal, setViewServiceModal] = useState(false);
  const [userHasSubscription, setUserSubscription] = useState(false);

  useEffect(() => {
    getAllProfileServices();
  }, []);

  const getAllProfileServices = async () => {
    setPageLoader(true);
    const res = await getData(`/companyservice?page=1&size=50`);
    if (res) {
      setPageLoader(false);
      return setServices(res?.data);
    }
  }


  useEffect(() => {
    getProfileStatus();
  }, []);

  const getProfileStatus = async () => {
    const res: any = await getData("/subscription/check/active");

    if (res?.isSuccess) {
      return setUserSubscription(res?.isSubscribed);
    }
    return setUserSubscription(res?.isSubscribed);
  }

  const getActiveProfileServices = async () => {
    setPageLoader(true);
    const res = await getData(`/companyservice/active?page=1&size=50`);
    if (res) {
      setPageLoader(false);
      return setServices(res?.data);
    }
  }

  const viewService = async () => {
    setViewLoader(true);
    const res = await getData(`/profileservice/${serviceDetails?.id}`);
    if (res) {
      setViewLoader(false);
      return setServiceDetails(res);
    }
  }

  const toggleServiceModal = () => {
    setViewServiceModal(true);
    viewService();
  }

  const toggleServices = (value: string) => {
    setConnectionLayout(value);
    if (value === "all") return getAllProfileServices();
    return getActiveProfileServices();
  }

  const handleEditService = () => {
    if (card) return setCard(false);
    return setCard(true);
  }

  const toggleConnectionModal = () => {
    if (openConnectionModal) return setConnectionModal(false);
    return setConnectionModal(true);
  }

  const handleServiceCreation = async (e: FormEvent<HTMLInputElement>) => {
    e.preventDefault();
    setRequestLoader(true);
    const id = toast.loading("...Loading");
    const res = await postData(`/companyservice`, service);

    if (res?.isSuccess || res?.isSucess) {
      setRequestLoader(false);
      toggleConnectionModal();
      getAllProfileServices();
      return toast.update(id, { render: res?.message, type: "success", isLoading: false, autoClose: 5000, draggablePercent: 60, closeOnClick: true });
    }

    toast.update(id, { render: res?.message, type: "error", isLoading: false, autoClose: 5000, draggablePercent: 60, closeOnClick: true });
    return setRequestLoader(false);
  }

  const handleServiceUpdate = async () => {
    setRequestLoader(true);
    const id = toast.loading("...Loading");
    const res = await putData(`/companyservice`, serviceDetails as ProfileServiceDetails);

    if (res?.isSuccess || res?.isSucess) {
      setRequestLoader(false);
      handleEditService();
      getAllProfileServices();
      return toast.update(id, { render: res?.message, type: "success", isLoading: false, autoClose: 5000, draggablePercent: 60, closeOnClick: true });
    }

    toast.update(id, { render: res?.message, type: "error", isLoading: false, autoClose: 5000, draggablePercent: 60, closeOnClick: true });
    return setRequestLoader(false);
  }

  const addService = () => {
    if (openConnectionModal) return setConnectionModal(false);
    return setConnectionModal(true);
  };

  const changeServiceStatus = () => {
    return setConfirmModal(true);
  }

  const handleChangeStatus = async () => {
    setRequestLoader(true);
    const id = toast.loading("...Loading");
    const payload = {
      id: serviceDetails?.id,
      status: serviceDetails?.status ? false : true,
    };
    const res = await putData(`/companyservice/status`, payload);

    if (res?.isSuccess || res?.isSucess) {
      setRequestLoader(false);
      setConfirmModal(false);
      getAllProfileServices();
      return toast.update(id, { render: res?.message, type: "success", isLoading: false, autoClose: 5000, draggablePercent: 60, closeOnClick: true });
    }

    toast.update(id, { render: res?.message, type: "error", isLoading: false, autoClose: 5000, draggablePercent: 60, closeOnClick: true });
    return setRequestLoader(false);
  }
  return (
    <>
      <div>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h5 className="mb-0">{" "}</h5>
          <ul className="d-flex align-items-center gap gx-2">
            <li>
              <div className="dropdown">
                <div className="form-control-wrap">
                  {pageLoader ? (<Skeleton />) : (
                    <>
                      {/* {userHasSubscription && ( */}
                      <button className="btn btn-primary btn-block" type="button" onClick={addService} >
                        <em className="icon ri-add-fill pe-2" />
                        Add Service
                      </button>
                      {/* )} */}
                    </>
                  )}
                </div>
              </div>
            </li>
          </ul>
        </div>

        <div className="hidden-toggles">
          <input name="coloration-level" value="all" type="radio" id="coloration-low" className="hidden-toggles__input" checked={connectionLayout === "all"} onChange={(e: ChangeEvent<HTMLInputElement>) => toggleServices(e.target?.value)} />
          <label htmlFor="coloration-low" className="hidden-toggles__label">View all services</label>

          <input name="coloration-level" value="active" type="radio" id="coloration-medium" className="hidden-toggles__input" checked={connectionLayout === "active"} onChange={(e: ChangeEvent<HTMLInputElement>) => toggleServices(e.target?.value)} />
          <label htmlFor="coloration-medium" className="hidden-toggles__label">View active service</label>
        </div>

        <div className="card shadow-none">
          <ul className="nav nav-tabs nav-tabs-s1 px-4">
            <li className="nav-item">
              <button
                className="nav-link active"
                type="button"
                data-bs-toggle="tab"
                data-bs-target="#recents-documents-tab-pane"
              >
                Your Services
              </button>
            </li>
          </ul>
          <div className="tab-content">
            <div
              className="tab-pane fade show active"
              id="recents-documents-tab-pane"
            >
              <table className="table table-middle mb-0">
                {pageLoader ? (<PageLoader />) : (
                  <>
                    {allServices.length > 0 ? (
                      <tbody>
                        {allServices.map((service: ProfileServiceDetails, index: number) => {
                          return (
                            <>
                              <tr key={index}>
                                <td className="tb-col">
                                  <div className="caption-text line-clamp-2">
                                    {service?.short_description}
                                  </div>
                                </td>
                                <td className="tb-col">
                                  <div className="caption-text line-clamp-2">
                                    {service?.button_text}
                                  </div>
                                </td>
                                <td className="tb-col tb-col-sm">
                                  <div className={`badge rounded-pill px-2 py-1 fs-6 lh-sm ${service.status === 1 ? 'text-bg-blue-soft' : "text-bg-dark-soft"}`}>
                                    {`${service?.status === 1 ? 'Activated' : 'Not activated'}`}
                                  </div>
                                </td>
                                <td className="tb-col tb-col-end">
                                  <Popover className="dropdown">
                                    <Popover.Button className="btn btn-sm btn-icon btn-zoom me-n1" onClick={() => { setServiceDetails({ ...service }) }}>
                                      <em className="icon ri-separator" />
                                    </Popover.Button>

                                    <Popover.Panel>
                                      <div
                                        className="dropdown-menu dropdown-menu-end show"
                                        style={{
                                          position: "absolute",
                                          inset: "0px 0px auto auto",
                                          transform: "translate3d(-24px, 32px, 0px)"
                                        }}>
                                        <div className="dropdown-content">
                                          <ul className="link-list link-list-hover-bg-primary link-list-md">
                                            <li style={{ cursor: "pointer" }}>
                                              <a onClick={handleEditService}>
                                                <em className="icon ri-edit-line" />
                                                <span>Edit</span>
                                              </a>
                                            </li>

                                            <li style={{ cursor: "pointer" }}>
                                              {/* <a onClick={() => { handleFavouriteModal(service?.id) }}> */}
                                              <a onClick={() => { changeServiceStatus() }}>
                                                <em className="icon ri-rotate-lock-line" />
                                                <span>{service?.status === 1 ? "Deactivate" : "Activate"}</span>
                                              </a>
                                            </li>

                                            <li style={{ cursor: "pointer" }}>
                                              <a onClick={() => { toggleServiceModal() }}>
                                                <em className="icon ri-eye-line" />
                                                <span>View</span>
                                              </a>
                                            </li>

                                          </ul>
                                        </div>
                                      </div>
                                    </Popover.Panel>
                                  </Popover>
                                </td>
                              </tr>
                            </>
                          )
                        })}
                      </tbody>
                    ) : (
                      <div className="tab-pane" id="trash-documents-tab-pane">
                        <div className="row justify-content-center py-5">
                          <div className="col-md-7 col-lg-4">
                            <div className="mb-5 text-center">
                              <img src="images/illustrations/no-documents.svg" alt="" />
                            </div>
                            <div className="nk-block-head text-center">
                              <div className="nk-block-head-content">
                                <h1 className="nk-block-title mb-2">
                                  You have not added any services yet.
                                </h1>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </table>
            </div>
          </div>
        </div>

        {openConnectionModal && (
          <ConnectionModal
            title={"Create Service"}
            handleClose={toggleConnectionModal}
            ModalComponent={
              <CreateService
                service={service}
                setCreateService={setCreateService}
                onProceed={(e: FormEvent<HTMLInputElement>) => {
                  handleServiceCreation(e);
                }}
                requestLoader={requestLoader}
                onClose={toggleConnectionModal}
              />
            }
            open={openConnectionModal}
          />
        )}

        {openConfirmModal && (
          <RevokeModal
            title={`${serviceDetails?.status === 1 ? 'Deactivate' : 'Activate'} Service`}
            handleAction={handleChangeStatus}
            handleClose={() => { setConfirmModal(false) }}
            open={openConfirmModal}
            proceedText={`Are you sure you want to ${serviceDetails?.status === 1 ? 'Deactivate' : 'Activate'} this service?`}
            buttonText={"Yes, Continue"}
            showOtherButton={true}
            requestLoader={requestLoader}
            otherButtonText={"Cancel"}
          />
        )}

        {card && (
          <ConnectionModal
            title={"Update Service"}
            handleClose={() => { setCard(false) }}
            ModalComponent={
              <EditService
                service={serviceDetails}
                setCreateService={setServiceDetails}
                onProceed={() => { handleServiceUpdate(); }}
                requestLoader={requestLoader}
                onClose={() => { setCard(false) }}
              />
            }
            open={card}
          />
        )}

        {viewServiceModal && (
          <ConnectionModal
            title={"About Service"}
            handleClose={() => { setViewServiceModal(false) }}
            ModalComponent={
              <ViewService
                details={serviceDetails}
                loader={viewLoader}
                onClose={() => { setViewServiceModal(false) }}
              />
            }
            open={viewServiceModal}
          />
        )}
      </div >
    </>
  );
}

export default CompanyServices;
