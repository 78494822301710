import { useEffect } from "react";

const useScript = (url: string, selector = "body", isLocal = true) => {
  useEffect(() => {
    const element = document.querySelector(selector);
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = url;
    script.async = true;
    element?.appendChild(script);
    return () => {
      element?.removeChild(script);
    };
  }, [url]);
};

export default useScript;
