import "../../../components/assets/css/dashboard.scoped.css";
import "../../../components/assets/css/style.scoped.css";
import "../../../components/assets/css/radio.scoped.css";
import { NavLink } from "react-router-dom";

export const ViewCard = ({
  onClose,
  onProceed,
  connection,
  requestLoader,
  isSearched,
  handleAction
}: any) => {

  // const handleProceed = () => {
  //   handleProceed();
  // }

  interface Tag {
    id: string;
    name: string;
    profile_id: string;
    createdAt: string;
    updatedAt: string;
  }

  return (
    <>
      <div className="card bg-lighter bg-opacity-80 shadow-none mt-2">
        {connection?.about_me && (
          <div className="card-body contact-form">
            <div className="row g-4">
              <h4>About me</h4>
              <ul className="list-dot gap gy-2 mt-2">
                <li>{connection?.about_me}</li>
              </ul>
            </div>
          </div>
        )}
      </div>
      <ul className="row gx-4 mt-4">
        {(connection?.is_favourite !== 1 && connection?.is_connected) && (
          <li className="col-4">
            <button
              className="btn btn-outline-light w-100"
              data-bs-dismiss="modal"
              onClick={onClose}
            >
              <em className="icon ri-heart-line pe-2" />
              Favourite
            </button>
          </li>
        )}
        {connection?.is_favourite === 1 && (
          <li className="col-6">
            <button
              className="btn btn-danger w-100"
              onClick={handleAction}
              disabled={requestLoader}
            >
              Remove favorite
            </button>
          </li>
        )}
        <li className="col-4">
          <NavLink
            to={`/dashboard/details/${connection?.id}?isSearched=${isSearched}`}
            className="btn btn-outline-light w-100"
            data-bs-dismiss="modal"
          >
            <em className="icon ri-eye-line pe-2" />
            Details
          </NavLink>
        </li>
        {!connection?.is_connected && (
          <li className="col-4">
            <button
              className="btn btn-primary w-100"
              data-bs-dismiss="modal"
              onClick={onProceed}
              disabled={requestLoader}
            >
              <em className="icon ri-user-follow-fill pe-2" />
              Connect
            </button>
          </li>
        )}
      </ul>
    </>
  );
};
