import { Link, useLocation, useNavigate } from "react-router-dom";
import Logo from "../assets/img/logo-2.png";
import styles from "../assets/css/global.module.css";
import { Input } from "../../components/ui/Input/Input";
import { useInput } from "../../validation/InputValidation";
import PrimaryButton from "../../components/ui/Button/PrimaryButton";
import { FormEvent, useEffect, useState } from "react";
import { postData } from "../../helpers/request";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomOtpInput from "../../components/ui/Input/CustomOTPInput";

function ResetPassword() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const [requestLoader, setRequestLoader] = useState(false);
  const otpInput = useInput("");
  const passwordInput = useInput("");
  const confirmPasswordInput = useInput("");
  const [regError, setRegError] = useState("");
  const [type, setType] = useState<"text" | "number" | "email" | "password">(
    "password"
  );
  const [confirmType, setConfirmType] = useState<
    "text" | "number" | "email" | "password"
  >("password");
  const paramValue = queryParams.get("id");
  const [value, setValue] = useState("");

  const validateInput = (input: {
    value: string;
    setError: (value: boolean) => void;
  }) => {
    if (!input.value.trim()) {
      return input.setError(true);
    } else {
      input.setError(false);
    }
  };

  const validatePassword = (input: {
    value: string;
    errorMessage: string;
    setError: (value: boolean) => void;
    setErrorMessage: (value: string) => void;
  }) => {
    if (!input.value.trim()) {
      input.setError(true);
      return input.setErrorMessage("Input filed can't be empty!");
    }
    if (input.value !== passwordInput.value) {
      input.setError(true);
      return input.setErrorMessage(
        "Confirm password and password do not match!"
      );
    }
    input.setError(false);
    input.setErrorMessage("");
  };

  const togglePassword = () => {
    if (type === "password") return setType("text");
    return setType("password");
  };

  const toggleConfirmPassword = () => {
    if (confirmType === "password") return setConfirmType("text");
    return setConfirmType("password");
  };

  const handleChange = (e: string) => {
    setValue(e);
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    validateInput(passwordInput);
    validateInput(confirmPasswordInput);

    const payload = {
      code: value,
      password: passwordInput.value,
      confirm_password: confirmPasswordInput.value,
    };

    if (otpInput.error) return;
    if (passwordInput.error) return;
    if (confirmPasswordInput.error) return;

    setRequestLoader(true);
    const res: any = await postData("/profile/resetpassword", payload);

    if (res.isSuccess) {
      setRequestLoader(false);
      toast.success(res.message, { closeOnClick: true });
      const newSearch = `${queryParams.toString()}`;
      if (paramValue) {
        return navigate({
          pathname: "/login",
          search: newSearch,
        });
      }
      return navigate(`/login`);
    }
    if (!res.isSuccess) {
      setRequestLoader(false);
      toast.error(res.message, { closeOnClick: true });
    }
  };

  const initialCountdown = 300; // Initial countdown value
  const [countdown, setCountdown] = useState(initialCountdown);
  const [isRunning, setIsRunning] = useState(true);

  useEffect(() => {
    let timer: any;

    if (isRunning) {
      timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    }

    if (countdown === 0) {
      setIsRunning(false);
    }

    return () => clearInterval(timer);
  }, [countdown, isRunning]);

  const minutes = Math.floor(countdown / 60);
  const seconds = countdown % 60;

  const handleResendOTP = async () => {
    setRequestLoader(true);

    const reqBody = {
      email: JSON.parse(
        window?.localStorage?.getItem("card-git-email") as string
      ),
    };

    const res: any = await postData("/profile/forgotpassword", reqBody);

    if (res.isSuccess || res.isSucess) {
      toast.success(res?.message, { closeOnClick: true });
      setCountdown(300);
      setIsRunning(!isRunning);
      setRequestLoader(false);
      return;
    }
    toast.error(res?.message, { closeOnClick: true });
    return setRequestLoader(false);
  };

  return (
    <div className={styles["nk-app-root"]}>
      <main className={styles["nk-pages"]}>
        <div
          className={[
            styles["min-vh-100"],
            styles["d-flex"],
            styles["flex-column"],
            styles["has-mask"],
          ].join(" ")}
        >
          <div
            className={[
              styles["nk-mask"],
              styles["bg-pattern-dot"],
              styles["bg-blend-around"],
            ].join(" ")}
          />

          <div className={styles["my-auto"]}>
            <div className={styles.container}>
              <div
                className={[
                  styles["text-center"],
                  styles["mt-6"],
                  styles["mb-5"],
                ].join(" ")}
              >
                <Link to="/" className={styles["logo-link"]}>
                  <div className={styles["logo-wrap"]}>
                    <img
                      className={styles["logo-img"]}
                      width="180"
                      src={Logo}
                      alt=""
                    />
                  </div>
                </Link>
              </div>

              <div
                className={[
                  styles["row"],
                  styles["g-gs"],
                  styles["justify-content-center"],
                ].join(" ")}
              >
                <div
                  className={[
                    styles["col-md-7"],
                    styles["col-lg-6"],
                    styles["col-xl-5"],
                  ].join(" ")}
                >
                  <div
                    className={[
                      styles["card"],
                      styles["border-0"],
                      styles["shadow-sm"],
                      styles["rounded-4"],
                    ].join(" ")}
                  >
                    <div className={styles["card-body"]}>
                      <h4>Reset password</h4>
                      <p>
                        To reset your password, kindly enter the code sent to
                        your email and your new password!
                      </p>

                      <form onSubmit={handleSubmit}>
                        <div
                          className={[styles["row"], styles["g-4"]].join(" ")}
                        >
                          <div className={styles["col-12"]}>
                            <CustomOtpInput
                              label="Code"
                              valueLength={6}
                              value={value}
                              onChange={handleChange}
                            />
                          </div>
                          <div className={styles["col-12"]}>
                            <Input
                              label="Enter new password"
                              type={type}
                              inputType="password"
                              name="password"
                              id="password"
                              placeholder="Enter New Password"
                              required={true}
                              togglePassword={togglePassword}
                              {...passwordInput}
                            />
                          </div>
                          <div className={styles["col-12"]}>
                            <Input
                              label="Confirm new password"
                              type={confirmType}
                              inputType="password"
                              name="confirm_password"
                              id="confirmPassword"
                              placeholder="Confirm new password"
                              required={true}
                              togglePassword={toggleConfirmPassword}
                              {...confirmPasswordInput}
                            />
                          </div>

                          <div className={styles["col-12"]}>
                            <div className={styles["form-group"]}>
                              {regError && (
                                <p className={styles["form-error-message"]}>
                                  {regError}
                                </p>
                              )}
                            </div>
                          </div>

                          <div className={styles["col-12"]}>
                            <div
                              className={[
                                styles["d-flex"],
                                styles["flex-wrap"],
                                styles["justify-content-between"],
                                styles["align-items-center"],
                                styles["has-gap"],
                                styles["g-3"],
                              ].join(" ")}
                            >
                              <div className={styles["form-group"]}>
                                <PrimaryButton
                                  type="submit"
                                  title="Reset Password"
                                  id="submit-btn"
                                  disabled={
                                    otpInput.error ||
                                    passwordInput.error ||
                                    requestLoader
                                  }
                                />
                              </div>
                            </div>
                          </div>

                          <div className={styles["col-12"]}>
                            <p>
                              {countdown !== 0 && (
                                <span>
                                  O.T.P will expire in {minutes}:
                                  {seconds < 10 ? "0" : ""}
                                  {seconds}{" "}
                                </span>
                              )}
                              {countdown === 0 && (
                                <span
                                  onClick={handleResendOTP}
                                  style={{
                                    color: "#3f3eed",
                                    fontWeight: "bold",
                                    cursor: "pointer",
                                  }}
                                  className={styles['font-bold']}
                                >
                                  Resend OTP
                                </span>
                              )}
                            </p>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <p className={`${styles['text-center']} ${styles['mt-4']}`}>
                    <Link className={`${styles['link']} ${styles['has-gap']} ${styles['g-2']}`} to="/login">
                      <em className={`${styles.icon} ${styles['ri-arrow-left-line']}`} />
                      <span>Back to Login</span>
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default ResetPassword;
