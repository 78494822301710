import Avatar from "../../../components/assets/img/logo_2.png";
// import "../../../components/assets/css/dashboard.scoped.css";
// import "../../../components/assets/css/style.scoped.css";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { UserTemplate } from "../Favorites";
import { getData } from "../../../helpers/request";
import { UserCard } from "../../../store/AuthContext";
import Skeleton from "react-loading-skeleton";
import { Templates } from "../Component/CardTemplateDesigns";
import PageLoader from "../../../components/ui/PageLoader";

export type UserProfile = {
  id: string;
  last_name: string;
  registration_type: string;
  first_name: string;
  other_name: string;
  prefix: string;
  suffix: string;
  is_corporate_profile: boolean;
  company_id: string;
  service_group_id: string;
  business_name: string;
  designation: string;
  about_me: string;
  email: string;
  password?: string;
  country_code: string;
  Phone_number: string;
  corporate_id: string;
  show_corporate_services: boolean;
  create_corporate_services: boolean;
  photograph: string;
  address: string;
  address2: string;
  city: string;
  state: string;
  country: string;
  website: string;
  whatsapp_country_code: string;
  whatsapp_phone_number: string;
  facebook: string;
  twitter: string;
  linkedin: string;
  instagram: string;
  youtube: string;
  tiktok: string;
  plan_id: string;
  template_id: string;
  role_id: string;
  professional_field_id: string;
  status: string;
  date_confirmed: string;
  modified_by: string;
  agent_id: string;
  notify_new_connection: boolean;
  notify_service_click: boolean;
  profile_access: boolean;
  private_mode: boolean;
  private_share: boolean;
  email_private: boolean;
  phone_private: boolean;
  address_private: boolean;
  whatsapp_private: boolean;
  createdAt: string;
  updatedAt: string;
  image?: string;
  profile_id?: string;
};

function ProfileShare() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const [userProfile, setUsrProfile] = useState<UserProfile>({
    id: "",
    last_name: "",
    registration_type: "",
    first_name: "",
    other_name: "",
    prefix: "",
    suffix: "",
    is_corporate_profile: false,
    company_id: "",
    service_group_id: "",
    business_name: "",
    designation: "",
    about_me: "",
    email: "",
    country_code: "",
    Phone_number: "",
    corporate_id: "",
    show_corporate_services: false,
    create_corporate_services: false,
    photograph: "",
    address: "",
    address2: "",
    city: "",
    state: "",
    country: "",
    website: "",
    whatsapp_country_code: "",
    whatsapp_phone_number: "",
    facebook: "",
    twitter: "",
    linkedin: "",
    instagram: "",
    youtube: "",
    tiktok: "",
    plan_id: "",
    template_id: "",
    role_id: "",
    professional_field_id: "",
    status: "",
    date_confirmed: "",
    modified_by: "",
    agent_id: "",
    notify_new_connection: false,
    notify_service_click: false,
    profile_access: false,
    private_mode: false,
    private_share: false,
    email_private: true,
    phone_private: true,
    address_private: true,
    whatsapp_private: true,
    createdAt: "",
    updatedAt: "",
  });
  const [userInfo, setUserInfo] = useState<UserCard>({
    last_name: "",
    first_name: "",
    other_name: "",
    email: "",
    prefix: "",
    suffix: "",
    business_name: "",
    designation: "",
    about_me: "",
    phone_number: "",
    photograph: "",
    address: "",
    address2: "",
    city: "",
    state: "",
    country: "",
    website: "",
    whatsapp_country_code: "",
    whatsapp_phone_number: "",
    facebook: "",
    twitter: "",
    linkedin: "",
    instagram: "",
    youtube: "",
    tiktok: "",
    professional_field_id: "",
    image: "",
    template: {} as Templates,
    template_id: "",
  });
  const [requestLoader, setRequestLoader] = useState(false);

  const getProfile = useCallback(async () => {
    setRequestLoader(true);
    const paramValue = queryParams.get("CardId");
    const res = await getData(`/card/sharedcard/${paramValue}`);
    if (res) {
      setUserInfo({ ...res });
      setRequestLoader(false);
    }
  }, []);

  useEffect(() => {
    getProfile();
  }, [getProfile]);

  const goToDashboardAndSaveContact = () => {
    // access query parameters
    const paramValue = queryParams.get("CardId");
    //set query for next route
    queryParams.set("id", paramValue as string);
    const newSearch = `${queryParams.toString()}`;

    return navigate({
      pathname: "/dashboard/index",
      search: newSearch,
    });
  };

  const downloadToFile = (
    content: any,
    filename: string,
  ) => {
    var blob = new Blob([content], { type: "text/x-vcard" });
    var downloadUrl = URL.createObjectURL(blob);
    var a = document.createElement("a");
    a.href = downloadUrl;
    a.download = filename + ".vcf";
    document.body.appendChild(a);
    a.click();
  };

  const makeVCard = () => {
    var vcfString = `BEGIN:VCARD\nVERSION:3.0\nREV:${new Date().toISOString()}\nN;CHARSET=utf-8:${
      userInfo?.first_name
    } ${userInfo?.last_name}\nEMAIL;INTERNET:${userInfo?.email}\nTEL:+${
      userInfo?.whatsapp_country_code
    }${userInfo?.phone_number}\nADR:${userInfo?.address}\nTITLE:${
      userInfo?.designation
    }\nORG:${userInfo?.business_name}\nEND:VCARD`;

    downloadToFile(
      vcfString,
      `${userInfo?.first_name}-contact`,
    );
  };

  const downloadVCF = () => {
    makeVCard();
  };

  return (
    <>
      <div className="profile--preview">
        <div className="container-xl">
          {requestLoader ? (
            <PageLoader />
          ) : (
            <div className="row">
              <div className="col-md-4 mb-3">
                <div className="card card-profile">
                  <img
                    src={userInfo?.template?.image}
                    alt="placeholder"
                    className="card-img-top card--format"
                  />
                  <div className="row justify-content-center">
                    <div className="mt-n4 mt-lg-n6 mb-4 mb-lg-0">
                      <section className="container text-center m-auto position-relative">
                        <form>
                          <div className="mt-n4 mt-lg-n6 mb-lg-0 text-center m-auto">
                            {userInfo?.photograph ? (
                              <img
                                src={userInfo?.photograph}
                                alt="placeholder"
                                className="upload_image img-fluid border border-2 border-white"
                                style={{
                                  width: "108px",
                                  height: "108px",
                                  objectFit: "cover",
                                }}
                              />
                            ) : (
                              <img
                                src={Avatar}
                                alt="placeholder"
                                className="upload_image img-fluid border border-2 border-white"
                              />
                            )}
                          </div>
                        </form>
                      </section>
                    </div>
                  </div>
                  <div className="card-body pt-0">
                    <div className="text-center mt-3">
                      <h5 className="fw-bold">
                        {userInfo?.prefix}{" "}
                        {`
                          ${userInfo.first_name} 
                          ${
                            userInfo?.other_name !== null
                              ? userInfo?.other_name
                              : ""
                          } 
                          ${userInfo.last_name}
                        `}{" "}
                        {userInfo?.suffix}
                      </h5>
                      <p className="tex-sm" style={{ fontStyle: "italic" }}>
                        {userInfo?.business_name}
                      </p>
                      <p className="tex-sm" style={{ color: "#3f3eed" }}>
                        {userInfo?.designation}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-8">
                <div className="card px-2 px-lg-4 py-3 py-lg-0">
                  <div className="card-header bg-transparent pb-3 mb-3">
                    <div className="d-flex align-items-center justify-content-between">
                      <p className="mb-0 fw-bold">Card</p>
                      <div>
                        <button
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          className="btn btn-dark btn-sm ms-auto"
                          onClick={goToDashboardAndSaveContact}
                        >
                          <i className="icon ri-user-follow-fill pe-2"></i>
                          Save card
                        </button>

                        <button
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          className="btn btn-outline-light btn-sm"
                          onClick={downloadVCF}
                          style={{
                            marginLeft: "10px",
                          }}
                        >
                          <i className="ri-contacts-book-2-fill pe-2"></i>
                          Add to contact
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="card-body">
                    <p className="text-uppercase text-sm">Information</p>

                    <form action="">
                      <div className="row">
                        {requestLoader ? (
                          <Skeleton />
                        ) : (
                          <div className="bostami-parsonal-info-contact mb-30">
                            {userInfo?.phone_number && (
                              <div className="col-md-12 mb-2">
                                <div className="bostami-parsonal-info-contact-item">
                                  <a
                                    href={`tel: ${userInfo?.phone_number}`}
                                    target="_blank"
                                    style={{
                                      color: "#E93B81",
                                      fontSize: "22px",
                                    }}
                                    className="icon"
                                  >
                                    <i className="ri-phone-line"></i>
                                  </a>
                                  <div className="text">
                                    <span>Phone</span>
                                    <p>{userInfo?.phone_number}</p>
                                  </div>
                                </div>
                              </div>
                            )}
                            {userInfo?.email && (
                              <div className="col-md-6 mb-2">
                                <div className="bostami-parsonal-info-contact-item">
                                  <a
                                    href={`mailto: ${userInfo?.email}`}
                                    target="_blank"
                                    style={{
                                      color: "#E93B81",
                                      fontSize: "22px",
                                    }}
                                    className="icon"
                                  >
                                    <i className="ri-mail-line"></i>
                                  </a>
                                  <div className="text">
                                    <span>Email</span>
                                    <p
                                      className="tex-sm"
                                      style={{ textTransform: "lowercase" }}
                                    >
                                      {userInfo?.email}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            )}
                            {userInfo?.address && (
                              <div className="col-md-6 mb-2">
                                <div className="bostami-parsonal-info-contact-item">
                                  <div
                                    style={{
                                      color: "#E93B81",
                                      fontSize: "22px",
                                    }}
                                    className="icon"
                                  >
                                    <i className="ri-map-pin-line"></i>
                                  </div>
                                  <div className="text">
                                    <span>Location</span>
                                    <p
                                      className="tex-sm"
                                      style={{ textTransform: "lowercase" }}
                                    >
                                      {userInfo?.address}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            )}
                            {userInfo?.facebook && (
                              <div className="col-md-6 mb-2">
                                <div className="bostami-parsonal-info-contact-item">
                                  <a
                                    target="_blank"
                                    href={userInfo?.facebook}
                                    style={{
                                      color: "#E93B81",
                                      fontSize: "22px",
                                    }}
                                    className="icon"
                                  >
                                    <i className="ri-facebook-fill"></i>
                                  </a>
                                  <div className="text">
                                    <span>Facebook</span>
                                    <p
                                      className="tex-sm"
                                      style={{ textTransform: "lowercase" }}
                                    >
                                      {userInfo?.facebook}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            )}
                            {userInfo?.youtube && (
                              <div className="col-md-6 mb-2">
                                <div className="bostami-parsonal-info-contact-item">
                                  <a
                                    href={userInfo?.youtube}
                                    target="_blank"
                                    style={{
                                      color: "#E93B81",
                                      fontSize: "22px",
                                    }}
                                    className="icon"
                                  >
                                    <i className="ri-youtube-line"></i>
                                  </a>
                                  <div className="text">
                                    <span>Youtube</span>
                                    <p
                                      className="tex-sm"
                                      style={{ textTransform: "lowercase" }}
                                    >
                                      {userInfo?.youtube}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            )}
                            {userInfo?.linkedin && (
                              <div className="col-md-6 mb-2">
                                <div className="bostami-parsonal-info-contact-item">
                                  <a
                                    href={userInfo?.linkedin}
                                    target="_blank"
                                    style={{
                                      color: "#E93B81",
                                      fontSize: "22px",
                                    }}
                                    className="icon"
                                  >
                                    <i className="ri-linkedin-fill"></i>
                                  </a>
                                  <div className="text">
                                    <span>LinkedIn</span>
                                    <p
                                      className="tex-sm"
                                      style={{ textTransform: "lowercase" }}
                                    >
                                      {userInfo?.linkedin}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            )}
                            {userInfo?.tiktok && (
                              <div className="col-md-6 mb-2">
                                <div className="bostami-parsonal-info-contact-item">
                                  <a
                                    href={userInfo?.tiktok}
                                    target="_blank"
                                    style={{
                                      color: "#E93B81",
                                      fontSize: "22px",
                                    }}
                                    className="icon"
                                  >
                                    <i className="ri-tiktok-fill"></i>
                                  </a>
                                  <div className="text">
                                    <span>TikTok</span>
                                    <p
                                      className="tex-sm"
                                      style={{ textTransform: "lowercase" }}
                                    >
                                      {userInfo?.tiktok}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            )}
                            {userInfo?.twitter && (
                              <div className="col-md-6 mb-2">
                                <div className="bostami-parsonal-info-contact-item">
                                  <a
                                    href={userInfo?.twitter}
                                    target="_blank"
                                    style={{
                                      color: "#E93B81",
                                      fontSize: "22px",
                                    }}
                                    className="icon"
                                  >
                                    <i className="ri-twitter-x-fill"></i>
                                  </a>
                                  <div className="text">
                                    <span>Twitter</span>
                                    <p
                                      className="tex-sm"
                                      style={{ textTransform: "lowercase" }}
                                    >
                                      {userInfo?.twitter}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            )}
                            {userInfo?.instagram && (
                              <div className="col-md-6 mb-2">
                                <div className="bostami-parsonal-info-contact-item">
                                  <a
                                    href={userInfo?.instagram}
                                    target="_blank"
                                    style={{
                                      color: "#E93B81",
                                      fontSize: "22px",
                                    }}
                                    className="icon"
                                  >
                                    <i className="ri-instagram-fill"></i>
                                  </a>
                                  <div className="text">
                                    <span>Instagram</span>
                                    <p
                                      className="tex-sm"
                                      style={{ textTransform: "lowercase" }}
                                    >
                                      {userInfo?.instagram}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            )}
                            {userInfo?.whatsapp_phone_number && (
                              <div className="col-md-6 mb-2">
                                <div className="bostami-parsonal-info-contact-item">
                                  <a
                                    href={`https://wa.me/${userInfo?.whatsapp_phone_number}`}
                                    target="_blank"
                                    style={{
                                      color: "#E93B81",
                                      fontSize: "22px",
                                    }}
                                    className="icon"
                                  >
                                    <i className="ri-whatsapp-fill"></i>
                                  </a>
                                  <div className="text">
                                    <span>WhatsApp</span>
                                    <p
                                      className="tex-sm"
                                      style={{ textTransform: "lowercase" }}
                                    >
                                      {userInfo?.whatsapp_phone_number}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            )}
                            {userInfo?.website && (
                              <div className="col-md-6 mb-2">
                                <div className="bostami-parsonal-info-contact-item">
                                  <a
                                    href={userInfo?.website}
                                    target="_blank"
                                    style={{
                                      color: "#E93B81",
                                      fontSize: "22px",
                                    }}
                                    className="icon"
                                  >
                                    <i className="ri-ie-fill"></i>
                                  </a>
                                  <div className="text">
                                    <span>Website</span>
                                    <p
                                      className="tex-sm"
                                      style={{ textTransform: "lowercase" }}
                                    >
                                      {userInfo?.website}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default ProfileShare;
