import {
  createRoutesFromElements,
  createBrowserRouter,
  Route,
} from "react-router-dom";

import Login from "../pages/auth/Login";
import Register from "../pages/auth/Register";
import ForgotPassword from "../pages/auth/ForgotPassword";
import EmailVerification from "../pages/auth/Verification";
import DashboardLayout from "../components/Layout/DashboardLayout";
import Dashboard from "../views/Dashboard/Dashboard";
import Favorites from "../views/Dashboard/Favorites";
import Connections from "../views/Dashboard/Connections";
import Cards from "../views/Dashboard/Cards";
import Profile from "../views/Dashboard/Profile";
import Details from "../views/Dashboard/Details";
import ResetPassword from "../pages/auth/ResetPassword";


import Settings from "../views/Dashboard/Settings";
import Services from "../views/Dashboard/Services";
import UpgradePlanPage from "../views/Dashboard/UpgradePlan";
import ProfileIndex from "../views/Dashboard/ProfileSetup/Index";
import ProfilePreview from "../views/Dashboard/ProfileSetup/ProfilePreview";
import ProfileShare from "../views/Dashboard/ProfileSetup/ProfileShare";
import CardPreview from "../views/Dashboard/CardPreview";
import CardEdit from "../views/Dashboard/CardPreviewEdit";
import CreateCard from "../views/Dashboard/CreateCard";
import ProfileEdit from "../views/Dashboard/ProfileEdit";
import ConnectedProfiles from "../views/Dashboard/ConnectedProfiles";


// COMPANY ADMIN ROUTES
import AdminDashboard from "../views/Admin/Admin/Dashboard";
import Staff from "../views/Admin/Admin/Staff";
import StaffDetails from "../views/Admin/Admin/StaffDetails";
import CompanyServices from "../views/Admin/Admin/Services";
import CreateStaffCard from "../views/Admin/Admin/Card/CreateStaffCard";
import CompanyProfile from "../views/Admin/Admin/CompanyProfile";
import CompanyProfileEdit from "../views/Admin/Admin/CompanyProfileEdit";
import StaffActivation from "../pages/auth/StaffActivation";
import CompanyCard from "../views/Admin/Admin/CompanyCard";
import CompanyCardPreview from "../views/Admin/Admin/CompanyCardPreview";
import CompanyCardEdit from "../views/Admin/Admin/CompanyCardPreviewEdit";

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/">
        {/* <Route index path="/" element={<HomePage />}></Route>
        <Route path="/cards" element={<CardPage />}></Route> */}
        <Route index element={<Login />} />
      </Route>
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Register />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/verification/:id" element={<EmailVerification />} />
      <Route path="/activate-account" element={<StaffActivation />} />
      <Route path="/profile-setup" element={<ProfileIndex />} />
      <Route path="/profile-preview" element={<ProfilePreview />} />
      <Route path="/sharecard" element={<ProfileShare />} />
      <Route path="/dashboard" element={<DashboardLayout />}>
        <Route
          index
          path="/dashboard/index"
          element={<Dashboard />}
          // loader={connectionsLoader}
        />
        <Route path="/dashboard/favorites" element={<Favorites />} />
        <Route path="/dashboard/connections" element={<Connections />} />
        <Route path="/dashboard/connected-profiles/:id" element={<ConnectedProfiles />} />
        <Route path="/dashboard/cards" element={<Cards />} />
        <Route path="/dashboard/profile" element={<Profile />} />
        <Route path="/dashboard/profile-edit" element={<ProfileEdit />} />
        <Route path="/dashboard/settings" element={<Settings />} />
        <Route path="/dashboard/services" element={<Services />} />
        <Route path="/dashboard/details/:id" element={<Details />} />
        <Route path="/dashboard/upgrade-plan" element={<UpgradePlanPage />} />

        {/* Card Setup */}
        <Route path="/dashboard/card-setup" element={<ProfileIndex />} />
        <Route path="/dashboard/card-preview" element={<ProfilePreview />} />
        <Route path="/dashboard/card/:id" element={<CardPreview />} />
        <Route path="/dashboard/card/:id/edit" element={<CardEdit />} />
        <Route path="/dashboard/card/add" element={<CreateCard />} />
        <Route path="/dashboard/share-card" element={<ProfileShare />} />

        {/* Company admin */}
        <Route path="/dashboard/admin-dashboard" element={<AdminDashboard />} />
        <Route path="/dashboard/admin-staff" element={<Staff />} />
        <Route path="/dashboard/staff/:id" element={<StaffDetails />} />
        <Route path="/dashboard/admin-services" element={<CompanyServices />} />
        <Route path="/dashboard/staff/card/add/:id" element={<CreateStaffCard/>} />
        <Route path="/dashboard/company" element={<CompanyProfile/>} />
        <Route path="/dashboard/company-edit" element={<CompanyProfileEdit/>} />
        <Route path="/dashboard/company-cards" element={<CompanyCard />} />
        <Route path="/dashboard/company-card/:id" element={<CompanyCardPreview />} />
        <Route path="/dashboard/company-card/:id/edit" element={<CompanyCardEdit />} />
      </Route>
    </Route>
  )
);
