// import "../../../components/assets/css/dashboard.scoped.css";
// import "../../../components/assets/css/style.scoped.css";
// import "../../../components/assets/css/radio.scoped.css";
// import "../../../pages/assets/css/global.scoped.css";
// import "../../../pages/assets/css/button.scoped.css";
import upgrade from "../../../components/assets/css/upgrade.module.css";

import { PaystackButton } from "react-paystack";
import {
  PaystackResponse,
  calculatePayStackFee,
  formatCurrency,
} from "../../../components/ui/UpgradePlan";
import { useRef } from "react";

export const ViewProfileSummary = ({
  onClose,
  onProceed,
  subscription,
  requestLoader,
  user,
  currency,
  activeTab,
}: any) => {
  const payStackRef: any = useRef();
  const handlePaystackSuccessAction = (reference: PaystackResponse) => {
    // Create user subscription after paystack is successful
    onProceed(reference);
  };

  // you can call this function anything
  const handlePaystackCloseAction = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    console.log("closed");
  };

  const config = {
    reference: new Date().getTime().toString(),
    email: user?.email as string,
    publicKey: process.env.REACT_APP_PAYSTACK_KEY as string,
  };

  const componentProps = {
    ...config,
    text: "",
    onSuccess: (reference: PaystackResponse) =>
      handlePaystackSuccessAction(reference),
    onClose: handlePaystackCloseAction,
  };

  const amtToPay = () => {
    if (activeTab === 1) return subscription?.monthly_fee;
    return subscription?.yearly_fee_with_discount;
  };

  const handlePayment = async () => {
    const payStactBtn = document.querySelector(".paystack-btn") as any;
    return payStactBtn?.click();
  };

  return (
    <>
      <div className="card bg-lighter bg-opacity-80 shadow-none mt-2">
        <div className="card-body contact-form">
          <div className="row g-4">
            <div className="list-dot gap gy-2 mt-2">
              <h4 className="mb-3">
                <span className="caption-text text-muted">Type: </span>
                <span>Personal</span>
                <br />
              </h4>
              <h4 className="mb-3">
                <span className="caption-text text-muted">Duration: </span>
                <span className="text-gradient-primary">
                  {activeTab === 1 ? "Monthly" : "Yearly"}
                </span>
                <br />
              </h4>
              <h4 className="mb-3">
                <span className="caption-text text-muted">Amount: </span>
                <span className="text-gradient-primary">
                  {currency}
                  {formatCurrency(amtToPay())}{" "}
                </span>
                <br />
              </h4>
            </div>
          </div>
        </div>
      </div>
      <ul className="row gx-4 mt-4">
        <li className="col-6">
          <button
            className="btn btn-outline-light w-100"
            data-bs-dismiss="modal"
            onClick={onClose}
          >
            Change
          </button>
        </li>
        <li className="col-6 paystack">
          <button
            className={[
              upgrade.button,
              "btn btn-primary btn-soft btn-block",
            ].join(" ")}
            disabled={requestLoader}
            onClick={() => {
              handlePayment();
            }}
          >
            Continue
          </button>
        </li>

        <button ref={payStackRef} style={{ visibility: "hidden", opacity: 0 }}>
          <PaystackButton
            {...componentProps}
            amount={calculatePayStackFee(amtToPay())}
            className="paystack-btn"
          />
        </button>

        {/* <PaystackButton
          {...componentProps}
          amount={calculatePayStackFee(amtToPay())}
        /> */}
      </ul>
    </>
  );
};
