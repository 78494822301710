import { useEffect, useState } from "react";
import "../../../components/assets/css/dashboard.scoped.css";
import "../../../components/assets/css/style.scoped.css";
import "../../../components/assets/css/radio.scoped.css";
import CustomSelect from "../../../components/ui/Select/Select";
import { getData } from "../../../helpers/request";

export const CreateConnection = ({
  onClose,
  onProceed,
  handleTag,
  connection,
  requestLoader,
  setCreateConnection,
}: any) => {
  const [tags, setTags] = useState([]);

  useEffect(() => {
    getAllTags();
  }, []);

  const getAllTags = async () => {
    const res = await getData(`/tag`);
    if (res) {
      setTags(res?.data);
    }
  }

  // const handleProceed = () => {
  //   handleProceed();
  // }

  interface Tag {
    id: string;
    name: string;
    profile_id: string;
    createdAt: string;
    updatedAt: string;
  }

  return (
    <>
      <div className="card bg-lighter bg-opacity-80 shadow-none mt-2">
        <div className="card-body contact-form">
          <div className="row g-4">
            <div className="col-12">
              <div className="form-group">
                <CustomSelect
                  label="Connection tag"
                  id="connectionTag"
                  value={connection?.connection_tag}
                  onChange={(e) => { setCreateConnection({ ...connection, connection_tag: e?.name }) }}
                  options={tags}
                  defaultOptionIsSelected="Select an option"
                  mapOptionToLabel={(tag: Tag) => tag?.name}
                  mapOptionToValue={(tag: Tag) => tag?.id}
                />
              </div>

              <div className="m-auto mt-4">
                <button
                  type="button"
                  className="btn btn-sm btn-dark float-right"
                  onClick={() => { handleTag() }}
                >
                  Add Tag
                </button>
              </div>
            </div>

            <div className="col-12">
              <div className="form-input-item mb-40">
                <label className="input-lebel message">Note</label>
                <textarea name="message"
                  className="input-box message false "
                  style={{ height: "70px" }}
                  rows={10}
                  value={connection?.note}
                  onChange={(e) => { setCreateConnection({ ...connection, note: e?.target?.value }) }}
                >
                </textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ul className="row gx-4 mt-4">
        <li className="col-6">
          <button
            className="btn btn-outline-light w-100"
            data-bs-dismiss="modal"
            onClick={onClose}
          >
            Cancel
          </button>
        </li>
        <li className="col-6">
          <button
            className="btn btn-primary w-100"
            data-bs-dismiss="modal"
            onClick={onProceed}
            disabled={requestLoader}
          >
            Connect
          </button>
        </li>
      </ul>
    </>
  );
};
