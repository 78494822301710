import React from "react";
import "../assets/css/pageLoader.css";

const PageLoader = () => {
  return (
    <>
      <div className="loader">
        <div className="duo duo1">
          <div className="dot dot-a"></div>
          <div className="dot dot-b"></div>
        </div>
        <div className="duo duo2">
          <div className="dot dot-a"></div>
          <div className="dot dot-b"></div>
        </div>
      </div>
    </>
  );
};

export default PageLoader;
