import Avatar from "../../components/assets/img/logo_2.png";
import dashboard from "../../components/assets/css/dashboard.module.css";
import styles from "../../components/assets/css/style.module.css";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getData, postData } from "../../helpers/request";
import ScanCodeModal from "../../components/ui/Modal/ScanCodeModal";
import { toast } from "react-toastify";
import { FullUserCard } from "./Cards";
import Skeleton from "react-loading-skeleton";
import DeleteCardModal from "../../components/ui/Modal/DeleteModal";
import ShareProfileWithEmail from "../../components/ui/Modal/ShareEmail";
import BackgroundImage from "../../components/assets/img/page-bg_2.jpeg";

export type UserProfile = {
  id: string;
  last_name: string;
  registration_type: string;
  first_name: string;
  other_name: string;
  prefix: string;
  suffix: string;
  is_corporate_profile: boolean;
  company_id: string;
  service_group_id: string;
  business_name: string;
  designation: string;
  about_me: string;
  email: string;
  password?: string;
  country_code: string;
  Phone_number: string;
  corporate_id: string;
  show_corporate_services: boolean;
  create_corporate_services: boolean;
  photograph: string;
  address: string;
  address2: string;
  city: string;
  state: string;
  country: string;
  website: string;
  whatsapp_country_code: string;
  whatsapp_phone_number: string;
  facebook: string;
  twitter: string;
  linkedin: string;
  instagram: string;
  youtube: string;
  tiktok: string;
  plan_id: string;
  template_id: string;
  role_id: string;
  professional_field_id: string;
  status: string;
  date_confirmed: string;
  modified_by: string;
  agent_id: string;
  notify_new_connection: boolean;
  notify_service_click: boolean;
  profile_access: boolean;
  private_mode: boolean;
  private_share: boolean;
  email_private: boolean;
  phone_private: boolean;
  address_private: boolean;
  whatsapp_private: boolean;
  createdAt: string;
  updatedAt: string;
};

function CardPreview() {
  const navigate = useNavigate();
  // const location = useLocation();

  const [cardProfile, setCardProfile] = useState<FullUserCard>({} as FullUserCard);
  const [requestLoader, setRequestLoader] = useState(false);
  const [emailRequest, setEmailRequest] = useState(false);

  const [openQRModal, setQRModal] = useState(false);
  const [openEmailModal, setEmailModal] = useState(false);
  const [card, setCard] = useState(false);

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState("");

  const { id } = useParams();

  const getProfile = useCallback(async () => {
    setRequestLoader(true);
    const res = await getData(`/card/${id?.toString()}`);
    if (res) {
      setCardProfile({ ...res });
      setRequestLoader(false);
    }
  }, [id]);

  const copyQR = () => {
    navigator.clipboard.writeText(cardProfile?.cardQrCodeUrl as string);
    toggleScanModal();
    toast.success('Copied successfully', { closeOnClick: true });
  }

  const handleProfileShareByEmail = async () => {
    const validEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (!email) {
      setEmailError(true);
      return setEmailErrorMessage("This field is required!");
    }

    if (!email.match(validEmail)) {
      setEmailError(true);
      return setEmailErrorMessage("Please enter a valid email address!");
    }

    setEmailError(false);
    setEmailErrorMessage("");
    setEmailRequest(true);

    const id = toast.loading("...Loading");

    const reqBody = {
      card_id: cardProfile?.id,
      email: email,
    };

    const res = await postData('cardconnection/sharecard', reqBody);

    if (res?.isSuccess || res?.isSucess) {
      toast.update(id, { render: `${res?.message}`, type: "success", isLoading: false, autoClose: 5000, draggablePercent: 60 });
      toggleEmailModal();
      setEmailRequest(false);
      return;
    }

    toast.update(id, { render: `${res?.message}`, type: "error", isLoading: false, autoClose: 5000, draggablePercent: 60 });
    setEmailRequest(false);
    return;
  }

  useEffect(() => {
    getProfile();
  }, [getProfile]);

  const goToDashboard = () => {
    return navigate(`/dashboard/card/${id}/edit`);
  };

  const goToConnectedProfiles = () => {
    return navigate(`/dashboard/connected-profiles/${id}`)
  }

  const toggleScanModal = () => {
    if (openQRModal) return setQRModal(false);
    return setQRModal(true);
  }

  const toggleEmailModal = () => {
    if (openEmailModal) return setEmailModal(false);
    return setEmailModal(true);
  }

  const toggleDeleteModal = () => {
    if (card) return setCard(false);
    return setCard(true);
  }

  const handleDelete = async () => {
    setRequestLoader(true);
    const id = toast.loading("...Loading");
    const payload = {
      card_id: cardProfile?.id,
    };
    const res = await postData(`card/deletecard`, payload);

    if (res?.isSuccess || res?.isSucess) {
      setRequestLoader(false);
      toggleDeleteModal();
      toast.update(id, { render: res?.message, type: "success", isLoading: false, autoClose: 5000, draggablePercent: 60, closeOnClick: true });
      return navigate('/dashboard/cards');
    }

    toast.update(id, { render: res?.message, type: "error", isLoading: false, autoClose: 5000, draggablePercent: 60, closeOnClick: true });
    return setRequestLoader(false);
  };

  const pattern = new RegExp('^(https?|ftp)://');

  const twitterUrl = (username: string = '') => {
    return `https://twitter.com/${username}`;
  }

  const instagramUrl = (username: string = '') => {
    return `https://www.instagram.com/${username}`;
  }

  const tiktokUrl = (username: string = '') => {
    return `https://www.tiktok.com/${username}`
  }

  return (
    <>
      <div className={styles['profile--preview']}>
        <div className={dashboard['container-xl']}>
          <div className={dashboard.row}>
            <div className="col-md-4 mb-3">
              <div className="card card-profile">
                {requestLoader ? (
                  <Skeleton
                    circle
                    height="100%"
                    containerClassName="avatar-skeleton"
                  />
                ) : (
                  <img
                    src={cardProfile?.template?.image ? cardProfile?.template?.image : BackgroundImage}
                    alt="placeholder"
                    className="card-img-top card--format"
                  />
                )}
                <div className="row justify-content-center">
                  <div className="mt-n4 mt-lg-n6 mb-4 mb-lg-0">
                    <section className="container text-center m-auto position-relative">
                      <form>
                        {requestLoader ? (
                          <Skeleton
                            circle
                            height="100%"
                            containerClassName="avatar-skeleton"
                          />
                        ) : (
                          <div className="mt-n4 mt-lg-n6 mb-lg-0 text-center m-auto">
                            {cardProfile?.photograph ? (
                              <img
                                src={cardProfile?.photograph}
                                alt="placeholder"
                                className="upload_image img-fluid border border-2 border-white"
                                style={{
                                  width: "108px",
                                  height: "108px",
                                  objectFit: "cover"
                                }}
                              />
                            ) : (
                              <img
                                src={Avatar}
                                alt="placeholder"
                                className="upload_image img-fluid border border-2 border-white"
                              />
                            )}
                          </div>
                        )}
                      </form>
                    </section>
                  </div>
                </div>
                <div className="card-body pt-0">
                  <div className="text-center mt-3">
                    {requestLoader ? (
                      <Skeleton />
                    ) : (
                      <>
                        <h5 className="fw-bold">
                          {cardProfile?.prefix}
                          {" "}
                          {
                            `
                              ${cardProfile.first_name} 
                              ${cardProfile?.other_name !== null ? cardProfile?.other_name : ''} 
                              ${cardProfile.last_name}
                            `
                          }{" "}
                          {cardProfile?.suffix}
                        </h5>
                        <p
                          className="tex-sm"
                          style={{ fontStyle: "italic" }}
                        >
                          {cardProfile?.business_name}
                        </p>
                        <p
                          className="tex-sm"
                          style={{ color: "#3f3eed" }}
                        >
                          {cardProfile?.designation}
                        </p>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-8">
              <div className="card px-2 px-lg-4 py-3 py-lg-0">
                <div className="card-header bg-transparent pb-3 mb-3">
                  <div className="d-flex align-items-center justify-content-between">
                    <p className="mb-0 fw-bold"></p>
                    <div>
                      <button
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        className="btn btn-dark btn-sm ms-auto mb-4"
                        onClick={toggleScanModal}
                      >
                        <i className="ri-qr-code-line pe-2"></i>
                        Scan QRCode
                      </button>
                      <button
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        className="btn btn-outline-light btn-sm mb-4"
                        onClick={toggleEmailModal}
                        style={{
                          marginLeft: "10px"
                        }}
                      >
                        <i className="ri-mail-send-line pe-2"></i>
                        Email Card
                      </button>
                      <button
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        className="btn btn-outline-light btn-sm mb-4"
                        style={{
                          marginLeft: "10px"
                        }}
                        onClick={goToDashboard}
                      >
                        <i className="ri-edit-2-line pe-2"></i>
                        Edit
                      </button>
                      <button
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        className="btn btn-outline-light btn-sm mb-4"
                        style={{
                          marginLeft: "10px"
                        }}
                        onClick={goToConnectedProfiles}
                      >
                        <i className="ri-link pe-2"></i>
                        View connections
                      </button>
                      <button
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        className="btn btn-outline-light btn-sm mb-4"
                        style={{
                          marginLeft: "10px",
                          color: "red",
                        }}
                        onClick={toggleDeleteModal}
                      >
                        <i className="ri-delete-bin-2-line pe-2"></i>
                        Delete
                      </button>
                    </div>
                  </div>
                </div>

                <div className="card-body">
                  <p className="text-uppercase text-sm">Your Information</p>

                  <form action="">
                    <div className="row">
                      {requestLoader ? (<Skeleton />) : (
                        <div className="bostami-parsonal-info-contact mb-30">
                          {cardProfile?.phone_number && (
                            <div className="col-md-12 mb-2">
                              <div className="bostami-parsonal-info-contact-item">
                                <a
                                  href={`tel: ${cardProfile?.phone_number}`}
                                  target="_blank"
                                  style={{ color: "#E93B81", fontSize: "22px" }}
                                  className="icon"
                                  rel="noreferrer"
                                >
                                  <i className="ri-phone-line"></i>
                                </a>
                                <div className="text">
                                  <span>Phone</span>
                                  <p>{cardProfile?.phone_number}</p>
                                </div>
                              </div>
                            </div>
                          )}
                          {cardProfile?.email && (<div className="col-md-6 mb-2">
                            <div className="bostami-parsonal-info-contact-item">
                              <a
                                href={`mailto: ${cardProfile?.email}`}
                                target="_blank"
                                style={{ color: "#E93B81", fontSize: "22px" }}
                                className="icon"
                                rel="noreferrer"
                              >
                                <i className="ri-mail-line"></i>
                              </a>
                              <div className="text">
                                <span>Email</span>
                                <p className="tex-sm" style={{ textTransform: "lowercase" }}>{cardProfile?.email}</p>
                              </div>
                            </div>
                          </div>)}
                          {cardProfile?.address && (<div className="col-md-6 mb-2">
                            <div className="bostami-parsonal-info-contact-item">
                              <div
                                style={{ color: "#E93B81", fontSize: "22px" }}
                                className="icon"
                              >
                                <i className="ri-map-pin-line"></i>
                              </div>
                              <div className="text">
                                <span>Location</span>
                                <p className="tex-sm" style={{ textTransform: "lowercase" }}>{cardProfile?.address}</p>
                              </div>
                            </div>
                          </div>)}
                          {cardProfile?.facebook && (<div className="col-md-6 mb-2">
                            <div className="bostami-parsonal-info-contact-item">
                              <a
                                target="_blank"
                                href={cardProfile?.facebook}
                                style={{ color: "#E93B81", fontSize: "22px" }}
                                className="icon"
                                rel="noreferrer"
                              >
                                <i className="ri-facebook-fill"></i>
                              </a>
                              <div className="text">
                                <span>Facebook</span>
                                <p className="tex-sm" style={{ textTransform: "lowercase" }}>{cardProfile?.facebook}</p>
                              </div>
                            </div>
                          </div>)}
                          {cardProfile?.youtube && (<div className="col-md-6 mb-2">
                            <div className="bostami-parsonal-info-contact-item">
                              <a
                                href={cardProfile?.youtube}
                                target="_blank"
                                style={{ color: "#E93B81", fontSize: "22px" }}
                                className="icon"
                                rel="noreferrer"
                              >
                                <i className="ri-youtube-line"></i>
                              </a>
                              <div className="text">
                                <span>Youtube</span>
                                <p className="tex-sm" style={{ textTransform: "lowercase" }}>{cardProfile?.youtube}</p>
                              </div>
                            </div>
                          </div>)}
                          {cardProfile?.linkedin && (<div className="col-md-6 mb-2">
                            <div className="bostami-parsonal-info-contact-item">
                              <a
                                href={cardProfile?.linkedin}
                                target="_blank"
                                style={{ color: "#E93B81", fontSize: "22px" }}
                                className="icon"
                                rel="noreferrer"
                              >
                                <i className="ri-linkedin-fill"></i>
                              </a>
                              <div className="text">
                                <span>LinkedIn</span>
                                <p className="tex-sm" style={{ textTransform: "lowercase" }}>{cardProfile?.linkedin}</p>
                              </div>
                            </div>
                          </div>)}
                          {cardProfile?.tiktok && (<div className="col-md-6 mb-2">
                            <div className="bostami-parsonal-info-contact-item">
                              <a
                                href={pattern.test(cardProfile?.tiktok) ? cardProfile?.tiktok : tiktokUrl(cardProfile?.tiktok)}
                                target="_blank"
                                style={{ color: "#E93B81", fontSize: "22px" }}
                                className="icon"
                                rel="noreferrer"
                              >
                                <i className="ri-tiktok-fill"></i>
                              </a>
                              <div className="text">
                                <span>Titkok</span>
                                <p className="tex-sm" style={{ textTransform: "lowercase" }}>{pattern.test(cardProfile?.tiktok) ? cardProfile?.tiktok : `@${(cardProfile?.tiktok)}`}</p>
                              </div>
                            </div>
                          </div>)}
                          {/* {cardProfile?.tiktok && (<div className="col-md-6 mb-2">
                            <div className="bostami-parsonal-info-contact-item">
                              <a
                                href={cardProfile?.tiktok}
                                target="_blank"
                                style={{ color: "#E93B81", fontSize: "22px" }}
                                className="icon"
                              >
                                <i className="ri-tiktok-fill"></i>
                              </a>
                              <div className="text">
                                <span>TikTok</span>
                                <p className="tex-sm" style={{ textTransform: "lowercase" }}>{pattern.test(cardProfile?.tiktok) ? cardProfile?.tiktok : tiktokUrl(cardProfile?.tiktok)}</p>
                              </div>
                            </div>
                          </div>)} */}
                          {cardProfile?.twitter && (<div className="col-md-6 mb-2">
                            <div className="bostami-parsonal-info-contact-item">
                              <a
                                href={pattern.test(cardProfile?.twitter) ? cardProfile?.twitter : twitterUrl(cardProfile?.twitter)}
                                target="_blank"
                                style={{ color: "#E93B81", fontSize: "22px" }}
                                className="icon"
                                rel="noreferrer"
                              >
                                <i className="ri-twitter-x-fill"></i>
                              </a>
                              <div className="text">
                                <span>Twitter (X)</span>
                                <p className="tex-sm" style={{ textTransform: "lowercase" }}>{pattern.test(cardProfile?.twitter) ? cardProfile?.twitter : `@${(cardProfile?.twitter)}`}</p>
                              </div>
                            </div>
                          </div>)}
                          {cardProfile?.instagram && (<div className="col-md-6 mb-2">
                            <div className="bostami-parsonal-info-contact-item">
                              <a
                                href={pattern.test(cardProfile?.instagram) ? cardProfile?.instagram : instagramUrl(cardProfile?.instagram)}
                                target="_blank"
                                style={{ color: "#E93B81", fontSize: "22px" }}
                                className="icon"
                                rel="noreferrer"
                              >
                                <i className="ri-instagram-fill"></i>
                              </a>
                              <div className="text">
                                <span>Instagram</span>
                                <p className="tex-sm" style={{ textTransform: "lowercase" }}>{pattern.test(cardProfile?.instagram) ? cardProfile?.instagram : `@${(cardProfile?.instagram)}`}</p>
                              </div>
                            </div>
                          </div>)}
                          {cardProfile?.whatsapp_phone_number && (<div className="col-md-6 mb-2">
                            <div className="bostami-parsonal-info-contact-item">
                              <a
                                href={`https://wa.me/${cardProfile?.whatsapp_country_code}${cardProfile?.whatsapp_phone_number}`}
                                target="_blank"
                                style={{ color: "#E93B81", fontSize: "22px" }}
                                className="icon"
                                rel="noreferrer"
                              >
                                <i className="ri-whatsapp-fill"></i>
                              </a>
                              <div className="text">
                                <span>WhatsApp</span>
                                <p className="tex-sm" style={{ textTransform: "lowercase" }}>{cardProfile?.whatsapp_phone_number}</p>
                              </div>
                            </div>
                          </div>)}
                          {cardProfile?.website && (<div className="col-md-6 mb-2">
                            <div className="bostami-parsonal-info-contact-item">
                              <a
                                href={cardProfile?.website}
                                target="_blank"
                                style={{ color: "#E93B81", fontSize: "22px" }}
                                className="icon"
                                rel="noreferrer"
                              >
                                <i className="ri-ie-fill"></i>
                              </a>
                              <div className="text">
                                <span>Website</span>
                                <p className="tex-sm" style={{ textTransform: "lowercase" }}>{cardProfile?.website}</p>
                              </div>
                            </div>
                          </div>)}
                        </div>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        {openQRModal && (
          <ScanCodeModal
            handleClose={toggleScanModal}
            open={openQRModal}
            qrcode_url={cardProfile.cardQrCodeUrl as string}
            handleAction={copyQR}
          />
        )}

        {openEmailModal && (
          <ShareProfileWithEmail
            handleClose={toggleEmailModal}
            open={openEmailModal}
            setEmail={setEmail}
            emailError={emailError}
            emailErrorMessage={emailErrorMessage}
            emailRequest={emailRequest}
            id={cardProfile.id as string}
            handleAction={handleProfileShareByEmail}
          />
        )}

        {card && (
          <DeleteCardModal
            title={"Delete card"}
            handleAction={handleDelete}
            handleClose={toggleDeleteModal}
            open={card}
            proceedText={"Are you sure you want to delete this card?"}
            buttonText={"Yes, Delete"}
            showOtherButton={true}
            requestLoader={requestLoader}
            otherButtonText={"Keep"}
          />
        )}
      </div>
    </>
  );
}

export default CardPreview;
