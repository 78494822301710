// import "../../../components/assets/css/dashboard.scoped.css";
// import "../../../components/assets/css/style.scoped.css";
// import "../../../components/assets/css/radio.scoped.css";
// import "../../../pages/assets/css/global.scoped.css";
// import "../../../pages/assets/css/button.scoped.css";
import upgrade from "../../../components/assets/css/upgrade.module.css";

import { formatCurrency } from "../../../components/ui/UpgradePlan";
import { Input } from "../../../components/ui/Input/Input";

export const UpgradeSummary = ({
  onClose,
  onProceed,
  subscription,
  requestLoader,
  user,
  currency,
  activeTab,
  setUserNumber,
  userNumberError,
}: any) => {
  const amtToPay = () => {
    if (activeTab === 1) return subscription?.monthly_fee;
    return subscription?.yearly_fee_with_discount;
  };

  return (
    <>
      <div className={upgrade.section}>
        <div className="card bg-lighter bg-opacity-80 shadow-none mt-2">
          <div className="card-body contact-form">
            <div className="row g-4">
              <div className="list-dot gap gy-2 mt-2">
                <h4 className="mb-3">
                  <span className="caption-text text-muted">Duration: </span>
                  <span className="text-gradient-primary">
                    {activeTab === 1 ? "Monthly" : "Yearly"}
                  </span>
                  <br />
                </h4>
                <h4 className="mb-3">
                  <span className="caption-text text-muted">Amount: </span>
                  <span className="text-gradient-primary">
                    {currency}
                    {formatCurrency(amtToPay())}{" "}
                  </span>
                  <span className="caption-text text-muted">Per User</span>
                  <br />
                </h4>
                <div className="mb-3">
                  <span className="title mt-3">
                    <Input
                      label="Number of users"
                      type="number"
                      name="numberOfUsers"
                      id="numberOfUsers"
                      placeholder="Number of users"
                      required={true}
                      value=""
                      onChange={(e: any) => {
                        setUserNumber(e.target?.value);
                      }}
                    />

                    {userNumberError.length > 0 && (
                      <p
                        style={{
                          marginTop: "8px",
                          color: "red",
                          textTransform: "none",
                        }}
                      >
                        {userNumberError}
                      </p>
                    )}
                  </span>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
        <ul className="row gx-4 mt-4">
          <li className="col-6">
            <button
              className="btn btn-outline-light w-100"
              data-bs-dismiss="modal"
              onClick={onClose}
            >
              Change
            </button>
          </li>
          <li className="col-6">
            <button
              className={[
                upgrade.button,
                "btn btn-primary btn-soft btn-block",
              ].join(" ")}
              disabled={requestLoader}
              onClick={onProceed}
            >
              Continue
            </button>
          </li>
        </ul>
      </div>
    </>
  );
};
