export const country_code = [
  {"country": "Nigeria", "dial_code": "234"},
  // {"country": "Algeria", "dial_code": "+213"},
  // {"country": "Angola", "dial_code": "+244"},
  // {"country": "Benin", "dial_code": "+229"},
  // {"country": "Botswana", "dial_code": "+267"},
  // {"country": "Burkina Faso", "dial_code": "+226"},
  // {"country": "Burundi", "dial_code": "+257"},
  // {"country": "Cabo Verde", "dial_code": "+238"},
  // {"country": "Cameroon", "dial_code": "+237"},
  // {"country": "Central African Republic", "dial_code": "+236"},
  // {"country": "Chad", "dial_code": "+235"},
  // {"country": "Comoros", "dial_code": "+269"},
  // {"country": "Congo", "dial_code": "+242"},
  // {"country": "Côte d'Ivoire", "dial_code": "+225"},
  // {"country": "Democratic Republic of the Congo", "dial_code": "+243"},
  // {"country": "Djibouti", "dial_code": "+253"},
  // {"country": "Egypt", "dial_code": "+20"},
  // {"country": "Equatorial Guinea", "dial_code": "+240"},
  // {"country": "Eritrea", "dial_code": "+291"},
  // {"country": "Eswatini", "dial_code": "+268"},
  // {"country": "Ethiopia", "dial_code": "+251"},
  // {"country": "Gabon", "dial_code": "+241"},
  // {"country": "Gambia", "dial_code": "+220"},
  {"country": "Ghana", "dial_code": "233"},
  // {"country": "Guinea", "dial_code": "+224"},
  // {"country": "Guinea-Bissau", "dial_code": "+245"},
  // {"country": "Kenya", "dial_code": "+254"},
  // {"country": "Lesotho", "dial_code": "+266"},
  // {"country": "Liberia", "dial_code": "+231"},
  // {"country": "Libya", "dial_code": "+218"},
  // {"country": "Madagascar", "dial_code": "+261"},
  // {"country": "Malawi", "dial_code": "+265"},
  // {"country": "Mali", "dial_code": "+223"},
  // {"country": "Mauritania", "dial_code": "+222"},
  // {"country": "Mauritius", "dial_code": "+230"},
  // {"country": "Morocco", "dial_code": "+212"},
  // {"country": "Mozambique", "dial_code": "+258"},
  // {"country": "Namibia", "dial_code": "+264"},
  // {"country": "Niger", "dial_code": "+227"},
  // {"country": "Rwanda", "dial_code": "+250"},
  // {"country": "São Tomé and Príncipe", "dial_code": "+239"},
  // {"country": "Senegal", "dial_code": "+221"},
  // {"country": "Seychelles", "dial_code": "+248"},
  // {"country": "Sierra Leone", "dial_code": "+232"},
  // {"country": "Somalia", "dial_code": "+252"},
  // {"country": "South Africa", "dial_code": "+27"},
  // {"country": "South Sudan", "dial_code": "+211"},
  // {"country": "Sudan", "dial_code": "+249"},
  // {"country": "Tanzania", "dial_code": "+255"},
  // {"country": "Togo", "dial_code": "+228"},
  // {"country": "Tunisia", "dial_code": "+216"},
  // {"country": "Uganda", "dial_code": "+256"},
  // {"country": "Zambia", "dial_code": "+260"},
  // {"country": "Zimbabwe", "dial_code": "+263"}
  {"country": "United Kingdom", "dial_code": "44"}
]
