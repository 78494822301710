import "../../../components/assets/css/dashboard.scoped.css";
import "../../../components/assets/css/style.scoped.css";
import Avatar from "../../../components/assets/img/logo_2.png";
import useScript from "../../../pages/hooks/useScript";
import { FormEvent, useCallback, useEffect, useState } from "react";
import { getData, putData } from "../../../helpers/request";
import { useParams } from "react-router-dom";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import { toast } from "react-toastify";
import BackgroundImage from "../../../components/assets/img/page-bg_2.jpeg";
import { FullUserCard } from "./CompanyCard";
import { AdminCardTemplateDesign } from "./Card/AdminCardTemplateDesign";
import CardSocialDetails from "../../Dashboard/Component/SocialDetails";
import { CardPersonalDetails } from "../../Dashboard/Component/PersonalDetails";

function CompanyCardEdit() {
  useScript("https://res.cloudinary.com/dovtransd/raw/upload/v1694620004/fx5xdbukdszs8vld93ar.js", "html", false);
  const { id } = useParams();
  const [cardProfile, setCardProfile] = useState<FullUserCard>({} as FullUserCard);
  // const [pageLoader, setPageLoader] = useState(false);
  const [pageLoader, setRequestLoader] = useState(false);
  const [stepper, setStepper] = useState(1);
  const [professionalFields, setProfFields] = useState<Array<any>>([]);
  const pattern = new RegExp('^(https?|ftp)://');

  useEffect(() => {
    getProfile();
    getProfessionalField();
  }, []);

  const getProfile = useCallback(async () => {
    setRequestLoader(true);
    const res = await getData(`/companycard/${id?.toString()}`);
    if (res) {
      setCardProfile({ ...res });
      setRequestLoader(false);
    }
  }, [id]);

  const getProfessionalField = useCallback(async () => {
    const res = await getData(`/utility/professionalfield?page=1&size=20`);
    if (res) {
      setProfFields([...res?.data]);
    }
  }, []);

  const onHandle = async (e: FormEvent) => {
    e.preventDefault();
    delete cardProfile["template"];
    setRequestLoader(true);
    const res = await putData('/companycard', cardProfile);

    if (res) {
      setRequestLoader(false);
      toast.success(res?.message, { closeOnClick: true });
      getProfile();
    }
  }

  const twitterUrl = (username: string = '') => {
    return `https://twitter.com/${username}`;
  }

  const instagramUrl = (username: string = '') => {
    return `https://www.instagram.com/${username}`;
  }

  return (
    <>
      <div>
        <div className="row">
          <div className="col-xxl-3 col-xl-3">
            <div className="bostami-parsonal-info-area shadow">
              <div className="bostami-parsonal-info-wrap">
                <div className="bostami-parsonal-info-img">
                  {pageLoader ? (
                    <Skeleton
                      circle
                      height="100%"
                      containerClassName="avatar-skeleton"
                    />
                  ) : (
                    <img
                      alt="avatar"
                      loading="lazy"
                      width="240"
                      height="240"
                      src={cardProfile?.template?.image ? cardProfile?.template?.image : BackgroundImage}
                    />
                  )}
                </div>

                <div className="row justify-content-center">
                  <div className="mt-n4 mt-lg-n6 mb-4 mb-lg-0">
                    <section className="container text-center m-auto position-relative">
                      <form>
                        {pageLoader ? (
                          <Skeleton
                            circle
                            height="100%"
                            containerClassName="avatar-skeleton"
                          />
                        ) : (
                          <div className="mt-n4 mt-lg-n6 mb-lg-0 text-center m-auto">
                            {cardProfile?.photograph ? (
                              <img
                                src={cardProfile?.photograph}
                                alt="placeholder"
                                className="upload_image img-fluid border border-2 border-white"
                                style={{
                                  width: "108px",
                                  height: "108px",
                                  objectFit: "cover"
                                }}
                              />
                            ) : (
                              <img
                                src={Avatar}
                                alt="placeholder"
                                className="upload_image img-fluid border border-2 border-white"
                              />
                            )}
                          </div>
                        )}
                      </form>
                    </section>
                  </div>
                </div>

                <h4 className="bostami-parsonal-info-name">
                  {pageLoader ? <Skeleton /> :
                    (<a href="#">
                      {cardProfile?.prefix && (
                        <span>
                          {cardProfile?.prefix}
                        </span>
                      )}
                      {" "}
                      {pageLoader ? <Skeleton /> : `${cardProfile?.first_name} ${cardProfile?.other_name !== null ? cardProfile?.other_name : ''} ${cardProfile?.last_name}`}{" "}
                      {" "}
                      {cardProfile?.suffix && (
                        <span>
                          {cardProfile?.suffix}
                        </span>
                      )}
                      {/* <span style={{ color: pageLoader ? "" : "#1773EA" }}>
                      {pageLoader ? <Skeleton /> : (<i className="ri-verified-badge-fill"></i>)}
                    </span>{" "} */}
                    </a>)}
                </h4>

                <h4 className="mt-0 pt-0">{pageLoader ? <Skeleton /> : cardProfile?.business_name}</h4>

                <span className="bostami-parsonal-info-bio mb-15">
                  {pageLoader ? <Skeleton /> : cardProfile?.designation}
                </span>

                <ul className="bostami-parsonal-info-social-link mb-30">
                  {pageLoader ? <Skeleton /> : (
                    <li>
                      {cardProfile?.facebook && (
                        <a style={{ color: "#1773EA" }} href={cardProfile?.facebook} target="_blank" rel="noreferrer">
                          <i className="fa-brands ri-facebook-fill"></i>
                        </a>
                      )}
                    </li>
                  )}
                  {pageLoader ? <Skeleton /> : (
                    <li>
                      {cardProfile?.twitter && (
                        <a style={{ color: "#1C9CEA" }} href={pattern.test(cardProfile?.twitter) ? cardProfile?.twitter : twitterUrl(cardProfile?.twitter)} target="_blank" rel="noreferrer">
                          <i className="fa-brands ri-twitter-fill"></i>
                        </a>
                      )}
                    </li>
                  )}
                  {pageLoader ? <Skeleton /> : (
                    <li>
                      {cardProfile?.instagram && (
                        <a style={{ color: "#e12a72" }} href={pattern.test(cardProfile?.instagram) ? cardProfile?.instagram : instagramUrl(cardProfile?.instagram)} target="_blank" rel="noreferrer">
                          <i className="fa-brands ri-instagram-fill"></i>
                        </a>
                      )}
                    </li>
                  )}
                  {pageLoader ? <Skeleton /> : (
                    <li>
                      {cardProfile?.linkedin && (
                        <a style={{ color: "#144679" }} href={cardProfile?.linkedin} target="_blank" rel="noreferrer">
                          <i className="fa-brands ri-linkedin-fill"></i>
                        </a>
                      )}
                    </li>
                  )}
                </ul>

                <div className="bostami-parsonal-info-contact mb-30">
                  <div className="bostami-parsonal-info-contact-item">
                    {pageLoader ? <Skeleton /> : (
                      <>
                        {cardProfile?.phone_number && (
                          <div className="bostami-parsonal-info-contact-item">
                            <a
                              href={`tel: ${cardProfile?.phone_number}`}
                              target="_blank"
                              style={{ color: "#E93B81", fontSize: "22px" }}
                              className="icon"
                              rel="noreferrer"
                            >
                              <i className="ri-phone-line"></i>
                            </a>
                            <div className="text">
                              <span>Phone</span>
                              <p>{cardProfile?.phone_number}</p>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>

                  {pageLoader ? <Skeleton /> : (
                    <>
                      {cardProfile?.email && (
                        <div className="bostami-parsonal-info-contact-item">
                          <a
                            href={`mailto: ${cardProfile?.email}`}
                            target="_blank"
                            style={{ color: "#E93B81", fontSize: "22px" }}
                            className="icon"
                            rel="noreferrer"
                          >
                            <i className="ri-mail-line"></i>
                          </a>
                          <div className="text">
                            <span>Email</span>
                            <p style={{ textTransform: "lowercase" }}>{cardProfile?.email}</p>
                          </div>
                        </div>
                      )}
                    </>
                  )}

                  {pageLoader ? <Skeleton /> : (
                    <>
                      {cardProfile?.whatsapp_phone_number && (
                        <div className="bostami-parsonal-info-contact-item">
                          <a
                            href={`https://wa.me/${cardProfile?.whatsapp_country_code}${cardProfile?.whatsapp_phone_number}`}
                            target="_blank"
                            style={{ color: "#E93B81", fontSize: "22px" }}
                            className="icon"
                            rel="noreferrer"
                          >
                            <i className="ri-whatsapp-fill"></i>
                          </a>
                          <div className="text">
                            <span>Whatsapp</span>
                            <p style={{ textTransform: "lowercase" }}>{cardProfile?.whatsapp_phone_number}</p>
                          </div>
                        </div>
                      )}
                    </>
                  )}

                  {pageLoader ? <Skeleton /> : (
                    <>
                      {cardProfile?.website && (
                        <div className="bostami-parsonal-info-contact-item">
                          <a
                            href={`${cardProfile?.website}`}
                            target="_blank"
                            style={{ color: "#E93B81", fontSize: "22px" }}
                            className="icon"
                            rel="noreferrer"
                          >
                            <i className="ri-ie-fill"></i>
                          </a>
                          <div className="text">
                            <span>Website</span>
                            <p style={{ textTransform: "lowercase" }}>{cardProfile?.website}</p>
                          </div>
                        </div>
                      )}
                    </>
                  )}

                  {pageLoader ? <Skeleton /> : (
                    <>
                      {cardProfile?.address && (
                        <div className="bostami-parsonal-info-contact-item">
                          <div
                            style={{ color: "#E93B81", fontSize: "22px" }}
                            className="icon"
                          >
                            <i className="ri-map-pin-line"></i>
                          </div>
                          <div className="text">
                            <span>Location</span>
                            <p style={{ textTransform: "lowercase" }}>{cardProfile?.address}</p>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Mobile stepper */}
          <div className="col-xxl-1 col-xl-1 show-mobileStepper mb-2">
            <div className="bostami-main-menu-wrap shadow">
              <nav className="bastami-main-menu main_menu">
                {pageLoader ? <Skeleton /> : (
                  <ul style={{
                    display: "flex",
                    justifyContent: "space-around"
                  }}>
                    <li
                      className={`menu-item profile_bar ${stepper === 1 ? 'active' : ''}`}
                      data-target="about"
                    >
                      <a href="#" onClick={() => setStepper(1)}>
                        <span>
                          <i
                            style={{ fontSize: "25px" }}
                            className="ri-user-line"
                          ></i>
                        </span>
                        Information
                      </a>
                    </li>
                    <li className={`menu-item profile_bar ${stepper === 2 ? 'active' : ''}`}>
                      <a href="#" onClick={() => setStepper(2)}>
                        <span>
                          <i
                            style={{ fontSize: "25px" }}
                            className="ri-file-text-line"
                          ></i>
                        </span>
                        Socials
                      </a>
                    </li>
                    <li className={`menu-item profile_bar ${stepper === 3 ? 'active' : ''}`}>
                      <a href="#" onClick={() => setStepper(3)}>
                        <span>
                          <em
                            style={{ fontSize: "25px" }}
                            className="icon ri-profile-line"
                          ></em>
                        </span>
                        Card design
                      </a>
                    </li>
                  </ul>
                )}
              </nav>
            </div>
          </div>

          <div className="col-xxl-8 col-xl-8">
            {stepper === 1 && (
              /* <!-- PERSONAL SECTION --> */
              <CardPersonalDetails
                pageLoader={pageLoader}
                cardProfile={cardProfile}
                setCardProfile={setCardProfile}
                professionalFields={professionalFields}
                onProceed={(e: FormEvent) => {
                  onHandle(e);
                }}
              />
            )}

            {stepper === 2 && (
              /* <!-- SOCIAL SECTION --> */
              <CardSocialDetails
                pageLoader={pageLoader}
                cardProfile={cardProfile}
                setCardProfile={setCardProfile}
                onProceed={(e: FormEvent) => {
                  onHandle(e);
                }}
              />
            )}

            {stepper === 3 && (
              /* <!-- PERSONAL SECTION --> */
              <AdminCardTemplateDesign
                pageLoader={pageLoader}
                cardProfile={cardProfile}
                setCardProfile={setCardProfile}
                professionalFields={professionalFields}
                onProceed={(e: FormEvent) => {
                  onHandle(e);
                }}
              />
            )}
          </div>

          <div className="col-xxl-1 col-xl-1 d-xl-block d-none">
            <div className="bostami-main-menu-wrap shadow">
              <nav className="bastami-main-menu main_menu">
                {pageLoader ? <Skeleton /> : (
                  <ul>
                    <li
                      className={`menu-item profile_bar ${stepper === 1 ? 'active' : ''}`}
                      data-target="about"
                    >
                      <a href="#" onClick={() => setStepper(1)}>
                        <span>
                          <i
                            style={{ fontSize: "25px" }}
                            className="ri-user-line"
                          ></i>
                        </span>
                        Information
                      </a>
                    </li>
                    <li className={`menu-item profile_bar ${stepper === 2 ? 'active' : ''}`}>
                      <a href="#" onClick={() => setStepper(2)}>
                        <span>
                          <i
                            style={{ fontSize: "25px" }}
                            className="ri-file-text-line"
                          ></i>
                        </span>
                        Socials
                      </a>
                    </li>
                    <li className={`menu-item profile_bar ${stepper === 3 ? 'active' : ''}`}>
                      <a href="#" onClick={() => setStepper(3)}>
                        <span>
                          <em
                            style={{ fontSize: "25px" }}
                            className="icon ri-profile-line"
                          ></em>
                        </span>
                        Card design
                      </a>
                    </li>
                  </ul>
                )}
              </nav>
            </div>
          </div>
        </div>
      </div >
    </>
  );
}

export default CompanyCardEdit;
