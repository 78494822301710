import { useEffect, useState } from "react";
// import "../../../pages/assets/css/global.scoped.css";
import ConnectionModal from "../../../components/ui/Modal/ConnectionModal";
import CardSetup from "./CardSetup";

function ProfileIndex() {
  const [formStep, setFormSteps] = useState(1);
  const [cardModal, setCardModal] = useState(false);

  useEffect(() => {
    setCardModal(true);
  }, []);

  const toggleCardModal = () => {
    if (cardModal) {
      return setCardModal(false);
    }
    return setCardModal(true);
  }

  return (
    // <div className="nk-app-root ">
    //   <main className="nk-pages">
    //     <div className="min-vh-100 d-flex flex-column has-mask">
    //       <div className="nk-mask bg-pattern-dot bg-blend-around" />

    // <div className="my-auto">
    //   <div className="container">
    //     <div className="text-center mt-6 mb-5"></div>

    // <div className="row g-gs justify-content-center">
    <div className="">
      {/* <div className="col-md-7 col-lg-6 col-xl-5">
        <div className="card border-0 shadow-sm rounded-4">
          {formStep === 1 && (
            <PersonalSetup setFormSteps={setFormSteps} />
          )}
          {formStep === 2 && (
            <SocialSetup setFormSteps={setFormSteps} />
          )}
        </div>
      </div> */}

      {cardModal && (
        <ConnectionModal
          title={""}
          // handleClose={toggleCardModal}
          ModalComponent={
            <CardSetup />
          }
          open={cardModal}
        />
      )}
    </div>
    // </div>
    // </div> 
    //     </div>
    //   </main>
    // </div>
  );
}

export default ProfileIndex;
