import React, { FormEvent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../../../components/assets/css/dashboard.scoped.css";
import 'react-loading-skeleton/dist/skeleton.css';
import { getData, postData, putData } from "../../../helpers/request";
import PageLoader from "../../../components/ui/PageLoader";
import dayjs from 'dayjs';
import { Input } from "../../../components/ui/Input/Input";
import { toast } from 'react-toastify';
import { country_code } from "../../../helpers/countryCode";
import { FullUserCard } from "../../Dashboard/Cards";
import { Permissions } from "./CompanyProfile";
import Logo from "../../../components/assets/img/logo.png";

export default function Staff() {
  type Role = {
    id: string;
    is_active: boolean;
    name: string;
    created_by?: string;
    modified_by?: null,
    createdAt?: string;
    updatedAt?: null
  };

  type Staff = {
    id?: string;
    last_name: string;
    about_me?: string | null;
    registration_type?: string;
    first_name: string;
    business_name?: string;
    company_name?: string;
    designation?: string;
    private_mode?: number;
    prefix?: string | null;
    suffix?: string | null;
    other_name?: string;
    is_corporate_profile?: number;
    service_group_id?: string | null;
    email: string;
    country_code?: string;
    Phone_number?: string;
    company_id?: string;
    is_profile_updated?: number;
    photograph?: string;
    address?: string;
    address2?: string | null;
    city?: string;
    state?: string;
    country?: string;
    website?: string | null;
    whatsapp_country_code?: string;
    whatsapp_phone_number?: string;
    facebook?: string | null;
    twitter?: string | null;
    linkedin?: string | null;
    instagram?: string | null;
    youtube?: string | null;
    tiktok?: string | null;
    plan_id?: string | null;
    role_id?: string | null;
    professional_field_id?: string | null;
    status?: string;
    date_confirmed?: string | null;
    modified_by?: string | null;
    agent_id?: string | null;
    notify_new_connection?: number;
    notify_service_click?: number;
    createdAt?: string;
    updatedAt?: string;
  }

  type StaffDetails = {
    professional_field?: string;
    profile: Staff;
    roles: Array<Role>;
  }

  type CompanyFullCard = FullUserCard & {
    company_id: string;
    status?: number;
  }

  const [pageLoader, setPageLoader] = useState(false);
  const [openStaffModal, setToggleStaffModal] = useState(false);
  const [openStaffPermModal, setToggleStaffPermModal] = useState(false);

  const [staffDetails, setStaffDetails] = useState<StaffDetails>({
    profile: {
      id: "",
      last_name: "",
      about_me: "",
      registration_type: "",
      first_name: "",
      business_name: "",
      designation: "",
      private_mode: 0,
      prefix: "",
      suffix: "",
      other_name: "",
      is_corporate_profile: 0,
      service_group_id: "",
      email: "",
      country_code: "",
      Phone_number: "",
      company_id: "",
      is_profile_updated: 0,
      photograph: "",
      address: "",
      address2: "",
      city: "",
      state: "",
      country: "",
      website: "",
      whatsapp_country_code: "",
      whatsapp_phone_number: "",
      facebook: "",
      twitter: "",
      linkedin: "",
      instagram: "",
      youtube: "",
      tiktok: "",
      plan_id: "",
      role_id: "",
      professional_field_id: "",
      status: "",
      date_confirmed: "",
      modified_by: "",
      agent_id: "",
      notify_new_connection: 0,
      notify_service_click: 0,
      createdAt: "",
      updatedAt: "",
    },
    roles: [],
    professional_field: "",
  })
  const [requestLoader, setRequestLoader] = useState(false);
  const [newStaff, setNewStaff] = useState<Staff>({
    last_name: "",
    first_name: "",
    email: "",
  });
  const [staffPermission, setStaffPermission] = useState<Permissions>({
    allow_create_card: true,
    allow_card_delete: true,
    allow_card_edit: true,
    allow_card_revoke: true,
    allow_card_unrevoke: false,
    allow_profile_edit: true,
    allow_service_create: true,
    allow_service_edit: true,
    allow_service_activate: true,
    allow_service_deactivate: true
  });
  const [staffCard, setStaffCard] = useState<Array<CompanyFullCard>>([]);

  const navigate = useNavigate();

  const { id } = useParams();

  const toggleAddEmployeeModal = () => {
    setNewStaff({
      last_name: "",
      first_name: "",
      email: "",
      photograph: "",
      address: "",
      address2: "",
      city: "",
      state: "",
      country: "",
      website: "",
      whatsapp_country_code: "",
      whatsapp_phone_number: "",
      facebook: "",
      twitter: "",
      linkedin: "",
      instagram: "",
      youtube: "",
      tiktok: "",
      plan_id: "",
      role_id: "",
      professional_field_id: "",
      status: "",
      business_name: "",
      designation: "",
      private_mode: 0,
      prefix: "",
      suffix: "",
      other_name: "",
      is_corporate_profile: 0,
      service_group_id: "",
      country_code: "",
      Phone_number: "",
    });
    setToggleStaffModal(!openStaffModal);
  }

  const toggleUpdateStaff = () => {
    setNewStaff({
      ...staffDetails.profile,
      professional_field_id: staffDetails.professional_field
    });
    setToggleStaffModal(!openStaffModal);
  }

  useEffect(() => {
    getStaffDetails();
    getStaffCards();
  }, []);

  const getStaffDetails = async () => {
    setPageLoader(true);
    const res = await getData(`companystaff/profile?profile_id=${id?.toString()}`);
    if (res) {
      setPageLoader(false);
      return setStaffDetails(res?.data);
    }
  }

  const getStaffCards = async () => {
    setPageLoader(true);
    const res = await getData(`companycard/profile/cardsbyprofileid?page=1&size=10&profile_id=${id?.toString()}`);
    if (res) {
      setPageLoader(false);
      setStaffCard(res?.data);
    }
  }

  const getStaffPermission = async () => {
    const payload = {
      profile_id: id?.toString(),
    };

    const res = await postData('company/profileaccess', payload);

    if (res?.isSuccess) {
      setStaffPermission({
        allow_create_card: res?.data.allow_create_card,
        allow_card_delete: res?.data.allow_card_delete,
        allow_card_edit: res?.data.allow_card_edit,
        allow_card_revoke: res?.data.allow_card_revoke,
        allow_card_unrevoke: res?.data.allow_card_unrevoke,
        allow_profile_edit: res?.data.allow_profile_edit,
        allow_service_create: res?.data.allow_service_create,
        allow_service_edit: res?.data.allow_service_edit,
        allow_service_activate: res?.data.allow_service_activate,
        allow_service_deactivate: res?.data.allow_service_deactivate
      });
    }
  }

  useEffect(() => {
    getStaffPermission();
  }, []);

  const resendVerification = async () => {
    setRequestLoader(true);
    const id = toast.loading("...Loading");

    const payload = {
      profile_id: staffDetails?.profile?.id,
    };

    const res = await postData(`company/resendprofileverification`, payload);

    if (res?.isSuccess || res?.isSucess) {
      setRequestLoader(false);
      return toast.update(id, { render: res?.message, type: "success", isLoading: false, autoClose: 5000, draggablePercent: 60, closeOnClick: true });
    }

    toast.update(id, { render: res?.message, type: "error", isLoading: false, autoClose: 5000, draggablePercent: 60, closeOnClick: true });
    return setRequestLoader(false);
  }

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setRequestLoader(true);
    const tid = toast.loading("...Loading");

    const payload = {
      profile_id: id,
      last_name: newStaff?.last_name,
      first_name: newStaff?.first_name,
      other_name: newStaff?.other_name,
      prefix: newStaff?.prefix,
      suffix: newStaff?.suffix,
      designation: newStaff?.designation,
      about_me: newStaff?.about_me,
      country_code: newStaff?.whatsapp_country_code,
      phone_number: newStaff?.Phone_number,
      whatsapp_country_code: newStaff?.whatsapp_country_code,
      whatsapp_phone_number: newStaff?.whatsapp_phone_number,
      professional_field_id: newStaff?.professional_field_id,
      photograph: newStaff?.photograph,
      address: newStaff?.address,
      city: newStaff?.city,
      state: newStaff?.state,
      country: newStaff?.country,
      website: newStaff?.website
    }

    const res = await putData(`companystaff`, payload);

    if (res?.isSuccess || res?.isSucess) {
      setRequestLoader(false);
      getStaffDetails();
      toggleAddEmployeeModal();
      return toast.update(tid, { render: res?.message, type: "success", isLoading: false, autoClose: 5000, draggablePercent: 60, closeOnClick: true });
    }

    toast.update(tid, { render: res?.message, type: "error", isLoading: false, autoClose: 5000, draggablePercent: 60, closeOnClick: true });
    return setRequestLoader(false);
  }

  const handlePermissionSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setRequestLoader(true);
    const tid = toast.loading("...Loading");

    const payload = { ...staffPermission, profile_id: id?.toString() };

    const res = await putData(`company/setprofileaccess`, payload);

    if (res?.isSuccess || res?.isSucess) {
      setRequestLoader(false);
      toggleStaffPermModal();
      getStaffPermission();
      return toast.update(tid, { render: res?.message, type: "success", isLoading: false, autoClose: 5000, draggablePercent: 60, closeOnClick: true });
    }

    toast.update(tid, { render: res?.message, type: "error", isLoading: false, autoClose: 5000, draggablePercent: 60, closeOnClick: true });
    return setRequestLoader(false);
  }

  const toggleStaffPermModal = () => {
    setToggleStaffPermModal(!openStaffPermModal);
  }

  // const truncateID = (string = '', maxLength = 10) => {
  //   return string.length > maxLength ? `${string.substring(0, maxLength)}...` : string
  // }

  const createStaffCard = () => {
    return navigate(`/dashboard/staff/card/add/${id}`);
  };

  const pattern = new RegExp('^(https?|ftp)://');
  const twitterUrl = (username: string = '') => {
    return `https://twitter.com/${username}`;
  }

  const instagramUrl = (username: string = '') => {
    return `https://www.instagram.com/${username}`;
  }

  return (
    <React.Fragment>
      <div>
        {pageLoader ? (<PageLoader />) : (
          <div className="container-fluid mt--6">
            <div className="card mb-5">
              <div className="card-header">
                <div className="row">
                  <div className="col-md-6">
                    Personal Information
                  </div>
                  <div className="col-md-6">
                    {staffDetails?.profile?.status?.toLowerCase() !== 'active' && (<button
                      type="button"
                      className="btn btn-primary btn-sm"
                      onClick={resendVerification}
                      disabled={requestLoader}
                    >
                      Resend verification
                    </button>)}
                    <button
                      type="button"
                      className="btn btn-primary btn-sm ms-1"
                      onClick={() => { toggleUpdateStaff() }}
                      disabled={requestLoader}
                    >
                      Update staff
                    </button>
                    <button
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                      className="btn btn-outline-light btn-sm ms-1"
                      onClick={toggleStaffPermModal}
                    >
                      <i className="ri-edit-2-line pe-2"></i>
                      Update staff access
                    </button>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-4">
                    <h6>First Name</h6>
                    <p className="text-dark mb-5">{staffDetails?.profile?.first_name}</p>
                    <h6>Phone Number</h6>
                    <p className="text-dark mb-5">{staffDetails?.profile?.Phone_number}</p>
                    {/* <h6> Company's ID </h6>
                    <p className="text-dark mb-5">{truncateID(staffDetails?.profile?.company_id, 8)}</p> */}
                  </div>
                  <div className="col-md-4">
                    <h6>Last Name</h6>
                    <p className="text-dark mb-5">{staffDetails?.profile?.last_name}</p>
                    <h6>Company Name</h6>
                    <p className="text-dark mb-5">{staffDetails?.profile?.company_name}</p>
                    {/* <h6>Staff's ID</h6>
                    <p className="text-dark mb-5">{truncateID(staffDetails?.profile?.id, 8)}</p> */}
                  </div>
                  <div className="col-md-4">
                    <h6>Email</h6>
                    <p className="text-dark mb-5">{staffDetails?.profile?.email}</p>
                    <h6>Created On</h6>
                    <p className="text-dark mb-5">{dayjs(staffDetails?.profile?.createdAt).format('ddd, MMM DD, YYYY')}{" "}{dayjs(staffDetails?.profile?.createdAt).format('hh:mm A')}</p>
                    <h6>Account Status </h6>
                    <p className="text-dark mb-5">{staffDetails?.profile?.status}</p>
                  </div>
                </div>
              </div>
            </div>

            {/* Cards */}
            <div className="card mb-5">
              <div className="card-header">
                <div className="row">
                  <div className="col-md-6">
                    Cards
                  </div>
                  <div className="col-md-6">
                    <button
                      type="button"
                      className="btn btn-dark btn-sm"
                      onClick={createStaffCard}
                    >
                      Create New Card
                    </button>
                    {/* <button
                      type="button"
                      className="btn btn-dark btn-sm ms-1"
                      onClick={() => { toggleUpdateStaff() }}
                      disabled={requestLoader}
                    >
                      Update staff
                    </button> */}
                  </div>
                </div>
              </div>
              <div className="card-body">
                {staffCard.length > 0 ? (
                  <div className="row">
                    {staffCard.map((item: CompanyFullCard, index: number) => {
                      return (
                        <div key={index} className="col-md-4" style={{ cursor: "pointer" }}>
                          <div
                            className="card connection_card p-4"
                            style={{
                              backgroundImage: `url(${item?.template?.image ? item?.template?.image : require("../../../components/assets/img/page-bg_2.jpeg")})`,
                              backgroundSize: 'cover',
                              backgroundRepeat: 'no-repeat',
                              minHeight: "300px",
                            }}
                            key={index}
                          >
                            <div className="container position-relative text-center m-auto">
                              {item?.photograph ? (
                                <img src={item?.photograph} className="rounded-circle" style={{ height: "95px" }} onClick={() => navigate(`/dashboard/card/${item?.id}`)} alt="" />
                              ) :
                                (
                                  <img src={Logo} className="rounded-circle" style={{ height: "95px" }} onClick={() => navigate(`/dashboard/card/${item?.id}`)} alt="" />
                                )
                              }

                              <h2 className="text-white pt-3" onClick={() => navigate(`/dashboard/card/${item?.id}`)}>
                                {`${item?.first_name} ${item?.last_name}`}{" "}
                                <span style={{ color: "#1773EA" }}>
                                  <i className="ri-verified-badge-fill" />
                                </span>
                              </h2>

                              <h6 className="mt-0 pt-0 text-white">
                                {" "}
                                <i>{item?.business_name}</i>{" "}
                              </h6>

                              <p className="text-white">
                                {item?.designation}
                              </p>

                              <ul className="m-auto text-center justify-content-center">
                                {item?.facebook && <li>
                                  <a href={`${item?.facebook}`} target="_blank" rel="noreferrer">
                                    {" "}
                                    <i className="ri-facebook-fill" />{" "}
                                  </a>
                                </li>}
                                {item?.instagram && <li>
                                  <a href={pattern.test(item?.instagram) ? item?.instagram : instagramUrl(item?.instagram)} target="_blank" rel="noreferrer">
                                    {" "}
                                    <i className="ri-instagram-fill" />{" "}
                                  </a>
                                </li>}
                                {item?.twitter && <li>
                                  <a href={pattern.test(item?.twitter) ? item?.twitter : twitterUrl(item?.twitter)} target="_blank" rel="noreferrer">
                                    {" "}
                                    <i className="ri-twitter-fill" />{" "}
                                  </a>
                                </li>}
                                {item?.whatsapp_phone_number && <li>
                                  {" "}
                                  <a href={`https://wa.me/${item?.whatsapp_country_code}${item?.whatsapp_phone_number}`} target="_blank" rel="noreferrer">
                                    <i className="ri-whatsapp-fill" />{" "}
                                  </a>
                                </li>}
                              </ul>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                ) : (
                  <div className="tab-pane" id="trash-documents-tab-pane">
                    <div className="row justify-content-center py-5">
                      <div className="col-md-7 col-lg-4">
                        <div className="mb-5 text-center">
                          <img src="images/illustrations/no-documents.svg" alt="" />
                        </div>
                        <div className="nk-block-head text-center">
                          <div className="nk-block-head-content">
                            <h1 className="nk-block-title mb-2">
                              This staff does not have any cards yet.
                            </h1>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        {/* <!-- ADD NEW STAFF --> */}
        <div
          className={`offcanvas offcanvas-end customeoff ${openStaffModal ? 'show' : ''}`}
          tabIndex={-1}
          id="offcanvasExample"
          aria-modal="true"
          role="dialog"
        >
          <div className="offcanvas-header">
            <h5 className="modal-title" id="#gridSystemModal">Update Staff</h5>
            <button type="button" className="btn-close" aria-label="Close" onClick={toggleAddEmployeeModal}>
              <i className="fa-solid fa-xmark"></i>
            </button>
          </div>
          <div className="offcanvas-body">
            <div className="container-fluid">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-xl-4 mb-3">
                    <Input
                      label="Employee First Name *"
                      type="text"
                      name="first_name"
                      id="first_name"
                      placeholder="Enter first name"
                      required={true}
                      value={newStaff.first_name}
                      onChange={(e: any) => { setNewStaff({ ...newStaff, first_name: e.target?.value }) }}
                    />
                  </div>

                  <div className="col-xl-4 mb-3">
                    <Input
                      label="Employee Other Name *"
                      type="text"
                      name="other_name"
                      id="other_name"
                      placeholder="Enter other name"
                      required={false}
                      value={newStaff.other_name}
                      onChange={(e: any) => { setNewStaff({ ...newStaff, other_name: e.target?.value }) }}
                    />
                  </div>

                  <div className="col-xl-4 mb-3">
                    <Input
                      label="Employee Last Name *"
                      type="text"
                      name="last_name"
                      id="last_name"
                      placeholder="Enter last name"
                      required={true}
                      value={newStaff.last_name}
                      onChange={(e: any) => { setNewStaff({ ...newStaff, last_name: e.target?.value }) }}
                    />
                  </div>

                  <div className="col-xl-6 mb-3">
                    <Input
                      label="Employee Email *"
                      type="email"
                      name="employeeEmail"
                      id="employeeEmail"
                      placeholder="Enter email"
                      required={true}
                      readonly={staffDetails?.profile?.status?.toLowerCase() === 'active'}
                      value={newStaff.email}
                      onChange={(e: any) => { setNewStaff({ ...newStaff, email: e.target?.value }) }}
                    />
                  </div>

                  <div className="col-xl-6 mb-3">
                    <Input
                      label="Employee Job Description *"
                      type="text"
                      name="employeeDesignation"
                      id="employeeDesignation"
                      placeholder="Enter job description"
                      required={true}
                      value={newStaff.designation}
                      onChange={(e: any) => { setNewStaff({ ...newStaff, designation: e.target?.value }) }}
                    />
                  </div>

                  <div className="col-xl-4 mb-3">
                    <label
                      htmlFor='country'
                      className="form-control-label form-label"
                    >
                      Country *
                    </label>
                    <select
                      className="form-control"
                      value={newStaff.country}
                      id='country'
                      required
                      onChange={(e: any) => setNewStaff({ ...newStaff, country: e?.target?.value })}
                    >
                      <option selected disabled>Select one</option>
                      {country_code.map(
                        ({ country }: { country: string }, index: number) => {
                          return (
                            <option
                              key={index}
                              value={country}
                            >
                              {country}
                            </option>
                          );
                        }
                      )}
                    </select>
                  </div>

                  <div className="col-xl-4 mb-3">
                    <Input
                      label="State *"
                      type="text"
                      name="employeeState"
                      id="employeeState"
                      placeholder="Enter state"
                      required={true}
                      value={newStaff.state}
                      onChange={(e: any) => { setNewStaff({ ...newStaff, state: e.target?.value }) }}
                    />
                  </div>

                  <div className="col-xl-4 mb-3">
                    <Input
                      label="City"
                      type="text"
                      name="employeeCity"
                      id="employeeCity"
                      placeholder="Enter city"
                      required={false}
                      value={newStaff.city}
                      onChange={(e: any) => { setNewStaff({ ...newStaff, city: e.target?.value }) }}
                    />
                  </div>

                  <div className="col-xl-4 mb-3">
                    <label
                      htmlFor='Phone_code'
                      className="form-control-label form-label"
                    >
                      Country code *
                    </label>
                    <select
                      className="form-control"
                      value={newStaff.whatsapp_country_code}
                      id='Phone_code'
                      required
                      onChange={(e: any) => setNewStaff({ ...newStaff, whatsapp_country_code: e?.target?.value })}
                    >
                      <option selected disabled>Select one</option>
                      {country_code.map(
                        ({ dial_code }: { dial_code: string }, index: number) => {
                          return (
                            <option
                              key={index}
                              value={dial_code}
                            >
                              {dial_code}
                            </option>
                          );
                        }
                      )}
                    </select>
                  </div>

                  <div className="col-xl-4 mb-3">
                    <Input
                      label="Phone number"
                      type="tel"
                      name="employeePhone"
                      id="employeePhone"
                      placeholder="Enter phone number"
                      required={false}
                      value={newStaff.Phone_number}
                      onChange={(e: any) => { setNewStaff({ ...newStaff, Phone_number: e.target?.value }) }}
                    />
                  </div>

                  <div className="col-xl-4 mb-3">
                    <Input
                      label="WhatsApp number"
                      type="tel"
                      name="employeeWhatsApp"
                      id="employeeWhatsApp"
                      placeholder="WhatsApp number"
                      required={false}
                      value={newStaff.whatsapp_phone_number}
                      onChange={(e: any) => { setNewStaff({ ...newStaff, whatsapp_phone_number: e.target?.value }) }}
                    />
                  </div>

                  {/* <div className="col-xl-12 mb-3">
                    <Input
                      label="Company Name"
                      type="text"
                      name="employeeCompany"
                      id="employeeCompany"
                      placeholder="Company name"
                      required={false}
                      value={newStaff.business_name}
                      onChange={(e: any) => { setNewStaff({ ...newStaff, business_name: e.target?.value }) }}
                    />
                  </div> */}

                  <div className="col-xl-12 mb-3">
                    <Input
                      label="Employee Image url"
                      type="text"
                      name="employeePhoto"
                      id="employeePhoto"
                      placeholder="Enter image url here"
                      required={false}
                      value={newStaff.photograph}
                      onChange={(e: any) => { setNewStaff({ ...newStaff, photograph: e.target?.value }) }}
                    />
                  </div>

                  <div className="col-xl-12 mb-3">
                    <Input
                      label="Employee Address"
                      type="text"
                      name="employeeAddress"
                      id="employeeAddress"
                      placeholder="Enter address"
                      required={false}
                      value={newStaff.address}
                      onChange={(e: any) => { setNewStaff({ ...newStaff, address: e.target?.value }) }}
                    />
                  </div>
                </div>

                <div>
                  <button type="button" className="btn btn-danger light ms-1" onClick={toggleAddEmployeeModal}>Cancel</button>
                  <button
                    type="submit"
                    className="btn btn-primary me-1 ms-1"
                    disabled={requestLoader}
                  >Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>

        {/* <!-- UPDATE STAFF PERMISSION --> */}
        <div
          className={`offcanvas offcanvas-end customeoff ${openStaffPermModal ? 'show' : ''}`}
          tabIndex={-1}
          id="offcanvasExample"
          aria-modal="true"
          role="dialog"
        >
          <div className="offcanvas-header">
            <h5 className="modal-title" id="#gridSystemModal">Update Staff Permission</h5>
            <button type="button" className="btn-close" aria-label="Close" onClick={toggleStaffPermModal}>
              <i className="fa-solid fa-xmark"></i>
            </button>
          </div>
          <div className="offcanvas-body">
            <div className="container-fluid">
              <form onSubmit={handlePermissionSubmit}>
                <div className="row">
                  <div className="col-xl-4 mt-4 mb-4">
                    <div className="form-check form-check-sm">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={staffPermission?.allow_create_card}
                        id="allowCreateCard"
                        onChange={(e) => setStaffPermission({ ...staffPermission, allow_create_card: e?.target?.checked })}
                      />
                      <label className="form-check-label" htmlFor="allowCreateCard">
                        {" "}
                        Allow staff create card{" "}
                      </label>
                    </div>
                  </div>
                  <div className="col-xl-4 mt-4 mb-4">
                    <div className="form-check form-check-sm">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={staffPermission?.allow_card_delete}
                        id="allowDeleteCard"
                        onChange={(e) => setStaffPermission({ ...staffPermission, allow_card_delete: e?.target?.checked })}
                      />
                      <label className="form-check-label" htmlFor="allowDeleteCard">
                        {" "}
                        Allow staff delete card{" "}
                      </label>
                    </div>
                  </div>
                  <div className="col-xl-4 mt-4 mb-4">
                    <div className="form-check form-check-sm">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={staffPermission?.allow_card_edit}
                        id="allowEditCard"
                        onChange={(e) => setStaffPermission({ ...staffPermission, allow_card_edit: e?.target?.checked })}
                      />
                      <label className="form-check-label" htmlFor="allowEditCard">
                        {" "}
                        Allow staff edit card{" "}
                      </label>
                    </div>
                  </div>

                  <div className="col-xl-4 mt-4 mb-4">
                    <div className="form-check form-check-sm">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={staffPermission?.allow_card_revoke}
                        id="allowRevokeCard"
                        onChange={(e) => setStaffPermission({ ...staffPermission, allow_card_revoke: e?.target?.checked })}
                      />
                      <label className="form-check-label" htmlFor="allowRevokeCard">
                        {" "}
                        Allow staff revoke card{" "}
                      </label>
                    </div>
                  </div>
                  <div className="col-xl-4 mt-4 mb-4">
                    <div className="form-check form-check-sm">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={staffPermission?.allow_card_unrevoke}
                        id="allowCardUnrevoke"
                        onChange={(e) => setStaffPermission({ ...staffPermission, allow_card_unrevoke: e?.target?.checked })}
                      />
                      <label className="form-check-label" htmlFor="allowCardUnrevoke">
                        {" "}
                        Allow staff unrevoke{" "}
                      </label>
                    </div>
                  </div>
                  <div className="col-xl-4 mt-4 mb-4">
                    <div className="form-check form-check-sm">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={staffPermission?.allow_profile_edit}
                        id="allow_profile_edit"
                        onChange={(e) => setStaffPermission({ ...staffPermission, allow_profile_edit: e?.target?.checked })}
                      />
                      <label className="form-check-label" htmlFor="allow_profile_edit">
                        {" "}
                        Allow staff edit profile{" "}
                      </label>
                    </div>
                  </div>

                  <div className="col-xl-6 mt-4 mb-4">
                    <div className="form-check form-check-sm">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={staffPermission?.allow_service_activate}
                        id="allowServiceActivate"
                        onChange={(e) => setStaffPermission({ ...staffPermission, allow_service_activate: e?.target?.checked })}
                      />
                      <label className="form-check-label" htmlFor="allowServiceActivate">
                        {" "}
                        Allow staff activate services{" "}
                      </label>
                    </div>
                  </div>
                  <div className="col-xl-6 mt-4 mb-4">
                    <div className="form-check form-check-sm">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={staffPermission?.allow_service_create}
                        id="allowServiceCreate"
                        onChange={(e) => setStaffPermission({ ...staffPermission, allow_service_create: e?.target?.checked })}
                      />
                      <label className="form-check-label" htmlFor="allowServiceCreate">
                        {" "}
                        Allow staff create services{" "}
                      </label>
                    </div>
                  </div>
                  <div className="col-xl-6 mt-4 mb-4">
                    <div className="form-check form-check-sm">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={staffPermission?.allow_service_deactivate}
                        id="allow_service_deactivate"
                        onChange={(e) => setStaffPermission({ ...staffPermission, allow_service_deactivate: e?.target?.checked })}
                      />
                      <label className="form-check-label" htmlFor="allow_service_deactivate">
                        {" "}
                        Allow staff deactivate services{" "}
                      </label>
                    </div>
                  </div>
                  <div className="col-xl-6 mt-4 mb-4">
                    <div className="form-check form-check-sm">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={staffPermission?.allow_service_edit}
                        id="allow_service_edit"
                        onChange={(e) => setStaffPermission({ ...staffPermission, allow_service_edit: e?.target?.checked })}
                      />
                      <label className="form-check-label" htmlFor="allow_service_edit">
                        {" "}
                        Allow staff edit services{" "}
                      </label>
                    </div>
                  </div>
                </div>

                <div>
                  <button type="button" className="btn btn-danger light ms-1" onClick={toggleStaffPermModal}>Cancel</button>
                  <button
                    type="submit"
                    className="btn btn-primary me-1 ms-1"
                    disabled={requestLoader}
                  >Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment >
  );
}
