import { useCallback, useEffect, useState } from "react";
// import "../../../pages/assets/css/global.scoped.css";
// import "../../../components/assets/css/dashboard.scoped.css";
// import "../../../components/assets/css/style.scoped.css";
import PersonalSetup from "./PersonalSetup";
import SocialSetup from "./SocialSetup";
import ConnectionModal from "../../../components/ui/Modal/ConnectionModal";
import { UserCard, UserInfo } from "../../../store/AuthContext";
import { getData } from "../../../helpers/request";
// import "../../../pages/assets/css/global.css";
// import "../../../pages/assets/css/dashboard.css";
// import "../../../pages/assets/css/style.css";

function CardSetup() {
  const [formStep, setFormSteps] = useState(1);
  const [cardModal, setCardModal] = useState(false);
  const [pageLoader, setPageLoader] = useState(false);
  const [loading, setLoader] = useState(false);
  const [userInfo, setUserInfo] = useState<UserCard>({
    last_name: "",
    first_name: "",
    other_name: "",
    prefix: "",
    suffix: "",
    business_name: "",
    designation: "",
    about_me: "",
    phone_number: "",
    photograph: "",
    address: "",
    address2: "",
    city: "",
    state: "",
    country: "Nigeria",
    website: "",
    whatsapp_country_code: "+234",
    whatsapp_phone_number: "",
    facebook: "",
    twitter: "",
    linkedin: "",
    instagram: "",
    youtube: "",
    tiktok: "",
    professional_field_id: "",
  });

  useEffect(() => {
    setCardModal(true);
  }, []);

  useEffect(() => {
    getProfile();
  }, []);

  const getProfile = useCallback(async () => {
    setPageLoader(true);
    const res = await getData(`/profile`);
    if (res) {
      setUserInfo({ 
        last_name: res?.last_name,
        first_name: res?.first_name,
        other_name: res?.other_name,
        prefix: res?.prefix,
        suffix: res?.suffix,
        business_name: res?.business_name,
        designation: res?.designation,
        about_me: res?.about_me,
        phone_number: res?.Phone_number,
        photograph: res?.photograph,
        address: res?.address,
        address2: res?.address2,
        city: res?.city,
        state: res?.state,
        country: res?.country,
        website: res?.website,
        whatsapp_country_code: res?.whatsapp_country_code,
        whatsapp_phone_number: res?.whatsapp_phone_number,
        facebook: res?.facebook,
        twitter: res?.twitter,
        linkedin: res?.linkedin,
        instagram: res?.instagram,
        youtube: res?.youtube,
        tiktok: res?.tiktok,
        professional_field_id: res?.professional_field_id,
      });
      setPageLoader(false);
    }
  }, []);

  const toggleCardModal = () => {
    if (cardModal) {
      return setCardModal(false);
    }
    return setCardModal(true);
  };

  return (
    <div className="row g-gs justify-content-center mt-2">
      <div className="col-md-12 col-lg-12 col-xl-12 p-0">
        <div className="card border-0 rounded-4">
          {formStep === 1 && (
            <PersonalSetup
              pageLoader={pageLoader}
              loading={loading}
              userInfo={userInfo}
              setFormSteps={setFormSteps}
              setPageLoader={setPageLoader}
              setUserInfo={setUserInfo}
              setLoader={setLoader}
            />
          )}
          {formStep === 2 && (
            <SocialSetup
              pageLoader={pageLoader}
              loading={loading}
              userInfo={userInfo}
              setFormSteps={setFormSteps}
              setPageLoader={setPageLoader}
              setUserInfo={setUserInfo}
              setLoader={setLoader}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default CardSetup;
