import { FormEvent, useCallback, useEffect, useState } from "react";
import PrimaryButton from "../../../components/ui/Button/PrimaryButton";
import { Input } from "../../../components/ui/Input/Input";
// import "../../../pages/assets/css/global.scoped.css";
// import "../../../components/assets/css/dashboard.scoped.css";
// import "../../../components/assets/css/style.scoped.css";
import { UserCard, UserInfo } from "../../../store/AuthContext";
import SessionStorageService from "../../../services/sessionStorage";
import { getData, postData, putData } from "../../../helpers/request";
import PageLoader from "../../../components/ui/PageLoader";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { country_code } from "../../../helpers/countryCode";

function PersonalSetup({
  setFormSteps,
  userInfo,
  loading,
  pageLoader,
  setPageLoader,
  setUserInfo,
  setLoader,
}: {
  setFormSteps: any;
  userInfo: UserCard;
  loading: boolean;
  pageLoader: boolean;
  setPageLoader: any;
  setUserInfo: any;
  setLoader: any;
}) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const paramValue = queryParams.get("id");

  // useEffect(() => {
  //   if (paramValue) {
  //     handleProceedConnection();
  //   }
  // }, []);

  // const handleProceedConnection = async () => {
  //   setPageLoader(true);
  //   const reqBody = {
  //     connection_id: paramValue?.toString(),
  //     is_private: false,
  //     is_favourite: false,
  //     connection_tag: "",
  //     note: "",
  //   }
  //   const res = await postData(`connection/create`, reqBody);

  //   if (res?.isSuccess || res?.isSucess) {
  //     toast.success(res?.message);
  //     return setPageLoader(false);
  //   }
  //   toast.error(res?.message);
  //   return setPageLoader(false);
  // }

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setFormSteps(2);
  }

  return (
    <div className="card-body pt-0">
      <h4 className="">Let's get you started!</h4>

      <form onSubmit={handleSubmit}>
        {pageLoader ? (<PageLoader />) : (
          <div className="row g-4 contact-form">
            {/* <div className="col-lg-6">
              <Input
                label="First name"
                type="text"
                name="firstname"
                id="lastname"
                placeholder="Enter first name"
                required={true}
                value={userInfo.first_name}
                defaultValue={userInfo?.first_name}
                onChange={(e: any) => { setUserInfo({ ...userInfo, first_name: e.target?.value }) }}
              />
            </div>

            <div className="col-lg-6">
              <Input
                label="Last name"
                type="text"
                name="lastname"
                id="lastname"
                placeholder="Enter last name"
                defaultValue={userInfo?.last_name}
                required={true}
                value={userInfo.last_name}
                onChange={(e: any) => { setUserInfo({ ...userInfo, last_name: e.target?.value }) }}
              />
            </div>

            <div className="col-lg-6">
              <Input
                label="Other name"
                type="text"
                name="username"
                id="username"
                placeholder="Enter other name"
                required={false}
                defaultValue={userInfo?.other_name}
                value={userInfo.other_name}
                onChange={(e: any) => { setUserInfo({ ...userInfo, other_name: e.target?.value }) }}
              />
            </div> */}

            <div className="row g-4">
              <div className="col-lg-4">
                <label
                  htmlFor='Phone_code'
                  className="form-control-label form-label"
                >
                  Country code
                </label>
                <select
                  className="form-control"
                  value={userInfo.whatsapp_country_code}
                  id='Phone_code'
                  onChange={(e: any) => setUserInfo({ ...userInfo, whatsapp_country_code: e?.target?.value }) }
                >
                  <option selected></option>
                  {country_code.map(
                    ({ dial_code }: { dial_code: string }, index: number) => {
                      return (
                        <option
                          key={index}
                          value={dial_code}
                        >
                          {dial_code}
                        </option>
                      );
                    }
                  )}
                </select>
              </div>

              <div className="col-lg-8">
                <Input
                  label="Phone number *"
                  type="tel"
                  name="phoneNumber"
                  id="phoneNumber"
                  placeholder="Enter phone number"
                  required={true}
                  value={userInfo.phone_number}
                  defaultValue={userInfo?.phone_number}
                  onChange={(e: any) => { setUserInfo({ ...userInfo, phone_number: e.target?.value }) }}
                />
              </div>
            </div>

            <div className="col-lg-12">
              <Input
                label="Business name"
                type="text"
                name="business_name"
                id="business_name"
                placeholder="Enter name"
                required={false}
                value={userInfo.business_name}
                defaultValue={userInfo?.business_name}
                onChange={(e: any) => { setUserInfo({ ...userInfo, business_name: e.target?.value }) }}
              />
            </div>

            <div className="row g-4">
              <div className="col-lg-4">
                <label
                  htmlFor='Phone_code'
                  className="form-control-label form-label"
                >
                  Country
                </label>
                <select
                  className="form-control"
                  value={userInfo.country}
                  id='country'
                  onChange={(e: any) => setUserInfo({ ...userInfo, country: e?.target?.value }) }
                >
                  <option selected disabled></option>
                  {country_code.map(
                    ({ country }: { country: string }, index: number) => {
                      return (
                        <option
                          key={index}
                          value={country}
                        >
                          {country}
                        </option>
                      );
                    }
                  )}
                </select>
              </div>
              <div className="col-lg-8">
                <Input
                  label="City"
                  type="text"
                  name="city"
                  id="city"
                  placeholder="Enter your city"
                  required={false}
                  value={userInfo.city}
                  defaultValue={userInfo?.city}
                  onChange={(e: any) => { setUserInfo({ ...userInfo, city: e.target?.value }) }}
                />
              </div>
            </div>

            <div className="col-12">
              <Input
                label="Designation"
                type="text"
                name="designation"
                id="title"
                placeholder="Enter job designation"
                required={false}
                value={userInfo.designation}
                onChange={(e: any) => { setUserInfo({ ...userInfo, designation: e.target?.value }) }}
              />
            </div>

            <div className="col-12">
              <Input
                label="About me"
                type="text"
                name="about_me"
                id="about_me"
                placeholder="Enter about me"
                required={false}
                value={userInfo.about_me}
                onChange={(e: any) => { setUserInfo({ ...userInfo, about_me: e.target?.value }) }}
              />
            </div>

            {/* <div className="col-12">
              <Input
                label="Image URL"
                type="url"
                name="photograph"
                id="photograph"
                placeholder="Enter image url"
                required={false}
                value={userInfo.photograph}
                onChange={(e: any) => { setUserInfo({ ...userInfo, photograph: e.target?.value }) }}
              />
            </div> */}

            <div className="col-12">
              <div className="form-group">
                <PrimaryButton
                  type="submit"
                  title="Proceed"
                  disabled={loading}
                />
              </div>
            </div>
          </div>
        )}
      </form>
    </div>
  );
}

export default PersonalSetup;
