import { useEffect, useState } from "react";
// import "../../components/assets/css/dashboard.scoped.css";
// import "../../components/assets/css/style.scoped.css";
import { randomCardImages } from "../../helpers/utilities";
import { getData, postData } from "../../helpers/request";
import { Connections } from "./Connections";
import RevokeModal from "../../components/ui/Modal/DeleteModal";
import { toast } from 'react-toastify';
import PageLoader from "../../components/ui/PageLoader";
import { FullUserCard } from "./Cards";

export type UserTemplate = {
  id: string;
  type: string;
  owner_id: string;
  image: string;
  created_by: string;
};

export default function Favorites() {
  type FavoriteConnections = {
    currentPage: number;
    totalItems: number;
    totalPages: number;
    data: Array<FullUserCard>;
  };
  const [connections, setConnections] = useState<Connections | null>(null);
  const [card, setCard] = useState(false);
  const [requestLoader, setRequestLoader] = useState(false);
  const [pageLoader, setPageLoader] = useState(false);
  const [openFavouriteModal, setFavouriteModal] = useState(false);
  const [favorites, setFavoriteConnections] = useState<FavoriteConnections>({
    currentPage: 0,
    totalItems: 0,
    totalPages: 0,
    data: []
  });
  // const favorites = useLoaderData() as FavoriteConnections;

  const favoritesLoader = async () => {
    setPageLoader(true);
    const res = await getData(`cardconnection/favourite/all?page=1&size=1000`);

    if (res) {
      setPageLoader(false);
      return setFavoriteConnections({ ...res });
    };
  };

  useEffect(() => {
    favoritesLoader();
  }, []);

  const handleCardModal = (connection: Connections) => {
    setConnections({ ...connection });
    return setCard(true);
  }

  const toggleFavouriteModal = () => {
    if (card) return setCard(false);
    return setCard(true);
  }

  const handleRevoke = async () => {
    setRequestLoader(true);
    const id = toast.loading("...Loading");
    const payload = {
      card_connection_id: connections?.id,
    };
    const res = await postData(`cardconnection/removefavourite`, payload);

    if (res?.isSuccess || res?.isSucess) {
      setRequestLoader(false);
      toggleFavouriteModal();
      favoritesLoader();
      return toast.update(id, { render: res?.message, type: "success", isLoading: false, autoClose: 5000, draggablePercent: 60, closeOnClick: true  });
    }

    toast.update(id, { render: res?.message, type: "error", isLoading: false, autoClose: 5000, draggablePercent: 60, closeOnClick: true });
    return setRequestLoader(false);
  };

  return (
    <>
      <div>
        <p>View your Favourites connections</p>
        {pageLoader ? 
          (<PageLoader />) : 
          (
            <>
              {favorites?.data?.length > 0 ? (
                <div className="row">
                  {favorites?.data?.map((favorite: FullUserCard, index: number) => {
                    return (
                      <>
                        <div className="col-md-6 mb-4" key={index}>
                          <div
                            className={`card connection_card p-4`}
                            style={{
                              backgroundImage: `url(${favorite?.template?.image ? favorite?.template?.image : require("../../components/assets/img/page-bg_2.jpeg")})`,
                              backgroundSize: 'cover',
                              backgroundRepeat: 'no-repeat',
                              minHeight: "300px",
                            }}
                          >
                            <div className="container position-relative text-center m-auto">
                              <div className="position-absolute">
                                <button
                                  data-bs-toggle="modal"
                                  className="btn btn-white btn-sm fs-15px"
                                  onClick={() => { handleCardModal(favorite) }}
                                >
                                  <i className="ri-eye-fill" />
                                </button>
                              </div>

                              <img
                                src={favorite?.photograph}
                                className="rounded-circle"
                                alt=""
                              />

                              <h3 className="text-white pt-3">
                                {`${favorite?.first_name} ${favorite?.last_name}`}{" "}
                                <span style={{ color: "#1773EA" }}>
                                  <i className="ri-verified-badge-fill" />
                                </span>{" "}
                              </h3>

                              <h6 className="mt-0 pt-0 text-white">
                                {" "}
                                <i>{favorite?.business_name}</i>{" "}
                              </h6>

                              <p className="text-white">
                                {favorite?.designation}
                              </p>
                            </div>
                          </div>
                        </div>
                      </>
                    )
                  })}
                </div>
              ) : (
                <div className="tab-pane" id="trash-documents-tab-pane">
                  <div className="row justify-content-center py-5">
                    <div className="col-md-7 col-lg-4">
                      <div className="mb-5 text-center"><img
                        src="images/illustrations/no-documents.svg"
                        alt="" />
                      </div>
                      <div className="nk-block-head text-center">
                        <div className="nk-block-head-content">
                          <h1 className="nk-block-title mb-2">No Favorite Cards yet
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )
        }

        {card && (
          <RevokeModal
            title={"Remove Favourites"}
            handleAction={handleRevoke}
            handleClose={toggleFavouriteModal}
            open={card}
            proceedText={"Are you sure you want to remove connection as favourite?"}
            buttonText={"Yes, Remove"}
            showOtherButton={true}
            requestLoader={requestLoader}
            otherButtonText={"Keep"}
          />
        )}
      </div>
    </>
  );
}

export const favoritesLoader = async () => {
  const res = await getData(`connection/favourite?page=1&size=10`);

  return res;
};
