import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
// import "../../../components/assets/css/dashboard.scoped.css";
import 'react-loading-skeleton/dist/skeleton.css';
import { getData, postData, putData } from "../../../helpers/request";
import PageLoader from "../../../components/ui/PageLoader";
import { Popover } from "@headlessui/react";
import dayjs from 'dayjs';
import { Input } from "../../../components/ui/Input/Input";
import RemoveModal from "../../../components/ui/Modal/DeleteModal";
import AddModal from "../../../components/ui/Modal/SuccessModal";
import { toast } from 'react-toastify';

export default function Staff() {
  type Role = {
    id: string;
    is_active: boolean;
    name: string;
    created_by?: string;
    modified_by?: null,
    createdAt?: string;
    updatedAt?: null
  };

  type Staff = {
    id?: string;
    last_name: string;
    about_me?: string | null;
    registration_type?: string;
    first_name: string;
    business_name?: string | null;
    designation?: string | null;
    private_mode?: number;
    prefix?: string | null;
    suffix?: string | null;
    other_name?: string | null;
    is_corporate_profile?: number;
    service_group_id?: string | null;
    email: string;
    country_code?: string;
    Phone_number?: string | null;
    company_id?: string;
    is_profile_updated?: number;
    photograph?: string | null;
    address?: string | null;
    address2?: string | null;
    city?: string | null;
    state?: string | null;
    country?: string;
    website?: string | null;
    whatsapp_country_code?: string | null;
    whatsapp_phone_number?: string | null;
    facebook?: string | null;
    twitter?: string | null;
    linkedin?: string | null;
    instagram?: string | null;
    youtube?: string | null;
    tiktok?: string | null;
    plan_id?: string | null;
    role_id?: string | null;
    professional_field_id?: string | null;
    status?: string;
    date_confirmed?: string | null;
    modified_by?: string | null;
    agent_id?: string | null;
    notify_new_connection?: number;
    notify_service_click?: number;
    createdAt?: string;
    updatedAt?: string;
    roles?: Array<Role>;
    professional_field?: null
  }
  const [pageLoader, setPageLoader] = useState(false);
  const [allStaff, setCompanyStaff] = useState<Array<Staff>>([]);
  const [searchValue, setSearchValue] = useState("");
  const [openStaffModal, setToggleStaffModal] = useState(false);
  const [newStaff, setNewStaff] = useState<Staff>({
    last_name: "",
    first_name: "",
    email: "",
  });
  const [staffDetail, setStaffDetails] = useState<Staff>({
    id: "",
    last_name: "",
    about_me: "",
    registration_type: "",
    first_name: "",
    business_name: "",
    designation: "",
    private_mode: 0,
    prefix: "",
    suffix: "",
    other_name: "",
    is_corporate_profile: 0,
    service_group_id: "",
    email: "",
    country_code: "",
    Phone_number: "",
    company_id: "",
    is_profile_updated: 0,
    photograph: "",
    address: "",
    address2: "",
    city: "",
    state: "",
    country: "",
    website: "",
    whatsapp_country_code: "",
    whatsapp_phone_number: "",
    facebook: "",
    twitter: "",
    linkedin: "",
    instagram: "",
    youtube: "",
    tiktok: "",
    plan_id: "",
    role_id: "",
    professional_field_id: "",
    status: "",
    date_confirmed: "",
    modified_by: "",
    agent_id: "",
    notify_new_connection: 0,
    notify_service_click: 0,
    createdAt: "",
    updatedAt: "",
    roles: [],
    professional_field: null,
  })
  const [deactivateStaff, setDeactivateStaff] = useState(false);
  const [activateStaff, setActivateStaff] = useState(false);
  const [makeAdmin, setAdminStaff] = useState(false);
  const [requestLoader, setRequestLoader] = useState(false);

  useEffect(() => {
    getCompanyStaff();
  }, []);

  const resetNewStaff = () => {
    setNewStaff({
      last_name: "",
      first_name: "",
      email: "",
    })
  }

  const getCompanyStaff = async () => {
    setPageLoader(true);
    const res = await getData(`companystaff?page=1&size=10000`);
    if (res) {
      setPageLoader(false);
      return setCompanyStaff(res?.data);
    }
  }

  const searchCompanyStaff = (value: string) => {
    setSearchValue(value);
    getSearchedCompanyStaff(value);
  }

  const getSearchedCompanyStaff = async (value: string) => {
    const payload = {
      parameter: value,
      page: 1,
      size: 10000,
    };

    if (value.length > 2) {
      setPageLoader(true);
      const res = await postData(`companystaff/search`, payload);

      if (res) {
        setCompanyStaff(res?.data)
        // setIsSearched(true);
        setPageLoader(false);
      }
      return;
    }

    return getCompanyStaff();
  }

  const toggleAddEmployeeModal = () => {
    setToggleStaffModal(!openStaffModal);
  }

  const toggleDeactivateStaff = () => {
    if (deactivateStaff) return setDeactivateStaff(false);
    return setDeactivateStaff(true);
  }

  const toggleActivateStaff = () => {
    if (activateStaff) return setActivateStaff(false);
    return setActivateStaff(true);
  }

  const toggleAdminStaff = () => {
    if (makeAdmin) return setAdminStaff(false);
    return setAdminStaff(true);
  }

  const handleStaffDeactivate = async () => {
    setRequestLoader(true);
    const id = toast.loading("...Loading");

    const payload = {
      profile_id: staffDetail?.id,
    };

    const res = await putData(`company/deactivateprofile`, payload);

    if (res?.isSuccess || res?.isSucess) {
      setRequestLoader(false);
      toggleDeactivateStaff();
      getCompanyStaff();
      return toast.update(id, { render: res?.message, type: "success", isLoading: false, autoClose: 5000, draggablePercent: 60, closeOnClick: true });
    }

    toast.update(id, { render: res?.message, type: "error", isLoading: false, autoClose: 5000, draggablePercent: 60, closeOnClick: true });
    return setRequestLoader(false);
  }

  const handleStaffActivation = async () => {
    setRequestLoader(true);
    const id = toast.loading("...Loading");

    const payload = {
      profile_id: staffDetail?.id,
    };

    const res = await putData(`company/activateprofile`, payload);

    if (res?.isSuccess || res?.isSucess) {
      setRequestLoader(false);
      toggleActivateStaff();
      getCompanyStaff();
      return toast.update(id, { render: res?.message, type: "success", isLoading: false, autoClose: 5000, draggablePercent: 60, closeOnClick: true });
    }

    toast.update(id, { render: res?.message, type: "error", isLoading: false, autoClose: 5000, draggablePercent: 60, closeOnClick: true });
    return setRequestLoader(false);
  }

  const handleStaffAdmin = async () => {
    setRequestLoader(true);
    const id = toast.loading("...Loading");

    const payload = {
      profile_id: staffDetail?.id,
    };

    const res = await postData(`company/makeadmin`, payload);

    if (res?.isSuccess || res?.isSucess) {
      setRequestLoader(false);
      toggleAdminStaff();
      getCompanyStaff();
      return toast.update(id, { render: res?.message, type: "success", isLoading: false, autoClose: 5000, draggablePercent: 60, closeOnClick: true });
    }

    toast.update(id, { render: res?.message, type: "error", isLoading: false, autoClose: 5000, draggablePercent: 60, closeOnClick: true });
    return setRequestLoader(false);
  }

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setRequestLoader(true);
    const id = toast.loading("...Loading");

    const res = await postData(`company/createprofile`, newStaff);

    if (res?.isSuccess || res?.isSucess) {
      setRequestLoader(false);
      getCompanyStaff();
      resetNewStaff();
      toggleAddEmployeeModal();
      return toast.update(id, { render: res?.message, type: "success", isLoading: false, autoClose: 5000, draggablePercent: 60, closeOnClick: true });
    }

    toast.update(id, { render: res?.message, type: "error", isLoading: false, autoClose: 5000, draggablePercent: 60, closeOnClick: true });
    return setRequestLoader(false);
  }

  // const confirmAdmin = () => {
  //   console.log(staffDetail?.roles?.some(role => role?.name?.toLowerCase() === 'company superuser'))
  // }

  return (
    <React.Fragment>
      <div>
        <div className="dropdown-content py-3">
          <div className="form-control-wrap">
            <div className="form-control-icon start md text-light">
              <em className="icon ri-search-line pt-3"></em>
            </div>
            <input type="text" className="form-control py-3 form-control-md" placeholder="Search Staffs" onInput={(e: ChangeEvent<HTMLInputElement>) => searchCompanyStaff(e.target?.value)} />
          </div>
        </div>

        <div className="nk-block">

          <div className="nk-block-head mt-5 pt-3">
            <div className="nk-block-head-between">
              <div className="nk-block-head-content">
                <h1 className="">Company Staffs</h1>
              </div>
              <button type="button" className="btn btn-primary btn-sm" onClick={toggleAddEmployeeModal}>
                + Add Employee
              </button>
            </div>
          </div>

          <div className="card">
            {pageLoader ? (
              <PageLoader />
            ) : (
              <>
                {allStaff.length > 0 ? (
                  <table className="table table-middle mb-0">
                    <thead className="table-light">
                      <tr>
                        <th className="tb-col tb-col-check"></th>
                        <th className="tb-col">
                          <h6 className="overline-title">FIRST NAME</h6>
                        </th>
                        <th className="tb-col">
                          <h6 className="overline-title">LAST NAME</h6>
                        </th>
                        <th className="tb-col">
                          <h6 className="overline-title">EMAIL</h6>
                        </th>
                        <th className="tb-col">
                          <h6 className="overline-title">PHONE NUMBER</h6>
                        </th>
                        <th className="tb-col">
                          <h6 className="overline-title">JOINED</h6>
                        </th>
                        <th className="tb-col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {allStaff.map((staff: Staff, index: number) => {
                        return (
                          <>
                            <tr key={index}>
                              <td className="tb-col tb-col-check"></td>

                              <td className="tb-col">
                                <div className="caption-text">{staff?.first_name}</div>
                              </td>

                              <td className="tb-col">
                                <div className="caption-text">{staff?.last_name}</div>
                              </td>

                              <td className="tb-col">
                                <div className="caption-text">{staff?.email}</div>
                              </td>

                              <td className="tb-col">
                                <div className="caption-text">{staff?.whatsapp_country_code} {" "} {staff?.Phone_number}</div>
                              </td>


                              <td className="tb-col">
                                {/* <div className="caption-text">Tue, Aug 22, 2023 6:19 PM</div> */}
                                <div className="caption-text">
                                  {dayjs(staff?.createdAt).format('ddd, MMM DD, YYYY')}
                                  {" "}
                                  {dayjs(staff?.createdAt).format('hh:mm A')}
                                </div>
                              </td>


                              <td className="tb-col tb-col-end">
                                <Popover className="dropdown">
                                  <Popover.Button className="btn btn-sm btn-icon btn-zoom me-n1" onClick={() => { setStaffDetails({ ...staff }) }}>
                                    <em className="icon ri-settings-2-line"></em>
                                  </Popover.Button>

                                  <Popover.Panel>
                                    <div
                                      className="dropdown-menu dropdown-menu-end show"
                                      style={{
                                        position: "absolute",
                                        inset: "0px 0px auto auto",
                                        transform: "translate3d(-24px, 32px, 0px)"
                                      }}>
                                      <div className="dropdown-content">
                                        <ul className="link-list link-list-hover-bg-primary link-list-md">
                                          <li>
                                            <NavLink to={`/dashboard/staff/${staff?.id}`}>
                                              <em className="icon ri-eye-fill"></em>
                                              <span>View</span>
                                            </NavLink>
                                          </li>

                                          {(staff?.status?.toLowerCase() === 'active' &&
                                            !staff?.roles?.some(role => role?.name?.toLowerCase() === 'company superuser')
                                          ) && (<li>
                                            <a onClick={() => { toggleDeactivateStaff() }}>
                                              <em className="icon ri-delete-bin-2-fill"></em>
                                              <span>Deactivate staff</span>
                                            </a>
                                          </li>)}

                                          {staff?.status?.toLowerCase() !== 'active' && (<li>
                                            <a onClick={() => { toggleActivateStaff() }}>
                                              <em className="icon ri-user-add-line"></em>
                                              <span>Activate staff</span>
                                            </a>
                                          </li>)}

                                          {!staff?.roles?.some(role => role?.name?.toLowerCase() === 'company superuser') && (<li>
                                            <a onClick={() => { toggleAdminStaff() }}>
                                              <em className="icon ri-admin-line"></em>
                                              <span>Make admin</span>
                                            </a>
                                          </li>)}
                                        </ul>
                                      </div>
                                    </div>
                                  </Popover.Panel>
                                </Popover>
                              </td>
                            </tr>
                          </>
                        )
                      }
                      )}
                    </tbody>
                  </table>
                ) : (
                  <div className="tab-pane" id="trash-documents-tab-pane">
                    <div className="row justify-content-center py-5">
                      <div className="col-md-7 col-lg-4">
                        <div className="mb-5 text-center">
                          <img src="images/illustrations/no-documents.svg" alt="" />
                        </div>
                        <div className="nk-block-head text-center">
                          <div className="nk-block-head-content">
                            <h1 className="nk-block-title mb-2">
                              Your company does not have any staff.
                            </h1>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>

        </div>


        {/* <!-- ADD NEW STAFF --> */}
        <div
          className={`offcanvas offcanvas-end customeoff ${openStaffModal ? 'show' : ''}`}
          tabIndex={-1}
          id="offcanvasExample"
          aria-modal="true"
          role="dialog"
        >
          <div className="offcanvas-header">
            <h5 className="modal-title" id="#gridSystemModal">Add Staff</h5>
            <button type="button" className="btn-close" aria-label="Close" onClick={toggleAddEmployeeModal}>
              <i className="fa-solid fa-xmark"></i>
            </button>
          </div>
          <div className="offcanvas-body">
            <div className="container-fluid">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-xl-12 mb-3">
                    <Input
                      label="Employee First Name *"
                      type="text"
                      name="first_name"
                      id="first_name"
                      placeholder="Enter first name"
                      required={true}
                      value={newStaff.first_name}
                      onChange={(e: any) => { setNewStaff({ ...newStaff, first_name: e.target?.value }) }}
                    />
                  </div>

                  <div className="col-xl-12 mb-3">
                    <Input
                      label="Employee Last Name *"
                      type="text"
                      name="last_name"
                      id="last_name"
                      placeholder="Enter last name"
                      required={true}
                      value={newStaff.last_name}
                      onChange={(e: any) => { setNewStaff({ ...newStaff, last_name: e.target?.value }) }}
                    />
                  </div>

                  <div className="col-xl-12 mb-3">
                    <Input
                      label="Employee Email *"
                      type="email"
                      name="employeeEmail"
                      id="employeeEmail"
                      placeholder="Enter email"
                      required={true}
                      value={newStaff.email}
                      onChange={(e: any) => { setNewStaff({ ...newStaff, email: e.target?.value }) }}
                    />
                  </div>
                </div>

                <div>
                  <button type="button" className="btn btn-danger light ms-1" onClick={toggleAddEmployeeModal}>Cancel</button>
                  <button
                    type="submit"
                    className="btn btn-primary me-1 ms-1"
                    disabled={requestLoader}
                  >Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>


        {/* Deactivate staff */}
        {deactivateStaff && (
          <RemoveModal
            title={"Deactivate Staff"}
            handleAction={handleStaffDeactivate}
            handleClose={toggleDeactivateStaff}
            open={deactivateStaff}
            proceedText={"Are you sure you want to deactivate this staff?"}
            buttonText={"Yes, Deactivate"}
            showOtherButton={true}
            requestLoader={requestLoader}
            otherButtonText={"No, Keep"}
          />
        )}

        {/* Activate Staff */}
        {activateStaff && (
          <AddModal
            title={"Activate Staff!"}
            handleAction={handleStaffActivation}
            handleClose={toggleActivateStaff}
            open={activateStaff}
            proceedText={"Proceed to activate staff"}
            buttonText={"Confirm"}
            showOtherButton={true}
            requestLoader={requestLoader}
            otherButtonText={"Cancel"}
          />
        )}

        {makeAdmin && (
          <AddModal
            title={"Make Staff an Admin"}
            handleAction={handleStaffAdmin}
            handleClose={toggleAdminStaff}
            open={makeAdmin}
            proceedText={"Are you sure you want to make this staff an admin?"}
            buttonText={"Yes, Confirm"}
            showOtherButton={true}
            requestLoader={requestLoader}
            otherButtonText={"No, Cancel"}
          />
        )}

      </div>
    </React.Fragment >
  );
}
