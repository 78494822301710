import { ChangeEvent, useEffect, useState } from "react";
import dashboard from "../../components/assets/css/dashboard.module.css";
import styles from "../../components/assets/css/style.module.css";
// import "../../components/assets/css/radio.scoped.css";
import { getData, postData } from "../../helpers/request";
import dayjs from "dayjs";
import Logo from "../../components/assets/img/logo.png";
import { Popover } from "@headlessui/react";
import { NavLink } from "react-router-dom";
import ConnectionModal from "../../components/ui/Modal/ConnectionModal";
import { CreateConnection } from "./Component/CreateConnection";
import { CreateTag } from "./Component/CreateTag";
import { toast } from "react-toastify";
import RevokeModal from "../../components/ui/Modal/SuccessModal";
import RemoveModal from "../../components/ui/Modal/DeleteModal";
import { ViewCard } from "./Component/ViewCard";
import PageLoader from "../../components/ui/PageLoader";
import { FullUserCard } from "./Cards";
import { Templates } from "./Component/CardTemplateDesigns";

export type Connection = {
  connection_id?: string;
  card_id?: string;
  is_private?: boolean;
  is_favourite?: number;
  connection_tag: string;
  note: string;
};

export interface Connections {
  note?: string;
  first_name: string;
  last_name: string;
  other_name?: string;
  country_code?: string;
  Phone_number?: string;
  address?: string;
  address2?: string;
  website?: string;
  whatsapp_country_code?: string;
  whatsapp_phone_number?: string;
  facebook?: string;
  twitter?: string;
  linkedin?: string;
  instagram?: string;
  youtube?: string;
  tiktok?: string;
  profile_id?: string;
  prefix?: string;
  suffix?: string;
  email: string;
  tag_name?: string;
  tag_id?: string;
  date_created?: string;
  id: string;
  photograph?: string;
  is_favourite?: number;
  business_name?: string;
  about_me?: string;
  designation?: string;
  city?: string;
  state?: string;
  country?: string;
  has_blocked_you?: boolean;
  is_connected?: boolean;
  is_favorite?: number | boolean;
  template?: Templates;
  template_id?: string;
  card_id?: string;
  is_subscribed?: boolean;
}

function Connections() {
  const [allConnections, setAllConnections] = useState<Array<FullUserCard>>([]);
  const [searchValue, setSearchValue] = useState("");
  const [connectionLayout, setConnectionLayout] = useState("table");
  const [openConnectionModal, setConnectionModal] = useState(false);
  const [openRevokeModal, setRevokeModal] = useState(false);
  const [openFavouriteModal, setFavouriteModal] = useState(false);
  const [connection, setCreateConnection] = useState<Connection>({
    connection_id: "",
    is_favourite: 0,
    connection_tag: "",
    note: "",
  });
  const [userCard, setUserCard] = useState<Connections | null>(null);
  const [openCreateTag, setTagModal] = useState(false);
  const [requestLoader, setRequestLoader] = useState(false);
  const [card, setCard] = useState(false);
  const [connections, setConnections] = useState<Connections | null>(null);
  const [pageLoader, setPageLoader] = useState(false);
  const [isSearched, setIsSearched] = useState(false);

  const [removeCard, setRemoveCard] = useState(false);

  const [revokeFavoriteModal, setRevokeFavoriteModal] = useState(false);

  const [userHasSubscription, setUserSubscription] = useState(false);

  const toggleCreateTagModal = () => {
    if (openCreateTag) {
      setConnectionModal(true);
      return setTagModal(false);
    }
    setConnectionModal(false);
    return setTagModal(true);
  };

  useEffect(() => {
    getAllConnections();
  }, []);

  const getAllConnections = async () => {
    setPageLoader(true);
    const res = await getData(`/cardconnection?page=1&size=100`);
    if (res) {
      setPageLoader(false);
      setIsSearched(false);
      return setAllConnections(res?.data);
    }
  };

  const searchCards = (value: string) => {
    setSearchValue(value);
    getSearchCards(value);
  };

  const getSearchCards = async (value: string) => {
    const payload = {
      parameter: value,
      page: 1,
      size: 20,
    };

    if (value.length > 2) {
      setPageLoader(true);
      const res = await postData(`card/search`, payload);

      if (res) {
        setAllConnections(res?.data);
        setIsSearched(true);
        setPageLoader(false);
        return;
      }
    }

    getAllConnections();
  };

  const handleProceed = () => {
    console.log("clicked");
  };

  const handleProceedConnection = async () => {
    setRequestLoader(true);
    const id = toast.loading("...Loading");
    const res = await postData(`cardconnection/create`, connection);

    if (res?.isSuccess || res?.isSucess) {
      setRequestLoader(false);
      toggleConnectionModal();
      getAllConnections();
      return toast.update(id, {
        render: res?.message,
        type: "success",
        isLoading: false,
        autoClose: 5000,
        draggablePercent: 60,
        closeOnClick: true,
      });
    }

    toast.update(id, {
      render: res?.message,
      type: "error",
      isLoading: false,
      autoClose: 5000,
      draggablePercent: 60,
      closeOnClick: true,
    });
    return setRequestLoader(false);
  };

  const handleConnectingModal = (id: string) => {
    setCreateConnection({
      ...connection,
      card_id: id,
    });
    setCard(false);
    return setConnectionModal(true);
  };

  const handleConnectionModal = (id: string) => {
    setCreateConnection({
      ...connection,
      card_id: id,
    });
    return setConnectionModal(true);
  };

  const toggleConnectionModal = () => {
    if (openConnectionModal) return setConnectionModal(false);
    return setConnectionModal(true);
  };

  const toggleRevokeModal = () => {
    if (openRevokeModal) return setRevokeModal(false);
    return setRevokeModal(true);
  };

  const toggleFavouriteModal = () => {
    if (openFavouriteModal) return setFavouriteModal(false);
    return setFavouriteModal(true);
  };

  const toggleFavouriteRevokeModal = () => {
    if (revokeFavoriteModal) return setRevokeFavoriteModal(false);
    return setRevokeFavoriteModal(true);
  };

  const toggleCardModal = () => {
    if (userCard?.is_favourite !== 1) {
      if (card) {
        setCard(false);
        // return setUserCard(null);
        return;
      }
      return setCard(true);
    }

    if (removeCard) {
      setRemoveCard(false);
      // return setUserCard(null);
      return;
    }
  };

  const handleFavModal = (id: string) => {
    if (userCard?.is_favourite !== 1) {
      setCreateConnection({
        ...connection,
        connection_id: id,
      });
      setCard(false);
      return setFavouriteModal(true);
    }
    setCreateConnection({
      ...connection,
      connection_id: id,
    });
    setCard(false);
    return setRevokeFavoriteModal(true);
  };

  // const handleRevokeModal = (id: string) => {
  //   setCreateConnection({
  //     ...connection,
  //     connection_id: id,
  //   })
  //   return setRevokeModal(true);
  // }

  const handleFavouriteModal = (id: string) => {
    if (connections?.is_favourite !== 1) {
      setCreateConnection({
        ...connection,
        connection_id: id,
      });
      setCard(false);
      return setFavouriteModal(true);
    }
    setCreateConnection({
      ...connection,
      connection_id: id,
    });
    setCard(false);
    return setRevokeFavoriteModal(true);
  };

  const handleCardModal = (user: Connections) => {
    setUserCard({ ...user });
    return setCard(true);
  };

  const handleRevoke = async () => {
    setRequestLoader(true);
    const id = toast.loading("...Loading");
    const payload = {
      connection_id: connections?.id,
    };
    const res = await postData(`connection/revoke`, payload);

    if (res?.isSuccess || res?.isSucess) {
      setRequestLoader(false);
      toggleRevokeModal();
      getAllConnections();
      return toast.update(id, {
        render: res?.message,
        type: "success",
        isLoading: false,
        autoClose: 5000,
        draggablePercent: 60,
        closeOnClick: true,
      });
    }

    toast.update(id, {
      render: res?.message,
      type: "error",
      isLoading: false,
      autoClose: 5000,
      draggablePercent: 60,
      closeOnClick: true,
    });
    return setRequestLoader(false);
  };

  const handleRemoveFavorite = async () => {
    setRequestLoader(true);
    const id = toast.loading("...Loading");
    const payload = {
      card_connection_id: connections?.id,
    };
    const res = await postData(`cardconnection/removefavourite`, payload);

    if (res?.isSuccess || res?.isSucess) {
      setRequestLoader(false);
      toggleFavouriteRevokeModal();
      getAllConnections();
      return toast.update(id, {
        render: res?.message,
        type: "success",
        isLoading: false,
        autoClose: 5000,
        draggablePercent: 60,
        closeOnClick: true,
      });
    }

    toast.update(id, {
      render: res?.message,
      type: "error",
      isLoading: false,
      autoClose: 5000,
      draggablePercent: 60,
      closeOnClick: true,
    });
    return setRequestLoader(false);
  };

  const handleAddFavourite = async () => {
    setRequestLoader(true);
    const id = toast.loading("...Loading");
    const payload = {
      card_connection_id: connection?.connection_id,
    };
    const res = await postData(`cardconnection/setfavourite`, payload);

    if (res?.isSuccess || res?.isSucess) {
      setRequestLoader(false);
      toggleFavouriteModal();
      getAllConnections();
      return toast.update(id, {
        render: res?.message,
        type: "success",
        isLoading: false,
        autoClose: 5000,
        draggablePercent: 60,
        closeOnClick: true,
      });
    }

    toast.update(id, {
      render: res?.message,
      type: "error",
      isLoading: false,
      autoClose: 5000,
      draggablePercent: 60,
      closeOnClick: true,
    });
    return setRequestLoader(false);
  };

  useEffect(() => {
    getProfileStatus();
  }, []);

  const getProfileStatus = async () => {
    const res: any = await getData("/subscription/check/active");

    if (res?.isSuccess) {
      return setUserSubscription(res?.isSubscribed);
    }
    return setUserSubscription(res?.isSubscribed);
  };

  const pattern = new RegExp("^(https?|ftp)://");
  const twitterUrl = (username: string = "") => {
    return `https://twitter.com/${username}`;
  };

  const instagramUrl = (username: string = "") => {
    return `https://www.instagram.com/${username}`;
  };
  return (
    <>
      <div>
        <div
          className={[
            dashboard["d-flex"],
            dashboard["justify-content-between"],
            dashboard["align-items-center"],
            dashboard["mb-3"],
          ].join(" ")}
        >
          <h5 className={dashboard["mb-0"]}>Connection </h5>
          <ul
            className={[
              dashboard["d-flex"],
              dashboard["align-items-center"],
              dashboard["gap"],
              dashboard["gx-2"],
            ].join(" ")}
          >
            <li>
              <div className={dashboard.dropdown}>
                <div className={dashboard["form-control-wrap"]}>
                  <div
                    className={`${dashboard["form-control-icon"]} ${dashboard.start} ${dashboard.md} ${dashboard["text-light"]}`}
                  >
                    <em className={`${dashboard.icon} ri-search-line`} />
                  </div>
                  <input
                    type="text"
                    className={`${dashboard["form-control"]} ${dashboard["form-control-md"]}`}
                    placeholder="Search Card"
                    onInput={(e: ChangeEvent<HTMLInputElement>) =>
                      searchCards(e.target?.value)
                    }
                  />
                </div>
              </div>
            </li>
          </ul>
        </div>

        <div className="hidden-toggles">
          <input
            name="coloration-level"
            value="table"
            type="radio"
            id="coloration-low"
            className="hidden-toggles__input"
            checked={connectionLayout === "table"}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setConnectionLayout(e.target?.value)
            }
          />
          <label htmlFor="coloration-low" className="hidden-toggles__label">
            Table View
          </label>

          <input
            name="coloration-level"
            value="card"
            type="radio"
            id="coloration-medium"
            className="hidden-toggles__input"
            checked={connectionLayout === "card"}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setConnectionLayout(e.target?.value)
            }
          />
          <label htmlFor="coloration-medium" className="hidden-toggles__label">
            Card View
          </label>
        </div>

        {connectionLayout === "table" ? (
          <div className={`${dashboard.card} ${dashboard["shadow-none"]}`}>
            <ul
              className={[
                dashboard.nav,
                dashboard["nav-tabs"],
                dashboard["nav-tabs-s1"],
                dashboard["px-4"],
              ].join(" ")}
            >
              <li className={dashboard["nav-item"]}>
                <button
                  className={`${dashboard["nav-link"]} ${dashboard["active"]}`}
                  type="button"
                  data-bs-toggle="tab"
                  data-bs-target="#recents-documents-tab-pane"
                >
                  Your Connection
                </button>
              </li>
            </ul>
            <div className={dashboard["tab-content"]}>
              <div
                className={[
                  dashboard["tab-pane"],
                  dashboard["fade"],
                  dashboard["show"],
                  dashboard["active"],
                ].join(" ")}
                id="recents-documents-tab-pane"
              >
                <table
                  className={`${dashboard.table} ${dashboard["table-middle"]} ${dashboard["mb-0"]}`}
                >
                  {pageLoader ? (
                    <PageLoader />
                  ) : (
                    <>
                      {allConnections.length > 0 ? (
                        <tbody>
                          {allConnections.map(
                            (connect: Connections, index: number) => {
                              return (
                                <>
                                  <tr key={index}>
                                    {/* <td className="tb-col">
                                      <div className="caption-text line-clamp-2">
                                        {connect?.note}
                                      </div>
                                    </td> */}
                                    <td
                                      className={`${dashboard["tb-col"]} ${dashboard["tb-col-sm"]}`}
                                    >
                                      <div
                                        className={[
                                          dashboard["badge"],
                                          dashboard["text-bg-blue-soft"],
                                          dashboard["rounded-pill"],
                                          dashboard["px-2"],
                                          dashboard["py-1"],
                                          dashboard["fs-6"],
                                          dashboard["lh-sm"],
                                        ].join(" ")}
                                      >
                                        {`${connect?.first_name} ${connect?.last_name}`}
                                      </div>
                                    </td>
                                    <td className="tb-col tb-col-md">
                                      <div className="fs-6 text-light d-inline-flex flex-wrap gap gx-2">
                                        <span>
                                          {dayjs(connect?.date_created).format(
                                            "MMM DD, YYYY"
                                          )}{" "}
                                        </span>
                                        <span>
                                          {dayjs(connect?.date_created).format(
                                            "hh:mm A"
                                          )}
                                        </span>
                                      </div>
                                    </td>
                                    {!connect?.has_blocked_you && (
                                      <td className="tb-col tb-col-end">
                                        <Popover className="dropdown">
                                          <Popover.Button
                                            className="btn btn-sm btn-icon btn-zoom me-n1"
                                            onClick={() => {
                                              setConnections({ ...connect });
                                            }}
                                          >
                                            <em className="icon ri-separator" />
                                          </Popover.Button>

                                          <Popover.Panel>
                                            <div
                                              className="dropdown-menu dropdown-menu-end show"
                                              style={{
                                                position: "absolute",
                                                inset: "0px 0px auto auto",
                                                transform:
                                                  "translate3d(-24px, 32px, 0px)",
                                              }}
                                            >
                                              <div className="dropdown-content">
                                                <ul className="link-list link-list-hover-bg-primary link-list-md">
                                                  {!connect?.is_connected &&
                                                    isSearched && (
                                                      <li>
                                                        <a
                                                          onClick={() => {
                                                            handleConnectionModal(
                                                              connect?.id
                                                            );
                                                          }}
                                                          className="pointer"
                                                        >
                                                          <em className="icon ri-user-follow-fill" />
                                                          <span>Connect</span>
                                                        </a>
                                                      </li>
                                                    )}
                                                  {connect?.is_favourite !==
                                                    1 &&
                                                    connect?.is_connected &&
                                                    !isSearched && (
                                                      <li>
                                                        <a
                                                          onClick={() => {
                                                            handleFavouriteModal(
                                                              connect?.id
                                                            );
                                                          }}
                                                          className="pointer"
                                                        >
                                                          <em className="icon ri-heart-line" />
                                                          <span>
                                                            Add to favorites
                                                          </span>
                                                        </a>
                                                      </li>
                                                    )}
                                                  {connect?.is_favourite ===
                                                    1 &&
                                                    connect?.is_connected &&
                                                    !isSearched && (
                                                      <li>
                                                        <a
                                                          onClick={() => {
                                                            handleFavouriteModal(
                                                              connect?.id
                                                            );
                                                          }}
                                                          className="pointer"
                                                        >
                                                          <em className="icon ri-heart-line" />
                                                          <span>
                                                            Remove favorite
                                                          </span>
                                                        </a>
                                                      </li>
                                                    )}
                                                  <li>
                                                    <NavLink
                                                      to={`/dashboard/details/${connect?.id}`}
                                                    >
                                                      <em className="icon ri-eye-line" />
                                                      <span>View</span>
                                                    </NavLink>
                                                  </li>
                                                  {/* {connect?.is_connected && (
                                                    <li>
                                                      <a onClick={() => { handleRevokeModal(connect?.id) }} className="pointer">
                                                        <em className="icon ni ni-trash" />
                                                        <span>Revoke connection</span>
                                                      </a>
                                                    </li>
                                                  )} */}
                                                </ul>
                                              </div>
                                            </div>
                                          </Popover.Panel>
                                        </Popover>
                                      </td>
                                    )}
                                  </tr>
                                </>
                              );
                            }
                          )}
                        </tbody>
                      ) : (
                        <div className="tab-pane" id="trash-documents-tab-pane">
                          <div className="row justify-content-center py-5">
                            <div className="col-md-7 col-lg-4">
                              <div className="mb-5 text-center">
                                <img
                                  src="images/illustrations/no-documents.svg"
                                  alt=""
                                />
                              </div>
                              <div className="nk-block-head text-center">
                                <div className="nk-block-head-content">
                                  <h1 className="nk-block-title mb-2">
                                    You do not have any connection.
                                  </h1>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </table>
              </div>
            </div>
          </div>
        ) : (
          <>
            {pageLoader ? (
              <PageLoader />
            ) : (
              <>
                {allConnections.length > 0 ? (
                  <div className={`${dashboard["nk-block"]} ${dashboard.row}`}>
                    {allConnections.map(
                      (connection: FullUserCard, index: number) => {
                        return (
                          <div
                            className={`${dashboard["col-md-6"]} ${dashboard["mb-4"]}`}
                            key={index}
                          >
                            <div
                              className={`${dashboard.card} ${styles.connection_card} ${dashboard["p-4"]}`}
                              style={{
                                backgroundImage: `url(${
                                  connection?.template?.image
                                    ? connection?.template?.image
                                    : require("../../components/assets/img/page-bg_2.jpeg")
                                })`,
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                height: "300px",
                              }}
                            >
                              <div
                                className={[
                                  dashboard.container,
                                  dashboard["position-relative"],
                                  dashboard["text-center"],
                                  dashboard["m-auto"],
                                ].join(" ")}
                              >
                                <div className={dashboard["position-absolute"]}>
                                  <button
                                    data-bs-toggle="modal"
                                    className={[
                                      dashboard.btn,
                                      dashboard["btn-white"],
                                      dashboard["btn-sm"],
                                      dashboard["fs-15px"],
                                    ].join(" ")}
                                    onClick={() => {
                                      handleCardModal(connection);
                                    }}
                                  >
                                    <i className="ri-eye-fill"></i>
                                  </button>
                                </div>

                                {connection?.photograph ? (
                                  <img
                                    src={connection?.photograph}
                                    className={dashboard["rounded-circle"]}
                                    style={{ height: "95px" }}
                                    alt=""
                                  />
                                ) : (
                                  <img
                                    src={Logo}
                                    className={dashboard["rounded-circle"]}
                                    style={{ height: "95px" }}
                                    alt=""
                                  />
                                )}

                                <h2
                                  className={`${dashboard["text-white"]} ${dashboard["pt-3"]}`}
                                >
                                  {`${connection?.first_name} ${connection?.last_name}`}{" "}
                                  {connection?.is_subscribed && (
                                    <span style={{ color: "#1773EA" }}>
                                      <i className="ri-verified-badge-fill" />
                                    </span>
                                  )}
                                  {/* <span style={{ color: "#1773EA" }}>
                                    <i className="ri-verified-badge-fill"></i>
                                  </span>{" "} */}
                                </h2>

                                <h6
                                  className={`${dashboard["mt-0"]} ${dashboard["pt-0"]} ${dashboard["text-white"]}`}
                                >
                                  {" "}
                                  <i>{connection?.business_name}</i>{" "}
                                </h6>

                                <p className={dashboard["text-white"]}>
                                  {connection?.about_me}
                                </p>

                                <ul
                                  className={`${dashboard["m-auto"]} ${dashboard["text-center"]} ${dashboard["justify-content-center"]}`}
                                >
                                  {connection?.facebook && (
                                    <li>
                                      <a
                                        href={`${connection?.facebook}`}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        {" "}
                                        <i className="ri-facebook-fill" />{" "}
                                      </a>
                                    </li>
                                  )}
                                  {connection?.instagram && (
                                    <li>
                                      <a
                                        href={
                                          pattern.test(connection?.instagram)
                                            ? connection?.instagram
                                            : instagramUrl(
                                                connection?.instagram
                                              )
                                        }
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        {" "}
                                        <i className="ri-instagram-fill" />{" "}
                                      </a>
                                    </li>
                                  )}
                                  {connection?.twitter && (
                                    <li>
                                      <a
                                        href={
                                          pattern.test(connection?.twitter)
                                            ? connection?.twitter
                                            : twitterUrl(connection?.twitter)
                                        }
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        {" "}
                                        <i className="ri-twitter-fill" />{" "}
                                      </a>
                                    </li>
                                  )}
                                  {connection?.linkedin && (
                                    <li>
                                      <a
                                        href={`${connection?.linkedin}`}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        {" "}
                                        <i className="ri-linkedin-fill" />{" "}
                                      </a>
                                    </li>
                                  )}
                                  {connection?.whatsapp_phone_number && (
                                    <li>
                                      {" "}
                                      <a
                                        href={`https://wa.me/${connection?.whatsapp_country_code}${connection?.whatsapp_phone_number}`}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        <i className="ri-whatsapp-fill" />{" "}
                                      </a>
                                    </li>
                                  )}
                                </ul>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                ) : (
                  <div
                    className={dashboard["tab-pane"]}
                    id="trash-documents-tab-pane"
                  >
                    <div
                      className={`${dashboard.row} ${dashboard["justify-content-center"]} ${dashboard["py-5"]}`}
                    >
                      <div
                        className={`${dashboard["col-md-7"]} ${dashboard["col-lg-4"]}`}
                      >
                        <div
                          className={`${dashboard["mb-5"]} ${dashboard["text-center"]}`}
                        >
                          <img
                            src="images/illustrations/no-documents.svg"
                            alt=""
                          />
                        </div>
                        <div
                          className={`${dashboard["nk-block-head"]} ${dashboard["text-center"]}`}
                        >
                          <div
                            className={`${dashboard["nk-block-head-content"]}`}
                          >
                            <h1
                              className={`${dashboard["nk-block-title"]} ${dashboard["mb-2"]}`}
                            >
                              You do not have any connections.
                            </h1>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        )}

        {openCreateTag && (
          <ConnectionModal
            title={"Create Tag"}
            handleClose={toggleCreateTagModal}
            // handleAction={handleLogin}
            ModalComponent={
              <CreateTag
                connection={connection}
                setCreateConnection={setCreateConnection}
                onProceed={() => {
                  handleProceed();
                }}
                onClose={toggleCreateTagModal}
              />
            }
            open={openCreateTag}
          />
        )}

        {openConnectionModal && (
          <ConnectionModal
            title={"Create Connection"}
            handleClose={toggleConnectionModal}
            // handleAction={handleLogin}
            ModalComponent={
              <CreateConnection
                connection={connection}
                setCreateConnection={setCreateConnection}
                onProceed={() => {
                  handleProceedConnection();
                }}
                requestLoader={requestLoader}
                handleTag={toggleCreateTagModal}
                onClose={toggleConnectionModal}
              />
            }
            open={openConnectionModal}
          />
        )}

        {openRevokeModal && (
          <RevokeModal
            title={"Revoke connection!"}
            handleAction={handleRevoke}
            handleClose={toggleRevokeModal}
            open={openRevokeModal}
            proceedText={"Are you sure you want to revoke your connection?"}
            buttonText={"Yes, Revoke"}
            showOtherButton={true}
            requestLoader={requestLoader}
            otherButtonText={"Cancel"}
          />
        )}

        {openFavouriteModal && (
          <RevokeModal
            title={"Add connection as favourite!"}
            handleAction={handleAddFavourite}
            handleClose={toggleFavouriteModal}
            open={openFavouriteModal}
            proceedText={
              "Are you sure you want to add this connection as favourite?"
            }
            buttonText={"Yes, add"}
            showOtherButton={true}
            requestLoader={requestLoader}
            otherButtonText={"Cancel"}
          />
        )}

        {card && (
          <ConnectionModal
            title={""}
            handleClose={toggleCardModal}
            // handleAction={handleLogin}
            ModalComponent={
              <ViewCard
                connection={userCard}
                isSearched={isSearched}
                onProceed={() => {
                  handleConnectingModal(userCard?.id as string);
                }}
                handleAction={() => {
                  handleFavModal(userCard?.id as string);
                }}
                requestLoader={requestLoader}
                onClose={() => {
                  handleFavModal(userCard?.id as string);
                }}
              />
            }
            open={card}
          />
        )}

        {revokeFavoriteModal && (
          <RemoveModal
            title={"Remove Favourites"}
            handleAction={handleRemoveFavorite}
            handleClose={toggleFavouriteModal}
            open={revokeFavoriteModal}
            proceedText={
              "Are you sure you want to remove connection as favourite?"
            }
            buttonText={"Yes, Remove"}
            showOtherButton={true}
            requestLoader={requestLoader}
            otherButtonText={"Keep"}
          />
        )}
      </div>
    </>
  );
}

export default Connections;
