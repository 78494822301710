import { Link, useLocation } from "react-router-dom";
import Logo from "../assets/img/logo-2.png";
import { FormEvent, useEffect, useState } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import { postData } from "../../helpers/request";
import SessionStorageService from "../../services/sessionStorage";
import { useNavigate } from "react-router-dom";
import { useInput } from "../../validation/InputValidation";
import { Input } from "../../components/ui/Input/Input";
import PrimaryButton from "../../components/ui/Button/PrimaryButton";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styles from "../assets/css/global.module.css";
import GoogleIcon from "../assets/img/shape/google-icon.svg";

export type LoginInfo = {
  email: string;
  registration_type: string;
  password?: string;
};

function Login() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const emailInput = useInput("");
  const passwordInput = useInput("");
  const [type, setType] = useState<"text" | "number" | "email" | "password">(
    "password"
  );
  const [registerPayload, setRegisterPayload] = useState<LoginInfo>({
    email: "",
    registration_type: "",
    password: "",
  });
  const [regError, setRegError] = useState("");
  const [proceedToRegister, setRegistration] = useState(false);
  const [loginForm, setLoginForm] = useState<LoginInfo>({
    email: "",
    registration_type: "",
    password: "",
  });

  const [requestLogin, setRequestLogin] = useState(false);
  const [requestLoader, setRequestLoader] = useState(false);
  const paramValue = queryParams.get("id");

  const validateInput = (input: {
    value: string;
    errorMessage: string;
    setError: (value: boolean) => void;
    setErrorMessage: (value: string) => void;
  }) => {
    if (!input.value.trim()) {
      input.setError(true);
      return input.setErrorMessage("Input filed can't be empty!");
    }
    input.setError(false);
    input.setErrorMessage("");
  };

  const togglePassword = () => {
    if (type === "password") return setType("text");
    return setType("password");
  };

  const registerAccount = async () => {
    setRequestLoader(true);
    const res: any = await postData("/profile/googlelogin", registerPayload);

    if (res.isSuccess) {
      SessionStorageService.setItem("token", res.token);
      const user = SessionStorageService.decodeToken();
      SessionStorageService.setItem(
        "is_profile_updated",
        user?.is_profile_updated
      );
      setRequestLoader(false);
      setRequestLogin(false);
      toast.success(res?.message, { closeOnClick: true });
      const newSearch = `${queryParams.toString()}`;
      if (paramValue) {
        return navigate({
          pathname: "/dashboard/index",
          search: newSearch,
        });
      }
      return navigate("/dashboard/index");
    }
    if (!res.isSuccess) {
      setRequestLoader(false);
      setRequestLogin(false);
      toast.error(res?.message, { closeOnClick: true });
      setRegError(res.message);
    }
  };

  const LoginWithGoogle = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        const userInfo: any = await new Promise((resolve) => {
          const xhrRequest = new XMLHttpRequest();

          xhrRequest.open(
            "GET",
            `https://www.googleapis.com/oauth2/v3/userinfo`
          );
          xhrRequest.setRequestHeader(
            "Authorization",
            `Bearer ${tokenResponse.access_token}`
          );
          xhrRequest.onload = function () {
            if (this.status >= 200 && this.status < 300)
              resolve(JSON.parse(this.responseText));
            else resolve({ err: "404" });
          };
          xhrRequest.send();
        });
        setRegisterPayload({
          registration_type: "Google",
          email: userInfo?.email,
        });
        setRegistration(true);
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    validateInput(emailInput);
    validateInput(passwordInput);

    setLoginForm({
      registration_type: "App",
      email: emailInput.value,
      password: passwordInput.value,
    });
    return setRequestLogin(true);
  };

  const loginUser = async () => {
    setRequestLoader(true);
    const id = toast.loading("...Loading");
    const res: any = await postData("/profile/login", loginForm);

    if (res.isSuccess) {
      if (res.isVerified) {
        SessionStorageService.setItem("token", res.token);
        const user = SessionStorageService.decodeToken();
        SessionStorageService.setItem(
          "is_profile_updated",
          user?.is_profile_updated
        );
        setRequestLogin(false);
        setRequestLoader(false);
        toast.update(id, {
          render: res?.message,
          type: "success",
          isLoading: false,
          autoClose: 5000,
          draggablePercent: 60,
          closeOnClick: true,
        });
        const newSearch = `${queryParams.toString()}`;
        if (paramValue) {
          return navigate({
            pathname: "/dashboard/index",
            search: newSearch,
          });
        }
        return navigate("/dashboard/index");
      }
      toast.update(id, {
        render: res?.message,
        type: "warning",
        isLoading: false,
        autoClose: 5000,
        draggablePercent: 60,
        closeOnClick: true,
      });
      const newSearch = `${queryParams.toString()}`;
      if (paramValue) {
        return navigate({
          pathname: `/verification/${res?.id}`,
          search: newSearch,
        });
      }
      return navigate(`/verification/${res?.id}`);
    }
    if (!res.isSuccess) {
      setRequestLoader(false);
      setRequestLogin(false);
      toast.update(id, {
        render: res?.message,
        type: "error",
        isLoading: false,
        autoClose: 5000,
        draggablePercent: 60,
        closeOnClick: true,
      });
    }
  };

  useEffect(() => {
    if (proceedToRegister) {
      registerAccount();
    }
  }, [proceedToRegister]);

  useEffect(() => {
    if (requestLogin) {
      loginUser();
    }
  }, [requestLogin]);

  return (
    <div className={styles["nk-app-root"]}>
      <main className={styles["nk-pages"]}>
        <div
          className={[
            styles["min-vh-100"],
            styles["d-flex"],
            styles["flex-column"],
            styles["has-mask"],
          ].join(" ")}
        >
          <div
            className={[
              styles["nk-mask"],
              styles["bg-pattern-dot"],
              styles["bg-blend-around"],
            ].join(" ")}
          />

          <div className={styles["my-auto"]}>
            <div className={styles.container}>
              <div
                className={[
                  styles["text-center"],
                  styles["mt-6"],
                  styles["mb-5"],
                ].join(" ")}
              >
                <Link to="/" className={styles["logo-link"]}>
                  <div className={styles["logo-wrap"]}>
                    <img
                      className={styles["logo-img"]}
                      width="180"
                      src={Logo}
                      alt=""
                    />
                  </div>
                </Link>
              </div>

              <div
                className={[
                  styles["row"],
                  styles["g-gs"],
                  styles["justify-content-center"],
                ].join(" ")}
              >
                <div
                  className={[
                    styles["col-md-7"],
                    styles["col-lg-6"],
                    styles["col-xl-5"],
                  ].join(" ")}
                >
                  <div
                    className={[
                      styles["card"],
                      styles["border-0"],
                      styles["shadow-sm"],
                      styles["rounded-4"],
                    ].join(" ")}
                  >
                    <div className={styles["card-body"]}>
                      <h4 className="mb-3">Welcome Back!</h4>

                      <form onSubmit={handleSubmit}>
                        <div
                          className={[styles["row"], styles["g-4"]].join(" ")}
                        >
                          <div className={styles["col-12"]}>
                            <Input
                              label="Email"
                              type="email"
                              name="emailorusername"
                              id="emailorusername"
                              placeholder="Enter Email"
                              required={true}
                              {...emailInput}
                            />
                          </div>

                          <div className={styles["col-12"]}>
                            <Input
                              label="Password"
                              inputType="password"
                              type={type}
                              name="password"
                              id="toggle-password"
                              placeholder="Enter Password"
                              required={true}
                              togglePassword={togglePassword}
                              {...passwordInput}
                            />
                          </div>

                          <div className={styles["col-12"]}>
                            <div className={styles["form-group"]}>
                              {regError && (
                                <p className={styles["form-error-message"]}>
                                  {regError}
                                </p>
                              )}
                            </div>
                          </div>

                          <div className={styles["col-12"]}>
                            <div
                              className={[
                                styles["d-flex"],
                                styles["flex-wrap"],
                                styles["justify-content-between"],
                                styles["has-gap"],
                                styles["g-3"],
                              ].join(" ")}
                            >
                              <Link
                                to={{
                                  pathname: "/forgot-password",
                                  search: `?id=${paramValue?.toString()}`,
                                }}
                                className={styles.small}
                              >
                                Forgot Password?
                              </Link>
                            </div>
                          </div>

                          <div className={styles["col-12"]}>
                            <div className={styles["form-group"]}>
                              <PrimaryButton
                                type="submit"
                                title="Sign in"
                                disabled={
                                  emailInput.error ||
                                  passwordInput.error ||
                                  requestLoader
                                }
                              />
                            </div>
                          </div>

                          <div className={[styles['col-12'], styles['text-center']].join(' ')}>
                            <div className={[styles['small'], styles['mb-3']].join(' ')}>or sign in with</div>

                            <button
                              className={[styles['btn'], styles['btn-outline-secondary'], styles['w-100']].join(' ')}
                              type="button"
                              disabled={requestLoader}
                              onClick={() => LoginWithGoogle()}
                            >
                              <img
                                src={GoogleIcon}
                                alt="Google logo"
                                className={`${styles.icon} ${styles['fs-5']} ${styles['pe-2']}`}
                              />
                              Continue with Google
                            </button>

                            {!requestLoader && (
                              <p className="mt-4">
                                Don't have an account?{" "}
                                {paramValue ? (
                                  <Link
                                    to={{
                                      pathname: "/signup",
                                      search: `?id=${paramValue?.toString()}`,
                                    }}
                                  >
                                    Register
                                  </Link>
                                ) : (
                                  <Link to="/signup">Register</Link>
                                )}
                              </p>
                            )}
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Login;
