import { router } from "./router/router";
import { RouterProvider } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <>
      <RouterProvider router={router} />
      <ToastContainer limit={2} closeButton={true} closeOnClick />
    </>
  );
}

export default App;
