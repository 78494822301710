import { CardEditProp } from "./PersonalDetails";
import "../../../components/assets/css/dashboard.scoped.css";
import "../../../components/assets/css/style.scoped.css";
import { Input } from "../../../components/ui/Input/Input";
import { useState } from "react";
import { country_code } from "../../../helpers/countryCode";

export default function CardSocialDetails({ pageLoader, cardProfile, setCardProfile, onProceed }: CardEditProp) {
  const [facebookError, setFacebookError] = useState(false);
  const [facebookErrorMessage, setFacebookErrorMessage] = useState("");

  const [youtubeError, setYoutubeError] = useState(false);
  const [youtubeErrorMessage, setYoutubeErrorMessage] = useState("");

  const [linkedinError, setLinkedinError] = useState(false);
  const [linkedinErrorMessage, setLinkedinErrorMessage] = useState("");

  const [twitterError, setTwitterError] = useState(false);
  const [twitterErrorMessage, setTwitterErrorMessage] = useState("");

  const [instagramError, setInstagramError] = useState(false);
  const [instagramErrorMessage, setInstagramErrorMessage] = useState("");

  const [tiktokError, setTiktokError] = useState(false);
  const [tiktokErrorMessage, setTiktokErrorMessage] = useState("");

  const validateUrl = (e: any) => {
    const { name, value } = e.target;

    if (name === 'facebook') {
      const pattern = /^(?:(?:http|https):\/\/)?(?:www.)?(web.facebook|m\.facebook|facebook)\.(com|me)\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w\-]*\/)*?(?:profile.php\?id=(?=\d.*))?([\w\-]*)?(?:[\w\-\.]*)?/g;

      if (pattern.test(value)) {
        setFacebookErrorMessage("");
        setFacebookError(false);
        return setCardProfile({ ...cardProfile, [name]: value });
      }
      setFacebookErrorMessage("Please enter a valid URL!");
      setFacebookError(true);
      return setCardProfile({ ...cardProfile, [name]: value });
    }

    if (name === 'youtube') {
      const pattern = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;

      if (pattern.test(value)) {
        setLinkedinErrorMessage("");
        setYoutubeError(false);
        return setCardProfile({ ...cardProfile, [name]: value });
      }
      setYoutubeErrorMessage("Please enter a valid URL!");
      setYoutubeError(true);
      return setCardProfile({ ...cardProfile, [name]: value });
    }

    if (name === 'linkedin') {
      const pattern = /(https?:\/\/(www.)|(www.))?linkedin.com\/(mwlite\/|m\/)?in\/[a-zA-Z0-9_.-]+\/?/;

      if (pattern.test(value)) {
        setLinkedinErrorMessage("");
        setLinkedinError(false);
        return setCardProfile({ ...cardProfile, [name]: value });
      }
      setLinkedinErrorMessage("Please enter a valid URL!");
      setLinkedinError(true);
      return setCardProfile({ ...cardProfile, [name]: value });
    }
  }

  const validateTwitterUsername = (e: any) => {
    const { name, value } = e.target;
    const pattern = new RegExp('^(https?|ftp)://');
    const isValidUrl = pattern.test(value);

    if (isValidUrl) {
      if (!value.includes(name)) {
        setTwitterError(true);
        // setCardProfile({ ...cardProfile, [name]: "" });
        setCardProfile({ ...cardProfile, [name]: value });
        return setTwitterErrorMessage("This fields value is invalid.");
      }
      // if (value.includes(name)) {
      setCardProfile({ ...cardProfile, [name]: value });
      setTwitterError(false);
      return setTwitterErrorMessage("");
      // }
    }
    setCardProfile({ ...cardProfile, [name]: value });
    setTwitterError(false);
    return setTwitterErrorMessage("");
  }

  const validateInstagramUsername = (e: any) => {
    const { name, value } = e.target;
    const pattern = new RegExp('^(https?|ftp)://');
    const isValidUrl = pattern.test(value);

    if (isValidUrl) {
      if (!value.includes(name)) {
        setInstagramError(true);
        // setCardProfile({ ...cardProfile, [name]: "" });
        setCardProfile({ ...cardProfile, [name]: value });
        return setInstagramErrorMessage("This fields value is invalid.");
      }
      // if (value.includes(name)) {
      setCardProfile({ ...cardProfile, [name]: value });
      setInstagramError(false);
      return setInstagramErrorMessage("");
      // }
    }
    setCardProfile({ ...cardProfile, [name]: value });
    setInstagramError(false);
    return setInstagramErrorMessage("");
  }

  const validateTiktokUsername = (e: any) => {
    const { name, value } = e.target;
    const pattern = new RegExp('^(https?|ftp)://');
    const isValidUrl = pattern.test(value);

    if (isValidUrl) {
      if (!value.includes(name)) {
        setTiktokError(true);
        // setCardProfile({ ...cardProfile, [name]: "" });
        setCardProfile({ ...cardProfile, [name]: value });
        return setTiktokErrorMessage("This fields value is invalid.");
      }
      // if (value.includes(name)) {
      setCardProfile({ ...cardProfile, [name]: value });
      setTiktokError(false);
      return setTiktokErrorMessage("");
      // }
    }
    setCardProfile({ ...cardProfile, [name]: value });
    setTiktokError(false);
    return setTiktokErrorMessage("");
  }

  return (
    <>
      <div
        className="sections_pages bostami-page-content-wrap shadow mb-4"
        style={{ display: "block" }}
      >
        <div className="section-wrapper pl-60 pr-60 pt-60">
          <div className="bostami-page-title-wrap mb-15">
            <h2 className="page-title">Socials</h2>
          </div>
        </div>

        <div className="section-wrapper pr-60 pl-60 mb-60">
          <div className="contact-area">
            <form className="contact-form" onSubmit={onProceed}>
              <div className="row mb-60">
                <div className="col-md-6 mb-2">
                  <label
                    htmlFor='Phone_code'
                    className="form-control-label form-label"
                  >
                    WhatsApp contry code
                  </label>
                  <select
                    className="form-control"
                    value={cardProfile?.whatsapp_country_code}
                    id='Phone_code'
                    onChange={(e: any) => setCardProfile({ ...cardProfile, whatsapp_country_code: e?.target?.value })}
                  > 
                    <option selected></option>
                    {country_code.map(
                      ({ dial_code }: { dial_code: string }, index: number) => {
                        return (
                          <option
                            key={index}
                            value={dial_code}
                          >
                            {dial_code}
                          </option>
                        );
                      }
                    )}
                  </select>
                </div>
                <div className="col-md-6 mb-2">
                  <Input
                    label="WhatsApp number"
                    type="tel"
                    name="whatsapp"
                    id="whatsapp"
                    placeholder="Enter WhatsApp number"
                    required={false}
                    defaultValue={cardProfile.whatsapp_phone_number}
                    value={cardProfile.whatsapp_phone_number}
                    onChange={(e) => setCardProfile({ ...cardProfile, whatsapp_phone_number: e?.target?.value })}
                  />
                </div>
                <div className="col-md-6 mb-2">
                  <Input
                    label="Facebook"
                    type="url"
                    name="facebook"
                    id="facebook"
                    placeholder="Enter your Facebook profile url"
                    required={false}
                    defaultValue={cardProfile.facebook}
                    value={cardProfile.facebook}
                    error={facebookError}
                    errorMessage={facebookErrorMessage}
                    onChange={(e: any) => { validateUrl(e) }}
                  />
                </div>
                <div className="col-md-6 mb-2">
                  <Input
                    label="Twitter(X)"
                    type="text"
                    name="twitter"
                    id="twitter"
                    placeholder="Username or profile url"
                    required={false}
                    defaultValue={cardProfile.twitter}
                    value={cardProfile.twitter}
                    onChange={(e: any) => validateTwitterUsername(e)}
                    error={twitterError}
                    errorMessage={twitterErrorMessage}
                  />
                </div>
                <div className="col-md-6 mb-2">
                  <Input
                    label="Linkedin"
                    type="url"
                    name="linkedin"
                    id="linkedin"
                    placeholder="Enter LinkedIn profile url"
                    required={false}
                    defaultValue={cardProfile.linkedin}
                    value={cardProfile.linkedin}
                    error={linkedinError}
                    errorMessage={linkedinErrorMessage}
                    onChange={(e: any) => { validateUrl(e) }}
                  />
                </div>
                <div className="col-md-6 mb-2">
                  <Input
                    label="Instagram"
                    type="text"
                    name="instagram"
                    id="instagram"
                    placeholder="Username or profile url"
                    required={false}
                    defaultValue={cardProfile.instagram}
                    value={cardProfile.instagram}
                    onChange={(e: any) => validateInstagramUsername(e)}
                    error={instagramError}
                    errorMessage={instagramErrorMessage}
                  />
                </div>
                <div className="col-md-6 mb-2">
                  <Input
                    label="Tiktok"
                    type="text"
                    name="tiktok"
                    id="tiktok"
                    placeholder="Username or profile url"
                    required={false}
                    defaultValue={cardProfile.tiktok}
                    value={cardProfile.tiktok}
                    onChange={(e: any) => validateTiktokUsername(e)}
                    error={tiktokError}
                    errorMessage={tiktokErrorMessage}
                  />
                </div>
                <div className="col-md-6 mb-2">
                  <Input
                    label="YouTube"
                    type="url"
                    name="youtube"
                    id="youtube"
                    placeholder="Enter YouTube url"
                    required={false}
                    defaultValue={cardProfile.youtube}
                    value={cardProfile.youtube}
                    error={youtubeError}
                    errorMessage={youtubeErrorMessage}
                    onChange={(e: any) => { validateUrl(e) }}
                  />
                </div>
                <div className="col-md-6 mb-2">
                  <Input
                    label="website"
                    type="url"
                    name="website"
                    id="website"
                    placeholder="Enter your website"
                    required={false}
                    defaultValue={cardProfile.website}
                    value={cardProfile.website}
                    onChange={(e) => setCardProfile({ ...cardProfile, website: e?.target?.value })}
                  />
                </div>
              </div>

              <div className="form-btn-wrap">
                <button
                  className="btn btn-dark mt-3 w-100"
                  type="submit"
                  style={{ fontSize: "14px" }}
                  disabled={
                    twitterError ||
                    instagramError ||
                    tiktokError
                  }
                >
                  Update card{" "}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}