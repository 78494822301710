import Avatar from "../../../components/assets/img/logo_2.png";
import "../../../components/assets/css/dashboard.scoped.css";
import "../../../components/assets/css/style.scoped.css";
import { FormEvent, useCallback, useEffect, useState } from "react";
import { toast } from 'react-toastify';
import { getData, putData } from "../../../helpers/request";
import SessionStorageService from "../../../services/sessionStorage";
import { useInput } from "../../../validation/InputValidation";
import { Input } from "../../../components/ui/Input/Input";
import ConnectionModal from "../../../components/ui/Modal/ConnectionModal";
import { UploadImage } from "../../Dashboard/Component/UploadImage";
import { CompanyProf } from "./CompanyProfile";
import { country_code } from "../../../helpers/countryCode";
import { useNavigate } from "react-router-dom";

function CompanyProfileEdit() {
  const [userProfile, setUserProfile] = useState<CompanyProf>({
    id: "",
    name: "",
    address: "",
    address2: "",
    country: "",
    state: "",
    city: "",
    logo: "",
    email: "",
    phone_number: "",
    website: "",
    company_summary: "",
    company_edit: false,
    createdAt: "",
    profile_edit: true,
    photograph: "",
  });
  const [requestLoader, setRequestLoader] = useState(false);
  const [imageModal, setImageModal] = useState(false);

  const [qrcode_url, setQrcode] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const token = SessionStorageService.getItem("token");
    if (token) {
      const user = SessionStorageService.decodeToken();
      return setQrcode(user?.qrcode_url);
    }
  }, []);

  const getProfile = useCallback(async () => {
    const res = await getData(`/company/profile`);
    if (res) {
      setUserProfile({ ...res?.data });
    }
  }, []);

  useEffect(() => {
    getProfile();
  }, [getProfile]);

  const emailInput = useInput("");

  const showImageModal = () => {
    if (imageModal) return setImageModal(false);
    return setImageModal(true);
  }

  const handleImageUpload = (e: FormEvent) => {
    submit(e);
  };

  const submit = async (e: FormEvent) => {
    e.preventDefault();
    setRequestLoader(true);
    const id = toast.loading("...Loading");
    const payload = {
      ...userProfile,
      logo: userProfile?.photograph
    };
    const res = await putData(`/company/update`, payload);

    if (res?.isSuccess || res?.isSucess) {
      setRequestLoader(false);
      getProfile();
      setImageModal(false);
      return toast.update(id, { render: res?.message, type: "success", isLoading: false, autoClose: 5000, draggablePercent: 60, closeOnClick: true });
    }

    toast.update(id, { render: res?.message, type: "error", isLoading: false, autoClose: 5000, draggablePercent: 60, closeOnClick: true });
    return setRequestLoader(false);
  }

  const goToCompany = () => {
    return navigate('/dashboard/company');
  }

  return (
    <>
      <div className="row">
        <div className="col-md-4 mb-3">
          <div className="card card-profile" style={{ paddingTop: "50px" }}>

            <div className="row justify-content-center">
              <div className="mt-n4 mt-lg-n6 mb-4 mb-lg-0">
                <section className="container text-center m-auto position-relative">
                  {/* <form> */}
                  <div
                    role="presentation"
                    tabIndex={0}
                    className="dropzone"
                    style={{ cursor: "pointer" }}
                  >
                    <input
                      className="b-none"
                      accept="image/*"
                      multiple
                      type="file"
                      tabIndex={-1}
                      style={{ display: "none" }}
                    />
                    <div className="upload">
                      {" "}
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 24 24"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M13 19v-4h3l-4-5-4 5h3v4z" />
                        <path d="M7 19h2v-2H7c-1.654 0-3-1.346-3-3 0-1.404 1.199-2.756 2.673-3.015l.581-.102.192-.558C8.149 8.274 9.895 7 12 7c2.757 0 5 2.243 5 5v1h1c1.103 0 2 .897 2 2s-.897 2-2 2h-3v2h3c2.206 0 4-1.794 4-4a4.01 4.01 0 0 0-3.056-3.888C18.507 7.67 15.56 5 12 5 9.244 5 6.85 6.611 5.757 9.15 3.609 9.792 2 11.82 2 14c0 2.757 2.243 5 5 5z" />
                      </svg>{" "}
                    </div>
                  </div>
                  <div className="mt-n4 mt-lg-n6 mb-lg-0 text-center m-auto">
                    {userProfile?.logo ? (
                      <img
                        src={userProfile?.logo}
                        alt="placeholder"
                        className="upload_image img-fluid border border-2 border-white"
                        style={{
                          width: "108px",
                          height: "108px",
                          objectFit: "cover"
                        }}
                      />
                    ) : (
                      <img
                        src={Avatar}
                        alt="placeholder"
                        className="upload_image img-fluid border border-2 border-white"
                      />
                    )}
                  </div>
                  <div className="text-center m-auto mt-4">
                    <button
                      type="button"
                      className="btn btn-sm btn-dark float-right"
                      onClick={showImageModal}
                    >
                      Update Image
                    </button>
                  </div>
                  {/* </form> */}
                </section>
              </div>
            </div>
            <div className="card-body pt-0">
              <div className="text-center mt-3">
                <h5 className="fw-bold">
                  {" "}
                  {`${userProfile.name}`}{" "}
                </h5>
                <p className="tex-sm">{userProfile.email}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-8">
          {/* Profile */}
          <div className="card px-2 px-lg-4 py-3 py-lg-0 mb-3">
            <div className="card-header bg-transparent pb-3 mb-3">
              <div className="d-flex align-items-center">
                <p className="mb-0 fw-bold">Company Information</p>
              </div>
            </div>

            <div className="card-body">
              <form onSubmit={submit}>
                <div className="row">
                  <div className="col-md-6 mb-2">
                    <Input
                      label="Email Address"
                      type="email"
                      name="emailorusername"
                      id="emailorusername"
                      placeholder="Enter Email"
                      required={true}
                      readonly={true}
                      defaultValue={userProfile.email}
                      {...emailInput}
                    />
                  </div>
                  <div className="col-md-6 mb-2">
                    <Input
                      label="Company name"
                      type="text"
                      name="first_name"
                      id="first_name"
                      placeholder=""
                      required={true}
                      defaultValue={userProfile.name}
                      value={userProfile.name}
                      onChange={(e) => setUserProfile({ ...userProfile, name: e?.target?.value })}
                    />
                  </div>
                  <div className="col-md-6 mb-2">
                    <Input
                      label="Phone"
                      type="tel"
                      name="phone"
                      id="phone"
                      placeholder=""
                      required={false}
                      defaultValue={userProfile.phone_number}
                      value={userProfile.phone_number}
                      onChange={(e) => setUserProfile({ ...userProfile, phone_number: e?.target?.value })}
                    />
                  </div>

                  <div className="col-md-6 mb-2">
                    <label
                      htmlFor='Phone_code'
                      className="form-control-label form-label"
                    >
                      Country
                    </label>
                    <select
                      className="form-control"
                      value={userProfile.country}
                      id='Phone_code'
                      onChange={(e: any) => setUserProfile({ ...userProfile, country: e?.target?.value })}
                    >
                      <option selected disabled>Select country</option>
                      {country_code.map(
                        ({ country }: { country: string }, index: number) => {
                          return (
                            <option
                              key={index}
                              value={country}
                            >
                              {country}
                            </option>
                          );
                        }
                      )}
                    </select>
                  </div>

                  <div className="col-md-6 mb-2">
                    <Input
                      label="State"
                      type="text"
                      name="state"
                      id="state"
                      placeholder=""
                      required={false}
                      defaultValue={userProfile.state}
                      value={userProfile.state}
                      onChange={(e) => setUserProfile({ ...userProfile, state: e?.target?.value })}
                    />
                  </div>

                  <div className="col-md-6 mb-2">
                    <Input
                      label="City"
                      type="text"
                      name="city"
                      id="city"
                      placeholder=""
                      required={false}
                      defaultValue={userProfile.city}
                      value={userProfile.city}
                      onChange={(e) => setUserProfile({ ...userProfile, city: e?.target?.value })}
                    />
                  </div>

                  <div className="col-md-12 mb-2">
                    <Input
                      label="website"
                      type="url"
                      name="website"
                      id="website"
                      placeholder="Enter website"
                      required={false}
                      defaultValue={userProfile.website}
                      value={userProfile.website}
                      onChange={(e) => setUserProfile({ ...userProfile, website: e?.target?.value })}
                    />
                  </div>

                  <div className="col-md-12 mb-2">
                    <Input
                      label="address"
                      type="text"
                      name="address"
                      id="address"
                      placeholder="Enter address"
                      required={false}
                      defaultValue={userProfile.address}
                      value={userProfile.address}
                      onChange={(e) => setUserProfile({ ...userProfile, address: e?.target?.value })}
                    />
                  </div>

                  <div className="col-md-12 mb-2">
                    <Input
                      label="address2"
                      type="text"
                      name="address2"
                      id="address2"
                      placeholder="Enter address 2"
                      required={false}
                      defaultValue={userProfile.address2}
                      value={userProfile.address2}
                      onChange={(e) => setUserProfile({ ...userProfile, address2: e?.target?.value })}
                    />
                  </div>

                  <div className="col-md-12 mt-4 mb-2 contact-form">
                    <div className="form-input-item mb-40">
                      <label className="input-lebel message">Company summary</label>
                      <textarea name="message"
                        className="input-box message false "
                        style={{ height: "70px" }}
                        rows={10}
                        value={userProfile?.company_summary}
                        onChange={(e) => { setUserProfile({ ...userProfile, company_summary: e?.target?.value }) }}
                      >
                      </textarea>
                    </div>
                  </div>

                  <div className="col-md-12 mt-4 mb-2">
                    <div className="form-check form-check-sm mt-4">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={userProfile?.notify_service_click}
                        id="privateMode"
                        onChange={(e) => setUserProfile({ ...userProfile, notify_service_click: e?.target?.checked })}
                      />
                      <label className="form-check-label" htmlFor="privateMode">
                        {" "}
                        Notify service click{" "}
                      </label>
                    </div>
                  </div>
                </div>
                <button type="button" className="btn btn-danger light mt-3" onClick={goToCompany}>Cancel</button>
                <button
                  className="btn btn-dark mt-3 w-60 ms-3"
                  type="submit"
                  disabled={requestLoader}
                  style={{ fontSize: "14px" }}
                >
                  Update Information{" "}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      {imageModal && (
        <ConnectionModal
          title={"Upload image"}
          handleClose={showImageModal}
          ModalComponent={
            <UploadImage
              profile={userProfile}
              setUserProfile={setUserProfile}
              onProceed={(e: FormEvent) => {
                handleImageUpload(e);
              }}
              requestLoader={requestLoader}
              onClose={showImageModal}
            />
          }
          open={imageModal}
        />
      )}
    </>
  );
}

export default CompanyProfileEdit;
