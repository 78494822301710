import "../../../components/assets/css/dashboard.scoped.css";
import "../../../components/assets/css/style.scoped.css";
import Skeleton from "react-loading-skeleton";
import { FullUserCard } from "../Cards";
import CustomSelect from "../../../components/ui/Select/Select";
import { Input } from "../../../components/ui/Input/Input";
import { UserCard } from "../../../store/AuthContext";

export interface CreateCardEditProp {
  pageLoader: boolean;
  cardProfile: UserCard;
  setCardProfile: any;
  onProceed: any;
}

export const CreatePersonalDetails = ({ pageLoader, cardProfile, setCardProfile, onProceed }: CreateCardEditProp) => {
  const prefix = ["Mr", "Master", "Miss", "Mrs"] as const;
  const suffix = ["Phd", "FCC", "MSc", "Prof"] as const;

  return (
    <>
      <div
        className="sections_pages bostami-page-content-wrap shadow mb-4"
        style={{ display: "block" }}
      >
        <div className="section-wrapper pl-60 pr-60 pt-60">
          <div className="bostami-page-title-wrap mb-15">
            <h1 className="page-title mb-3">{pageLoader ? <Skeleton /> : "Information"}</h1>

            <p>
              {pageLoader ? <Skeleton /> : cardProfile?.about_me}
            </p>
          </div>
        </div>

        <div className="section-wrapper pr-60 pl-60 mb-60">
          <div className="contact-area">
            {pageLoader ? <Skeleton /> : (
              <form className="contact-form">
                <div className="row mb-60">
                  <h4>Personal</h4>
                  <hr />
                  <div className="col-md-4 mb-2">
                    {/* <CustomSelect
                      label="Prefix/Title"
                      id="prefix"
                      value={cardProfile?.prefix}
                      onChange={(e) => { setCardProfile({ ...cardProfile, prefix: e }) }}
                      options={prefix}
                    /> */}
                    <Input
                      label="Prefix/Title"
                      type="text"
                      name="prefix"
                      id="prefix"
                      placeholder="Enter your prefix"
                      required={false}
                      defaultValue={cardProfile.prefix}
                      value={cardProfile.prefix}
                      onChange={(e) => setCardProfile({ ...cardProfile, prefix: e?.target?.value })}
                    />
                  </div>

                  <div className="col-md-4 mb-2">
                    {/* <CustomSelect
                      label="Suffix"
                      id="suffix"
                      value={cardProfile?.suffix}
                      onChange={(e) => { setCardProfile({ ...cardProfile, suffix: e }) }}
                      options={suffix}
                    /> */}
                    <Input
                      label="Suffix"
                      type="text"
                      name="suffix"
                      id="suffix"
                      placeholder="Enter your suffix"
                      required={false}
                      defaultValue={cardProfile.suffix}
                      value={cardProfile.suffix}
                      onChange={(e) => setCardProfile({ ...cardProfile, suffix: e?.target?.value })}
                    />
                  </div>

                  <div className="col-md-4 mb-2">
                    <Input
                      label="Profession"
                      type="text"
                      name="professional_field_id"
                      id="profField"
                      placeholder="Enter profession"
                      required={false}
                      defaultValue={cardProfile.professional_field_id}
                      value={cardProfile.professional_field_id}
                      onChange={(e) => setCardProfile({ ...cardProfile, professional_field_id: e?.target?.value })}
                    />
                  </div>

                  <div className="col-md-6 mb-2">
                    <Input
                      label="First name"
                      type="text"
                      name="first_name"
                      id="designation"
                      placeholder="Enter first name"
                      required={false}
                      defaultValue={cardProfile.first_name}
                      value={cardProfile.first_name}
                      onChange={(e) => setCardProfile({ ...cardProfile, first_name: e?.target?.value })}
                    />
                  </div>

                  <div className="col-md-6 mb-2">
                    <Input
                      label="Last name"
                      type="text"
                      name="last_name"
                      id="designation"
                      placeholder="Enter last name"
                      required={false}
                      defaultValue={cardProfile.last_name}
                      value={cardProfile.last_name}
                      onChange={(e) => setCardProfile({ ...cardProfile, last_name: e?.target?.value })}
                    />
                  </div>

                  <div className="col-md-6 mb-3">
                    <Input
                      label="Other name"
                      type="text"
                      name="other_name"
                      id="designation"
                      placeholder="Enter other names"
                      required={false}
                      defaultValue={cardProfile.other_name}
                      value={cardProfile.other_name}
                      onChange={(e) => setCardProfile({ ...cardProfile, other_name: e?.target?.value })}
                    />
                  </div>

                  {cardProfile?.email && (
                    <div className="col-md-6 mb-3">
                      <Input
                        label="Email"
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Enter email"
                        required={false}
                        defaultValue={cardProfile.email}
                        value={cardProfile.email}
                        readonly={true}
                        onChange={(e) => setCardProfile({ ...cardProfile, email: e?.target?.value })}
                      />
                    </div>
                  )}

                  <div className="col-md-6 mb-3">
                    <Input
                      label="Phone number"
                      type="tel"
                      name="phone_number"
                      id="phone_number"
                      placeholder="Enter phone number"
                      required={false}
                      defaultValue={cardProfile.phone_number}
                      value={cardProfile.phone_number}
                      onChange={(e) => setCardProfile({ ...cardProfile, phone_number: e?.target?.value })}
                    />
                  </div>

                  <div className="form-input-item mb-40">
                    <label className="input-lebel message">About me*</label>
                    <textarea
                      name="message"
                      className="input-box message false "
                      style={{ height: "100px" }}
                      cols={0}
                      rows={19}
                    ></textarea>
                  </div>

                  <h4 className="mt-4">Business</h4>
                  <hr />

                  <div className="col-md-6 mb-2">
                    <Input
                      label="Busines name"
                      type="text"
                      name="business_name"
                      id="business_name"
                      placeholder="Enter business name"
                      required={false}
                      defaultValue={cardProfile.business_name}
                      value={cardProfile.business_name}
                      onChange={(e) => setCardProfile({ ...cardProfile, business_name: e?.target?.value })}
                    />
                  </div>
                  <div className="col-md-6 mb-2">
                    <Input
                      label="Title"
                      type="text"
                      name="designation"
                      id="designation"
                      placeholder="Enter designation"
                      required={false}
                      defaultValue={cardProfile.designation}
                      value={cardProfile.designation}
                      onChange={(e) => setCardProfile({ ...cardProfile, designation: e?.target?.value })}
                    />
                  </div>
                  <div className="col-md-6 mb-2">
                    <Input
                      label="Address"
                      type="text"
                      name="address"
                      id="address"
                      placeholder="Enter address"
                      required={false}
                      defaultValue={cardProfile.address}
                      value={cardProfile.address}
                      onChange={(e) => setCardProfile({ ...cardProfile, address: e?.target?.value })}
                    />
                  </div>
                  <div className="col-md-6 mb-2">
                    <Input
                      label="Image"
                      type="url"
                      name="photograph"
                      id="photograph"
                      placeholder="Enter image url"
                      required={false}
                      defaultValue={cardProfile.photograph}
                      value={cardProfile.photograph}
                      onChange={(e) => setCardProfile({ ...cardProfile, photograph: e?.target?.value })}
                    />
                  </div>
                  <div className="col-md-6 mb-2">
                    <div className="form-check form-check-sm mt-4">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={cardProfile?.is_default_card}
                        id="norif"
                        onChange={(e) => setCardProfile({ ...cardProfile, is_default_card: e?.target?.checked })}
                      />
                      <label className="form-check-label" htmlFor="norif">
                        {" "}
                        Use this card as my default card{" "}
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6 mb-2">
                    <div className="form-check form-check-sm mt-4">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={cardProfile?.private_mode}
                        id="private_mode"
                        onChange={(e) => setCardProfile({ ...cardProfile, private_mode: e?.target?.checked })}
                      />
                      <label className="form-check-label" htmlFor="norif">
                        {" "}
                        Set my card to private{" "}
                      </label>
                    </div>
                  </div>
                </div>

                <div className="form-btn-wrap">
                  <button
                    className="btn btn-dark mt-3 w-100"
                    type="submit"
                    style={{ fontSize: "14px" }}
                    onClick={onProceed}
                  >
                    Next{" "}
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
