import dashboard from "../../components/assets/css/dashboard.module.css";
import styles from "../../components/assets/css/style.module.css";
import Avatar from "../../components/assets/img/logo_2.png";
import useScript from "../../pages/hooks/useScript";
import { FormEvent, useCallback, useEffect, useState } from "react";
import { getData, postData, putData } from "../../helpers/request";
import { useParams } from "react-router-dom";
import { Connections } from "./Connections";
import PageLoader from "../../components/ui/PageLoader";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { FullUserCard } from "./Cards";
import { UserTemplate } from "./Favorites";
import { CardPersonalDetails } from "./Component/PersonalDetails";
import CardSocialDetails from "./Component/SocialDetails";
import CardPrivacy from "./Component/CardPrivacy";
import { toast } from "react-toastify";
import { CardTemplateDesigns } from "./Component/CardTemplateDesigns";
import BackgroundImage from "../../components/assets/img/page-bg_2.jpeg";

function CardEdit() {
  useScript(
    "https://res.cloudinary.com/dovtransd/raw/upload/v1694620004/fx5xdbukdszs8vld93ar.js",
    "html",
    false
  );
  const { id } = useParams();
  const [cardProfile, setCardProfile] = useState<FullUserCard>(
    {} as FullUserCard
  );
  // const [pageLoader, setPageLoader] = useState(false);
  const [pageLoader, setRequestLoader] = useState(false);
  const [stepper, setStepper] = useState(1);
  const [professionalFields, setProfFields] = useState<Array<any>>([]);
  const pattern = new RegExp("^(https?|ftp)://");

  useEffect(() => {
    getProfile();
    getProfessionalField();
  }, []);

  const getProfile = useCallback(async () => {
    setRequestLoader(true);
    const res = await getData(`/card/${id?.toString()}`);
    if (res) {
      setCardProfile({ ...res });
      setRequestLoader(false);
    }
  }, []);

  const getProfessionalField = useCallback(async () => {
    const res = await getData(`/utility/professionalfield?page=1&size=20`);
    if (res) {
      setProfFields([...res?.data]);
    }
  }, []);

  const onHandle = async (e: FormEvent) => {
    e.preventDefault();
    delete cardProfile["template"];
    setRequestLoader(true);
    const res = await putData("/card", cardProfile);

    if (res) {
      setRequestLoader(false);
      toast.success(res?.message, { closeOnClick: true });
      getProfile();
    }
  };

  const twitterUrl = (username: string = "") => {
    return `https://twitter.com/${username}`;
  };

  const instagramUrl = (username: string = "") => {
    return `https://www.instagram.com/${username}`;
  };

  return (
    <>
      <div>
        <div className={dashboard.row}>
          <div
            className={[dashboard["col-xxl-3"], dashboard["col-xl-3"]].join(
              " "
            )}
          >
            <div
              className={[
                styles["bostami-parsonal-info-area"],
                dashboard["shadow"],
              ].join(" ")}
            >
              <div className={[styles["bostami-parsonal-info-wrap"]].join(" ")}>
                <div className={styles["bostami-parsonal-info-img"]}>
                  {pageLoader ? (
                    <Skeleton
                      circle
                      height="100%"
                      containerClassName="avatar-skeleton"
                    />
                  ) : (
                    <img
                      alt="avatar"
                      loading="lazy"
                      width="240"
                      height="240"
                      src={
                        cardProfile?.template?.image
                          ? cardProfile?.template?.image
                          : BackgroundImage
                      }
                    />
                  )}
                </div>

                <div
                  className={[
                    dashboard["row"],
                    dashboard["justify-content-center"],
                  ].join(" ")}
                >
                  <div
                    className={[
                      dashboard["mt-n4"],
                      dashboard["mt-lg-n6"],
                      dashboard["mb-4"],
                      dashboard["mb-lg-0"],
                    ].join(" ")}
                  >
                    <section
                      className={[
                        dashboard.container,
                        dashboard["text-center"],
                        dashboard["m-auto"],
                        dashboard["position-relative"],
                      ].join(" ")}
                    >
                      <form>
                        {pageLoader ? (
                          <Skeleton
                            circle
                            height="100%"
                            containerClassName="avatar-skeleton"
                          />
                        ) : (
                          <div
                            className={[
                              dashboard["mt-n4"],
                              dashboard["mt-lg-n6"],
                              dashboard["text-center"],
                              dashboard["mb-lg-0"],
                              dashboard["m-auto"],
                            ].join(" ")}
                          >
                            {cardProfile?.photograph ? (
                              <img
                                src={cardProfile?.photograph}
                                alt="placeholder"
                                className={[
                                  dashboard["upload_image"],
                                  dashboard["img-fluid"],
                                  dashboard["border"],
                                  dashboard["border-2"],
                                  dashboard["border-white"],
                                ].join(" ")}
                                style={{
                                  width: "108px",
                                  height: "108px",
                                  objectFit: "cover",
                                }}
                              />
                            ) : (
                              <img
                                src={Avatar}
                                alt="placeholder"
                                className={[
                                  dashboard["upload_image"],
                                  dashboard["img-fluid"],
                                  dashboard["border"],
                                  dashboard["border-2"],
                                  dashboard["border-white"],
                                ].join(" ")}
                              />
                            )}
                          </div>
                        )}
                      </form>
                    </section>
                  </div>
                </div>

                <h4 className={styles["bostami-parsonal-info-name"]}>
                  {pageLoader ? (
                    <Skeleton />
                  ) : (
                    <a href="#">
                      {cardProfile?.prefix && (
                        <span>{cardProfile?.prefix}</span>
                      )}{" "}
                      {pageLoader ? (
                        <Skeleton />
                      ) : (
                        `${cardProfile?.first_name} ${
                          cardProfile?.other_name !== null
                            ? cardProfile?.other_name
                            : ""
                        } ${cardProfile?.last_name}`
                      )}{" "}
                      {cardProfile?.suffix && (
                        <span>{cardProfile?.suffix}</span>
                      )}
                      {/* <span style={{ color: pageLoader ? "" : "#1773EA" }}>
                      {pageLoader ? <Skeleton /> : (<i className="ri-verified-badge-fill"></i>)}
                    </span>{" "} */}
                    </a>
                  )}
                </h4>

                <h4
                  className={[dashboard["mt-0"], dashboard["pt-0"]].join(" ")}
                >
                  {pageLoader ? <Skeleton /> : cardProfile?.business_name}
                </h4>

                <span
                  className={[
                    styles["bostami-parsonal-info-bio"],
                    dashboard["mb-15"],
                  ].join(" ")}
                >
                  {pageLoader ? <Skeleton /> : cardProfile?.designation}
                </span>

                <ul
                  className={[
                    styles["bostami-parsonal-info-social-link"],
                    dashboard["mb-15"],
                  ].join(" ")}
                >
                  {pageLoader ? (
                    <Skeleton />
                  ) : (
                    <li>
                      {cardProfile?.facebook && (
                        <a
                          style={{ color: "#1773EA" }}
                          href={cardProfile?.facebook}
                          target="_blank"
                        >
                          <i className="fa-brands ri-facebook-fill"></i>
                        </a>
                      )}
                    </li>
                  )}
                  {pageLoader ? (
                    <Skeleton />
                  ) : (
                    <li>
                      {cardProfile?.twitter && (
                        <a
                          style={{ color: "#1C9CEA" }}
                          href={
                            pattern.test(cardProfile?.twitter)
                              ? cardProfile?.twitter
                              : twitterUrl(cardProfile?.twitter)
                          }
                          target="_blank"
                        >
                          <i className="fa-brands ri-twitter-fill"></i>
                        </a>
                      )}
                    </li>
                  )}
                  {pageLoader ? (
                    <Skeleton />
                  ) : (
                    <li>
                      {cardProfile?.instagram && (
                        <a
                          style={{ color: "#e12a72" }}
                          href={
                            pattern.test(cardProfile?.instagram)
                              ? cardProfile?.instagram
                              : instagramUrl(cardProfile?.instagram)
                          }
                          target="_blank"
                        >
                          <i className="fa-brands ri-instagram-fill"></i>
                        </a>
                      )}
                    </li>
                  )}
                  {pageLoader ? (
                    <Skeleton />
                  ) : (
                    <li>
                      {cardProfile?.linkedin && (
                        <a
                          style={{ color: "#144679" }}
                          href={cardProfile?.linkedin}
                          target="_blank"
                        >
                          <i className="fa-brands ri-linkedin-fill"></i>
                        </a>
                      )}
                    </li>
                  )}
                </ul>

                <div
                  className={[
                    styles["bostami-parsonal-info-contact"],
                    dashboard["mb-30"],
                  ].join(" ")}
                >
                  <div className={styles["bostami-parsonal-info-contact-item"]}>
                    {pageLoader ? (
                      <Skeleton />
                    ) : (
                      <>
                        {cardProfile?.phone_number && (
                          <div
                            className={
                              styles["bostami-parsonal-info-contact-item"]
                            }
                          >
                            <a
                              href={`tel: ${cardProfile?.phone_number}`}
                              target="_blank"
                              style={{ color: "#E93B81", fontSize: "22px" }}
                              className={styles.icon}
                            >
                              <i className="ri-phone-line"></i>
                            </a>
                            <div className={styles.text}>
                              <span>Phone</span>
                              <p>{cardProfile?.phone_number}</p>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>

                  {pageLoader ? (
                    <Skeleton />
                  ) : (
                    <>
                      {cardProfile?.email && (
                        <div
                          className={
                            styles["bostami-parsonal-info-contact-item"]
                          }
                        >
                          <a
                            href={`mailto: ${cardProfile?.email}`}
                            target="_blank"
                            style={{ color: "#E93B81", fontSize: "22px" }}
                            className={styles.icon}
                          >
                            <i className="ri-mail-line"></i>
                          </a>
                          <div className={styles.text}>
                            <span>Email</span>
                            <p style={{ textTransform: "lowercase" }}>
                              {cardProfile?.email}
                            </p>
                          </div>
                        </div>
                      )}
                    </>
                  )}

                  {pageLoader ? (
                    <Skeleton />
                  ) : (
                    <>
                      {cardProfile?.whatsapp_phone_number && (
                        <div
                          className={
                            styles["bostami-parsonal-info-contact-item"]
                          }
                        >
                          <a
                            href={`https://wa.me/${cardProfile?.whatsapp_country_code}${cardProfile?.whatsapp_phone_number}`}
                            target="_blank"
                            style={{ color: "#E93B81", fontSize: "22px" }}
                            className={styles.icon}
                          >
                            <i className="ri-whatsapp-fill"></i>
                          </a>
                          <div className={styles.text}>
                            <span>Whatsapp</span>
                            <p style={{ textTransform: "lowercase" }}>
                              {cardProfile?.whatsapp_phone_number}
                            </p>
                          </div>
                        </div>
                      )}
                    </>
                  )}

                  {pageLoader ? (
                    <Skeleton />
                  ) : (
                    <>
                      {cardProfile?.website && (
                        <div
                          className={
                            styles["bostami-parsonal-info-contact-item"]
                          }
                        >
                          <a
                            href={`${cardProfile?.website}`}
                            target="_blank"
                            style={{ color: "#E93B81", fontSize: "22px" }}
                            className={styles.icon}
                          >
                            <i className="ri-ie-fill"></i>
                          </a>
                          <div className={styles.text}>
                            <span>Website</span>
                            <p style={{ textTransform: "lowercase" }}>
                              {cardProfile?.website}
                            </p>
                          </div>
                        </div>
                      )}
                    </>
                  )}

                  {pageLoader ? (
                    <Skeleton />
                  ) : (
                    <>
                      {cardProfile?.address && (
                        <div
                          className={
                            styles["bostami-parsonal-info-contact-item"]
                          }
                        >
                          <div
                            style={{ color: "#E93B81", fontSize: "22px" }}
                            className={styles.icon}
                          >
                            <i className="ri-map-pin-line"></i>
                          </div>
                          <div className={styles.text}>
                            <span>Location</span>
                            <p style={{ textTransform: "lowercase" }}>
                              {cardProfile?.address}
                            </p>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Mobile stepper */}
          <div className="col-xxl-1 col-xl-1 show-mobileStepper mb-2">
            <div className="bostami-main-menu-wrap shadow">
              <nav className="bastami-main-menu main_menu">
                {pageLoader ? (
                  <Skeleton />
                ) : (
                  <ul
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                    }}
                  >
                    <li
                      className={`menu-item profile_bar ${
                        stepper === 1 ? "active" : ""
                      }`}
                      data-target="about"
                    >
                      <a href="#" onClick={() => setStepper(1)}>
                        <span>
                          <i
                            style={{ fontSize: "25px" }}
                            className="ri-user-line"
                          ></i>
                        </span>
                        Information
                      </a>
                    </li>
                    <li
                      className={`menu-item profile_bar ${
                        stepper === 2 ? "active" : ""
                      }`}
                    >
                      <a href="#" onClick={() => setStepper(2)}>
                        <span>
                          <i
                            style={{ fontSize: "25px" }}
                            className="ri-file-text-line"
                          ></i>
                        </span>
                        Socials
                      </a>
                    </li>
                    <li
                      className={`menu-item profile_bar ${
                        stepper === 3 ? "active" : ""
                      }`}
                    >
                      <a href="#" onClick={() => setStepper(3)}>
                        <span>
                          <em
                            style={{ fontSize: "25px" }}
                            className="icon ri-profile-line"
                          ></em>
                        </span>
                        Card design
                      </a>
                    </li>
                  </ul>
                )}
              </nav>
            </div>
          </div>

          <div className="col-xxl-8 col-xl-8">
            {stepper === 1 && (
              /* <!-- PERSONAL SECTION --> */
              <CardPersonalDetails
                pageLoader={pageLoader}
                cardProfile={cardProfile}
                setCardProfile={setCardProfile}
                professionalFields={professionalFields}
                onProceed={(e: FormEvent) => {
                  onHandle(e);
                }}
              />
            )}

            {stepper === 2 && (
              /* <!-- SOCIAL SECTION --> */
              <CardSocialDetails
                pageLoader={pageLoader}
                cardProfile={cardProfile}
                setCardProfile={setCardProfile}
                onProceed={(e: FormEvent) => {
                  onHandle(e);
                }}
              />
            )}

            {stepper === 3 && (
              /* <!-- PERSONAL SECTION --> */
              <CardTemplateDesigns
                pageLoader={pageLoader}
                cardProfile={cardProfile}
                setCardProfile={setCardProfile}
                professionalFields={professionalFields}
                onProceed={(e: FormEvent) => {
                  onHandle(e);
                }}
              />
            )}
          </div>

          <div
            className={[
              dashboard["col-xxl-1"],
              dashboard["col-xl-1"],
              dashboard["d-xl-block"],
              dashboard["d-none"],
            ].join(" ")}
          >
            <div className={[styles['bostami-main-menu-wrap'], dashboard['shadow']].join(' ')}>
              <nav className="bastami-main-menu main_menu">
                {pageLoader ? (
                  <Skeleton />
                ) : (
                  <ul>
                    <li
                      className={`menu-item profile_bar ${
                        stepper === 1 ? "active" : ""
                      }`}
                      data-target="about"
                    >
                      <a href="#" onClick={() => setStepper(1)}>
                        <span>
                          <i
                            style={{ fontSize: "25px" }}
                            className="ri-user-line"
                          ></i>
                        </span>
                        Information
                      </a>
                    </li>
                    <li
                      className={`menu-item profile_bar ${
                        stepper === 2 ? "active" : ""
                      }`}
                    >
                      <a href="#" onClick={() => setStepper(2)}>
                        <span>
                          <i
                            style={{ fontSize: "25px" }}
                            className="ri-file-text-line"
                          ></i>
                        </span>
                        Socials
                      </a>
                    </li>
                    <li
                      className={`menu-item profile_bar ${
                        stepper === 3 ? "active" : ""
                      }`}
                    >
                      <a href="#" onClick={() => setStepper(3)}>
                        <span>
                          <em
                            style={{ fontSize: "25px" }}
                            className="icon ri-profile-line"
                          ></em>
                        </span>
                        Card design
                      </a>
                    </li>
                  </ul>
                )}
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CardEdit;
