import "../../../../components/assets/css/dashboard.scoped.css";
import "../../../../components/assets/css/style.scoped.css";
import Skeleton from "react-loading-skeleton";
import { getData, postFormData } from "../../../../helpers/request";
import { useCallback, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import type { SwiperSlideProps, SwiperProps } from 'swiper/react';
import { register } from 'swiper/element/bundle';
import { FullUserCard } from "../../../Dashboard/Cards";
// register Swiper custom elements
register();

export interface CardEditProp {
  pageLoader: boolean;
  cardProfile: FullUserCard;
  setCardProfile: any;
  onProceed: any;
  professionalFields?: Array<any>;
}

export interface Templates {
  id: string;
  owner?: string;
  owner_id?: string;
  image: string;
  created_by?: string;
  modified_by?: string;
  createdAt?: string;
  updateAt?: string;
}

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "swiper-container": React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement> & SwiperProps,
        HTMLElement
      >;
      "swiper-slide": React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement> & SwiperSlideProps,
        HTMLElement
      >;
    }
  }
}

export const AdminCardTemplateDesign = ({ pageLoader, cardProfile, setCardProfile, onProceed, professionalFields }: CardEditProp) => {
  const [templates, setTemplates] = useState<Array<Templates>>([]);
  const [profileTemplates, setProfileTemplates] = useState<Array<Templates>>([]);
  const [image, setImage] = useState("");
  const [formImage, setFormImage] = useState<any>("");
  const [requestLoader, setRequestLoader] = useState(false);
  const fileRef: any = useRef();
  const swiperElRef: any = useRef(null);

  useEffect(() => {
    getFreeTemplates();
    getProfileTemplates();
  }, [])

  const getFreeTemplates = useCallback(async () => {
    const res: any = await getData("/template/freetemplate?page=1&size=100");

    if (res) {
      return setTemplates([...res?.data]);
    }
    return setTemplates([]);
  }, []);

  const getProfileTemplates = useCallback(async () => {
    const res: any = await getData("/companyadmintemplate?page=1&size=10");

    if (res) {
      return setProfileTemplates([...res?.data]);
    }
    return setProfileTemplates([]);
  }, []);

  const convertToBase64 = (file: any) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader?.readAsDataURL(file);
      reader.onload = () => resolve(reader?.result);
      reader.onerror = (error) => reject(error);
    });

  const openFileSelection = () => {
    fileRef.current?.click();
  }

  const onFileChange = async ({ e }: { e: any }) => {
    const { target = {} } = e || {};

    setFormImage(e.target.files[0]);

    const fileBase64: any = await convertToBase64(e.target.files[0]);

    setImage(fileBase64)
    target.value = "";
  };

  const uploadTemplate = async () => {
    setRequestLoader(true);
    let formData = new FormData();
    formData.append("template", formImage);
    const res = await postFormData('/companyadmintemplate', formData);

    if (res?.isSucess) {
      setRequestLoader(false);
      toast.success(res?.message);
      getFreeTemplates();
      getProfileTemplates();
      return;
    }

    setRequestLoader(false);
    return toast.error(res?.message);
  };

  return (
    <>
      <div
        className="sections_pages bostami-page-content-wrap shadow mb-4"
        style={{ display: "block" }}
      >
        <div className="section-wrapper pl-60 pr-60 pt-60">
          <div className="bostami-page-title-wrap mb-15">
            <h1 className="page-title mb-3">{pageLoader ? <Skeleton /> : "Design"}</h1>

            <p>
              {pageLoader ? <Skeleton /> : 'Select from our free templates or upload one'}
            </p>
          </div>
        </div>

        <div className="section-wrapper pr-60 pl-60 mb-60">
          <div className="contact-area" style={{ paddingLeft: "0", paddingRight: "0" }}>
            {pageLoader ? <Skeleton /> : (
              <form className="contact-form" onSubmit={onProceed}>
                <div className="row mb-60">
                  <h4>Free templates</h4>
                  <hr />

                  {templates.length > 0 ? (
                    <>
                      <div className="row mb-40">
                        {templates.map((item: Templates, index: number) => {
                          return (
                            <div key={index} className="col-md-4 mb-4" style={{ cursor: "pointer" }}>
                              <input
                                className="template-booking"
                                type="radio"
                                name="template_id"
                                id={item?.id}
                                value={item?.id}
                                onChange={(e) => setCardProfile({ ...cardProfile, template_id: e?.target?.value })}
                              />
                              <label className="for-checkbox-booking" htmlFor={item?.id}>
                                <div
                                  className="card connection_card p-4"
                                  style={{
                                    backgroundImage: `url(${item?.image})`,
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat',
                                    minHeight: "200px",
                                  }}
                                  key={index}
                                >
                                </div>
                              </label>
                            </div>
                          )
                        }
                        )}
                      </div>
                    </>
                  ) : (
                    <>
                      <Skeleton height={200} />
                    </>
                  )}

                  <h4>Company templates</h4>
                  <hr />

                  {profileTemplates.length > 0 ? (
                    <>
                      <div className="row mb-40">
                        <swiper-container
                          ref={swiperElRef}
                          slides-per-view="2"
                          navigation={true}
                          pagination={true}
                          scrollbar={false}
                        >
                          {profileTemplates.map((item: Templates, index: number) => {
                            return (
                              <swiper-slide key={index} className="col-md-6 mb-4">
                                <div key={index} style={{ cursor: "pointer" }}>
                                  <input
                                    className="template-booking"
                                    type="radio"
                                    name="template_id"
                                    id={item?.id}
                                    value={item?.id}
                                    onChange={(e) => setCardProfile({ ...cardProfile, template_id: e?.target?.value })}
                                  />
                                  <label className="for-checkbox-booking" htmlFor={item?.id}>
                                    <div
                                      className="card connection_card p-4"
                                      style={{
                                        backgroundImage: `url(${item?.image})`,
                                        backgroundSize: 'cover',
                                        backgroundRepeat: 'no-repeat',
                                        minHeight: "200px",
                                      }}
                                      key={index}
                                    >
                                    </div>
                                  </label>
                                </div>
                              </swiper-slide>
                            )
                          }
                          )}
                        </swiper-container>
                      </div>
                    </>
                  ) : (
                    <>
                      <Skeleton
                        circle
                        height="100%"
                        containerClassName="avatar-skeleton"
                      />
                    </>
                  )}

                  <h4 className="mt-4">Upload your own</h4>
                  <hr />

                  <div className="col-md-6 mb-2">
                    <button
                      type="button"
                      className="template--button"
                      onClick={openFileSelection}
                    >
                      <svg viewBox="0 0 24 24" focusable="false" className="template--button-icon css-1i6tzi4">
                        <path d="M19 6V18H11V20H19C20.103 20 21 19.103 21 18V6C21 4.897 20.103 4 19 4L6 4C4.897 4 4 4.897 4 6V13H6L6 6L19 6Z"></path>
                        <path d="M10.4545 11.6667L8 15H17L13.7273 10L11.2727 13.3333L10.4545 11.6667Z"></path>
                        <path d="M6.75 15H5.25V17.25H3V18.75H5.25V21H6.75V18.75H9V17.25H6.75V15Z"></path>
                      </svg>
                      Upload Image
                      <input
                        accept="image/png, image/jpeg, image/jpg"
                        type="file"
                        style={{ visibility: "hidden" }}
                        ref={fileRef}
                        onChange={(e) => {
                          onFileChange({
                            e,
                          });
                        }}
                      />
                    </button>
                  </div>

                  {image && (<div className="col-md-6 mb-2">
                    <div
                      className="card connection_card p-4"
                      style={{
                        backgroundImage: `url(${image})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        minHeight: "200px",
                      }}
                    >
                    </div>
                  </div>)}

                  <div className="col-md-6 mb-2">
                    <button
                      className="btn btn-dark mt-3 w-100"
                      type="button"
                      style={{ fontSize: "14px" }}
                      onClick={uploadTemplate}
                      disabled={requestLoader}
                    >
                      Upload template{" "}
                    </button>
                  </div>
                </div>

                <div className="form-btn-wrap">
                  <button
                    className="btn btn-dark mt-3 w-100"
                    type="submit"
                    style={{ fontSize: "14px" }}
                  >
                    Save template{" "}
                  </button>
                </div>
              </form>
            )}
          </div >
        </div >
      </div >
    </>
  )
}
